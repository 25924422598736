import React from 'react';
import { connect } from 'react-redux';
//import { sidebarActions } from '../../../Redux/actions';
import { Switch, Route } from 'react-router-dom';
import CreateLocation from "./createLocation";
import LocationOverview from "./locationOverview"
import UpdateLocation from "./updateLocation"

class Location extends React.Component {

    getPath = () => ('/location');

    render = () => (
        <Switch>
            <Route exact path={this.getPath() + '/create'} component={CreateLocation} />
            <Route exact path={this.getPath()} component={LocationOverview}/>
            <Route exact path={this.getPath() + '/update'} component={UpdateLocation}/>
        </Switch>
    )
}


export default connect()(Location);
import Axios from "axios";
import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { urlActions, globalToastActions } from "../../Redux/actions";
import { auth } from "../../helperFunctions";

class ContractsResultsDisplay extends Component {
    state = {
        showContextMenu: false,
        deleteBkg: null
    }

    initListeners = () => {
        document.addEventListener("keyup", this.bookingNumberSelect);
    }

    removeListeners = () => {
        document.removeEventListener("keyup", this.bookingNumberSelect);
    }

    handleEdit = async (e, contract) => {

        Axios.get("/api/v1/contract/carriers", { params: { ...auth.getAuthData(), shipperId: contract.shipperId, carrierId: contract.carrierId, contractId: contract.id, commodityId: contract.commodityId, containerTypeId: contract.containerTypeId } }).then(async result => {
            if (result === "NOT AUTHENTICATED") {
                localStorage.clear();
                this.props.dispatch({ type: "SCORCHED_EARTH " });
            }

            let contracts = result.data.length ? result.data : [{ carrierName: "NO OTHER CONTRACTS" }];

            this.props.dispatch({
                type: urlActions.UPDATE_URL, payload: {
                    url: '/contract/edit', state: {
                        origin: "contractOverview", contractSearchTerm: contract.id, shipperId: contract.shipperId, shipperName: contract.shipperName,
                        filters: this.props.filters, status: contract.status, carrierId: contract.carrierId, carrierName: contract.carrierDisplayName, contracts
                    }
                }
            });
        })
    }

    bookingNumberSelect = (e) => {

        if (e.keyCode === 17 && document.getSelection().type === "Range") {
            let highlightedItems = [];
            let nodes = document.querySelectorAll(".bookingNum");
            nodes.forEach(n => {
                let word = n.textContent;
                let regex = new RegExp(word, "gm");
                if (regex.test(document.getSelection().toString())) {
                    highlightedItems.push(n.textContent);
                }
            })
            navigator.clipboard.writeText(highlightedItems.join('\n'));
            this.props.dispatch({ type: globalToastActions.UPDATE_MSG, payload: { msg: "Copied To Clipboard" } });
        }

    }

    refresh = () => {
        if (this.props.loading) {
            return;
        } else {
            this.props.getContracts();
        }
    }
    componentDidMount = () => { this.initListeners(); this.refresh(); }
    componentWillUnmount = () => { this.removeListeners(); }


    render = () => {
        return (
            <Fragment>
                {this.props.loading ?
                    <tr>
                        <td>
                            <div className="preloader-wrapper big active overviewLoader">
                                <div className="spinner-layer spinner-blue-only">
                                    <div className="circle-clipper left">
                                        <div className="circle"></div>
                                    </div><div className="gap-patch">
                                        <div className="circle"></div>
                                    </div><div className="circle-clipper right">
                                        <div className="circle"></div>
                                    </div>
                                </div>
                            </div>
                        </td>
                    </tr>
                    :
                    this.props.contracts.map((c, index) => (
                        <tr className={`${c.expiredContract ? 'red-text' : ''}  resultsDisplay`} id={`bkg-tr-${index}`} key={index} >
                            <td className={`unselectable ${c.status.split(" ").join("")}`} onClick={(e) => this.handleEdit(e, c)}>{c.status}</td>
                            <td className="bookingNum" onClick={(e) => this.handleEdit(e, c)}>{c.shipperName}</td>
                            <td className="unselectable" onClick={(e) => this.handleEdit(e, c)}>{c.carrierName}</td>
                            <td className="refNum unselectable" onClick={(e) => this.handleEdit(e, c)}>{c.contractNumber}</td>
                            <td className="unselectable" onClick={(e) => this.handleEdit(e, c)}>{c.minimumQuantityCommitment}/{c.enteredMQC}</td>
                            <td className="unselectable" onClick={(e) => this.handleEdit(e, c)}>{c.commodity}</td>
                            <td className="unselectable" onClick={(e) => this.handleEdit(e, c)}>{c.effectiveDate}</td>
                            <td className="unselectable" onClick={(e) => this.handleEdit(e, c)}>{c.expirationDate}</td>
                            <td className="unselectable" onClick={(e) => this.handleEdit(e, c)}>{c.contractSalesRep}</td>
                            <td className="unselectable" onClick={(e) => this.handleEdit(e, c)}>{c.contractInsideSales}</td>
                            <td className="unselectable" onClick={(e) => this.handleEdit(e, c)}><i className="material-icons">{c.isSigned && c.isManaged ? "star" : c.isManaged && !c.isSigned ? "star_half" : "star_border"}</i></td>
                        </tr>
                    ))}
            </Fragment>
        )
    }
}


const mapStateToProps = state => {
    const { location, user, urls, index } = state;
    const url = urls[index];
    return { location, user, url }
}

export default connect(mapStateToProps)(ContractsResultsDisplay) 
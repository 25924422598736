import React, { Component } from 'react';
import { connect } from 'react-redux';
import Chart from "chart.js";
let myChart;

class BarChart extends Component {

     destroyChart = () => {
        if (typeof myChart !== "undefined") {
            myChart.destroy();
        }
    }


     setChartSettings = () => {


        let ctx = document.getElementById("myChart");

        if (typeof myChart !== "undefined") {
            myChart.destroy();
        }

        myChart = new Chart(ctx, {
            type: "bar",
            data: {
                labels: this.props.labels,
                datasets: [{
                    label:this.props.dataSetLabels,
                    data: this.props.dataSet,
                    backgroundColor: [
                        ...new Array(this.props.dataSet.length).fill('#33679e')
                    ],
                    borderColor: [
                        '#33679e'
                    ],
                    borderWidth: 1
                }]
            },
            options: {
                events: false,
                tooltips: {
                    enabled: false
                },
                hover: {
                    animationDuration: 0
                },
                showInlineValues: true,
                animation: {
                    onComplete: () => {

                        let chartInstance = myChart,
                            ctx = chartInstance.ctx;
                        ctx.font = Chart.helpers.fontString(Chart.defaults.global.defaultFontSize, Chart.defaults.global.defaultFontStyle, Chart.defaults.global.defaultFontFamily);
                        ctx.textAlign = 'center';
                        ctx.textBaseline = 'bottom';

                        myChart.data.datasets.forEach(function (dataset, i) {
                            var meta = chartInstance.controller.getDatasetMeta(i);
                            meta.data.forEach(function (bar, index) {
                                var data = dataset.data[index];
                                ctx.fillText(data, bar._model.x, bar._model.y - 5);
                            });
                        });
                    }
                },
                scales: {
                    Yaxes: [{
                        ticks: {
                            stepValue: 1,
                            suggestedMin: 0,
                            suggestedMax: 1,
                            beginAtZero: true
                        }
                    }],
                }
            }
        })
    }

    componentDidUpdate = (prevProps) => {
        if(this.props.showGraph){
            this.setChartSettings();
        } else if (this.props.showGraph !== prevProps.showGraph && !this.props.showGraph){
            this.destroyChart();
        }
    }

    render = () => (
        <div>
            <canvas id="myChart" width="1000px" height="200px"></canvas>
        </div>
    )
}

const mapStateToProps = state => {
    const { urls, index } = state;
    const url = urls[index];
    return { url }
}

export default connect(mapStateToProps)(BarChart)
import React, { Fragment } from "react";

const TableHeader = (props) => {
    return (
        <Fragment>
            <thead className="lf-portal-thead">
                <tr className={props.trClassName}>
                    {props.headers.map((l, i) => (
                        <th key={i} data-sort={l.attribute} onClick={e => props.sort(e)} className={l.className}>{l.label} &nbsp;
                            <span data-sort={l.attribute} onClick={e => props.sort(e)}>{props.filters.currentSort === l.attribute && l.attribute ? props.filters.reverse === 1 ? `⮝` : `⮟` : ""}</span>
                            <span data-sort={l.attribute} onClick={e => props.sort(e)} style={{ color: "lightblue" }}>{props.filters.currentSort2 === l.attribute && l.attribute ? props.filters.reverse2 === 1 ? `⮝` : `⮟` : ""}</span>
                            <span data-sort={l.attribute} onClick={e => props.sort(e)} style={{ color: "lightcoral" }}>{props.filters.currentSort3 === l.attribute && l.attribute ? props.filters.reverse3 === 1 ? `⮝` : `⮟` : ""}</span>
                        </th>
                    ))}
                </tr>
            </thead>
        </Fragment>
    )
}

export default TableHeader;
import React from 'react';

class Radio extends React.Component {

    onChange = e => this.props.onChange(this.props.name, this.props.value);

    render = () => (
        <p>
            <label>
                <input name={this.props.name} type="radio" className="with-gap" onChange={this.onChange} checked={this.props.checked} />
                <span>{this.props.label}</span>
            </label>
        </p>
    )
}

export default Radio;
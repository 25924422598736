import React from 'react';
import { connect } from 'react-redux';
import { Switch, Route } from 'react-router-dom';
import CarrierOverview from './CarrierOverview';
import Alliance from './Alliance/Alliance';
import Vessel from './Vessel/Vessel';
import ServiceRoute from './ServiceRoute/ServiceRoute';
import UpdateCarrier from "./UpdateCarrier";

class Carriers extends React.Component {

    getPath = () => ('/carrier');

    render = () => (
        <Switch>
            <Route exact path={this.getPath()} component={CarrierOverview} />
            <Route exact path={this.getPath() + '/create'} component={UpdateCarrier} />
            <Route exact path={this.getPath() + "/create/:id"} component={UpdateCarrier} />
            <Route path={this.getPath() + '/alliance'} component={Alliance} />
            <Route path={this.getPath() + '/vessel'} component={Vessel} />
            <Route path={this.getPath() + '/serviceroute'} component={ServiceRoute} />
        </Switch>
    )
}


export default connect()(Carriers);
import React from 'react';
import AutoSuggest from 'react-autosuggest';
import styles from './emailautocomplete.module.css';
import { types } from '../../constants';
import { inputValidation } from '../../helperFunctions';

class EmailAutoComplete extends React.Component {
    constructor(props) { super(props); this.state = this.initState(); }

    initState = () => ({ suggestions: [], searchTerm: '', dataInstance: null, err: false, new: false });

    initListener = () => {
        document.getElementById(this.props.name).addEventListener("keydown", this.backspaceListener);
        document.getElementById(this.props.name).addEventListener("keydown", this.enterListener);
        document.getElementById(this.props.name).addEventListener("paste", this.pasteEmailFormat);
    }

    removeListener = () => {
        document.getElementById(this.props.name).removeEventListener("keydown", this.backspaceListener);
        document.getElementById(this.props.name).removeEventListener("keydown", this.enterListener);
        document.getElementById(this.props.name).removeEventListener("paste", this.pasteEmailFormat);
    }

    backspaceListener = (e) => {
        if (!this.props.value && e.target.id === this.props.name && e.keyCode === 8) {
            let newValueList = this.props.valueList;
            newValueList.splice(newValueList.length - 1, 1);
            this.props.onChange(`${this.props.name}List`, newValueList)
        }
    }

    enterListener = (e) => {
        if ((e.key === "Enter" || e.key === "Tab" || e.keyCode === 32) && !this.getSuggestions(this.props.value).length && e.target.id === this.props.name && this.props.value) {
            e.preventDefault()

            let validEmailRegExp = new RegExp(/^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/);

            let customEmail = {
                email: this.props.value,
                name: this.props.value,
                invalidEmail: validEmailRegExp.test(this.props.value) ? false : true
            }
            let newValueList = this.props.valueList;
            newValueList.push(customEmail);
            this.props.onChange(`${this.props.name}List`, newValueList)
            this.props.onChange(this.props.name, "");
            return;
        }
    }

    pasteEmailFormat = (e) => {
        if (e.clipboardData || window.clipboardData) {
            e.preventDefault();
            let validEmailRegExp = new RegExp(/^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/);
            if (e.clipboardData) {
                if (!e.clipboardData.getData('text')) { return; }
                let newValueList = this.props.valueList;
                let pasteArr = e.clipboardData.getData("text").split(";");
                pasteArr.forEach(p => {
                    if (!!p.trim()) {
                        newValueList.push({
                            name: p.trim(),
                            email: p.trim(),
                            invalidEmail: validEmailRegExp.test(p.trim()) ? false : true
                        });
                    }
                })
                this.props.onChange(`${this.props.name}List`, newValueList);
                return
            } else if (window.clipboardData) {
                if (!window.clipboardData.getData('text')) { return; }
                let newValueList = this.props.valueList;
                let pasteArr = window.clipboardData.getData("text").split(";");
                if (!pasteArr) { return; }
                pasteArr.forEach(p => {
                    if (!!p.trim()) {
                        newValueList.push({
                            name: p.trim(),
                            email: p.trim(),
                            invalidEmail: validEmailRegExp.test(p.trim()) ? false : true
                        });
                    }
                })
                this.props.onChange(`${this.props.name}List`, newValueList);
                return;
            }
        }
    }

    getSuggestions = searchTerm => {
        const data = JSON.parse(JSON.stringify(this.props.data));
        return this.filterData(searchTerm, data);
    }

    filterData = (searchTerm, data) => {
        const inputValue = searchTerm.trim().toLowerCase();
        const inputLength = searchTerm.length;

        if (inputLength < 1)
            return [];

        const arr = data.filter(obj => obj['email'].toLowerCase().slice(0, inputLength) === inputValue || obj['name'].toLowerCase().slice(0, inputLength) === inputValue);
        return arr;

    }

    getSuggestionValue = suggestion => suggestion['email'];
    renderSuggestion = suggestion => (<div className={styles.suggestion}>{suggestion['name']}<br />{suggestion['email']}</div>)
    handleChange = (e, { newValue }) => {
        const eventType = e.type;
        const eventKey = e.key;

        let newValueAsArray = this.props.value.split("; ");
        let newValueList = this.props.valueList;

        newValueAsArray.pop();

        const selected = this.props.data.filter(v => v['email'] === newValue || v['name'])[0];

        if (selected) {
            if (eventKey === "ArrowDown" || eventKey === "ArrowUp") {
                return;

            } else if (eventType === 'click' || eventKey === 'Enter') {

                newValueList.push(this.props.data.filter(d => d['email'] === newValue || d['name'] === newValue)[0]);

                this.props.onChange(`${this.props.name}List`, newValueList)
                this.props.onChange(this.props.name, "");

                return;
            }
        }
        this.props.onChange(this.props.name, newValue);
    }

    handleBlur = async (event, suggestion) => {

        let newValueList = this.props.valueList;
        const obj = suggestion.highlightedSuggestion;

        if (obj) {
            event.preventDefault();
            document.getElementById(this.props.name).focus();
            newValueList.push(obj);
            this.props.onChange(`${this.props.name}List`, newValueList);
            this.props.onChange(this.props.name, "");
            return;
        } else if (this.props.value) {
            let validEmailRegExp = new RegExp(/^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/);

            let customEmail = {
                email: this.props.value,
                name: this.props.value,
                invalidEmail: validEmailRegExp.test(this.props.value) ? false : true
            }
            let newValueList = this.props.valueList;
            newValueList.push(customEmail);
            this.props.onChange(`${this.props.name}List`, newValueList)
            this.props.onChange(this.props.name, "");
            return;
        }
    }

    removeBubble = (index) => {
        let newValueList = this.props.valueList;
        newValueList.splice(index, 1);
        this.props.onChange(`${this.props.name}List`, newValueList);
        document.getElementById(this.props.name).focus();
    }



    fetchSuggestions = ({ value }) => {
        this.setState({ suggestions: this.getSuggestions(value.split("; ")[value.split("; ").length - 1]) });
    }

    clearSuggestions = () => this.setState({ suggestions: [] });

    getinputProps = () => ({
        value: this.props.value || '',
        onChange: this.handleChange,
        onBlur: this.handleBlur,
        onClick: this.focusHandler,
        id: this.props.name,
        disabled: this.props.disabled,
        placeholder: this.props.placeholder
    })

    getTheme = () => ({
        input: `browser-default ${this.state.err ? styles.err : ''} ${styles.input} `,
        container: styles.container,
        suggestionsContainerOpen: styles.open,
        suggestionsList: styles.list,
        suggestion: styles.item,
        suggestionHighlighted: styles.highlighted
    })

    handleAdd = () => {
        this.props.add();
        this.setState({ new: false })
    }

    verifyInput = () => {
        const arr = [{ name: 'value', type: types.STRING, value: this.props.value, flags: this.props.flags }];
        const success = () => this.setState({ err: false, msg: '' });
        const failure = err => this.setState({ err: true, msg: err.value.msg })
        inputValidation(arr, success, failure)
    }

    componentDidMount = () => {
        this.initListener();
    }

    componentWillUnmount = () => {
        this.removeListener();
    }

    render = () => (
        <div className={`${this.props.col ? `col ${this.props.col}` : ''} ${styles.div} `}>
            {this.props.valueList.map((f, index) => (
                <div key={index} className={`${f.invalidEmail ? styles.invalidEmail : styles.span}`}>{f.name}<i className={`material-icons tiny ${styles.icon}`} onClick={() => this.removeBubble(index)}>cancel</i></div>
            ))}
            <AutoSuggest
                theme={this.getTheme()}
                suggestions={this.state.suggestions}
                onSuggestionsFetchRequested={this.fetchSuggestions}
                onSuggestionsClearRequested={this.clearSuggestions}
                getSuggestionValue={this.getSuggestionValue}
                renderSuggestion={this.renderSuggestion}
                inputProps={this.getinputProps()}
                highlightFirstSuggestion={true}
                disabled={this.props.disabled}
                onBlur={this.handleBlur}
            />
            <label className={styles.label}>{this.props.label} {this.props.required ? <span style={{color: "red"}}>*</span> : ''}</label>
            {(this.state.new && this.props.add) ?
                <button id={`${this.props.id}-addnew`} onClick={this.handleAdd} tabIndex="0" className={`material-icons ${styles.add}`}>add_box</button>
                : null
            }

        </div>
    )
}

export default EmailAutoComplete;
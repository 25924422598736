export const tabActions = {
  ADD_TAB: "ADD_TAB",
  TAB_LEFT: "TAB_LEFT",
  TAB_RIGHT: "TAB_RIGHT",
  SELECT_TAB: "SELECT_TAB",
  CLOSE_TAB: "CLOSE_TAB"
};

export const urlActions = {
  UPDATE_URL: "UPDATE_URL",
  REFRESH_URL: "REFRESH_URL"
};

export const sidebarActions = {
  NEXT_NAV: "NEXT_NAV",
  PREV_NAV: "PREV_NAV",
  PUSH_NAV: "PUSH_NAV",
  POP_NAV: "POP_NAV",
  REBUILD_NAV: "REBUILD_NAV"
};

export const navActions = {
  CHANGE_ID: "CHANGE_ID",
  SET_FILTER: "SET_FILTER",
  SET_SIDEBAR: "SET_SIDEBAR"
};

export const init = {
  INITIALIZE_STORE: "INITIALIZE_STORE"
};

export const globalToastActions = {
  UPDATE_MSG: "UPDATE_MSG"
};

export const updateActions = {
  UPDATE_VAR: "UPDATE_VAR"
};
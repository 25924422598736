import React from 'react';
import axios from 'axios';
import Form from '../../../Components/NewComponents';
import { connect } from 'react-redux';
import { flags } from '../../../constants';
import { globalToastActions, urlActions } from '../../../Redux/actions';
import { auth } from "../../../helperFunctions";

class CreateUser extends React.Component {
    constructor(props) { super(props); this.state = this.initState() };

    initState = () => ({
        first: '',
        middle: '',
        last: '',
        isEmployee: false,
        isBookkeeper: false,
        isAdmin: false,
        partyId: 1,
        slackId: '',
        email: '',
        password: '',
        confirmPassword: '',
        filteredParties: [],
        msg: ''
    })

    onChange = (name, value) => {
        if (name === 'isEmployee' && value) {
            this.setState({ partyId: 1 })
        } else if (name === 'isEmployee' && !value) {
            this.setState({ slackId: '' });
        }
        this.setState({ [name]: value });
    }

    saveUser = () => {

        let emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        if (!emailRegex.test(this.state.email)) {
            this.props.dispatch({ type: globalToastActions.UPDATE_MSG, payload: { msg: "Invalid Email", type: "error" } });
            return
        }

        if (this.verifyNewPassword()) {
            this.setState({ msg: '' });

            axios.post("/api/v1/users/new", { ...auth.getAuthData(), userInfo: this.state }).then(result => {
                if (result.data === "NOT AUTHENTICATED") {
                    localStorage.clear();
                    this.props.dispatch({ type: "SCORCHED_EARTH" });
                    return;
                } else if (result.data.errno) {
                    this.props.dispatch({ type: globalToastActions.UPDATE_MSG, payload: { msg: result.data.code, type: "error" } });
                    return;
                } else {
                    this.props.dispatch({ type: globalToastActions.UPDATE_MSG, payload: { msg: "User Created", type: "success" } });
                    this.clear();
                }
            })
        }
    }

    verifyNewPassword = () => {
        const { password, confirmPassword } = this.state;

        if (password.length < 8)
            return this.setState({ msg: 'Password must be a minimum of 8 characters' });

        if (!this.checkPasswordForDigits())
            return this.setState({ msg: 'Password must have at least one digit' });

        if (!this.checkPasswordForSymbol())
            return this.setState({ msg: 'Password must have one special character. ie: !,@,#,$' });

        if (password !== confirmPassword)
            return this.setState({ msg: 'Passwords do not match' });

        return true;
    }

    checkPasswordForDigits = () => {
        return /\d/.test(this.state.confirmPassword);
    }

    checkPasswordForSymbol = () => {
        return /[\s~`!@#$%^&*+=\-[\]\\';,/{}|\\":<>?()._]/g.test(this.state.confirmPassword);
    }


    goToOverview = () => {
        this.props.dispatch({
            type: urlActions.UPDATE_URL, payload: {
                url: '/useroverview', state: {}
            }
        })
    }

    clear = () => {
        this.setState({
            first: '',
            middle: '',
            last: '',
            isEmployee: false,
            isBookkeeper: false,
            isAdmin: false,
            partyId: 1,
            slackId: '',
            email: '',
            password: '',
            confirmPassword: ''
        })
    }

    filterParties = async () => {
        let filteredParties = await this.props.party.filter((set => s => !set.has(s.partyId) && set.add(s.partyId))(new Set()));
        this.setState({ filteredParties });
    }

    componentDidMount = () => {
        this.filterParties();
    }

    render = () => (
        <div>
            <div className="row">
                <h5>Create New User</h5>
            </div>
            <div className="row">
                <Form.TextInput col="s4 l4" name="first" label="First Name" onChange={this.onChange} value={this.state.first} flags={[flags.ALLOW_EMPTY]} />
                <Form.TextInput col="s4 l4" name="middle" label="Middle Name" onChange={this.onChange} value={this.state.middle} flags={[flags.ALLOW_EMPTY]} />
                <Form.TextInput col="s4 l4" name="last" label="Last Name" onChange={this.onChange} value={this.state.last} flags={[flags.ALLOW_EMPTY]} />
            </div>
            <div className="row">
                <Form.CheckBox col="s2 userEditCheckbox" name="isEmployee" label="Employee" onChange={this.onChange} value={this.state.isEmployee} />
                <Form.CheckBox col="s2 userEditCheckbox" name="isBookkeeper" label="Bookkeep" onChange={this.onChange} value={this.state.isBookkeeper} />
                <Form.CheckBox col="s2 userEditCheckbox" name="isAdmin" label="Administrator" onChange={this.onChange} value={this.state.isAdmin} />
                <Form.Select col="s3" name="partyId" label="Party" onChange={this.onChange} value={this.state.partyId} list={this.state.filteredParties} filter={{ label: 'name', value: 'id' }} disabled={this.state.isEmployee ? true : false} />
                <Form.TextInput col="s3" name="slackId" label="Slack ID" onChange={this.onChange} value={this.state.slackId} disabled={this.state.isEmployee ? false : true} flags={[flags.ALLOW_EMPTY]} />
            </div>
            <div className={`row ${this.state.msg.length ? 'userBadPassword' : ''}`}>
                <Form.TextInput col="s6 l6" name="email" label="Email" onChange={this.onChange} value={this.state.email} />
                <Form.Password col="s6 l6" name="password" label="Password" onChange={this.onChange} value={this.state.password} />
            </div>
            <div className="row">
                <Form.Password col="s6 offset-s6" name="confirmPassword" label="Confirm Password" onChange={this.onChange} value={this.state.confirmPassword} />
            </div>
            {this.state.msg.length ?
                <div className="row">
                    <div className="col s12" style={{ border: "1px solid red", color: "red", textAlign: "center" }}>
                        {this.state.msg}
                    </div>
                </div>
                : null
            }
            <div className="row">
                <Form.Button col="s2 offset-s2" color="green" round type="outline" icon="person_add" label="Save" onClick={this.saveUser} />
                <Form.Button col="s2 offset-s1" color="orange" round type="outline" icon="cancel" label="Clear" onClick={this.clear} />
                <Form.Button col="s2 offset-s1" color="blue" round type="outline" icon="account_circle" label="All Users" onClick={this.goToOverview} />
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    const { index, navs, party } = state;
    const shipperId = navs[index].shipperId;
    return { shipperId, party };
}

export default connect(mapStateToProps)(CreateUser);
import React, { Component } from "react";
import axios from "axios";
import { connect } from "react-redux";
import styles from "./location.module.css";
import "./location.css";
import { globalToastActions, urlActions, init } from "../../Redux/actions";
import Form from "../../Components/NewComponents";
import { auth } from "../../helperFunctions";
import TableHeader from "../../Components/TableHeader/TableHeader";

class LocationOverview extends Component {
  state = {
    locations: [],
    translatedTypes: [],
    locationTypes: [
      {
        type: "All",
        id: 0
      }
    ],
    filters: {
      name: "",
      countryName: "",
      locationTypes: "",
      state: "",
      lcode: "",
      currentSort: "name",
      reverse: 1,
      currentSort2: "",
      reverse2: "",
      currentSort3: "",
      reverse3: ""
    }
  };

  initState = () => {
    this.setState({
      locations: [],
      translatedTypes: [],
      locationTypes: [
        {
          type: "All",
          id: 0
        }
      ],
      filters: {
        name: "",
        countryName: "",
        locationTypes: "",
        state: "",
        lcode: "",
        currentSort: "",
        reverse: 1,
        currentSort2: "",
        reverse2: "",
        currentSort3: "",
        reverse3: ""
      }
    });
  };

  getLocations = async () => {
    const locations = (await axios.get("/api/v1/location/overview", {
      params: { ...auth.getAuthData(), filters: this.state.filters }
    })).data;
    if (locations === "NOT AUTHENTICATED") {
      localStorage.clear();
      this.props.dispatch({ type: "SCORCHED_EARTH" });
      return;
    }
    this.setState({ locations });
    if (!locations.length) {
      this.props.dispatch({
        type: globalToastActions.UPDATE_MSG,
        payload: { msg: "No Results", type: "warning" }
      });
    }
  };

  grabTypes = () => {
    axios
      .get("/api/v1/locationtype", { params: auth.getAuthData() })
      .then(result => {
        if (result.data === "NOT AUTHENTICATED") {
          localStorage.clear();
          this.props.dispatch({
            type: "SCORCHED_EARTH"
          });
          return;
        }
        let newList = this.state.locationTypes;
        this.setState({
          locationTypes: newList.concat(result.data)
        });
      });
  };

  checkForFilters = () => {
    return new Promise((resolve, reject) => {
      if (this.props.url.state.filters) {
        this.setState({ filters: this.props.url.state.filters }, () => {
          resolve();
        })
      } else {
        resolve();
      }
    })
  };

  filterChange = (name, value) => {
    const { filters } = this.state;
    filters[name] = value;
    this.setState({ filters });
  };

  filterChangeDropDown = (name, value) => {
    const { filters } = this.state;
    filters[name] = value;
    this.setState({ filters }, this.getLocations);
  };

  initListeners = () => {
    document
      .querySelectorAll(".search-field")
      .forEach(elem => elem.addEventListener("keyup", this.searchListener));
    document.addEventListener("keydown", this.clearListener);
  };

  removeListeners = () => {
    document
      .querySelectorAll(".search-field")
      .forEach(elem => elem.removeEventListener("keyup", this.searchListener));
    document.removeEventListener("keydown", this.clearListener);
  };

  searchListener = e => {
    if (e.keyCode === 13) this.getLocations();
  };

  openLocation = location => {
    this.props.dispatch({
      type: urlActions.UPDATE_URL,
      payload: {
        url: "/location/update",
        state: { id: location.id, name: location.name, filters: this.state.filters }
      }
    });
  };

  archiveLocation = e => {
    if (window.confirm("Are you sure?")) {
      let id = e.id;
      axios
        .post("/api/v1/location/archive", { ...auth.getAuthData(), id })
        .then(results => {
          if (results.data === "NOT AUTHENTICATED") {
            localStorage.clear();
            this.props.dispatch({
              type: "SCORCHED_EARTH"
            });
            return;
          } else if (results.data.archived) {
            this.props.dispatch({
              type: globalToastActions.UPDATE_MSG,
              payload: {
                msg: "Location Deleted",
                type: "warning"
              }
            });
            this.updateRedux();
            this.clear();
          } else {
            this.props.dispatch({
              type: globalToastActions.UPDATE_MSG,
              payload: {
                msg: "Unknown Error",
                type: "warning"
              }
            });
          }
        });
    }
  };

  clearListener = e => {
    if (e.keyCode === 27) this.clear();
  };

  clear = () => this.setState(this.initState(), this.getLocations);

  sort = e => {
    if (!e.target.getAttribute("data-sort")) {
      return;
    } else if (e.ctrlKey) {
      const value = typeof e === "string" ? this.props.url.state.filters.currentSort2 : e.target.getAttribute('data-sort');
      const reverse2 = this.state.filters.currentSort2 === value ? (-1 * this.state.filters.reverse2) : 1;
      this.setState({ filters: { ...this.state.filters, currentSort2: value, reverse2 } }, () => { this.getLocations(); })
    } else if (e.altKey) {
      const value = typeof e === "string" ? this.props.url.state.filters.currentSort3 : e.target.getAttribute('data-sort');
      const reverse3 = this.state.filters.currentSort3 === value ? (-1 * this.state.filters.reverse3) : 1;
      this.setState({ filters: { ...this.state.filters, currentSort3: value, reverse3 } }, () => { this.getLocations(); })
    } else {
      const value = typeof e === "string" ? this.props.url.state.filters.currentSort : e.target.getAttribute('data-sort');
      const reverse = this.state.filters.currentSort === value ? (-1 * this.state.filters.reverse) : 1;
      this.setState({ filters: { ...this.state.filters, currentSort: value, reverse } }, () => { this.getLocations(); })
    }
  };

  componentDidMount = async () => {
    await this.checkForFilters();
    this.grabTypes();
    this.getLocations();
    this.initListeners();
  };

  createLocation = e => {
    e.preventDefault();
    this.props.dispatch({
      type: urlActions.UPDATE_URL,
      payload: { url: "/location/create", state: {} }
    });
  };

  componentWillUnmount = () => {
    this.removeListeners();
  };

  updateRedux = () => {
    axios.get("/api/v1/location", { params: auth.getAuthData() }).then(result => {
      if (result.data === "NOT AUTHENTICATED") {
        localStorage.clear();
        this.props.dispatch({
          type: "SCORCHED_EARTH"
        });
        return;
      } else if (Array.isArray(result.data))
        this.props.dispatch({
          type: init.INITIALIZE_STORE,
          payload: { listName: "location", list: result.data }
        });
    });
  }

  render() {
    return (
      <div>
        <div id="bkg-ov-search-fields">
          <div className="row">
            <Form.TextInput
              col="s3 search-field"
              name="name"
              label="Name"
              value={this.state.filters.name}
              onChange={this.filterChange}
              flags={["ALLOW_EMPTY"]}
            />

            <Form.TextInput
              col="s3 search-field"
              name="lcode"
              label="Code"
              onChange={this.filterChange}
              value={this.state.filters.lcode}
              flags={["ALLOW_EMPTY"]}
            />
            <Form.TextInput
              col="s3 search-field"
              name="countryName"
              label="Country"
              value={this.state.filters.countryName}
              onChange={this.filterChange}
              flags={["ALLOW_EMPTY"]}
            />
            <Form.TextInput
              col="s1 search-field"
              name="state"
              label="State"
              value={this.state.filters.state}
              onChange={this.filterChange}
              flags={["ALLOW_EMPTY"]}
            />
            <Form.Select
              col="s2 search-field"
              name="locationTypes"
              label="Type"
              onChange={this.filterChangeDropDown}
              value={this.state.filters.locationTypes}
              list={this.state.locationTypes}
              filter={{ label: "type", value: "id" }}
            />
          </div>
        </div>
        <table className="lf-portal-table">
          <TableHeader
            headers={[
              { label: "Name", attribute: "name" },
              { label: "Code", attribute: "l_code" },
              { label: "Country/State", attribute: "CountryState" },
              { label: "Type", attribute: "code" },
              { label: "", attribute: "" }
            ]}
            sort={this.sort}
            filters={this.state.filters}
          />
          <tbody className={styles.tbody}>
            {this.state.locations.map((location, index) => (
              <tr key={index}>
                <td onClick={() => this.openLocation(location)}>
                  {location.name}
                </td>
                <td onClick={() => this.openLocation(location)}>
                  {location.l_code}
                </td>
                <td onClick={() => this.openLocation(location)}>
                  {location.CountryState}
                </td>
                <td onClick={() => this.openLocation(location)}>
                  {location.code}
                </td>
                <td
                  onClick={() => this.archiveLocation(location)}
                  icon="delete_forever"
                >
                  <span>
                    <i className={`material-icons tiny ${styles.trash}`}>
                      delete_forever
                    </i>
                  </span>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div onClick={this.createLocation} className="fixed-action-btn">
          <a href="/" className="btn-floating btn-large green">
            <i className="large material-icons">add</i>
          </a>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { location, urls, index } = state;
  const url = urls[index];
  return { location, url, index };
};

export default connect(mapStateToProps)(LocationOverview);

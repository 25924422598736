import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { globalToastActions, urlActions } from '../../Redux/actions';
import Axios from 'axios';
import './shipment.css';
import Form from '../../Components/NewComponents/index';
import { flags } from '../../constants';
import M from 'materialize-css';
import moment from "moment";
import ResultsDisplay from "./ResultsDisplay/ResultsDisplay";
import PageCountDisplay from "./PageCountDisplay/PageCountDisplay";
import OverviewGraph from './OverviewGraph';
import ExdecResultsDisplay from './ExdecResultsDisplay/ExdecResultsDisplay';
import FilterContainer from "../../Components/NewComponents/FilterContainer";
import { auth } from "../../helperFunctions";
import EmailForm from './EmailForm/EmailForm';
import styles from "./shipments.module.css";
import LoadingSpinner from '../../Components/LoadingSpinner/LoadingSpinner';
import TableHeader from "../../Components/TableHeader/TableHeader";

class ShipmentOverview extends React.Component {
    constructor(props) { super(props); this.state = { ...this.initState() } };

    initState = () => ({
        bookings: [],
        notesArray: [],
        employees: [],
        submitUserNames: [],
        bookingsUserNames: [],
        showContextMenu: false,
        showAssigneeMenu: false,
        showEmailMenu: false,
        showBookingsAssignedTo: false,
        deleteBkg: null,
        reportType: "bookingsummary",
        filterList: [
            { searchTerm: "ACTIVE", type: "status", id: "1" }
        ],
        loading: false,
        prepareJson: false,
        processingJson: false,
        filters: {
            shipperName: '',
            carrierName: '',
            originCode: '',
            destCode: '',
            bookingNumber: '',
            shipperRef: '',
            consigneeName: '',
            vesselName: '',
            voyageNumber: '',
            billOfLadingNumber: '',
            pickupLocation: "",
            returnLocation: '',
            destCountryName: "",
            portOfLoad: '',
            lNumber: '',
            serviceRoute: '',
            isDummy: "ALL",
            flaggedForReview: false,
            status: 'ACTIVE',
            stage: "ALL",
            dateType: 'CUT',
            startDate: '',
            endDate: '',
            currentSort: 'cargoCutDate',
            reverse: 1,
            currentSort2: 'shipperName',
            reverse2: 1,
            currentSort3: "carrierName",
            reverse3: 1,
            reportFormat: "0",
            employee: "0",
            submitSiAssignTo: this.props.url.state.filters ? this.props.url.state.filters.employee : this.props.user.id,
            bookingsAssignedTo: "0",
            pageSize: 50,
            page: 0,
            count: [],
            resultsCount: null,
            showCount: false,
            searchOpen: false,
            showGraph: false,
            pageView: false,
            useNotes: false,
            timezone: moment.tz.guess()
        },
        attachments: [],
        subject: '',
        emails: [],
        isMouseDown: false,
        editBkgIndex: null,
        cannotUpdate: [],
        usePdf: true
    });

    initListeners = () => {

        document.querySelectorAll('.search-field').forEach(elem => elem.addEventListener('keydown', this.searchListener));
        document.addEventListener('keydown', this.clearListener);
        document.addEventListener("keydown", this.switchViewListener);
        document.addEventListener("keydown", this.backSpaceListener);
        document.querySelectorAll('.status-select').forEach(elem => elem.addEventListener("keydown", this.addStatus));
        document.addEventListener('keydown', this.controlCListener);
        document.addEventListener('mousedown', this.setMouseStatus);
        document.addEventListener('mouseup', this.setMouseStatus);
        document.addEventListener("keydown", this.arrowKeyListener);
        document.addEventListener("keydown", this.editBookingListener);
    }

    removeListeners = () => {
        document.querySelectorAll('.search-field').forEach(elem => elem.removeEventListener('keydown', this.searchListener));
        document.removeEventListener('keydown', this.clearListener);
        document.removeEventListener("keydown", this.switchViewListener);
        document.removeEventListener("keydown", this.backSpaceListener);
        document.querySelectorAll('.status-select').forEach(elem => elem.removeEventListener("keydown", this.addStatus));
        document.removeEventListener('keydown', this.controlCListener);
        document.removeEventListener('mousedown', this.setMouseStatus)
        document.removeEventListener('mouseup', this.setMouseStatus);
        document.removeEventListener("keydown", this.arrowKeyListener);
        document.removeEventListener("keydown", this.editBookingListener);
    }

    setMouseStatus = (e) => {
        if (e.ctrlKey && e.buttons === 1) {
            e.preventDefault();
            if (this.state.isMouseDown) {
                this.setState({ isMouseDown: false })
            } else {
                this.setState({ isMouseDown: true })
            }
        } else if (this.state.isMouseDown) {
            this.setState({ isMouseDown: false });
        } else {
            return;
        }
    }

    clearListener = async e => {
        if (e.keyCode === 27) {
            if (await this.checkForOpenModals()) {
                return;
            } else {
                if (this.props.url.state.origin) {
                    let forecastSorting = this.props.url.state.origin === '/forecast' ? this.props.url.state.forecastSorting : null;
                    return this.props.dispatch({ type: urlActions.UPDATE_URL, payload: { url: this.props.url.state.origin, state: { filters: { ...this.props.url.state.filters, ...forecastSorting } } } })
                } else {
                    this.clear();
                }
            }
        }
    }

    switchViewListener = e => {

        if (e.keyCode === 112) {
            e.preventDefault();
            this.clear();
        } else if (e.keyCode === 113) {
            e.preventDefault();
            this.setState({
                filters: {
                    ...this.state.filters,
                    dateType: "DOC",
                    startDate: moment().format("MM/DD/YY"),
                    endDate: moment().format("MM/DD/YY"),
                    isDummy: "NO BKG DUMMIES",
                    employee: this.props.user.id,
                    pageView: true,
                    currentSort: "shipperName",
                    reverse: 1
                }
            })
        }
    }

    backSpaceListener = (e) => {
        if (e.keyCode === 8) {
            if (this.state.loading) {
                return;
            } else if (e.target.type === "text" && e.target.value) {
                return;
            } else if (
                document.activeElement.parentElement.classList.contains("search-field") &&
                !document.activeElement.parentElement.classList.contains("date-field")) {
                e.preventDefault();
                let filterList = this.state.filterList;
                filterList.pop();
                this.setState({ filterList }, this.getBookings);
            }
        }
    }

    editBookingListener = (e) => {
        if (e.key === "Enter" && document.activeElement.tagName === "TR") {
            document.activeElement.children[3].click();
        }
    }

    arrowKeyListener = (e) => {
        if (!document.activeElement) {
            return;
        } else {
            let tag = document.activeElement.tagName;
            if (tag !== "TR") {
                return
            } else if (e.code === "ArrowUp" && this.state.editBkgIndex !== 0) {
                e.preventDefault();
                let row = document.querySelector(`#bkg-tr-${this.state.editBkgIndex - 1}`);
                if (row) {
                    row.focus();
                }
            } else if (e.code === "ArrowDown" && this.state.editBkgIndex !== this.state.bookings.length + 1) {
                e.preventDefault();
                let row = document.querySelector(`#bkg-tr-${this.state.editBkgIndex + 1}`);
                if (row) {
                    row.focus();
                }
            } else {
                return;
            }
        }
    }

    setIndexOnFocus = (e, index) => {
        e.preventDefault(e);
        if (e.ctrlKey) {
            return;
        } else {
            this.setState({ editBkgIndex: index });
        }
    }

    getStatuses = () => ([{ label: 'ACTIVE' }, { label: 'CLOSED' }, { label: 'CANCELLED' }, { label: 'ARCHIVED' }]);

    getDummyDropDownValues = () => ([{ label: 'ALL' }, { label: 'NO BKG DUMMIES' }, { label: "BKG DUMMIES ONLY" }, { label: "SI DUMMIES ONLY" }])

    getReportFormats = () => ([{ label: "DEFAULT", id: 0 }, { label: "DOCS DUE", id: 1 }, { label: "AES", id: 2 }]);

    getDateTypes = () => ([{ label: 'CUT' }, { label: 'DOC' }, { label: 'ETD' }, { label: 'ETA' }]);

    getStageValues = () => ([{ label: "ALL" }, { label: "DUE TODAY" }, { label: "PAST DUE" }, { label: "INVOICED" }, { label: "PRINTED DUMMY" }, { label: "PRINTED SI" }, { label: "PRINTED" }])

    getBookings = async () => {
        if (this.state.loading) {
            return;
        } else {
            this.setState({ loading: true }, () => {
                Axios.get(`/api/v1/booking/filter`, { params: { ...auth.getAuthData(), filters: this.state.filters, filterList: this.state.filterList } }).then((result) => {
                    if (result.data === 'NOT AUTHENTICATED') {
                        localStorage.clear();
                        this.props.dispatch({ type: 'SCORCHED_EARTH' })
                        return;
                    }

                    const bookings = result.data.result;
                    const filters = result.data.filters;
                    const notesArray = result.data.notesArray;

                    this.getBookingsCount();
                    this.setState({
                        bookings,
                        loading: false,
                        showBookingsAssignedTo: this.state.filters.flaggedForReview ? true : false,
                        filters: {
                            ...this.state.filters,
                            currentSort: filters.currentSort,
                            reverse: filters.reverse,
                            useNotes: filters.useNotes

                        },
                        notesArray
                    })
                    if (!bookings.length) {
                        this.props.dispatch({ type: globalToastActions.UPDATE_MSG, payload: { msg: 'No Results', type: 'warning' } })
                    }
                });
            })
        }
    }

    getEmployees = () => {
        let employees = [];
        let reduxUsers = this.props.portalUser.filter(u => u.isArchived !== 1);
        let i = 0;
        while (i < reduxUsers.length) {
            employees.push({ name: reduxUsers[i].first + " " + reduxUsers[i].last, id: reduxUsers[i].id });
            i++
        }
        employees.unshift({ name: "ANY", id: 0 })
        this.setState({ employees })
    }

    checkForOpenModals = async () => {
        let modals = document.querySelectorAll('.modal');
        let openModal = false;
        await modals.forEach(modal => {
            if (M.Modal.getInstance(modal).isOpen) {
                M.Modal.getInstance(modal).close();
                return openModal = true;
            }
        });
        return openModal;
    }

    outlineLastBooking = () => {
        if (this.props.url.state.bookingId) {
            if (!this.state.bookings.length) {
                setTimeout(() => {
                    this.outlineLastBooking();
                }, 100)
            } else {
                let editBkgIndex = this.state.bookings.map(b => b.id).indexOf(this.props.url.state.bookingId);
                if (editBkgIndex && document.querySelector(`#bkg-tr-${editBkgIndex}`)) {
                    document.querySelector(`#bkg-tr-${editBkgIndex}`).focus();
                    this.setState({ editBkgIndex });
                } else {
                    return;
                }
            }
        }
    }

    pageCheck = () => {
        if (this.props.url.state.filters) {
            if (this.props.url.state.filters.page && this.props.url.state.filters.page > 0) {
                this.setState({ filters: { ...this.state.filters, page: this.props.url.state.filters.page } })
            }
        }
    }

    setPage = (page) => {
        return new Promise((resolve, reject) => {
            if (page === undefined) {
                page = 0
            }
            if (page < 0) {
                return
            }
            if (this.state.filters.count.length < 1) {
                page = 0
            } else if (this.state.filters.count.length > 0) {
                if (page === this.state.filters.count.length) {
                    return
                }
            }
            this.setState({ filters: { ...this.state.filters, page: page } })
            resolve(page);
            reject(new Error('Promise Rejected'))
        }).catch(e => {
            console.log(e)
        })
    }

    getBookingsCount = async () => {
        let result = (await Axios.get('/api/v1/booking/count', { params: { ...auth.getAuthData(), filters: this.state.filters, filterList: this.state.filterList } })).data
        let enteredContainerResultsCount = result.result[0].enteredContainerResultsCount;
        let containerResultsCount = result.result[0].containerResultsCount;
        let showCount = result.showCount;
        let amount = result.result[0].count;
        let list = Math.ceil(amount / this.state.filters.pageSize);
        let count = [];
        for (list; list > 0; list--) {
            count.push(list)
        }
        this.setState({ filters: { ...this.state.filters, count: count.reverse(), resultsCount: amount, enteredContainerResultsCount, containerResultsCount, showCount: showCount } })
    }

    singlePageTurn = async (e) => {
        let direction = e.target.getAttribute('name')
        let page = await this.state.filters.page;
        direction === 'back' ? page-- : page++;
        this.setPage(page).then(() => {
            this.getBookings();
        })
    }

    pageJump = (e) => {
        let choice = e.target.getAttribute("name");
        this.setPage((parseInt(choice) - 1)).then(() => {
            this.getBookings();
        })
    }

    addStatus = e => {
        if (e.keyCode === 13) {
            e.preventDefault();
            let value = e.target.value;
            let name = e.target.id
            if (name === "status" && value !== "ACTIVE") {
                let filterList = this.state.filterList;
                if (!new Array(...filterList.map(f => f.searchTerm)).includes(value)) {
                    filterList.push({ searchTerm: value, type: name, id: (this.state.filterList.length + 1) });
                }
                this.setState({ filters: { ...this.state.filters, [name]: value, showGraph: false }, filterList }, this.freshSearch);
            } else if (name === "status") {
                let filterList = this.state.filterList;
                if (!new Array(...filterList.map(f => f.searchTerm)).includes(value)) {
                    filterList.push({ searchTerm: value, type: name, id: (this.state.filterList.length + 1) });
                }
                this.setState({ filters: { ...this.state.filters, [name]: value }, filterList }, this.freshSearch)
            }
        }
    };

    controlCListener = (e) => {
        if (e.ctrlKey && e.key === "c") {
            if (document.querySelectorAll(".menu-selected").length) {
                e.preventDefault();
                this.copyBookingNumbers();
            } else {
                return;
            }
        }
    }

    searchListener = e => {
        if (e.keyCode === 13) {
            e.preventDefault();
            let activeField = document.getElementById(`${e.target.id}`);
            if (activeField) {
                activeField.setAttribute("autocomplete", "new-password");
                activeField.blur();
                activeField.focus();
                activeField.setAttribute("autocomplete", "on");
            }
            this.populateFilterBubbles();
        }
    }

    freshSearch = () => {
        this.setPage().then(() => {
            this.getBookings();
        })
    }

    toggleFlag = () => {
        let highlightedItems = document.querySelectorAll(".menu-selected");
        let idList = [];
        if (highlightedItems.length > 1) {
            highlightedItems.forEach(i => idList.push(this.state.bookings[i.rowIndex - 1].id));
        } else {
            idList = [this.state.editBkg.id];
        }
        Axios.post(`/api/v1/booking/flag/toggle`, { ...auth.getAuthData(), idList }).then(result => {
            if (result.data === 'NOT AUTHENTICATED') {
                localStorage.clear();
                this.props.dispatch({ type: 'SCORCHED_EARTH' })
                return;
            }
            this.getBookings();
        })
    }

    addOrRemoveFlag = (bool) => {

        let highlightedItems = document.querySelectorAll(".menu-selected");
        let idList = [];
        if (highlightedItems.length > 1) {
            highlightedItems.forEach(i => idList.push(this.state.bookings[i.rowIndex - 1].id));
        } else {
            idList = [this.state.editBkg.id];
        }

        Axios.post(`/api/v1/booking/flag/onoff`, { ...auth.getAuthData(), idList, bool }).then(result => {
            if (result.data === 'NOT AUTHENTICATED') {
                localStorage.clear();
                this.props.dispatch({ type: 'SCORCHED_EARTH' })
                return;
            }
            this.getBookings();
        })
    }

    copyBookingNumbers = () => {
        let highlightedItems = document.querySelectorAll(".menu-selected>td.bookingNum");
        let bookingNumberList = [];
        highlightedItems.forEach(row => bookingNumberList.push(row.innerHTML));
        navigator.clipboard.writeText(bookingNumberList.join('\n'));
        this.props.dispatch({ type: globalToastActions.UPDATE_MSG, payload: { msg: "Copied To Clipboard" } });
        document.querySelectorAll(".menu-selected").forEach(i => i.classList.remove('menu-selected'));
    }

    openCol = () => {
        if (this.state.searchOpen) {
            this.setState({ searchOpen: false })
        } else {
            this.setState({ searchOpen: true })
        }
    }

    populateFilterBubbles = async () => {
        if (this.state.loading) {
            return this.props.dispatch({ type: globalToastActions.UPDATE_MSG, payload: { msg: "Please wait . . .", type: "warning" } });
        }
        let filterList = this.state.filterList;
        let filters = document.querySelectorAll(".filterList");

        await filters.forEach((f, index) => {
            if (f.localName === "select") {
                if ([...filterList.map(filter => filter.searchTerm)].includes(f.value)) {
                    return;
                }
                filterList.push({ searchTerm: f.value, type: f.id, id: (index + filterList.length) });
            } else {
                if (!(f.children[0].value).trim()) {
                    return;
                }
                filterList.push({ searchTerm: (f.children[0].value).trim(), type: f.children[0].id, id: (index + filterList.length) });
                this.setState({ filters: { ...this.state.filters, [f.children[0].id]: "" } });
            }
        });
        this.setState({ filterList }, this.freshSearch)
    }

    changeStatus = status => {
        const bkg = JSON.parse(JSON.stringify(this.state.editBkg));
        bkg.status = status;
        Axios.post('/api/v1/booking/updatestatus', { ...bkg, status, ...auth.getAuthData() }).then(result => {
            if (result.data === 'NOT AUTHENTICATED') {
                localStorage.clear();
                this.props.dispatch({ type: 'SCORCHED_EARTH' })
                return;
            }
            this.refresh()
        });
    }

    handleDelete = () => {
        const bkg = JSON.parse(JSON.stringify(this.state));
        if (window.confirm(`ARCHIVE BOOKING: ${bkg.bookingNumber}?`)) {
            Axios.post('/api/v1/booking/updatestatus',
                {
                    ...auth.getAuthData(),
                    status: 'ARCHIVED',
                    id: bkg.bookingId,
                }).then(result => {
                    if (result.data === 'NOT AUTHENTICATED') {
                        localStorage.clear();
                        this.props.dispatch({ type: 'SCORCHED_EARTH' })
                        return;
                    }
                    this.getBookings();
                });
        }
        else {
            this.setState({ editBkg: null });
        }
    }


    handleContextMenu = (e, index, bkg, status) => {
        e.preventDefault();
        if (status) {
            return
        }
        else {
            this.setState({ showContextMenu: true, x: e.pageX, y: e.pageY, editBkg: bkg })
        }
        document.addEventListener('click', this.clickListener);

        const list = document.querySelectorAll('.lf-portal-tbody>tr');
        list.forEach(tr => tr.classList.remove('menu-selected'));
        document.querySelector(`#bkg-tr-${index}`).classList.add('menu-selected');
    };

    handleStatusMenu = (e, index, bkg) => {
        return
    }

    changeAssignee = () => {

        let infoSet = [];
        if (document.querySelectorAll(".menu-selected").length) {
            const list = document.querySelectorAll(".menu-selected")

            list.forEach(row => {
                infoSet.push({
                    bookingNumber: this.state.bookings[row.sectionRowIndex].bookingNumber,
                    shipmentId: this.state.bookings[row.sectionRowIndex].shipmentId,
                    shipperId: this.state.bookings[row.sectionRowIndex].shipperId,
                    submitUser: parseInt(this.state.filters.submitSiAssignTo)
                })
                row.classList.remove("menu-selected");

            });
        } else {
            infoSet.push({
                bookingNumber: this.state.editBkg.bookingNumber,
                shipmentId: this.state.editBkg.shipmentId,
                shipperId: this.state.editBkg.shipperId,
                submitUser: parseInt(this.state.filters.submitSiAssignTo)
            });
        }
        this.setState({ showAssigneeMenu: false })
        Axios.post("api/v1/instructions/changesubmituser", { ...auth.getAuthData(), infoSet }).then(result => {
            if (result.data === 'NOT AUTHENTICATED') {
                localStorage.clear();
                this.props.dispatch({ type: 'SCORCHED_EARTH' });
                return;
            }

            let bkgPrefix = "The following bookings do not have an attached Shipping Instructions\n\r "
            let bkgList = []

            infoSet.forEach(i => {
                if (i.shipmentId === null) {
                    bkgList.push(i.bookingNumber)
                }
            })
            if (bkgList.length) {
                alert(bkgPrefix + bkgList.join(", "))
            }

            this.freshSearch();
        });

    }

    handleAssigneeMenu = (e, index, bkg) => {
        e.preventDefault();
        this.setState({ showAssigneeMenu: true, showContextMenu: false, showStatusMenu: false, x: e.pageX, y: e.pageY, editBkg: bkg })
    }

    handleMultipleBookingAckMenu = (e, index, bkg) => {
        e.preventDefault();
        if (document.querySelectorAll('.menu-selected').length) {
            this.setState({ showEmailMenu: true, showContextMenu: false, showStatusMenu: false, x: e.pageX, y: e.pageY, editBkg: bkg })
        } else {
            return;
        }
        document.addEventListener('click', this.ackClickListener);
    }

    clickListener = e => {
        const list = document.querySelectorAll('.lf-portal-tbody>tr');
        list.forEach(tr => tr.classList.remove('menu-selected'));
        this.setState({ showContextMenu: false, showStatusMenu: false, showEmailMenu: false }, () => document.removeEventListener('click', this.clickListener))
    }

    ackClickListener = e => {
        this.setState({ showContextMenu: false, showStatusMenu: false, showEmailMenu: false }, () => document.removeEventListener('click', this.ackClickListener))
    }

    closeEmailMenu = () => {
        const list = document.querySelectorAll('.lf-portal-tbody>tr');
        list.forEach(tr => tr.classList.remove('menu-selected'));
        this.setState({ showEmailMenu: false });
    }

    changeReportFormat = (name, value) => {
        let { filters } = this.state;
        filters[name] = value;
        this.setState({ filters });
        switch (value) {
            case "0":
                filters.shipperName = '';
                filters.carrierName = '';
                filters.originCode = '';
                filters.destCode = '';
                filters.bookingNumber = '';
                filters.shipperRef = '';
                filters.consigneeName = '';
                filters.vesselName = '';
                filters.voyageNumber = '';
                filters.billOfLadingNumber = '';
                filters.lNumber = '';
                filters.flaggedForReview = false;
                filters.status = 'ACTIVE';
                filters.currentSort = '';
                filters.reverse = 1;
                filters.dateType = "CUT";
                filters.startDate = '';
                filters.endDate = '';
                filters.isDummy = "ALL";
                filters.employee = this.state.filters.pageView ? this.props.user.id : this.state.filters.employee
                this.setState({
                    filters: { ...this.initState().filters }
                });
                this.freshSearch()
                break;
            case "1":
                filters.shipperName = '';
                filters.carrierName = '';
                filters.originCode = '';
                filters.destCode = '';
                filters.bookingNumber = '';
                filters.shipperRef = '';
                filters.consigneeName = '';
                filters.vesselName = '';
                filters.voyageNumber = '';
                filters.billOfLadingNumber = '';
                filters.lNumber = '';
                filters.flaggedForReview = false;
                filters.status = 'ACTIVE';
                filters.currentSort = 'shipperName';
                filters.reverse = 1;
                filters.dateType = "DOC";
                filters.startDate = moment().format("MM/DD/YY");
                filters.endDate = moment().format("MM/DD/YY");
                filters.isDummy = "NO BKG DUMMIES";
                filters.employee = this.state.filters.pageView ? this.props.user.id : this.state.filters.employee
                this.setState({
                    filters
                })
                this.freshSearch();
                break;
            case "2":
                filters.shipperName = '';
                filters.carrierName = '';
                filters.originCode = '';
                filters.destCode = '';
                filters.bookingNumber = '';
                filters.shipperRef = '';
                filters.consigneeName = '';
                filters.vesselName = '';
                filters.voyageNumber = '';
                filters.billOfLadingNumber = '';
                filters.lNumber = '';
                filters.flaggedForReview = false;
                filters.status = 'ACTIVE';
                filters.currentSort = 'docCutDate';
                filters.reverse = 1;
                filters.dateType = "DOC";
                filters.startDate = moment().format("MM/DD/YY");
                filters.endDate = moment().add(10, "d").format("MM/DD/YY");
                filters.isDummy = "NO BKG DUMMIES";
                filters.employee = "ALL"
                this.setState({
                    filters
                })
                this.freshSearch();
                break;
            default:

                this.setState({
                    ...this.initState().filters
                })
                this.freshSearch();
        }
    }

    onChange = (name, value) => {
        this.setState({ [name]: value })
    }

    pageViewChange = () => {
        let { pageView } = this.state.filters;
        if (pageView === true) {
            this.clear();
        } else {
            this.setState({
                filters: {
                    ...this.state.filters,
                    dateType: "DOC",
                    startDate: moment().format("MM/DD/YY"),
                    endDate: moment().format("MM/DD/YY"),
                    isDummy: "NO BKG DUMMIES",
                    employee: this.props.user.id,
                    pageView: true,
                    currentSort: "shipperName",
                    reverse: 1
                }
            });
        }
    }

    filterChange = (name, value) => {
        this.setState({ filters: { ...this.state.filters, [name]: value } });
    }

    filterChangePlus = (name, value) => {
        if (this.state.loading) {
            return;
        } else if (name === "status" && value !== "ACTIVE") {
            let filterList = this.state.filterList;
            if (!new Array(...filterList.map(f => f.searchTerm)).includes(value)) {
                filterList.push({ searchTerm: value, type: name, id: (this.state.filterList.length + 1) });
            }
            this.setState({ filters: { ...this.state.filters, [name]: value, showGraph: false }, filterList }, this.freshSearch);
        } else if (name === "status") {
            let filterList = this.state.filterList;
            if (!new Array(...filterList.map(f => f.searchTerm)).includes(value)) {
                filterList.push({ searchTerm: value, type: name, id: (this.state.filterList.length + 1) });
            }
            this.setState({ filters: { ...this.state.filters, [name]: value }, filterList }, this.freshSearch)
        } else {
            const { filters } = this.state;
            filters[name] = value;
            this.setState({ filters }, this.freshSearch);
        }
    }

    docsDueDummyOnChange = (name, value) => {
        if (value === "SI DUMMIES ONLY") {
            const { filters } = this.state;
            filters[name] = value;
            this.setState({ filters: { ...this.state.filters, startDate: "", endDate: "" } }, this.freshSearch);
        } else {
            const { filters } = this.state;
            filters[name] = value;
            this.setState({ filters }, this.freshSearch)
        }
    }

    flaggedFilterPress = () => {
        let flaggedForReview = this.state.filters.flaggedForReview;
        if (!flaggedForReview) {
            this.setState({ filters: { ...this.state.filters, flaggedForReview: !flaggedForReview, isDummy: "NO BKG DUMMIES" } }, () => {
                this.refresh();
            })
        } else {
            this.setState({ filters: { ...this.state.filters, flaggedForReview: !flaggedForReview, isDummy: "ALL", bookingsAssignedTo: "0" } }, () => {
                this.refresh();
            })
        }

    }

    spliceFilterList = (index) => {
        let filterList = this.state.filterList;
        filterList.splice(index, 1);
        this.setState({ filterList }, () => this.getBookings())
    }

    setToToday = () => {
        if (this.state.loading) {
            return this.props.dispatch({ type: globalToastActions.UPDATE_MSG, payload: { type: "warning", msg: "Searching - Please Wait . . ." } });
        } else {
            let today = moment(new Date()).format("MM/DD/YY");
            this.setState({ filters: { ...this.state.filters, startDate: today, endDate: today } }, this.getBookings);
        }
    }

    setToThisWeek = () => {
        if (this.state.loading) {
            return this.props.dispatch({ type: globalToastActions.UPDATE_MSG, payload: { type: "warning", msg: "Searching - Please Wait . . ." } });
        } else {
            let startDate = moment().startOf('week').format("MM/DD/YY");
            let endDate = moment().startOf('week').add(6, "days").format("MM/DD/YY");
            this.setState({ filters: { ...this.state.filters, startDate, endDate } }, this.getBookings)
        }
    }

    increaseWeek = () => {
        if (this.state.loading) {
            return this.props.dispatch({ type: globalToastActions.UPDATE_MSG, payload: { type: "warning", msg: "Searching - Please Wait . . ." } });
        } else {
            let startDate = this.state.filters.startDate;
            let endDate = this.state.filters.endDate;
            if (startDate) {
                startDate = moment(startDate, "MM/DD/YY").add(7, 'days').format("MM/DD/YY");
            }
            if (endDate) {
                endDate = moment(endDate, "MM/DD/YY").add(7, "days").format("MM/DD/YY");
            }
            this.setState({ filters: { ...this.state.filters, startDate, endDate } }, this.getBookings)
        }

    }

    decreaseWeek = () => {
        if (this.state.loading) {
            return this.props.dispatch({ type: globalToastActions.UPDATE_MSG, payload: { type: "warning", msg: "Searching - Please Wait . . ." } });
        } else {
            let startDate = this.state.filters.startDate;
            let endDate = this.state.filters.endDate;
            if (startDate) {
                startDate = moment(startDate, "MM/DD/YY").subtract(7, 'days').format("MM/DD/YY");
            }
            if (endDate) {
                endDate = moment(endDate, "MM/DD/YY").subtract(7, "days").format("MM/DD/YY");
            }
            this.setState({ filters: { ...this.state.filters, startDate, endDate } }, this.getBookings)
        }
    }

    showGraph = () => {
        this.setState({ filters: { ...this.state.filters, showGraph: !this.state.filters.showGraph, status: "ACTIVE" } }, this.freshSearch);
    }

    getEmails = async (bkg) => {
        let partyId = parseInt(bkg.shipperId);
        let role = 'BOOKINGS';
        await Axios.get("/api/v1/contacts", { params: { ...auth.getAuthData(), partyId, role } }).then(result => {
            if (result.data === "NOT AUTHENTICATED") {
                localStorage.clear();
                this.props.dispatch({ type: "SCORCHED_EARTH" });
                return;
            }
            this.setState({ emails: result.data });
        })
    }

    initModal = () => {
        document.querySelectorAll(".modal").forEach(m => {
            M.Modal.init(m);
        })
    }

    showModal = () => {
        M.Modal.getInstance(document.querySelector('#print-modal')).open();
    }

    showFileUploadWindow = (e) => {
        document.getElementById("hiddenJsonUploadButton").click();
    }

    uploadJson = (e) => {

        if (e.target.files[0].type !== "application/json") {
            return this.props.dispatch({ type: globalToastActions.UPDATE_MSG, payload: { msg: "File Must Be JSON", type: "error" } });
        } else {

            let fr = new FileReader();
            let fileName = e.target.files[0].name;

            fr.addEventListener("load", e => {
                if (!this.checkForValidJson(fr.result)) {
                    return this.props.dispatch({ type: globalToastActions.UPDATE_MSG, payload: { msg: "Error Within File", type: "error" } });
                }
                let data = JSON.parse(fr.result);
                if (!Array.isArray(data)) {
                    return this.props.dispatch({ type: globalToastActions.UPDATE_MSG, payload: { msg: "JSON File Must Be Array", type: "error" } });
                }
                this.setState({ preparingJson: true }, async () => {
                    let preparedJson = await this.prepareJson(data);
                    this.setState({ processingJson: true, preparingJson: false }, () => {
                        Axios.post("/api/v1/booking/zjson", { ...auth.getAuthData(), timestamp: moment(new Date()).format("MM/DD/YY HH:mm"), fileName, bookingInfo: preparedJson, userEmail: this.props.user.emailAddress }).then(result => {
                            if (result.data.errno) {
                                this.setState({ processingJson: false });
                                return alert(`Bookings Could Not Be Added/Updated \r\n Error: ${result.data.sqlMessage}`)
                            } else if (result.data.customErrorMsg) {
                                this.setState({ processingJson: false });
                                if (result.data.customErrorMsg.sqlMessage) {
                                    return alert(`Bookings Could Not Be Added/Updated \r\n Error: ${result.data.customErrorMsg.sqlMessage}`)
                                } else {
                                    return alert(`Bookings Could Not Be Added/Updated \r\n Error: ${result.data.customErrorMsg.customErrorMsg}`)
                                }
                            } else if (result.data.cannotUpdate.length) {
                                this.setState({ processingJson: false, cannotUpdate: result.data.cannotUpdate }, () => {
                                    setTimeout(() => {

                                        if (result.data.cannotUpdate.length) {
                                            alert(`${result.data.newBookings.length} New Bookings Entered\n\r ${result.data.updateBookings} Bookings Updated\n\r ${result.data.deletedBookings} Bookings Have Been Removed By Shipper\n\rThe Following Bookings Have SI's and Cannot Be Updated -- ${result.data.cannotUpdate.map(o => `${o.BookingNumber}`).join(",")}`);
                                            return this.getBookings();
                                        }
                                    }, 2000)
                                });
                            } else {
                                this.setState({ processingJson: false }, () => {
                                    alert(`${result.data.newBookings.length} New Bookings Entered\n\r ${result.data.updateBookings} Bookings Updated\n\r ${result.data.deletedBookings} Bookings Have Been Removed by Shipper\n\r`)
                                    return this.getBookings();
                                })
                            }
                        })
                    })
                })

            })
            fr.readAsText(e.target.files[0]);
            document.getElementById("hiddenJsonUploadButton").value = null;
        }

    }

    prepareJson = (json) => {
        return new Promise(async (resolve, reject) => {
            let arr = [];
            for (let i = 0; i < json.length; i++) {
                if (arr.map(a => a.BookingID).includes(json[i].BookingID)) {
                    let foundIndex = arr.map(a => a.BookingID).indexOf(json[i].BookingID);
                    arr[foundIndex].ContainerCount = parseInt(arr[foundIndex].ContainerCount ? arr[foundIndex].ContainerCount : 0, 10) + parseInt(json[i].ContainerCount, 10);
                    arr[foundIndex].ReferenceNumber = arr[foundIndex].ReferenceNumber + ", " + json[i].ReferenceNumber;
                } else {
                    arr.push(json[i])
                }
            }
            resolve(arr);
        })

    }

    checkForValidJson = (string) => {
        try {
            JSON.parse(string);
        } catch (e) {
            return false;
        }
        return true;
    }

    showEmailModal = async () => {

        let selectedBookings = [];
        document.querySelectorAll(".menu-selected").forEach(r => selectedBookings.push(this.state.bookings[r.rowIndex - 1]));
        if (selectedBookings.filter(b => b.isDummy).length) {
            return this.props.dispatch({ type: globalToastActions.UPDATE_MSG, payload: { msg: "Cannot Send Updates for Dummies", type: "error" } });
        }

        let uniqueDisplayNames = [...new Set(selectedBookings.map(b => !!parseInt(b.displayNameId, 10) ? b.displayNameId : null))];
        let uniqueShippers = [...new Set(selectedBookings.map(b => b.shipperId))];
        let consignees = selectedBookings.filter(b => b.consigneeCode !== "N/A").length;
        let shipperRefs = selectedBookings.filter(b => !!b.shipperReferenceNumber).length;
        let uniqueVoyageNumbers = [...new Set(selectedBookings.map(b => b.voyageNumber))];
        let uniqueVessels = [...new Set(selectedBookings.map(b => b.vesselName))];
        let uniqueCarriers = [...new Set(selectedBookings.map(b => b.carrierId))];
        let uniquePlrs = [...new Set(selectedBookings.map(b => b.origin))];
        let uniquePols = [...new Set(selectedBookings.map(b => b.portOfLoad))];
        let uniqueReturnLocations = [...new Set(selectedBookings.map(b => b.returnLoc))];
        let uniquePlds = [...new Set(selectedBookings.map(b => b.dest))];
        let uniquePods = [...new Set(selectedBookings.map(b => b.portOfDischarge))];
        let uniqueCargoCut = [...new Set(selectedBookings.map(b => b.fullCargoCutDateAndTime))].length > 1;
        let uniqueDocCut = [...new Set(selectedBookings.map(b => b.fullDocCutDateAndTime))].length > 1;
        let etd = [...new Set(selectedBookings.map(b => b.etd))].length > 1;
        let erd = [...new Set(selectedBookings.map(b => b.erd))].length > 1;
        let uniqueStatus = [...new Set(selectedBookings.map(b => b.status))];

        if (selectedBookings.length < 2) {
            return this.props.dispatch({ type: globalToastActions.UPDATE_MSG, payload: { msg: "Must Select At Least 2 Bookings", type: "error" } });
        } else if (uniqueShippers.length > 1) {
            return this.props.dispatch({ type: globalToastActions.UPDATE_MSG, payload: { msg: "Must Share Shipper", type: "error" } });
        } else if (uniqueDisplayNames.length > 1) {
            return this.props.dispatch({ type: globalToastActions.UPDATE_MSG, payload: { msg: "Must Share Display Name", type: "error" } });
        } else if (uniqueReturnLocations.length > 1) {
            return this.props.dispatch({ type: globalToastActions.UPDATE_MSG, payload: { msg: "Must Share Return Locations", type: "error" } });
        } else if (uniquePlrs.length > 1) {
            return this.props.dispatch({ type: globalToastActions.UPDATE_MSG, payload: { msg: "Must Share Same PLR", type: "error" } });
        } else if (uniquePols.length > 1) {
            return this.props.dispatch({ type: globalToastActions.UPDATE_MSG, payload: { msg: "Must Share Same POL", type: "error" } });
        } else if (uniqueCarriers.length > 1) {
            return this.props.dispatch({ type: globalToastActions.UPDATE_MSG, payload: { msg: "Must Share Same Carrier", type: "error" } });
        } else if (uniqueVessels.length > 1) {
            return this.props.dispatch({ type: globalToastActions.UPDATE_MSG, payload: { msg: "Must Share Same Vessel", type: "error" } });
        } else if (uniqueVoyageNumbers.length > 1) {
            return this.props.dispatch({ type: globalToastActions.UPDATE_MSG, payload: { msg: "Must Share Same Voyage", type: "error" } });
        } else if (uniqueCargoCut || uniqueDocCut || etd || erd) {
            return this.props.dispatch({ type: globalToastActions.UPDATE_MSG, payload: { msg: "Must Share Same Voyage Times", type: "error" } });
        } else if (uniqueStatus.length > 1) {
            return this.props.dispatch({ type: globalToastActions.UPDATE_MSG, payload: { msg: "Must Share Status", type: "error" } });
        }

        let subject;
        let body;
        let firstName = `${this.props.user.first.charAt(0) + this.props.user.first.slice(1).toLowerCase()}`;
        let lastName = `${this.props.user.last.charAt(0) + this.props.user.last.slice(1).toLowerCase()}`;
        let finalName = `${firstName + " " + lastName}`
        let attachments = [];
        let datetime = moment(new Date()).format('MMDDYY_HHmmss');
        let tableString = '';

        selectedBookings.forEach(bkg => {
            tableString = tableString + `<tr>
                        <td>${bkg.bookingNumber}</td>
                        ${shipperRefs ? `<td>${bkg.shipperReferenceNumber ? bkg.shipperReferenceNumber : "N/A"}</td>` : ''}
                        ${consignees ? `<td>${bkg.consigneeCode}</td>` : ''}
                        ${`<td style="text-align: center" >${bkg.containerCount}</td>`}
                    </tr>`
        })

        if (document.querySelectorAll(".menu-selected").length) {
            await this.getEmails(selectedBookings[0]);

            if (uniqueStatus[0] === "ACTIVE") {
                subject = `(${selectedBookings.length}) Batch Bkg Update - ${selectedBookings[0].vesselName} ${selectedBookings[0].voyageNumber} - ${selectedBookings[0].carrierName} ${selectedBookings[0].cargoCutDate} cutoff`;
                body = `<body><table style="font-size: 12px" ><thead><tr>
                <th style="font-weight: 900">Booking Number</th>
                ${shipperRefs ? `<th style="font-weight: 900">Shipper Ref #</th>` : ''}
                ${consignees ? `<th style="font-weight: 900">Consignee</th>` : ''}
                <th style="font-weight: 900">Count</th>
                </tr></thead>
                <tbody>
                ${tableString}
                </tbody></table><br/>
                <table border="1" style="font-size: 12px" ><thead><tr>
                    <th style="font-weight: 900">ITEM</th><th style="font-weight: 900">NEW DATA</th>
                </tr></thead>
                <tbody>
                    <tr><td>Vessel</td><td>${selectedBookings[0].vesselName} ${selectedBookings[0].voyageNumber}</td></tr>
                    <tr><td>Place of Receipt</td><td>${selectedBookings[0].placeOfReceiptFullName}</td></tr>
                    <tr><td>Port of Load</td><td>${selectedBookings[0].portOfLoadFullName}</td></tr>
                    <tr><td>Port of Discharge</td><td>${uniquePods.length > 1 ? 'VARIOUS' : selectedBookings[0].portOfDischargeFullName}</td></tr>
                    <tr><td>Place of Delivery</td><td>${uniquePlds.length > 1 ? "VARIOUS" : selectedBookings[0].destFullName}</td></tr>
                    ${selectedBookings[0].fullErd ? `<tr><td>ERD</td><td>${selectedBookings[0].fullErd}</td></tr>` : ''}
                    <tr><td>Cargo Cut Date</td><td>${selectedBookings[0].fullCargoCutDate} ${selectedBookings[0].fullCargoCutTime}</td></tr>
                    <tr><td>Doc Cut Date</td><td>${selectedBookings[0].fullDocCutDate} ${selectedBookings[0].fullDocCutTime}</td></tr>
                    <tr><td>ETD Date</td><td>${selectedBookings[0].fullEtd} </td></tr>
                    <tr><td>Return Location</td><td>${selectedBookings[0].returnLoc}</td></tr>
                </tbody></table><p>Please see attached booking acknowledgement sheets for greater detail.</p>`
                body = body + (`</p>\n\r
                        <p>Regards,</p>\r\n
                        <p>${finalName}\n<br>Lindsey Forwarders</p></body>`)
            } else if (uniqueStatus[0] === "CANCELLED") {
                subject = `(${selectedBookings.length}) CANCELLED BOOKINGS - ${selectedBookings[0].vesselName} ${selectedBookings[0].voyageNumber} - ${selectedBookings[0].carrierName} ${selectedBookings[0].cargoCutDate} cutoff`;
                body = `<body><p style="color: red">The following booking(s) are now cancelled.</p>
                <table style="font-size: 12px" ><thead><tr>
                <th style="font-weight: 900">Booking Number</th>
                ${shipperRefs ? `<th style="font-weight: 900">Shipper Ref #</th>` : ''}
                ${consignees ? `<th style="font-weight: 900">Consignee</th>` : ''}
                <th style="font-weight: 900">Count</th>
                </tr></thead>
                <tbody>
                ${tableString}
                </tbody></table><br/>
                <table border="1" style="font-size: 12px" ><thead><tr>
                    <th style="font-weight: 900">ITEM</th><th style="font-weight: 900">NEW DATA</th>
                </tr></thead>
                <tbody>
                    <tr><td>Vessel</td><td>${selectedBookings[0].vesselName} ${selectedBookings[0].voyageNumber}</td></tr>
                    <tr><td>Place of Receipt</td><td>${selectedBookings[0].placeOfReceiptFullName}</td></tr>
                    <tr><td>Port of Load</td><td>${selectedBookings[0].portOfLoadFullName}</td></tr>
                    <tr><td>Port of Discharge</td><td>${uniquePods.length > 1 ? 'VARIOUS' : selectedBookings[0].portOfDischargeFullName}</td></tr>
                    <tr><td>Place of Delivery</td><td>${uniquePlds.length > 1 ? "VARIOUS" : selectedBookings[0].destFullName}</td></tr>
                    ${selectedBookings[0].fullErd ? `<tr><td>ERD</td><td>${selectedBookings[0].fullErd}</td></tr>` : ''}
                    <tr><td>Cargo Cut Date</td><td>${selectedBookings[0].fullCargoCutDate} ${selectedBookings[0].fullCargoCutTime}</td></tr>
                    <tr><td>Doc Cut Date</td><td>${selectedBookings[0].fullDocCutDate} ${selectedBookings[0].fullDocCutTime}</td></tr>
                    <tr><td>ETD Date</td><td>${selectedBookings[0].fullEtd} </td></tr>
                    <tr><td>Return Location</td><td>${selectedBookings[0].returnLoc}</td></tr>
                </tbody></table><p>Please see attached booking acknowledgement sheets for greater detail.</p>`
                body = body + (`</p>\n\r
                        <p>Regards,</p>\r\n
                        <p>${finalName}\n<br>Lindsey Forwarders</p></body>`)
            } else {
                return this.props.dispatch({ type: globalToastActions.UPDATE_MSG, payload: { msg: "Must Be ACTIVE or CANCELLED Status Only", type: "error" } });
            }


        } else {
            return
        }

        let selectedBookingIds = selectedBookings.map(b => b.id);
        selectedBookingIds.forEach((bkg, index) => {
            attachments.push({
                id: index,
                bookingId: bkg,
                contentType: 'application/pdf',
                datetimeUploaded: '',
                directory: "SHIPMENTS/",
                fileName: `${selectedBookings[index].bookingNumber}_${datetime}`,
                fileType: this.state.usePdf ? 'pdf' : "html",
                isHidden: 0,
                lNumber: selectedBookings[index].lNumber,
                datetime,
                bookingNumber: selectedBookings[index].bookingNumber
            })
        });

        this.setState({ subject, body, selectedBookingIds, emailShipperId: selectedBookings[0].shipperId, attachments }, () => {
            M.Modal.getInstance(document.querySelector("#emailModal")).open();
            document.querySelector("#recipient").focus();
        })
    }


    hideModal = () => M.Modal.getInstance(document.querySelector('#print-modal')).close();

    hideEmailModal = () => { return M.Modal.getInstance(document.querySelector("#emailModal")).close() }

    clear = () => this.setState(this.initState(), () => {
        this.freshSearch();
        this.getEmployees();
        document.getElementById('shipperName').focus();
    })

    refresh = () => { this.getBookings() }

    printReport = async () => {
        const report = (await Axios.post(`/api/v1/pages/${this.state.reportType}`, { ...auth.getAuthData(), filters: this.state.filters, filterList: this.state.filterList })).data;

        if (report === 'NOT AUTHENTICATED') {
            localStorage.clear();
            this.props.dispatch({ type: 'SCORCHED_EARTH' })
            return;
        }

        const win = window.open('', '', 'width=960 height=1080');
        win.document.write(report);
    }

    sendBookingAcks = (emailInfoFromState, fileInfo) => {

        this.hideEmailModal();
        emailInfoFromState.tz = moment.tz.guess();
        let datetime = this.state.attachments[0].datetime;
        let userName = `${this.props.user.first.charAt(0)}${this.props.user.first.substring(1).toLowerCase()} ${this.props.user.last.charAt(0)}${this.props.user.last.substring(1).toLowerCase()}`;
        const list = document.querySelectorAll('.lf-portal-tbody>tr');
        list.forEach(tr => tr.classList.remove('menu-selected'));

        Axios.post('/api/v1/email/multi', { ...auth.getAuthData(), fileInfo, email: emailInfoFromState, userName, datetime, usePdf: this.state.usePdf }).then(async (result) => {
            if (result.data === "NOT AUTHENTICATED") {
                localStorage.clear();
                this.props.dispatch({
                    type: "SCORCHED_EARTH"
                });
                return;
            } else if (result.data.messageSent) {
                this.props.dispatch({
                    type: globalToastActions.UPDATE_MSG, payload: {
                        type: "success", msg: "E-Mail Sent"
                    }
                });
                return this.getBookings();
            } else {
                window.alert("Error: Email was not sent.");
                return this.props.dispatch({
                    type: globalToastActions.UPDATE_MSG, payload: {
                        type: "error", msg: "E-Mail Was Not Sent"
                    }
                });
            }
        })

    }

    resetFiles = (bool) => {
        this.setState({ usePdf: bool }, () => {
            this.showEmailModal();
        });
    }

    sort = e => {
        if (!e.target.getAttribute("data-sort")) {
            return;
        } else if (e.ctrlKey) {
            const value = typeof e === "string" ? this.props.url.state.filters.currentSort2 : e.target.getAttribute('data-sort');
            const reverse2 = this.state.filters.currentSort2 === value ? (-1 * this.state.filters.reverse2) : 1;
            this.setState({ filters: { ...this.state.filters, currentSort2: value, reverse2 } }, () => { this.getBookings(); })
        } else if (e.altKey) {
            const value = typeof e === "string" ? this.props.url.state.filters.currentSort3 : e.target.getAttribute('data-sort');
            const reverse3 = this.state.filters.currentSort3 === value ? (-1 * this.state.filters.reverse3) : 1;
            this.setState({ filters: { ...this.state.filters, currentSort3: value, reverse3 } }, () => { this.getBookings(); })
        } else {
            const value = typeof e === "string" ? this.props.url.state.filters.currentSort : e.target.getAttribute('data-sort');
            const reverse = this.state.filters.currentSort === value ? (-1 * this.state.filters.reverse) : 1;
            this.setState({ filters: { ...this.state.filters, currentSort: value, reverse } }, () => { this.getBookings(); })
        }
    }

    UNSAFE_componentWillMount = () => {
        if (this.props.url.state.origin === "/forecast") {
            this.setState({
                filterList: this.props.url.state.filterList ? this.props.url.state.filterList : this.initState().filterList,
                filters: { ...this.initState().filters, currentSort: this.props.url.state.filters.currentSort, reverse: this.props.url.state.filters.reverse }
            })
        } else {
            this.setState({
                filterList: this.props.url.state.filterList ? this.props.url.state.filterList : this.initState().filterList,
                filters: { ...this.initState().filters, ...this.props.url.state.filters }
            })
        }
    }

    componentDidMount = () => {
        this.initListeners(); this.initModal(); this.pageCheck(); this.getEmployees(); M.Collapsible.init(document.querySelector('.collapsible'));
        document.getElementById("shipperName").focus();
        this.outlineLastBooking();
        if (this.props.url.state.filters) {

            if (this.props.url.state.filters.originCode || this.props.url.state.filters.destCode ||
                this.props.url.state.filters.consigneeName || this.props.url.state.filters.returnLocation ||
                this.props.url.state.filters.portOfLoad || this.props.url.state.filters.billOfLadingNumber || this.props.url.state.filters.flaggedForReview) {
                M.Collapsible.init(document.querySelector('.collapsible')).open();
            }
        }
    }

    componentDidUpdate = (prevProps, prevState) => {
        if (this.state.filters.pageView !== prevState.filters.pageView) {
            this.initListeners();
            M.Collapsible.init(document.querySelector('.collapsible'));
        }
    }

    componentWillUnmount = () => { this.removeListeners(); }

    render = () => (
        <div>
            {this.state.preparingJson ?
                <div className={styles.transparentSpinner}>
                    <LoadingSpinner className={styles.contractLoadingSpinner} label="Preparing Data . . ." size="big" color="blue" />
                </div> : null}
            {this.state.processingJson ?
                <div className={styles.transparentSpinner}>
                    <LoadingSpinner className={styles.processingLoadingSpinner} label="Processing Data . . ." size="big" color="blue" /><br />
                    {/* <div className={styles.loadingLabel}>Processing Data . . .</div> */}
                </div> : null}
            {this.state.filters.pageView ?
                <div>
                    <div id="bkg-ov-search-fields">
                        <ul className="collapsible overviewCollapsible" style={{ padding: '12px' }} >
                            <li>
                                <div className="collapsible-header" onClick={this.openCol} style={{ margin: '0px', padding: '0px', maxHeight: '12px', border: 'none' }}>
                                    <i className="material-icons searchOpenIcon">{!this.state.searchOpen ? "expand_more" : "expand_less"}</i><p style={{ position: 'relative', bottom: '4px' }}>Advanced Search</p>
                                </div>
                                <div className="collapsible-body overviewCollapsibleBody" style={{ margin: '0px', border: 'none', paddingBottom: "0px" }}>
                                    <div className="row customMarginRemove">
                                        <Form.TextInput col="col s3 m3 l3 xl3 search-field" name="bookingNumber" label="Booking #" onChange={this.filterChange} value={this.state.filters.bookingNumber} flags={[flags.ALLOW_EMPTY]} />
                                        <Form.TextInput col="col s4 m4 l4 xl4 search-field" name="shipperRef" label="Ref #" onChange={this.filterChange} value={this.state.filters.shipperRef} flags={[flags.ALLOW_EMPTY]} />
                                        <Form.TextInput col="col s3 m3 l3 xl3 search-field filterList" name="consigneeName" label="Consignee" onChange={this.filterChange} value={this.state.filters.consigneeName} flags={[flags.ALLOW_EMPTY]} />
                                        <Form.TextInput col="col s2 m2 l2 xl2 search-field" name="lNumber" label="L #" onChange={this.filterChange} value={this.state.filters.lNumber} flags={[flags.ALLOW_EMPTY]} />
                                    </div>
                                    <div className="row customMarginRemove">
                                        <Form.Select col="col s1 m1 l1 xl1 search-field" name="stage" label="Stage" onChange={this.filterChangePlus} value={this.state.filters.stage} filter={{ label: 'label', value: 'label' }} list={this.getStageValues()} />
                                        <Form.Select col="s2 search-field" name="employee" label="Assigned To" onChange={this.filterChangePlus} value={this.state.filters.employee} list={this.state.employees} filter={{ label: "name", value: "id" }} flags={[flags.ALLOW_EMPTY]} />
                                        <Form.TextInput col="col s2 m2 l2 xl2 search-field filterList" name="pickupLocation" label="Pickup" onChange={this.filterChange} value={this.state.filters.pickupLocation} flags={[flags.ALLOW_EMPTY]} />
                                        <Form.TextInput col="col s2 m2 l2 x12 search-field filterList" name="returnLocation" label="Return" onChange={this.filterChange} value={this.state.filters.returnLocation} flags={[flags.ALLOW_EMPTY]} />
                                        <Form.TextInput col="col s3 m3 l3 xl3 search-field" name="destCountryName" label="Dest Country" onChange={this.filterChange} value={this.state.filters.destCountryName} flags={[flags.ALLOW_EMPTY]} />
                                        <div className="col s2 m l2 xl2 overviewFilterButtonsContainer">
                                            <Form.Button col="" color="blue" type="outline" icon="print" onClick={this.showModal} tooltip="Print" />
                                            <Form.Button col={` ${this.state.filters.flaggedForReview ? "chartButtonPressed" : ""}`} tooltip="Flagged" type="outline" name="flaggedForReview" color="black" icon="flag" onClick={this.flaggedFilterPress} />
                                            <Form.Button col={` ${this.state.filters.showGraph ? "chartButtonPressed" : ""}`} tooltip="Show Graph" type="outline" color="black" name="pageView" icon="insert_chart" onClick={this.showGraph} />
                                            <Form.Button col={`${this.state.filters.pageView ? "chartButtonPressed" : ""}`} tooltip="Docs Due" color="black" type="outline" icon='view_list' onClick={this.pageViewChange} />
                                            {/* <Form.Button col={`overviewFilterButton `} tooltip="Upload JSON" color="black" type="outline" icon="cloud_upload" onClick={this.showFileUploadWindow} /> */}
                                            {/* <input type="file" id={`hiddenJsonUploadButton`} onChange={this.uploadJson} /> */}
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                        <div className="row customMarginRemove">
                            <Form.Select col="col s2 m2 l2 xl1 search-field" name="dateType" label="Date Type" onChange={this.filterChange} value={this.state.filters.dateType} list={this.getDateTypes()} filter={{ label: 'label', value: 'label' }} />
                            <div className="col s2 m3 l3 xl1 row dateSearchButtonContainer">
                                <Form.Button col="s3 dateSearchButtons" icon="today" iconClass="tiny" type="outline" color="black" onClick={this.setToToday} />
                                <Form.Button col="s3 dateSearchButtons" icon="view_week" iconClass="tiny" type="outline" color="black" onClick={this.setToThisWeek} />
                                <Form.Button col="s3 dateSearchButtonsArrowBack" icon="arrow_backward" iconClass="tiny" type="outline" color="black" onClick={this.decreaseWeek} />
                                <Form.Button col="s3 dateSearchButtons dateSearchButtonsRight" icon="arrow_forward" iconClass="tiny" type="outline" color="black" onClick={this.increaseWeek} />
                            </div>
                            <Form.DateInput col="col s2 m2 l2 xl1 search-field date-field" name="startDate" label="Start Date" onChange={this.filterChange} value={this.state.filters.startDate} flags={[flags.ALLOW_EMPTY]} />
                            <Form.DateInput col="col s2 m2 l2 xl1 search-field date-field" name="endDate" label="End Date" onChange={this.filterChange} value={this.state.filters.endDate} flags={[flags.ALLOW_EMPTY]} />
                            <Form.TextInput col="col s2 m3 l3 xl2 search-field filterList" name="portOfLoad" label="POL" onChange={this.filterChange} value={this.state.filters.portOfLoad} flags={[flags.ALLOW_EMPTY]} />
                            <Form.TextInput col="col s3 m3 l3 xl2 search-field filterList" name="vesselName" label="Vessel" onChange={this.filterChange} value={this.state.filters.vesselName} flags={[flags.ALLOW_EMPTY]} />
                            <Form.TextInput col="col s2 m3 l3 xl1  search-field filterList push" name="voyageNumber" label="Voyage" onChange={this.filterChange} value={this.state.filters.voyageNumber} flags={[flags.ALLOW_EMPTY]} />
                            <Form.TextInput col="col s2 m2 l2 xl1 search-field filterList" name="serviceRoute" label="Service" onChange={this.filterChange} value={this.state.filters.serviceRoute} flags={[flags.ALLOW_EMPTY]} />
                            <Form.Button col="col s2 m2 l2 xl1 searchButtons" color="blue" type="outline" label="Search" icon="search" onClick={this.populateFilterBubbles} />
                            <Form.Button col="col s2 m2 l2 xl1 searchButtons clearButton" color="red" type="outline" label="Clear" icon="clear" onClick={this.clear} />
                        </div>
                        <div className="row customMarginRemove">
                            <Form.Select col="s1 search-field" className="status-select filterList" name="status" label="Status" onChange={this.filterChange} value={this.state.filters.status} list={this.getStatuses()} filter={{ label: 'label', value: 'label' }} />
                            <Form.Select col="s1 search-field" name="isDummy" label="Dummy" onChange={this.docsDueDummyOnChange} value={this.state.filters.isDummy} list={this.getDummyDropDownValues()} filter={{ label: 'label', value: 'label' }} />
                            <Form.TextInput col="s2 search-field filterList" name="shipperName" label="Shipper" onChange={this.filterChange} value={this.state.filters.shipperName} flags={[flags.ALLOW_EMPTY]} />
                            <Form.TextInput col="s2 search-field filterList" name="carrierName" label="Carrier" onChange={this.filterChange} value={this.state.filters.carrierName} flags={[flags.ALLOW_EMPTY]} />
                            <Form.TextInput col="col s2 search-field filterList" name="originCode" label="Origin" onChange={this.filterChange} value={this.state.filters.originCode} flags={[flags.ALLOW_EMPTY]} />
                            <Form.TextInput col="col s2 search-field filterList" name="destCode" label="Destination" onChange={this.filterChange} value={this.state.filters.destCode} flags={[flags.ALLOW_EMPTY]} />
                            <Form.Select col="col s2 search-field" name="reportFormat" label="Format" onChange={this.changeReportFormat} value={this.state.filters.reportFormat} list={this.getReportFormats()} filter={{ label: "label", value: "id" }} flags={[flags.ALLOW_EMPTY]} />
                        </div>
                        <div className="row" style={{ marginBottom: "0px" }}>
                            <FilterContainer col="s12" filterList={this.state.filterList} spliceFilterList={this.spliceFilterList} />
                        </div>
                    </div>
                    <OverviewGraph filters={this.state.filters} filterList={this.state.filterList} bookings={this.state.bookings} dateType={this.state.filters.dateType} startDate={this.state.filters.startDate} endDate={this.state.filters.endDate} showGraph={this.state.filters.showGraph} />
                    <table className="lf-portal-table">
                        <TableHeader
                            headers={[
                                { label: "SI", attribute: "" },
                                { label: "", attribute: "" },
                                { label: "", attribute: "" },
                                { label: "Booking #", attribute: "bookingNumber" },
                                { label: "Ref #", attribute: "shipperReferenceNumber" },
                                { label: "Shipper", attribute: "shipperName" },
                                { label: "Consignee", attribute: "consigneeName" },
                                { label: "Carrier", attribute: "carrierName" },
                                { label: "FCL", attribute: "containerCount" },
                                { label: "Origin", attribute: "originName" },
                                { label: "POL", attribute: "portOfLoad" },
                                { label: "Dest", attribute: "destName" },
                                { label: "Vessel", attribute: "vesselName" },
                                { label: "Voyage", attribute: "voyageNumber" },
                                { label: "Cut", attribute: "cargoCutDate" },
                                { label: "Doc", attribute: "docCutDate" },
                                { label: "AES", attribute: "aes" },
                                { label: "ETD", attribute: "etd" },
                                { label: "", attribute: "" }
                            ]}
                            sort={this.sort}
                            filters={this.state.filters}
                        />
                        <tbody className={`lf-portal-tbody`}>
                            <ExdecResultsDisplay
                                handleContextMenu={this.handleContextMenu}
                                handleDelete={this.handleDelete}
                                handleStatusMenu={this.handleStatusMenu}
                                handleAssigneeMenu={this.handleAssigneeMenu}
                                editBkg={this.state.editBkg}
                                bookings={this.state.bookings}
                                notesArray={this.state.notesArray}
                                submitUserNames={this.state.submitUserNames}
                                getBookings={this.getBookings}
                                filters={this.state.filters}
                                filterList={this.state.filterList}
                                loading={this.state.loading}
                                isMouseDown={this.state.isMouseDown}
                                copyBookingNumbers={this.copyBookingNumbers}
                                setIndexOnFocus={this.setIndexOnFocus}
                            />
                        </tbody>
                    </table>
                </div>
                :
                <div>
                    <div id="bkg-ov-search-fields">
                        <ul className="collapsible overviewCollapsible" style={{ padding: '12px' }} >
                            <li>
                                <div className="collapsible-header" onClick={this.openCol} style={{ margin: '0px', padding: '0px', maxHeight: '12px', border: 'none' }}>
                                    <i className="material-icons searchOpenIcon">{!this.state.searchOpen ? "expand_more" : "expand_less"}</i><p style={{ position: 'relative', bottom: '4px' }}>Advanced Search</p>
                                </div>
                                <div className="collapsible-body overviewCollapsibleBody" style={{ margin: '0px', border: 'none', paddingBottom: "0px" }}>
                                    <div className="row customMarginRemove">
                                        {this.state.showBookingsAssignedTo ?
                                            <Fragment>
                                                <Form.TextInput col="col s4 m4 l4 xl2 search-field" name="bookingNumber" label="Booking #" onChange={this.filterChange} value={this.state.filters.bookingNumber} flags={[flags.ALLOW_EMPTY]} />
                                                <Form.TextInput col="col s2 m2 l2 xl4 search-field" name="shipperRef" label="Ref #" onChange={this.filterChange} value={this.state.filters.shipperRef} flags={[flags.ALLOW_EMPTY]} />
                                                <Form.TextInput col="col s2 m2 l2 xl3 search-field filterList" name="consigneeName" label="Consignee" onChange={this.filterChange} value={this.state.filters.consigneeName} flags={[flags.ALLOW_EMPTY]} />
                                                <Form.TextInput col="col s2 m2 l2 xl1 search-field" name="lNumber" label="L #" onChange={this.filterChange} value={this.state.filters.lNumber} flags={[flags.ALLOW_EMPTY]} autoComplete="off" />
                                                <Form.Select col="col s2 m2 l2 xl2" name="bookingsAssignedTo" value={this.state.filters.bookingsAssignedTo} label="Assigned To" onChange={this.filterChangePlus} list={this.state.employees} filter={{ label: "name", value: "id" }} flags={[flags.ALLOW_EMPTY]} />
                                            </Fragment>
                                            :
                                            <Fragment>
                                                <Form.TextInput col="col s4 m4 l4 xl2 search-field" name="bookingNumber" label="Booking #" onChange={this.filterChange} value={this.state.filters.bookingNumber} flags={[flags.ALLOW_EMPTY]} />
                                                <Form.TextInput col="col s2 m2 l2 xl4 search-field" name="shipperRef" label="Ref #" onChange={this.filterChange} value={this.state.filters.shipperRef} flags={[flags.ALLOW_EMPTY]} />
                                                <Form.TextInput col="col s2 m2 l2 xl3 search-field filterList" name="consigneeName" label="Consignee" onChange={this.filterChange} value={this.state.filters.consigneeName} flags={[flags.ALLOW_EMPTY]} />
                                                <Form.TextInput col="col s4 m4 l4 xl3 search-field" name="lNumber" label="L #" onChange={this.filterChange} value={this.state.filters.lNumber} flags={[flags.ALLOW_EMPTY]} autoComplete="off" />
                                            </Fragment>
                                        }
                                    </div>
                                    <div className="row customMarginRemove">
                                        <Form.TextInput col="col s2 m2 l2 xl2 search-field" name="billOfLadingNumber" label="Bill of Lading #" onChange={this.filterChange} value={this.state.filters.billOfLadingNumber} flags={[flags.ALLOW_EMPTY]} />
                                        <Form.TextInput col="col s2 m2 l2 xl2 search-field filterList" name="pickupLocation" label="Pickup" onChange={this.filterChange} value={this.state.filters.pickupLocation} flags={[flags.ALLOW_EMPTY]} />
                                        <Form.TextInput col="col s2 m2 l2 x12 search-field filterList" name="returnLocation" label="Return" onChange={this.filterChange} value={this.state.filters.returnLocation} flags={[flags.ALLOW_EMPTY]} />
                                        <Form.TextInput col="col s2 m2 l3 xl3 search-field" name="destCountryName" label="Dest Country" onChange={this.filterChange} value={this.state.filters.destCountryName} flags={[flags.ALLOW_EMPTY]} />
                                        <div className="col s4 m4 l3 xl3 overviewFilterButtonsContainer">
                                            <Form.Button col="overviewFilterButton" color="blue" tooltip="Print" type="outline" icon="print" onClick={this.showModal} />
                                            <Form.Button col={` overviewFilterButton ${this.state.filters.flaggedForReview ? "chartButtonPressed" : ""}`} tooltip="Flagged" type="outline" name="flaggedForReview" color="black" icon="flag" onClick={this.flaggedFilterPress} />
                                            <Form.Button col={` overviewFilterButton ${this.state.filters.showGraph ? "chartButtonPressed" : ""}`} tooltip="Show Graph" type="outline" color="black" name="pageView" icon="insert_chart" onClick={this.showGraph} />
                                            <Form.Button col={` overviewFilterButton ${this.state.filters.pageView ? "chartButtonPressed" : ""}`} tooltip="Docs Due" color="black" type="outline" icon='view_list' onClick={this.pageViewChange} />
                                            {/* <Form.Button col={`overviewFilterButton `} tooltip="Upload JSON" color="black" type="outline" icon="cloud_upload" onClick={this.showFileUploadWindow} />
                                            <input type="file" id={`hiddenJsonUploadButton`} onChange={this.uploadJson} /> */}
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                        <div className="row customMarginRemove">
                            <Form.Select col="col s2 m2 l2 xl1 search-field" name="dateType" label="Date Type" onChange={this.filterChange} value={this.state.filters.dateType} list={this.getDateTypes()} filter={{ label: 'label', value: 'label' }} />
                            <div className="col s2 m3 l3 xl1 row dateSearchButtonContainer">
                                <Form.Button col="s3 dateSearchButtons" icon="today" iconClass="tiny" type="outline" color="black" onClick={this.setToToday} />
                                <Form.Button col="s3 dateSearchButtons" icon="view_week" iconClass="tiny" type="outline" color="black" onClick={this.setToThisWeek} />
                                <Form.Button col="s3 dateSearchButtonsArrowBack" icon="arrow_backward" iconClass="tiny" type="outline" color="black" onClick={this.decreaseWeek} />
                                <Form.Button col="s3 dateSearchButtons dateSearchButtonsRight" icon="arrow_forward" iconClass="tiny" type="outline" color="black" onClick={this.increaseWeek} />
                            </div>
                            <Form.DateInput col="col s2 m2 l2 xl1 search-field" name="startDate" label="Start Date" onChange={this.filterChange} value={this.state.filters.startDate} flags={[flags.ALLOW_EMPTY]} />
                            <Form.DateInput col="col s2 m2 l2 xl1 search-field" name="endDate" label="End Date" onChange={this.filterChange} value={this.state.filters.endDate} flags={[flags.ALLOW_EMPTY]} />
                            <Form.TextInput col="col s2 m3 l3 xl2 search-field filterList" name="portOfLoad" label="POL" onChange={this.filterChange} value={this.state.filters.portOfLoad} flags={[flags.ALLOW_EMPTY]} />
                            <Form.TextInput col="col s3 m3 l3 xl2 search-field filterList" name="vesselName" label="Vessel" onChange={this.filterChange} value={this.state.filters.vesselName} flags={[flags.ALLOW_EMPTY]} />
                            <Form.TextInput col="col s2 m3 l3 xl1  search-field filterList push" name="voyageNumber" label="Voyage" onChange={this.filterChange} value={this.state.filters.voyageNumber} flags={[flags.ALLOW_EMPTY]} />
                            <Form.TextInput col="col s2 m2 l2 xl1 search-field filterList" name="serviceRoute" label="Service" onChange={this.filterChange} value={this.state.filters.serviceRoute} flags={[flags.ALLOW_EMPTY]} />
                            <Form.Button col="col s2 m2 l2 xl1 searchButtons" color="blue" type="outline" label="Search" icon="search" onClick={this.populateFilterBubbles} />
                            <Form.Button col="col s2 m2 l2 xl1 searchButtons clearButton" color="red" type="outline" label="Clear" icon="clear" onClick={this.clear} />
                        </div>
                        <div className="row customMarginRemove">
                            <Form.Select col="s1 search-field" className="status-select filterList" optionClassName="status-option" name="status" label="Status" onChange={this.filterChange} value={this.state.filters.status} list={this.getStatuses()} filter={{ label: 'label', value: 'label' }} />
                            <Form.Select col="s1 search-field" name="isDummy" label="Dummy" onChange={this.filterChangePlus} value={this.state.filters.isDummy} list={this.getDummyDropDownValues()} filter={{ label: 'label', value: 'label' }} />
                            <Form.TextInput col="s2 search-field filterList" name="shipperName" label="Shipper" onChange={this.filterChange} value={this.state.filters.shipperName} flags={[flags.ALLOW_EMPTY]} />
                            <Form.TextInput col="s2 search-field filterList" name="carrierName" label="Carrier" onChange={this.filterChange} value={this.state.filters.carrierName} flags={[flags.ALLOW_EMPTY]} />
                            <Form.TextInput col="col s2 search-field filterList" name="originCode" label="Origin" onChange={this.filterChange} value={this.state.filters.originCode} flags={[flags.ALLOW_EMPTY]} />
                            <Form.TextInput col="col s2 search-field filterList" name="destCode" label="Destination" onChange={this.filterChange} value={this.state.filters.destCode} flags={[flags.ALLOW_EMPTY]} />
                            <Form.Select col="col s2 search-field" name="reportFormat" label="Format" onChange={this.changeReportFormat} value={this.state.filters.reportFormat} list={this.getReportFormats()} filter={{ label: "label", value: "id" }} flags={[flags.ALLOW_EMPTY]} />
                        </div>
                        <div className="row" style={{ marginBottom: "0px" }}>
                            <FilterContainer col="s12" filterList={this.state.filterList} spliceFilterList={this.spliceFilterList} />
                        </div>
                    </div>
                    <OverviewGraph filters={this.state.filters} filterList={this.state.filterList} bookings={this.state.bookings} dateType={this.state.filters.dateType} startDate={this.state.filters.startDate} endDate={this.state.filters.endDate} showGraph={this.state.filters.showGraph} />
                    <table className="lf-portal-table-shipment-overview">
                        <TableHeader
                            headers={
                                this.state.showBookingsAssignedTo ?
                                    [
                                        { label: "", attribute: "" },
                                        { label: "", attribute: "" },
                                        { label: "", attribute: "" },
                                        { label: "", attribute: "" },
                                        { label: "Booking #", attribute: "bookingNumber" },
                                        { label: "L #", attribute: "lNumber" },
                                        { label: "Ref #", attribute: "shipperReferenceNumber" },
                                        { label: "Shipper", attribute: "shipperName" },
                                        { label: "Carrier", attribute: "carrierName" },
                                        { label: "FCL", attribute: "containerCount" },
                                        { label: "Origin", attribute: "origin" },
                                        { label: "POL", attribute: "portOfLoad" },
                                        { label: "Dest", attribute: "dest" },
                                        { label: "Vessel", attribute: "vesselName" },
                                        { label: "Voyage", attribute: "voyageNumber" },
                                        { label: "Service", attribute: "serviceRoute" },
                                        { label: "ERD", attribute: "erd" },
                                        { label: "Cut", attribute: "cargoCutDate" },
                                        { label: "Doc", attribute: "docCutDate" },
                                        { label: "ETD", attribute: "etd" },
                                        { label: "Pickup", attribute: "pickup" },
                                        { label: "Return", attribute: "return" },
                                        { label: "SI", attribute: "" }
                                    ] :
                                    [
                                        { label: "", attribute: "" },
                                        { label: "", attribute: "" },
                                        { label: "", attribute: "" },
                                        { label: "Booking #", attribute: "bookingNumber" },
                                        { label: "L #", attribute: "lNumber" },
                                        { label: "Ref #", attribute: "shipperReferenceNumber" },
                                        { label: "Shipper", attribute: "shipperName" },
                                        { label: "Carrier", attribute: "carrierName" },
                                        { label: "FCL", attribute: "containerCount" },
                                        { label: "Origin", attribute: "origin" },
                                        { label: "POL", attribute: "portOfLoad" },
                                        { label: "Dest", attribute: "dest" },
                                        { label: "Vessel", attribute: "vesselName" },
                                        { label: "Voyage", attribute: "voyageNumber" },
                                        { label: "Service", attribute: "serviceRoute" },
                                        { label: "ERD", attribute: "erd" },
                                        { label: "Cut", attribute: "cargoCutDate" },
                                        { label: "Doc", attribute: "docCutDate" },
                                        { label: "ETD", attribute: "etd" },
                                        { label: "ETA", attribute: "eta" },
                                        { label: "Pickup", attribute: "pickup" },
                                        { label: "Return", attribute: "return" },
                                        { label: "SI", attribute: "" }
                                    ]
                            }
                            sort={this.sort}
                            filters={this.state.filters}
                        />
                        <tbody className={`lf-portal-tbody`}>
                            <ResultsDisplay
                                handleContextMenu={this.handleContextMenu}
                                handleMultipleBookingAckMenu={this.handleMultipleBookingAckMenu}
                                showEmailMenu={this.state.showEmailMenu}
                                showEmailModal={this.showEmailModal}
                                hideEmailModal={this.hideEmailModal}
                                closeEmailMenu={this.closeEmailMenu}
                                toggleFlag={this.toggleFlag}
                                addOrRemoveFlag={this.addOrRemoveFlag}
                                handleDelete={this.handleDelete}
                                handleStatusMenu={this.handleStatusMenu}
                                showBookingsAssignedTo={this.state.showBookingsAssignedTo}
                                editBkg={this.state.editBkg}
                                bookings={this.state.bookings}
                                getBookings={this.getBookings}
                                filters={this.state.filters}
                                filterList={this.state.filterList}
                                x={this.state.x}
                                y={this.state.y}
                                loading={this.state.loading}
                                isMouseDown={this.state.isMouseDown}
                                editBkgIndex={this.state.editBkgIndex}
                                setIndexOnFocus={this.setIndexOnFocus}
                                copyBookingNumbers={this.copyBookingNumbers}
                            />
                        </tbody>
                    </table>
                    <EmailForm
                        shipperId={this.state.emailShipperId}
                        onChange={this.onChange}
                        generatePdf={false}
                        emails={this.state.emails}
                        party={this.props.party}
                        showEmailModal={this.showEmailModal}
                        hideEmailModal={this.hideEmailModal}
                        subject={this.state.subject}
                        body={this.state.body}
                        send={this.sendBookingAcks}
                        attachments={this.state.attachments}
                        disableMergeCheckBox={true}
                        batchEmails={true}
                        resetFiles={this.resetFiles}
                    />
                </div>
            }
            <PageCountDisplay
                url={this.props.url.url}
                pageJump={this.pageJump}
                singlePageTurn={this.singlePageTurn}
                count={this.state.filters.count}
                page={this.state.filters.page}
                pageReset={this.props.url.state.origin ? true : false}
            />

            {this.state.filters.vesselName ? <b className="containerResultsCount">Total Containers: {this.state.filters.containerResultsCount}/{this.state.filters.enteredContainerResultsCount}</b> : <b></b>}
            {this.state.filters.showCount ? <b className="resultsCount">Total Results: {this.state.filters.resultsCount}</b> : <b></b>}
            <div style={{ position: 'fixed', top: this.state.y, left: this.state.x }} className="lf-shipment-context-menu">
                <ul className="collection">
                    {this.state.showContextMenu && <li className="collection-item blue-text" onClick={this.toggleFlag}>TOGGLE FLAG</li>}
                    {this.state.showStatusMenu && <li onClick={() => this.changeStatus('ACTIVE')} className="collection-item green-text">ACTIVE</li>}
                    {this.state.showStatusMenu && <li onClick={() => this.changeStatus('CLOSED')} className="collection-item grey-text">CLOSED</li>}
                    {this.state.showStatusMenu && <li onClick={() => this.changeStatus('CANCELLED')} className="collection-item red-text">CANCELLED</li>}
                    {this.state.showStatusMenu && <li onClick={this.handleDelete} className="collection-item black-text" >ARCHIVE</li>}
                    {this.state.showAssigneeMenu && <li className="collection-item black-text contextList">
                        Assign SI Submission To:&nbsp;
                        <Form.Select col="s2 search-field contextMenuSelect" className="contextMenuSelect" name="submitSiAssignTo" onChange={this.filterChange} value={this.state.filters.submitSiAssignTo} list={this.state.employees.filter(e => e.name !== "ANY")} filter={{ label: "name", value: "id" }} flags={[flags.ALLOW_EMPTY]} />
                        <button className="assignButton" onClick={() => this.changeAssignee()}>Assign</button>
                    </li>}
                    {this.state.showAssigneeMenu && <li className="collection-item black-text material-icons cancelOption" onClick={() => this.setState({ showAssigneeMenu: false })}>
                        <span className="cancelWords"><i className="material-icons">cancel</i></span></li>}
                </ul>
            </div>
            <div className="modal" id="print-modal">
                <div className="modal-content">
                    <h4>Print Report</h4>
                    <div className="row">
                        <Form.Radio name="reportType" value="bookingsummary" label="Booking Summary" checked={this.state.reportType === "bookingsummary"} onChange={this.onChange} />
                        <Form.Radio name="reportType" value="exdecreport" label="Docs Due Report" onChange={this.onChange} />
                    </div>
                    <div className="row">
                        <Form.Button onClick={this.printReport} label="Print" type="outline" color="blue" />
                        <Form.Button onClick={this.hideModal} label="Cancel" type="outline" color="red" />
                    </div>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    const { location, user, urls, index, portalUser } = state;
    const url = urls[index];
    return { location, user, url, portalUser };
}
export default connect(mapStateToProps)(ShipmentOverview);
import axios from 'axios';
import React, { useState, useEffect, useLayoutEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { flags } from '../../constants';
import { auth, logout } from '../../helperFunctions';
import Form from '../NewComponents';
import styles from './sidebar.module.css';
import { globalToastActions, urlActions } from '../../Redux/actions'
const componentId = '_' + crypto ? crypto.randomUUID() : Math.random

const GlobalSearch = props => {

    //HAVE input value persist via redux
    const [globalSearch, setGlobalSearch] = useState('');
    const [searching, setSearching] = useState(false);

    const dispatch = useDispatch();

    const onChange = (name, value) => setGlobalSearch(value);

    const search = e => {
        const { key, type } = e;
        const { globalSearch } = ref.current;

        if (type && type === 'paste') {
            const text = e.clipboardData.getData('Text').trim()

            if (text)
                searchDb(text);
        }
        else if (key && key === 'Enter') {
            if (globalSearch)
                searchDb(globalSearch)
        }
    }

    const searchDb = (queryString) => {
        setSearching(true);
        ref.current.abortController && ref.current.abortController.abort();
        ref.current.abortController = new AbortController();

        axios.get('/api/v2/booking/read/globalsearch', {
            params: {
                ...auth.getAuthData(),
                queryString
            },
            signal: ref.current.abortController.signal
        })
            .then(result => {
                if (!result.data || !result.data.length)
                    dispatch({ type: globalToastActions.UPDATE_MSG, payload: { msg: 'No Results', type: 'warning' } })
                else {
                    let url;
                    let state = {};
                    switch (result.data[0].type) {
                        case 'booking':
                            url = `/shipment/create/${result.data[0].data}`;
                            state = { searchTerm: result.data[0].data };
                            break;
                        case 'shipping':
                            url = `/shipment/instructions`;
                            state = { searchTerm: result.data[0].data };
                            break;
                        case 'forecast':
                            if (result.data.length > 1) {
                                const uniqueWeeks = []
                                const arr = result.data.map(row => row.data).forEach(wk => {
                                    if (uniqueWeeks.indexOf(wk) === -1)
                                        uniqueWeeks.push(wk)
                                })


                                dispatch({
                                    type: globalToastActions.UPDATE_MSG, payload: {
                                        msg: `Multiple results on the following weeks: ${uniqueWeeks.join(', ')}`,
                                        type: 'warning',
                                        displayLength: 5000
                                    }
                                })
                            }

                            url = '/forecast';
                            state = {
                                filters: {
                                    bookingNumber: ref.current.globalSearch,
                                    forecastAssignedTo: 0,
                                    bookingAssignedTo: 0,
                                    week: parseInt(result.data[0].data)
                                }
                            };
                            break;
                        default:
                            return;
                    }


                    dispatch({
                        type: urlActions.UPDATE_URL,
                        payload: { url, state }
                    })
                }
            })
            .catch(logout)
            .then(() => setSearching(false))
    }

    const selectContents = e => e.target.select();

    const focusGlobalSearch = e => {
        const { ctrlKey, keyCode } = e;

        if (ctrlKey && keyCode === 69) {
            e.preventDefault();
            document.querySelector(`#global-search-${componentId}`).select();
        }
    }

    const ref = useRef({
        globalSearch,
        abortController: new AbortController()
    });
    useEffect(() => ref.current.globalSearch = globalSearch.trim(), [globalSearch])

    useEffect(() => {
        const id = `#global-search-${componentId}`;
        const component = document.querySelector(id);

        const initListeners = () => {
            component.addEventListener('keyup', search);
            component.addEventListener('paste', search);
            component.addEventListener('click', selectContents);
        }

        if (component) { initListeners(); }
        document.addEventListener('keydown', focusGlobalSearch);

        if (component) { component.select() }

        return () => ref.current.abortController.abort();
    }, [])

    useLayoutEffect(() => {
        const id = `#global-search-${componentId}`;
        const component = document.querySelector(id);


        const removeListeners = () => {
            if (component) {
                component.removeEventListener('keyup', search);
                component.removeEventListener('paste', search);
                component.removeEventListener('click', selectContents);
            }

            document.removeEventListener('keydown', focusGlobalSearch);
        }

        return removeListeners;
    }, [])

    return (
        <Form.TextInput
            label="Search"
            labelStyle={styles.label}
            col="collection-item s12"
            name={`global-search-${componentId}`}
            placeholder="Booking / L-Number"
            onChange={onChange}
            value={globalSearch}
            flags={[flags.ALLOW_EMPTY]}
        />
    )
}

export default GlobalSearch;
import React from 'react';
import styles from './textinputSmall.module.css';
import { types } from '../../constants';
import { inputValidation } from '../../helperFunctions';



class TextInputSmall extends React.Component {
    constructor(props) {
        super(props); this.state = this.initState()
    }

    componentDidUpdate = prevProps => {
        if (prevProps !== this.props) {
            this.setState({ value: this.props.value })
        }
    }

    initState = () => ({ err: null, value: this.props.value })
    onChange = e => {
        const value = e.target.value;
        this.props.onChange(this.props.name, value);
    }

    getDivClassName = () => {
        return this.props.col ? `col ${this.props.col} ${styles.div}` : styles.div;
    }

    genInputClass = () => {
        return `browser-default ${this.props.stylingClass} ${styles.input} ${this.state.err ? styles.err : ''} ${this.props.disabled ? styles.disabled : ''}`;
    }

    getInputErrInfo = () => ([{ name: 'value', type: types.STRING, value: this.props.value, flags: this.props.flags }])

    onBlur = () => {
        if (this.props.noblurcap) {
            return
        } else {
            inputValidation(this.getInputErrInfo(), this.success, this.failure);
            this.setState(p => ({ value: p.value ? p.value.toString().toUpperCase() : p.value }), () => this.props.onChange(this.props.name, this.state.value ? this.state.value.toString().toUpperCase() : ''))
        }
    }

    success = value => this.setState({ err: null }, this.props.onBlur);
    failure = value => this.setState({ err: { msg: value.value.msg } }, this.props.onBlur);

    render = () => (
        <div className={this.getDivClassName()}>
            <input
                type="text"
                noblurcap={this.props.noblurcap}
                autoFocus={this.props.autoFocus ? this.props.autoFocus : false}
                data-name={this.props.data}
                className={this.genInputClass()}
                value={this.props.value}
                onChange={this.onChange}
                onBlur={this.onBlur}
                id={this.props.name}
                disabled={this.props.disabled}
                placeholder={this.props.placeholder}
                maxLength={this.props.maxLength ? this.props.maxLength : ""}
                tabIndex={this.props.tabIndex}
            />
            {this.props.label ?
                <label className={`${this.props.labelStyle ? this.props.labelStyle : styles.label} ${this.props.labelClassName}`}>{this.props.label} {this.props.required ? <span style={{ color: "red" }}>*</span> : ''}</label>
                : null}
            {this.state.err ? <span className={styles.msg}>{this.state.err.msg}</span> : null}
        </div>
    )
}

export default TextInputSmall;
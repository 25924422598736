import { createStore } from "redux";
import {
  tabFunctions,
  urlFunctions,
  sidebarFunctions,
  navFunctions,
  initFunctions,
  globalToastFunctions,
  updateFunctions
} from "./reducerFunctions";
import {
  tabActions,
  urlActions,
  sidebarActions,
  navActions,
  init,
  globalToastActions,
  updateActions
} from "./actions";

const defaultStore = {
  index: 0,
  tabCount: 1,
  navs: [navFunctions.createDefaultNav()],
  urls: [urlFunctions.createDefaultUrl()],
  sidebars: [tabFunctions.createDefaultTab()],
  globalToast: {}
};

const reducer = (state = defaultStore, action) => {
  switch (action.type) {
    case "SCORCHED_EARTH":
      localStorage.clear()
      return {
        index: 0,
        tabCount: 1,
        navs: [navFunctions.createDefaultNav()],
        urls: [urlFunctions.createDefaultUrl()],
        sidebars: [tabFunctions.createDefaultTab()],
        globalToast: {},
        logout: true
      };

    case init.INITIALIZE_STORE:
      return initFunctions.initializeList(
        state,
        action.payload.listName,
        action.payload.list
      );

    //START NAV FUNCTIONS
    case navActions.CHANGE_ID:
      return navFunctions.changeId(state, action.payload);
    case navActions.SET_FILTER:
      return navFunctions.setFilter(state, action.payload);
    case navActions.SET_SIDEBAR:
      return navFunctions.setSearchTerm(state, action.payload);
    

    //START TAB FUNCTIONS
    case tabActions.ADD_TAB:
      return tabFunctions.addTab(state);
    case tabActions.TAB_LEFT:
      return tabFunctions.decrementCurrentTab(state);
    case tabActions.TAB_RIGHT:
      return tabFunctions.incrementCurrentTab(state);
    case tabActions.SELECT_TAB:
      return tabFunctions.selectTab(state, action.payload);
    case tabActions.CLOSE_TAB:
      return tabFunctions.closeTab(state, action.payload);
    // --END TAB FUNCTIONS

    //START SIDEBAR FUNCTIONS
    case sidebarActions.NEXT_NAV:
      return sidebarFunctions.nextNav(state);
    case sidebarActions.PREV_NAV:
      return sidebarFunctions.prevNav(state);
    case sidebarActions.PUSH_NAV:
      return sidebarFunctions.pushNav(state, action.payload);
    case sidebarActions.POP_NAV:
      return sidebarFunctions.popNav(state, action.payload);
    case sidebarActions.REBUILD_NAV:
      return sidebarFunctions.rebuildNav(state, action.payload);
    // --END SIDEBAR FUNCTIONS

    //START URL FUNCTIONS
    case urlActions.UPDATE_URL:
      return urlFunctions.updateUrl(state, action.payload);
    case urlActions.REFRESH_URL:
      return urlFunctions.refreshUrl(state, action.payload);
    // --END URL FUNCTIONS

    //START GLOBALTOAST FUNCTIONS
    case globalToastActions.UPDATE_MSG:
      return globalToastFunctions.updateMsg(state, action.payload);

    case updateActions.UPDATE_VAR:
      return updateFunctions.updateVar(state, action.payload);

    default:
      return state;
  }
};

export default createStore(
  reducer,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

import React, { Component } from 'react';
import { connect } from "react-redux";
import { flags } from "../../../constants";
import { globalToastActions } from '../../../Redux/actions';
import Form from "../../../Components/NewComponents";
import axios from "axios";
import moment from "moment-timezone";
import { auth } from "../../../helperFunctions";
class FreetimeDisplay extends Component {
    state = {
        demurrageDays: "",
        demurrageTimeType: "CALENDAR DAYS",
        detentionDays: "",
        detentionTimeType: "CALENDAR DAYS",
        additonalDemurrage: "",
        expirationDate: "",
        notes: "",
        editCountryId: "",
        editLocationId: "",
        editDemurrageDays: "",
        editDemurrageTimeType: "",
        editDetentionDays: "",
        editDetentionTimeType: "",
        editAdditionalDemurrage: "",
        editExpirationDate: "",
        editNotes: "",
        showAddFreetime: false,
        copiedFreetime: {},
        copiedFreetimeIndex: '',
        showCopyMenu: false,
        showPasteMenu: false,
        editRowIndex: undefined
    }

    onChange = (name, value) => {
        this.setState({ [name]: value });
    }

    timeTypeChangeAndMakeActive = (e, index) => {
        let name = e.name;
        let value = e.value;
        this.onChange(name, value)
    }

    initListeners = () => {
        document.addEventListener('keydown', this.clearListener);
    }

    removeListeners = () => {
        document.removeEventListener('keydown', this.clearListener);
    }

    clickListener = e => {
        const list = document.querySelectorAll('.freetime-tbody>tr');
        list.forEach(tr => tr.classList.remove('menu-selected'));
        this.setState({ showCopyMenu: false, showPasteMenu: false }, () => document.removeEventListener("click", this.clickListener))
    }

    handleCopyPasteMenu = (e, index, freetime) => {
        e.preventDefault();
        if (e.ctrlKey) {
            document.querySelector(`#edit-freetime-${index}`).classList.contains('menu-selected') ?
                document.querySelector(`#edit-freetime-${index}`).classList.remove('menu-selected') :
                document.querySelector(`#edit-freetime-${index}`).classList.add('menu-selected')
        } else {
            this.setState({ showCopyMenu: true, showPasteMenu: true, copiedFreetimeIndex: index, x: e.pageX, y: e.pageY, freetime: freetime });
            document.addEventListener("click", this.clickListener);
        }
    }

    copyFreetime = () => {

        let copyIndex = this.state.copiedFreetimeIndex;
        let copiedFreetime = this.props.contract.freetime[copyIndex];

        this.setState({ copiedFreetime }, () => {
            this.props.dispatch({ type: globalToastActions.UPDATE_MSG, payload: { type: "success", msg: "Freetime Copied" } })
        })

    }

    pasteFreetime = () => {

        let copiedFreetime = { ...this.state.copiedFreetime };
        let pasteRows = document.querySelectorAll('.menu-selected');
        let idsForUpdate = [];

        if (!pasteRows.length) {
            idsForUpdate.push(this.props.contract.freetime[this.state.copiedFreetimeIndex].id)
        } else {
            pasteRows.forEach((r, index) => {
                idsForUpdate.push(this.props.contract.freetime[r.sectionRowIndex].id);
            });
        }

        copiedFreetime.expirationDate = copiedFreetime.expirationDate ? moment(copiedFreetime.expirationDate, "MM/DD/YY").format("YYYY-MM-DD") : null

        axios.post("/api/v1/contract/freetime/paste", { ...auth.getAuthData(), idsForUpdate, copiedFreetime }).then(result => {
            if (result === "NOT AUTHENTICATED") {
                localStorage.clear();
                this.props.dispatch({ type: "SCORCHED_EARTH" });
                return;
            } else if (result.errno) {
                this.props.dispatch({ type: globalToastActions.UPDATE_MSG, payload: { type: "error", msg: `${result.code}` } })
            }
            this.props.dispatch({ type: globalToastActions.UPDATE_MSG, payload: { type: "success", msg: "Freetime Pasted" } })
            this.props.getContract();
        })

    }

    deleteFreetime = (id) => {
        let contractId = this.props.contract.contractDetails.id;
        axios.post("/api/v1/contract/freetime/delete", { ...auth.getAuthData(), id, contractId }).then(result => {
            if (result === "NOT AUTHENTICATED") {
                localStorage.clear();
                this.props.dispatch({ type: "SCORCHED_EARTH" });
                return;
            } else if (result.errno) {
                this.props.dispatch({ type: globalToastActions.UPDATE_MSG, payload: { type: "error", msg: `${result.code}` } });
                return
            }
            this.props.dispatch({ type: globalToastActions.UPDATE_MSG, payload: { msg: "Freetime Deleted", type: "success" } })
            this.props.getContract();
        })
    }

    makeActive = async (index) => {

        let freetime = this.props.contract.freetime[index];

        this.setState({
            editCountryId: freetime.countryId,
            editLocationId: freetime.locationId,
            editDemurrageDays: freetime.demurrageDays,
            editDemurrageTimeType: freetime.demurrageTimeType ? freetime.demurrageTimeType : "BUSINESS DAYS",
            editDetentionDays: freetime.detentionDays,
            editDetentionTimeType: freetime.detentionTimeType ? freetime.detentionTimeType : "BUSINESS DAYS",
            editAdditionalDemurrage: freetime.additionalDemurrage,
            editExpirationDate: freetime.expirationDate,
            editNotes: freetime.notes,
            editRowIndex: index
        }, () => {
            let form = document.querySelector(`#edit-freetime-${index}`).querySelectorAll(`td>div>input`);
            let row = document.getElementById(`edit-freetime-${index}`);
            if (document.querySelector('.lastActiveFreetime')) {
                document.querySelector('.lastActiveFreetime').classList.remove('lastActiveFreetime');
            }
            row.classList.add('lastActiveFreetime');
            form[0].focus();
        });
    }

    makeInactiveAndSave = (index) => {

        let freetime = {
            contractId: this.props.contract.contractDetails.id,
            id: this.props.contract.freetime[index].id,
            countryId: this.state.editCountryId,
            locationId: this.state.editLocationId,
            demurrageDays: this.state.editDemurrageDays ? this.state.editDemurrageDays : null,
            demurrageTimeType: this.state.editDemurrageDays ? this.state.editDemurrageTimeType : null,
            detentionDays: this.state.editDetentionDays ? this.state.editDetentionDays : null,
            detentionTimeType: this.state.editDetentionDays ? this.state.editDetentionTimeType : null,
            additionalDemurrage: this.state.editAdditionalDemurrage,
            expirationDate: this.state.editExpirationDate,
            notes: this.state.editNotes
        }

        freetime.expirationDate = freetime.expirationDate ? moment(freetime.expirationDate, "MM/DD/YY").format("YYYY-MM-DD") : null;

        axios.post("/api/v1/contract/freetime/edit", { ...auth.getAuthData(), freetime }).then(async result => {
            if (result.data === "NOT AUTHENTICATED") {
                localStorage.clear();
                this.props.dispatch({ type: "SCORCHED_EARTH" });
                return;
            } else if (result.errno) {
                this.props.dispatch({ type: globalToastActions.UPDATE_MSG, payload: { type: "error", msg: `${result.code}` } });
                return
            }
            this.props.dispatch({ type: globalToastActions.UPDATE_MSG, payload: { type: "success", msg: "Freetime Saved" } });
            await this.props.getContract();
            this.setState({
                editCountryId: "",
                editLocationId: "",
                editDemurrageDays: "",
                editDemurrageTimeType: "",
                editDetentionDays: "",
                editDetentionTimeType: "",
                editAdditionalDemurrage: "",
                editExpirationDate: "",
                editNotes: "",
                editRowIndex: undefined
            })
        })
    }

    componentDidMount = () => {
        this.initListeners();
    }

    componentWillUnmount = () => {
        this.removeListeners();
    }

    render = () => {
        return (
            <div>
                <div className="row">
                    <div className="col s12">
                        <h5>Free Time</h5>
                        <table className="displayTable">
                            <thead>
                                <tr className="tableHeaderLeftAlign">
                                    <th data-sort="countryName" onClick={(e) => this.props.sort(e)}>Country</th>
                                    <th data-sort="locationName" onClick={(e) => this.props.sort(e)}>Port</th>
                                    <th data-sort="demurrageDays" onClick={(e) => this.props.sort(e)}>Demurrage</th>
                                    <th data-sort="additionalDemurrage" onClick={(e) => this.props.sort(e)}>Add. Demurrage</th>
                                    <th data-sort="detentionDays" onClick={(e) => this.props.sort(e)}>Detention</th>
                                    <th data-sort="notes" onClick={(e) => this.props.sort(e)}>Notes</th>
                                    <th data-sort="expirationDate" onClick={(e) => this.props.sort(e)}>Exp. Date</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody className="freetime-tbody">
                                {this.props.contract.freetime.map((fr, index) => (
                                    this.state.editRowIndex === index ?
                                        <tr key={fr.id} id={`edit-freetime-${index}`} onContextMenu={e => this.handleCopyPasteMenu(e, index, fr)}>
                                            <td>{this.props.countries.filter(c => c.id === this.state.editCountryId)[0].name}</td>
                                            <td>{this.props.location.filter(l => l.id === this.state.editLocationId)[0].name}</td>
                                            <td className="demurrageCombo" style={{ marginTop: "6px" }}>
                                                <Form.NumberInput col="col s3" name={`editDemurrageDays`} value={this.state.editDemurrageDays} onChange={this.onChange} flags={[flags.ALLOW_EMPTY]} />
                                                <Form.Select col="col s9" name={`editDemurrageTimeType`} value={this.state.editDemurrageTimeType} onChange={this.onChange} list={[{ label: "CALENDAR DAYS" }, { label: "BUSINESS DAYS" }, { label: "COMBINED CALENDAR" }, { label: "COMBINED BUSINESS" }]} filter={{ label: "label", value: "label" }} flags={[flags.ALLOW_EMPTY]} />
                                            </td>
                                            <td>
                                                <Form.TextInput name={`editAdditionalDemurrage`} value={this.state.editAdditionalDemurrage} onChange={this.onChange} flags={[flags.ALLOW_EMPTY]} />
                                            </td>
                                            {this.state.editDemurrageTimeType === "COMBINED BUSINESS" || this.state.editDemurrageTimeType === "COMBINED CALENDAR" || this.state.editDetentionTimeType === "COMBINED BUSINESS" || this.state.editDetentionTimeType === "COMBINED CALENDAR" ?
                                                <td> - </td>
                                                :
                                                <td className="demurrageCombo" style={{ marginTop: "6px" }}>
                                                    <Form.NumberInput name={`editDetentionDays`} col="col s3" value={this.state.editDetentionDays} stylingClass="" onChange={this.onChange} flags={[flags.ALLOW_EMPTY]} />
                                                    <Form.Select name={`editDetentionTimeType`} col="col s9" value={this.state.editDetentionTimeType} onChange={this.onChange} list={[{ label: "BUSINESS DAYS" }, { label: "CALENDAR DAYS" }]} filter={{ label: "label", value: "label" }} flags={[flags.ALLOW_EMPTY]} />
                                                </td>
                                            }
                                            <td>
                                                <Form.TextInput name={`editNotes`} value={this.state.editNotes} onChange={this.onChange} flags={[flags.ALLOW_EMPTY]} />
                                            </td>
                                            <td>
                                                <Form.DateInput id={`editExpirationDate`} name={`editExpirationDate`} value={this.state.editExpirationDate} onChange={this.onChange} flags={[flags.ALLOW_EMPTY]} />
                                            </td>
                                            <td>
                                                <i tabIndex="0" className={`material-icons saveIcon`} onClick={() => this.makeInactiveAndSave(index)}>save</i>
                                            </td>
                                        </tr>
                                        :
                                        <tr key={fr.id} id={`edit-freetime-${index}`} onContextMenu={e => this.handleCopyPasteMenu(e, index, fr)}>
                                            <td>{fr.countryName}</td>
                                            <td>{fr.locationName}</td>
                                            <td>{fr.demurrageDays}&nbsp;
                                                {fr.demurrageTimeType}</td>
                                            <td>{fr.additionalDemurrage}</td>
                                            {fr.demurrageTimeType === "COMBINED BUSINESS" || fr.demurrageTimeType === "COMBINED CALENDAR" || fr.detentionTimeType === "COMBINED BUSINESS" || fr.detentionTimeType === "COMBINED CALENDAR" ?
                                                <td>-</td>
                                                :
                                                <td>
                                                    {fr.detentionDays}&nbsp;
                                                    {fr.detentionTimeType}
                                                </td>
                                            }
                                            <td>{fr.notes}</td>
                                            <td>{fr.expirationDate}</td>
                                            <td className="editAndDeleteIcons">
                                                <i tabIndex="0" className={`material-icons fr-text-${index} editIcons col s6`} onClick={() => this.makeActive(index)}>edit</i>&nbsp;
                                                <i tabIndex="0" style={{ marginTop: "5px" }} className={`material-icons fr-text-${index} editIcons col s5`} onClick={() => this.deleteFreetime(fr.id)}>cancel</i>
                                            </td>
                                        </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
                <div style={{ position: "fixed", top: this.state.y, left: this.state.x }} className="lf-shipment-context-menu">
                    <ul className="collection">
                        {this.state.showCopyMenu && <li className="collection-item blue-text" onClick={this.copyFreetime}>COPY FREETIME</li>}
                        {this.state.showPasteMenu && <li className="collection-item orange-text" onClick={this.pasteFreetime}>PASTE FREETIME</li>}
                    </ul>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    const { index, urls, location, carrier, shipper } = state;
    const url = urls[index];
    return { url, location, shipper, carrier };
};

export default connect(mapStateToProps)(FreetimeDisplay);
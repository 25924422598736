import React from "react";
import styles from "./selectSmall.module.css";
import { types } from "../../constants";
import { inputValidation } from '../../helperFunctions';

class SelectSmall extends React.Component {
  constructor(props) {
    super(props);
    this.state = { value: this.props.value };
  }

  populateOptions = () => {
    if (Array.isArray(this.props.list)) return this.fromArray();
    else return this.fromObject();
  };

  fromArray = () => {
    const arr = [];
    if (this.props.list.length) {
      if (this.props.placeholder) {
        arr.push({ label: this.props.placeholder, value: 0 });
      }
      this.props.list.forEach(item => {
        arr.push({
          label: item[this.props.filter.label],
          value: item[this.props.filter.value],
          className: this.props.filter.className ? item[this.props.filter.className] : ''
        })
      }
      );
    } else arr.push({ label: "..Loading", value: 0 });

    return arr;
  };

  fromObject = () => {
    if (!this.props.list) return [{ label: "...Loading", value: null }];
    else {
      const arr = [];
      Object.keys(this.props.list).forEach(key => {
        arr.push({
          label: this.props.list[key][this.props.filter.label],
          value: this.props.list[key][this.props.filter.value],
          className: this.props.filter.className ? this.props.list[key][this.props.filter.className] : null
        });
      });
      return arr;
    }
  };

  genDivClass = () =>
    `${this.props.col ? `col ${this.props.col}` : ""} ${styles.div}`;

  onChange = e => {
    this.setState(
      { value: e.target.value },
      this.props.onChange(this.props.name, e.target.value)
    );
  }
  getInputErrInfo = () => ([{ name: 'value', type: types.STRING, value: this.props.value, flags: this.props.flags }]);

  onBlur = () => {
    inputValidation(this.getInputErrInfo(), this.success, this.failure);
  };
  success = value => this.setState({ err: null }, this.props.onBlur);
  failure = value => this.setState({ err: { msg: value.value.msg } }, this.props.onBlur);

  render = () => (
    <div className={this.genDivClass()}>
      <select
        id={this.props.name}
        className={`browser-default ${styles.select} 
        ${this.props.className}
        ${this.state.err ? styles.err : ""}`}
        disabled={`${this.props.disabled ? "disabled" : ""}`}
        onChange={this.onChange}
        onClick={this.props.onClick}
        value={this.props.value}
        onBlur={this.onBlur}
        tabIndex={this.props.tabIndex ? this.props.tabIndex : 0}
        onContextMenu={this.props.onContextMenu}
      >
        {this.populateOptions().map((o, index) => (
          <option key={index} value={o.value} className={o.className ? styles[o.className] : null}>
            {o.label}
          </option>
        ))}
      </select>
      {this.props.label ? <label className={`${this.props.labelStyle ? this.props.labelStyle : styles.label} ${this.props.labelClassName}`}>{this.props.label} {this.props.required ? <span style={{ color: "red" }}>*</span> : ''}</label> : null}
    </div>
  );
}

export default SelectSmall;

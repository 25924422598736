import React from 'react';
import M from 'materialize-css';
import styles from "./confirm.module.css"

class Confirm extends React.Component {

    init = () => M.Modal.init(document.querySelector(`#${this.props.id}`));
    componentDidMount = () => this.init();
    componentDidUpdate = async () => {
        await this.init();
        if (this.props.show) { this.show() };
    }
    show = () => M.Modal.getInstance(document.querySelector(`#${this.props.id}`)).open();
    close = () => M.Modal.getInstance(document.querySelector(`#${this.props.id}`)).close();

    render = () => (
        <div id={this.props.id} className={`modal white ${styles.modal}`}>
            <div className="modal-content">
                <h4>{this.props.msg}</h4>
                <p>{this.props.body}</p>
            </div>
            <div className="row">
                <button className={`col s2 offset-s4 btn blue white-text outline ${styles.blue}`} onClick={() => this.props.confirm(this.props.index)}>Confirm</button>
                <button className={`col s2  btn red white-text outline ${styles.red}`} onClick={this.close}>Cancel</button>
            </div>
        </div>
    )
}

export default Confirm
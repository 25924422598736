import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Switch, Route, Redirect, withRouter } from 'react-router-dom';
import './content.css';
import CustomerOverview from "../../Pages/Customer/CustomerOverview";
import RequestOverview from "../../Pages/Requests/RequestOverview";
import { urlActions } from '../../Redux/actions';


const notFound = () => {
    return <div>
        <h2> Error 404. Sorry, there's nothing here. </h2>
        <Redirect to="/customer" />
    </div>
}


class CustomerContent extends Component {

    componentDidUpdate = (prevProps) => {
        if (this.props.url && this.props.url.url !== prevProps.url.url) {
            this.props.history.push(this.props.url.url)
        } else if ( this.props.location.pathname && this.props.location.pathname !== prevProps.location.pathname ) {
            this.props.dispatch({ type: urlActions.UPDATE_URL, payload: { url: this.props.location.pathname, state: {...this.props.url.state } } } )
        }
    }

    render = () => {
        return (
            <div id="master-content" className="content">
                <Switch>
                    <Route exact path="/shipment" component={CustomerOverview} />
                    <Route exact path="/customer" component={CustomerOverview} />
                    <Route exact path="/request" component={RequestOverview} />
                    <Route path="/*" component={notFound} />
                </Switch>
            </div>
        )
    }
}

const mapStateToProps = state => {
    const { urls, index, user } = state;
    return { url: urls[index], user };
}

export default withRouter(connect(mapStateToProps)(CustomerContent));
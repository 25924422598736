import React from 'react';
import { connect } from 'react-redux';
import { sidebarActions } from '../../../Redux/actions';
import { Switch, Route } from 'react-router-dom';
import VesselOverview from './VesselOverview';
import CreateVessel from './CreateVessel';


class Vessels extends React.Component {

    getPath = () => ('/carrier/vessel');
    rebuildNav = () => this.props.dispatch({ type: sidebarActions.REBUILD_NAV, payload: { url: this.getPath() } })
    componentDidMount = () => this.rebuildNav();

    render = () => (
        <Switch>
            <Route exact path={this.getPath()} component={VesselOverview} />
            <Route path={this.getPath() + '/create'} component={CreateVessel} />
        </Switch>
    )
}

const mapStateToProps = state => {
    const { index } = state;
    return { index };
}

export default connect(mapStateToProps)(Vessels);
import React from 'react';
import { connect } from 'react-redux';
import { sidebarActions } from '../../../Redux/actions';
import { Switch, Route } from 'react-router-dom';
import ServiceRouteOverview from './ServiceRouteOverview';
import CreateServiceRoute from './CreateServiceRoute';

class ServiceRoutes extends React.Component {

    getPath = () => ('/carrier/serviceroute');
    rebuildNav = () => this.props.dispatch({ type: sidebarActions.REBUILD_NAV, payload: { url: this.getPath() } })
    componentDidMount = () => this.rebuildNav();

    render = () => (
        <Switch>
            <Route exact path={this.getPath()} component={ServiceRouteOverview} />
            <Route exact path={this.getPath() + '/create'} component={CreateServiceRoute} />
        </Switch>
    )
}

export default connect()(ServiceRoutes);
import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { urlActions, navActions, globalToastActions } from "../../Redux/actions";
import moment from "moment-timezone";

class CustomerResultsDisplay extends Component {
    state = {
        deleteShipment: null,
        loading: true
    }

    initListeners = () => {
        document.getElementsByTagName("tbody")[0].addEventListener("click", this.informationSelect);
        document.addEventListener("mousedown", this.addUnselected);
        document.addEventListener("mouseup", this.removeUnselected);
    }

    removeListeners = () => {
        document.getElementsByTagName("tbody")[0].removeEventListener("click", this.informationSelect);
        document.removeEventListener("mousedown", this.addUnselected);
        document.removeEventListener("mouseup", this.removeUnselected);
    }


    handleEdit = bkg => {
        this.props.dispatch({
            type: urlActions.UPDATE_URL, payload: {
                url: '/shipment/create', state: {
                    searchTerm: bkg.bookingNumber.split(' / ')[0], carrierId: bkg.carrierId, shipperId: bkg.shipperId, filters: this.props.filters, status: bkg.status, stage: {
                        unformattedDocCutDate: bkg.unformattedDocCutDate, aes: bkg.aes, datetimePrinted: bkg.datetimePrinted, invoiceStatus: bkg.invoiceStatus, isDummy: bkg.isDummy//lobDate: bkg.lobDate
                    }
                }
            }
        });
        this.props.dispatch({ type: navActions.SET_FILTER, payload: { filterByShipper: true } });
    }

    goToInstructions = bkg => {
        this.props.dispatch({
            type: urlActions.UPDATE_URL, payload: {
                url: '/shipment/instructions', state: {
                    searchTerm: bkg.lNumber, shipperId: bkg.shipperId, filters: this.props.filters, status: bkg.status, stage: {
                        unformattedDocCutDate: bkg.unformattedDocCutDate, aes: bkg.aes, datetimePrinted: bkg.datetimePrinted, invoiceStatus: bkg.invoiceStatus//lobDate: bkg.lobDate
                    }
                }
            }
        })
        this.props.dispatch({ type: navActions.SET_FILTER, payload: { filterByShipper: true } });
    }


    addUnselected = (e) => {
        let selectedColumnClass = e.target.classList[0];

        document.querySelectorAll("td").forEach(t => {
            if (!t.classList.contains(`${selectedColumnClass}`)) {
                t.classList.add('unselectable')
            }
        })
    }

    removeUnselected = (e) => {
        let selectedColumnClass = e.target.classList[0];

        document.querySelectorAll("td").forEach(t => {
            if (!t.classList.contains(`${selectedColumnClass}`)) {
                t.classList.remove('unselectable')
            }
        })
    }

    informationSelect = (e) => {
        let selectedColumnClass = document.getSelection().focusNode ? document.getSelection().focusNode.parentNode.attributes[0].value.split(" ")[0] : "";
        e.preventDefault();
        if (document.getSelection().focusNode) {
            if (document.getSelection().focusNode.classList) {
                if (document.getSelection().focusNode.classList.contains("search-field")) {
                    return;
                }
            }
            if (document.getSelection().focusNode.parentNode.tagName === "TD") {
                if (document.getSelection().type === "Range") {
                    let highlightedItems = [];
                    let nodes = document.querySelectorAll(`.${selectedColumnClass}`);
                    let rows = document.getSelection().toString().split(`\t`);
                    let nodeWords = [];

                    nodes.forEach(n => {
                        nodeWords.push(n.innerText.split('\n')[0])
                    });
                    rows.forEach((r) => {
                        let regex = new RegExp(`\\b${r}\\b`, 'g');
                        if (regex.test(nodeWords) && r) {
                            highlightedItems.push(r);
                        }
                    });
                    navigator.clipboard.writeText(highlightedItems.join('\n'));
                    this.props.dispatch({ type: globalToastActions.UPDATE_MSG, payload: { msg: "Copied To Clipboard" } });
                    if (window.getSelection) {
                        window.getSelection().removeAllRanges();
                    } else if (document.selection) {
                        document.selection.empty();
                    }
                }
            } else {
                if (window.getSelection) {
                    window.getSelection().removeAllRanges();
                } else if (document.selection) {
                    document.selection.empty();
                }
                return;
            }
        } else {
            if (window.getSelection) {
                window.getSelection().removeAllRanges();
            } else if (document.selection) {
                document.selection.empty();
            }
            return;
        }
    }

    //COMMENTING THESE OUT IN CASE WE CHANGE COURSE AND NEED THEM IN THE FUTURE

    // generateBadge = status => {
    //     switch (status) {
    //         case 'ACTIVE':
    //             return <span id="activeBadge" className="badge">ACTV</span>
    //         case 'CANCELLED':
    //             return <span id="cancelBadge" className="new red badge">CNCL</span>
    //         case 'CLOSED':
    //             return <span id="closedBadge" className="new badge">CLSD</span>
    //         case 'ARCHIVED':
    //             return <span id="archiveBadge" className="new black badge">ACVD</span>
    //         default:
    //             return;
    //     }
    // }

    // generateStage = (docCutDate, aes, SIPrinted, LOB, invoice, dummy, dummySI, enteredContainers) => {

    //     let date = moment().format("YYYY-MM-DD HH:mm:ss");

    //     if (invoice !== null) {
    //         return <span className="material-icons" style={dummy ? { color: "#9c27b0" } : { "color": "green" }}>attach_money</span>
    //     }
    //     else if (SIPrinted !== null && SIPrinted !== undefined && SIPrinted.length) {
    //         if (dummySI || !enteredContainers) {
    //             return <span className="material-icons">assignment_ind</span>
    //         }
    //         return <span className="material-icons">assignment_turned_in</span>
    //     }
    //     else if (moment(date).isAfter(moment(docCutDate).local().format("YYYY-MM-DD HH:mm:ss"))) {
    //         return <span className="material-icons" style={dummy ? { color: "#9c27b0" } : { "color": "red" }}>assignment_late</span>
    //     }
    //     else if (moment(date).isSame(moment(docCutDate).local().format("YYYY-MM-DD HH:mm:ss"), "D")) {
    //         return <span className="material-icons">history</span>
    //     } else {
    //         return <span className="material-icons"></span>
    //     }
    // }

    refresh = () => {
        this.setState({ loading: true }, () => this.props.getBookings)

    }
    componentDidMount = () => {
        this.initListeners();
    }
    componentDidUpdate = (prevProps) => {
    }
    componentWillUnmount = () => {
        this.removeListeners();
    }

    render = () => {
        return (
            <Fragment>
                {!this.props.bookings.length ?
                    null
                    :
                    this.props.bookings.map((bkg, index) => (
                        <tr className={`resultsDisplay
                        ${moment(bkg.fullDocCutDate, "MM/DD/YY").isSame(moment(new Date()), "day") ? "customerDocDate" : ""}
                        ${this.props.bookings[index + 1] !== undefined && this.props.bookings[index - 1] !== undefined && this.props.filters.currentSort === "cargoCutDate" &&
                                (moment(bkg.fullCargoCutDate, "MM/DD/YY").isSame(moment(new Date()), "day")
                                    ?
                                    moment(bkg.fullCargoCutDate, "MM/DD/YY").isSame(moment(new Date()), "day") &&
                                    !moment(this.props.bookings[index - 1].fullCargoCutDate, "MM/DD/YY").isSame(moment(new Date(), "MM/DD/YY"), "day")
                                    :
                                    moment(this.props.bookings[index - 1].fullCargoCutDate, "MM/DD/YY").isBefore(moment(new Date()), "day") &&
                                    moment(this.props.bookings[index + 1].fullCargoCutDate, "MM/DD/YY").isAfter(moment(new Date()), "day") &&
                                    moment(bkg.fullCargoCutDate, "MM/DD/YY").isAfter(moment(new Date()), "day"))
                                ? "firstCargoDate" : ""}`}
                            id={`bkg-tr-${index}`} key={index} >
                            <td className={`shipperName ${this.props.user.partyId === bkg.shipperId ? 'customerName' : ''}`}> {bkg.shipperName}</td>
                            <td className="bookingNum">{bkg.bookingNumber}</td>
                            <td className="refNum">{bkg.shipperReferenceNumber}</td>
                            <td className={`supplierName ${this.props.user.partyId === bkg.supplierId ? 'customerName' : ''}`}>{bkg.supplierName}</td>
                            <td className="consigneeName">{this.props.user.partyId !== bkg.shipperId ? "-" : bkg.consigneeName}</td>

                            <td className="carrierName">{bkg.carrierName}</td>
                            {/* {bkg.lobDate ? 
                            <td style={{ fontSize: '1.1em', fontWeight: '500' }}>{bkg.containerCount}/{bkg.enteredContainerCount ? bkg.enteredContainerCount : 0}</td>
                            : */}
                            <td className="containerCount" style={{ fontSize: '1.1em', fontWeight: '500' }}>{bkg.containerCount}</td>
                            {/* } */}
                            <td style={{ marginLeft: "20px" }} className="pickupLocation">{bkg.origin}</td>
                            <td className="dest">{bkg.dest}</td>
                            <td className="vesselName">{bkg.vesselName}</td>
                            <td className="voyageNumber">{bkg.voyageNumber}</td>
                            <td className="erd dateBordersLeft">{bkg.erd}</td>
                            <td className="cargoCutDate dateBordersRight">{bkg.cargoCutDate}</td>
                            <td className="docCutDate">{bkg.docCutDate}</td>
                            <td className="etd">{bkg.etd}</td>
                            <td className="eta">{bkg.eta}</td>
                            <td className="pickupLoc">{bkg.pickupLocationCode}</td>
                            <td className="returnLoc">{bkg.returnLocationCode}</td>
                        </tr>
                    ))
                }
            </Fragment>
        )
    }

}

const mapStateToProps = state => {
    const { user, urls, index } = state;
    const url = urls[index];
    return { user, url }
}

export default connect(mapStateToProps)(CustomerResultsDisplay)
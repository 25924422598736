import React, { Component } from "react";
import axios from "axios";
import Form from "../../Components/NewComponents";
import styles from "./Carrier.module.css";
import { flags } from "../../constants";
import { connect } from "react-redux";
import M from "materialize-css";
import { globalToastActions, urlActions, init } from "../../Redux/actions";
import ContactDisplay from "../Parties/ContactDisplay";
import { auth, logout } from "../../helperFunctions";

class UpdateCarrier extends Component {
    state = {
        name: "",
        code: "",
        scacCode: "",
        displayName: "",
        alternateName: "",
        contacts: [],
        contactFieldOptions: [{ label: "TO" }, { label: "CC" }, { label: "BCC" }],
        contactName: "",
        contactEmail: "",
        contactDefault: 0,
        contactField: "TO",
        contactBl: 0,
        contactSwb: 0,
        contactObl: 0,
        contactTelex: 0,
        contactRole: "BL",
        editId: undefined,
        editIndex: undefined,
        editName: "",
        editEmail: "",
        editDefault: 0,
        editField: "",
        editBl: 0,
        editSwb: 0,
        editObl: 0,
        editTelex: 0,
        vessels: [],
        displayVessels: [],
        vesselName: "",
        filterByVessel: ""
    };

    onChange = (name, value) => {
        this.setState({
            [name]: value
        });
    };

    vesselFilterOnChange = (name, value) => {
        const displayVessels = this.state.vessels.filter(v => v.name.toLowerCase().includes(value.trim().toLowerCase()))
        this.setState({ displayVessels, [name]: value })
    }

    roleOnChange = (name, value) => {
        this.setState({
            [name]: value
        }, this.chooseList);
    };

    contactTypeOnChange = (name, value) => {
        this.setState({ [name]: value }, () => M.Tabs.getInstance(document.getElementById("tabs")).select(value))
    }

    changeTabFocus = (option) => {
        this.setState({ contactRole: option });
    }

    //Final submission function with validation that needs to be reworked
    submitUpdate = async () => {
        try {
            let carrier = {
                id: this.state.id,
                name: this.state.name,
                code: this.state.code,
                scacCode: this.state.scacCode,
                displayName: this.state.displayName,
                alternateName: this.state.alternateName
            };

            if (Object.entries(carrier).filter(([key, value]) => !value && key !== "id").length) {
                return this.props.dispatch({ type: globalToastActions.UPDATE_MSG, payload: { msg: "Missing Field(s)", type: "error" } })
            } else {
                axios.post("/api/v1/carrier", { ...auth.getAuthData(), carrier })
                    .then(result => {
                        if (result.data === "NOT AUTHENTICATED") {
                            localStorage.clear();
                            this.props.dispatch({ type: "SCORCHED_EARTH" });
                            return;
                        }

                        if (result.data.insert) {
                            this.props.dispatch({ type: globalToastActions.UPDATE_MSG, payload: { msg: "Carrier Added", type: "success" } });
                            return this.props.history.push(`/carrier/create/${result.data.carrierId}`)
                        } else {
                            this.props.dispatch({ type: globalToastActions.UPDATE_MSG, payload: { msg: "Carrier Updated", type: "success" } })
                            return this.props.dispatch({
                                type: urlActions.UPDATE_URL,
                                payload: { url: "/carrier", state: this.props.url.state }
                            });
                        }

                    });
            }
        } catch (err) {
            console.log(err);
        }
    };

    initModal = () => M.Modal.init(document.querySelectorAll(".modal"));

    initTabs = () => {
        M.Tabs.init(document.getElementById("tabs"));
    }

    hideEditModal = () => {
        M.Modal.getInstance(document.querySelector("#edit-address-modal")).close();
        document.getElementById("updateButton").focus();
    }

    showAddModal = () => {
        this.clearNewAddForm();
        M.Modal.getInstance(document.querySelector("#add-address-modal")).open();
    };

    hideAddModal = () => {
        M.Modal.getInstance(document.querySelector("#add-address-modal")).close();
        document.getElementById("updateButton").focus();
    }

    initListener = () => {
        document.addEventListener("keydown", this.escListener);
        document.addEventListener("keydown", this.addContactListener);
        document.getElementById("vesselName").addEventListener("keydown", this.addVesselListener);
    };

    removeListener = () => {
        document.removeEventListener("keydown", this.escListener);
        document.removeEventListener("keydown", this.addContactListener);
        document.getElementById("vesselName").removeEventListener("keydown", this.addVesselListener);
    };

    escListener = async e => {
        if (e.keyCode === 27) {
            if (await this.checkForOpenModals()) {
                return;
            } else {
                this.props.dispatch({
                    type: urlActions.UPDATE_URL,
                    payload: { url: "/carrier", state: this.props.url.state }
                });
            }
        }
    };

    addContactListener = e => {
        if (e.keyCode === 13 && document.activeElement.id === "addIcon") {
            this.addContact();
        }
    }

    addVesselListener = (e) => {
        if (e.key === "Enter") {
            e.preventDefault();
            this.addVessel();
        }
    }

    getCarrier = (id) => {
        axios.get(`/api/v1/carrier/create/${id}`, { params: { ...auth.getAuthData() } }).then(result => {
            if (result.data === "NOT AUTHENTICATED") {
                localStorage.clear();
                return this.props.dispatch({ type: "SCORCHED_EARTH" });
            } else {
                this.setState({ ...result.data.carrierInfo[0], contacts: result.data.contacts })
            }
        })
    }

    getVessels = (carrierId) => {
        axios.get('/api/v2/carrier/read/all/vessels/by/carrierid', {
            params: {
                ...auth.getAuthData(),
                carrierId
            }
        })
            .then(result => this.setState({ vessels: result.data, displayVessels: result.data }))
            .catch(logout)
    }

    checkForOpenModals = async () => {
        let modals = document.querySelectorAll('.modal');
        let openModal = false;
        await modals.forEach(modal => {
            if (M.Modal.getInstance(modal).isOpen) {
                M.Modal.getInstance(modal).close();
                return openModal = true;
            }
        });
        return openModal;
    }

    getContacts = () => {
        let carrierId = this.props.match.params.id;
        axios.get("/api/v1/carrier/contact", { params: { ...auth.getAuthData(), carrierId } }).then(result => {
            this.setState({ contacts: result.data })
        })
    }

    addContact = (flag) => {

        let edit = flag === "edit" ? true : false;
        let emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        let contacts = this.state.contacts;
        let contact = edit ?
            {
                id: this.state.editId,
                carrierId: this.state.id,
                name: this.state.editName.trim(),
                email: (this.state.editEmail.trim()).toLowerCase(),
                defaultEmail: parseInt(this.state.editDefault) ? true : false,
                field: this.state.editField === "-" && this.state.editDefault ? "TO" : this.state.editField,
                blEmail: this.state.editBl,
                swbEmail: this.state.editSwb,
                oblEmail: this.state.editObl,
                telexEmail: this.state.editTelex
            } : {
                carrierId: this.state.id,
                name: this.state.contactName.trim(),
                email: (this.state.contactEmail.trim()).toLowerCase(),
                defaultEmail: parseInt(this.state.contactDefault) ? true : false,
                field: this.state.contactField,
                blEmail: this.state.contactBl,
                swbEmail: this.state.contactSwb,
                oblEmail: this.state.contactObl,
                telexEmail: this.state.contactTelex
            }

        if (!contact.email) {
            this.props.dispatch({
                type: globalToastActions.UPDATE_MSG, payload: { type: "error", msg: "Email Field Is Required" }
            });
            return;
        };
        if (!contact.name) {
            this.props.dispatch({
                type: globalToastActions.UPDATE_MSG, payload: { type: "error", msg: "Name Field Is Required" }
            });
            return;
        }
        if (!emailRegex.test(contact.email)) {
            this.props.dispatch({
                type: globalToastActions.UPDATE_MSG, payload: { type: "error", msg: "Not A Valid Email" }
            });
            return;
        }
        if (contacts.filter(c => c.email === contact.email && c.role === contact.role).length && !edit) {
            this.props.dispatch({
                type: globalToastActions.UPDATE_MSG, payload: { type: "error", msg: "Duplicate Contact" }
            });
            return;
        };

        axios.post("/api/v1/carrier/contact", { ...auth.getAuthData(), contact }).then(result => {
            if (result.data === "NOT AUTHENTICATED") {
                localStorage.clear();
                this.props.dispatch({ type: "SCORCHED_EARTH" });
                return
            }
            if (edit) {
                this.setState({
                    editIndex: undefined,
                    editId: undefined,
                    editName: "",
                    editEmail: "",
                    editDefault: false,
                    editSwbEmail: 0,
                    editOblEmail: 0,
                    editBlEmail: 0,
                    editTelexEmail: 0,
                    editField: "TO"
                });
                this.props.dispatch({
                    type: globalToastActions.UPDATE_MSG, payload: { type: "success", msg: "Contact Updated" }
                });
            } else {
                this.props.dispatch({
                    type: globalToastActions.UPDATE_MSG, payload: { type: "success", msg: "Contact Added" }
                });
            }
            this.getContacts();
            document.getElementById("contactName").focus();
        })
    }

    removeConfirm = () => {
        return new Promise((resolve, reject) => {
            if (window.confirm("Are you sure you would like to remove this contact?")) {
                resolve(true)
            } else {
                resolve(false)
            }
        })
    }

    editContact = async (id, index, role) => {
        let contact = this.state.contacts.filter(c => c.id === id)[0]
        this.setState({
            editIndex: index,
            editId: id,
            editName: contact.name,
            editEmail: contact.email,
            editDefault: contact.defaultEmail,
            editField: contact.field,
            editBl: contact.blEmail,
            editSwb: contact.swbEmail,
            editObl: contact.oblEmail,
            editTelex: contact.telexEmail
        })
    }

    removeContact = async (id) => {
        let confirmRemove;
        confirmRemove = await this.removeConfirm();
        if (confirmRemove) {
            let type = `${this.state.contactRole.toLowerCase()}_email`;
            axios.post("/api/v1/carrier/contact/delete", { ...auth.getAuthData(), id, type }).then((result) => {
                if (result.data === "NOT AUTHENTICATED") {
                    localStorage.clear();
                    this.props.dispatch({ type: "SCORCHED_EARTH" });
                    return;
                } else if (result.data.errno) {
                    this.props.dispatch({ type: globalToastActions.UPDATE_MSG, payload: { msg: "Error Deleting Contact", type: "error" } });
                } else {
                    this.props.dispatch({ type: globalToastActions.UPDATE_MSG, payload: { msg: "Contact Removed", type: "success" } });
                    this.getContacts();
                }
            })
        }
    }

    addVessel = () => {
        if (!this.state.vesselName.trim()) {
            return;
        }
        let vessels = this.state.vessels;
        let vesselName = this.state.vesselName.toUpperCase().trim();
        axios.post("/api/v2/vessel/create", { ...auth.getAuthData(), vesselName, carrierId: this.props.match.params.id }).then(result => {
            const { msg, insertId } = result.data;
            vessels.push({ id: insertId, name: vesselName });
            this.setState({ vessels, displayVessels: vessels, vesselName: "", filterByVessel: "" });
            return this.props.dispatch(msg)
        }).catch(logout);
    }

    deleteVessel = (vesselId) => {
        if (window.confirm("Are you sure you would like to delete this vessel?")) {
            axios.post('/api/v2/vessel/delete/one', {
                ...auth.getAuthData(),
                vesselId
            })
                .then(result => {
                    this.props.dispatch(result.data)
                    let vessels = this.state.vessels;
                    vessels = vessels.filter(v => v.id !== vesselId);
                    this.setState({ vessels, displayVessels: vessels, filterByVessel: '' });
                })
                .catch(err => logout(err, err => {
                    if (window.confirm('This vessel cannot be delete because it has existing bookings, which must first be moved to a different vessel. Would you like to copy those bookings to the clipboard?')) {
                        let str = '';

                        if (err.response && Array.isArray(err.response.data))
                            err.response.data.forEach(b => {
                                str += `${b.shipperName} - ${b.bookingNumbers} \n`;
                            })

                        this.copyBookingNumbers(str)
                    }
                }))
        }
    }

    copyBookingNumbers = (data) => {
        document.querySelector('#vesselName').focus();
        navigator.clipboard.writeText(data).then(() => {
            return this.props.dispatch({ type: globalToastActions.UPDATE_MSG, payload: { msg: "Copied Booking Numbers to Clipboard", type: "success" } });
        })
    }

    componentDidMount = () => {
        this.initListener();
        this.initTabs();
    };

    UNSAFE_componentWillMount = () => {
        this.getCarrier(this.props.match.params.id);
        this.getVessels(this.props.match.params.id);
    }


    componentWillUnmount = () => {
        this.removeListener();

        axios.get("/api/v1/redux/carrier", { params: auth.getAuthData() }).then(result => {
            if (result.data === "NOT AUTHENTICATED") {
                localStorage.clear();
                this.props.dispatch({ type: "SCORCHED_EARTH" });
                return;
            } else if (Array.isArray(result.data)) {
                this.props.dispatch({
                    type: init.INITIALIZE_STORE,
                    payload: { listName: "carrier", list: result.data }
                });
            };
        })


    };

    render() {
        return (
            <div>
                <Form.Section label="Carrier Profile">
                    <div className="row">
                        <Form.TextInput id="name" name="name" label="Name" value={this.state.name} onChange={this.onChange} col="s6" maxLength="120" flags={[flags.MAX_LENGTH + 120]} />
                        <Form.TextInput name="code" label="Code" value={this.state.code} onChange={this.onChange} col="s3" maxLength="3" flags={[flags.MAX_LENGTH + 3, flags.ALLOW_EMPTY]} />
                        <Form.TextInput name="scacCode" label="SCAC" value={this.state.scacCode} onChange={this.onChange} col="s3" maxLength="16" />
                    </div>
                    <div className="row">
                        <Form.TextInput name="displayName" label="Display Name" value={this.state.displayName} onChange={this.onChange} col="s6" maxLength="20" flags={[flags.MAX_LENGTH + 20, flags.ALLOW_EMPTY]} />
                        <Form.TextInput name="alternateName" label="Alternate Name (Zen-Noh Only)" value={this.state.alternateName} onChange={this.onChange} col="s6" maxLength="60" flags={[flags.MAX_LENGTH + 60, flags.ALLOW_EMPTY]} noblurcap="true" />
                    </div>
                </Form.Section>
                <Form.Section label="Vessels" className={styles.vesselSection}>
                    <div className="row">
                        <Form.TextInput label="Vessel Name" name="vesselName" value={this.state.vesselName} onChange={this.onChange} col="col s8" flags={[flags.ALLOW_EMPTY]} />
                        <Form.Button type="outline" color="green" label="Add" onClick={this.addVessel} col="col s4" />
                    </div>
                    <div className="row">
                        <h6 className="col s6">Vessels</h6>
                        <Form.TextInput col="s6" label="Filter By Vessel Name" name="filterByVessel" value={this.state.filterByVessel} onChange={this.vesselFilterOnChange} flags={[flags.ALLOW_EMPTY]} />
                    </div>
                    <div className={`row ${styles.vesselDisplayContainer}`}>
                        {this.state.displayVessels.map(v => (
                            <div className={`card col s12 m6  ${styles.vesselCard}`} key={v.id}>
                                <div className={`card-content`}>
                                    <span>{v.name}</span>
                                    <i className="material-icons right" onClick={() => this.deleteVessel(v.id)}>cancel</i>
                                </div>
                            </div>
                        ))}
                    </div>
                </Form.Section>
                <Form.Section label="Contacts" className={styles.contactSection}>
                    <div className="row">
                        <Form.TextInput col="col s6" label="Name" onChange={this.onChange} value={this.state.contactName} name="contactName" maxLength="30" flags={[flags.ALLOW_EMPTY]} />
                        <Form.TextInput col="col s6" label="Email" onChange={this.onChange} value={this.state.contactEmail} name="contactEmail" maxLength="50" flags={[flags.ALLOW_EMPTY]} />
                    </div>
                    <div className="row">
                        <Form.CheckBox col={'col s1'} label='Default' onChange={this.onChange} value={this.state.contactDefault} name='contactDefault' />
                        <Form.Select col="col s2" label="Field" disabled={this.state.contactDefault === true ? false : true} onChange={this.onChange} value={this.state.contactField} name="contactField" list={this.state.contactFieldOptions} filter={{ label: "label", value: "label" }} />
                        <Form.CheckBox col="col s2" label="SI" onChange={this.onChange} value={this.state.contactBl} name="contactBl" />
                        <Form.CheckBox col="col s2" label="SWB" onChange={this.onChange} value={this.state.contactSwb} name="contactSwb" />
                        <Form.CheckBox col="col s2" label="OBL" onChange={this.onChange} value={this.state.contactObl} name="contactObl" />
                        <Form.CheckBox col="col s2" disabled={true} label="Telex" onChange={this.onChange} value={this.state.contactTelex} name="contactTelex" />
                        <div tabIndex="0" id="addIcon" className={styles.addContactSpan} onClick={this.addContact}>
                            <i className="material-icons col s1">add</i>
                        </div>
                    </div>

                    <div className="row" id="contactsRow">
                        <div className="col s12">
                            <ul className={`tabs`} id="tabs">
                                <li className={`tab col s2 offset-s2`}><a tabIndex="-1" href="#SI" onClick={() => this.changeTabFocus('SI')}>SI</a></li>
                                <li className={'tab col s2'}><a tabIndex="-1" href="#SWB" onClick={() => this.changeTabFocus('SWB')}>SWB</a></li>
                                <li className="tab col s2"><a tabIndex="-1" href="#OBL" onClick={() => this.changeTabFocus("OBL")}>OBL</a></li>
                                <li className="tab col s2 disabled"><a tabIndex="-1" href="#TELEX" onClick={() => this.changeTabFocus('DOCS')}>TELEX</a></li>
                            </ul>
                        </div>
                        <div id="SI" className="col s12">
                            <ContactDisplay
                                onChange={this.onChange}
                                contacts={this.state.contacts}
                                sectionType="bl"
                                placeholderText={"SI"}
                                editId={this.state.editId}
                                editIndex={this.state.editIndex}
                                editName={this.state.editName}
                                editEmail={this.state.editEmail}
                                editDefault={this.state.editDefault}
                                editField={this.state.editField}
                                contactFieldOptions={this.state.contactFieldOptions}
                                saveContact={this.addContact}
                                editContact={this.editContact}
                                removeContact={this.removeContact}
                            />
                        </div>

                        <div id="SWB" className="col s12">
                            <ContactDisplay
                                onChange={this.onChange}
                                contacts={this.state.contacts}
                                sectionType="swb"
                                placeholderText={"SWB"}
                                editId={this.state.editId}
                                editIndex={this.state.editIndex}
                                editName={this.state.editName}
                                editEmail={this.state.editEmail}
                                editDefault={this.state.editDefault}
                                editField={this.state.editField}
                                contactFieldOptions={this.state.contactFieldOptions}
                                saveContact={this.addContact}
                                editContact={this.editContact}
                                removeContact={this.removeContact}
                            />
                        </div>
                        <div id="OBL" className="col s12">
                            <ContactDisplay
                                onChange={this.onChange}
                                contacts={this.state.contacts}
                                sectionType="obl"
                                placeholderText={"OBL"}
                                editId={this.state.editId}
                                editIndex={this.state.editIndex}
                                editName={this.state.editName}
                                editEmail={this.state.editEmail}
                                editDefault={this.state.editDefault}
                                editField={this.state.editField}
                                contactFieldOptions={this.state.contactFieldOptions}
                                saveContact={this.addContact}
                                editContact={this.editContact}
                                removeContact={this.removeContact}
                            />
                        </div>
                        <div id="TELEX" className="col s12">
                            <ContactDisplay
                                onChange={this.onChange}
                                contacts={this.state.contacts}
                                sectionType="telex"
                                placeholderText={"Telex"}
                                editId={this.state.editId}
                                editIndex={this.state.editIndex}
                                editName={this.state.editName}
                                editEmail={this.state.editEmail}
                                editDefault={this.state.editDefault}
                                editField={this.state.editField}
                                contactFieldOptions={this.state.contactFieldOptions}
                                saveContact={this.addContact}
                                editContact={this.editContact}
                                removeContact={this.removeContact}
                            />
                        </div>
                    </div>
                </Form.Section>
                <div className="row">
                    <Form.Button id="updateButton" col="s2" className={styles.button} type="outline" onClick={this.submitUpdate} label={this.props.match.params.id ? "Update" : "Save"} icon="sync" color="orange" />
                </div>

            </div>
        );
    }
}

const mapStateToProps = state => {
    const { country, urls, index, portalUser } = state;
    const url = urls[index];
    return { country, url, index, portalUser };
};

export default connect(mapStateToProps)(UpdateCarrier);

import React, { Component } from "react";
import { connect } from "react-redux";
import Form from "../../../Components/NewComponents";
import { Editor } from "@tinymce/tinymce-react"
import axios from "axios";
import { flags } from "../../../constants";
import { globalToastActions } from "../../../Redux/actions";
import { auth } from "../../../helperFunctions";
import moment from "moment-timezone";
import styles from "./EmailForm.module.css";


class EmailForm extends Component {
    state = {
        sender: "",
        recipient: "",
        recipientList: [],
        cc: "",
        ccList: [],
        bcc: "",
        bccList: [],
        useBcc: false,
        subject: this.props.subject ? this.props.subject : "",
        body: "",
        signature: "",
        attachments: [],
        pdf: "",
        loadingPdf: false,
        useAllChanges: false,
        mergeFiles: false,
        pdfHtml: '',
        defaultEmailBody: '',
        usePdf:true 
    }

    useBcc = () => {
        let useBcc = !this.state.useBcc;
        this.setState({ useBcc })
    }

    onChange = (name, value) => this.setState({ [name]: value });

    batchOnChange = (name, value) => {
        this.setState({ [name]: value }, () => {
            this.props.resetFiles(value);
        });
    }

    emailOnChange = (name, value) => {
        let recipient = this.state.recipient;
        recipient.push(value);
        this.setState({ recipient })
    }

    handleEditorChange = body => {
        this.setState({ body })
    }


    generatePdf = () => {
        this.setState({ loadingPdf: true }, () => {
            let tz = moment.tz.guess();
            axios.post("/api/v1/email/generatepdf/", {
                ...auth.getAuthData(), tz, fileType: this.props.fileType, fileInfo: this.props.fileInfo, useAllChanges: this.state.useAllChanges
            }).then(result => {
                if (result.data === "NOT AUTHENTICATED") {
                    localStorage.clear();
                    this.props.dispatch({
                        type: "SCORCHED_EARTH"
                    })
                    return;
                } else if (result.data.pdf) {
                    this.setState({ loadingPdf: false, pdf: result.data.pdf, body: result.data.html, pdfHtml: result.data.pdfHtml }, () => {
                        let window = document.getElementById("displayWindow")
                        let data = document.createAttribute("data");
                        data.value = "data:application/pdf;base64," + result.data.pdf
                        window.setAttributeNode(data);
                    })

                } else {
                    this.props.dispatch({
                        type: globalToastActions.UPDATE_MSG,
                        payload: {
                            type: "error", msg: "Error Creating PDF"
                        }
                    })
                    this.setState({ loadingPdf: false })
                }
            })
        })
    }

    generateHtml = () => {
        this.setState({ loadingPdf: true }, () => {
            let tz = moment.tz.guess();
            axios.post("/api/v1/email/generatehtml/", {
                ...auth.getAuthData(), tz, fileType: this.props.fileType, fileInfo: this.props.fileInfo, useAllChanges: this.state.useAllChanges
            }).then(result => {
                if (result.data === "NOT AUTHENTICATED") {
                    localStorage.clear();
                    this.props.dispatch({
                        type: "SCORCHED_EARTH"
                    })
                    return;
                } else if (result.data.html) {
                    this.setState({ loadingPdf: false, html: result.data.html, body: result.data.emailHtml, emailBodyDoc: result.data.emailBodyDoc }, () => {
                        let window = document.getElementById("displayWindow");
                        let data = document.createAttribute("data");
                        data.value = "data:text/html;base64," + Buffer.from(result.data.html).toString('base64')
                        window.setAttributeNode(data);
                    })

                } else {
                    this.props.dispatch({
                        type: globalToastActions.UPDATE_MSG,
                        payload: {
                            type: "error", msg: "Error Creating HTML"
                        }
                    })
                    this.setState({ loadingPdf: false })
                }
            })
        })

    }

    sendEmail = () => {

        if (this.props.generatePdf && !this.state.pdf && !this.state.html) {
            return;
        }
        if (!this.state.recipientList.length) {
            this.props.dispatch({
                type: globalToastActions.UPDATE_MSG, payload: {
                    type: "error", msg: "Email Not Sent: No Recipient Entered"
                }
            });
            return;
        }
        if (this.state.recipientList.map(r => r.invalidEmail).includes(true) ||
            this.state.ccList.map(c => c.invalidEmail).includes(true) ||
            this.state.bccList.map(b => b.invalidEmail).includes(true) ||
            this.state.recipient.trim() || this.state.cc.trim() || this.state.bcc.trim()) {
            alert("Invalid Email Attached");
            return;
        }
    console.log(this.state);

        if (this.state.mergeFiles && this.state.attachments.filter(a => (a.fileType).toLowerCase() !== (this.state.attachments[0].fileType).toLowerCase()).length) {
            this.props.dispatch({
                type: globalToastActions.UPDATE_MSG, payload: {
                    type: "error", msg: "File Types Must Match to Merge!"
                }
            });
            return;
        }
        this.props.send(this.state, this.props.fileInfo);
    }

    persistEmails = (recipientList) => {
        this.setState({ recipientList })
    }

    selectorFunction = (editor) => {
        let user = `${this.props.user.first.charAt(0)}${this.props.user.first.substring(1).toLowerCase()} ${this.props.user.last.charAt(0)}${this.props.user.last.substring(1).toLowerCase()}`
        let html = `                                <p>\n
            <span>Thank you,</span><br/><br/>
            <span>${user}</span><br/>
            <span>Lindsey Forwarders</span><br/>
        </p>`
        editor.setContent(html);
        this.setState({ defaultEmailBody: html })
    };

    componentDidMount = () => {
        let first = this.props.user.first.charAt(0) + ((this.props.user.first).toLowerCase()).slice(1);
        let last = this.props.user.last.charAt(0) + ((this.props.user.last).toLowerCase()).slice(1);
        let sender = first + " " + last;
        let userEmail = this.props.user.emailAddress;
        let recipientList = [];
        let ccList = [];
        let bccList = [];
        this.props.emails.forEach(e => {
            if (e.defaultEmail) {
                if (e.field === "TO") { recipientList.push(e) }
                else if (e.field === "CC") { ccList.push(e) }
                else if (e.field === "BCC") { bccList.push(e) }
            }
        });

        this.setState({ sender, userEmail, recipientList, ccList, bccList });
    }

    componentDidUpdate = (prevProps, prevState) => {

        if ((prevState.useAllChanges !== this.state.useAllChanges || prevState.usePdf !== this.state.usePdf) && !this.state.attachments.length) {
            this.props.saveAndEmail();
        }

        if (this.props.generatePdf && !this.state.loadingPdf && !prevProps.generatePdf) {
            if (this.state.usePdf) {
                this.generatePdf();
            } else {
                this.generateHtml();
            }
        }

        if (prevProps.emails.length !== this.props.emails.length) {
            let recipientList = [];
            let ccList = [];
            let bccList = [];
            this.props.emails.forEach(e => {
                if (e.defaultEmail) {
                    if (e.field === "TO") { recipientList.push(e) }
                    else if (e.field === "CC") { ccList.push(e) }
                    else if (e.field === "BCC") { bccList.push(e) }
                }
            });
            this.setState({ recipient: "", recipientList, ccList, bccList });
        }

        if (prevProps.body !== this.props.body || prevProps.subject !== this.props.subject) {
            this.setState({ subject: this.props.subject, body: this.props.body })
        }


        if (this.props.attachments !== prevProps.attachments) {
            this.setState({ attachments: this.props.attachments });
        }
    }

    render = () => {
        return (
            <div className="modal" id={this.props.id ? this.props.id : 'emailModal'}>
                <div className="modal-content email-modal row">
                    <div className="row">
                        <div className="col s12">
                            <div className="row">
                                <span style={{ float: 'right' }}><i className="material-icons" onClick={this.props.hideEmailModal}>close</i></span>
                                <div className="col s12" style={{ textAlign: "center" }}>
                                    <p style={{ marginTop: "0px", marginBottom: "0px", fontSize: "20px" }}>{this.props.shipperId && this.props.shipperId !== "0" && this.props.party.filter(p => p.id === this.props.shipperId)[0] ? this.props.party.filter(p => p.id === parseInt(this.props.shipperId))[0].name : ""}</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col s1 badgeCol">
                                    <b>To</b>
                                </div>
                                <div className="col s11">
                                    <Form.EmailAutoComplete onChange={this.onChange} name="recipient" value={this.state.recipient} valueList={this.state.recipientList} data={this.props.emails} />
                                </div>
                            </div>
                            {!this.state.useBcc ?
                                <div className="row">
                                    <div className="col s1 badgeCol">
                                        <b>Cc</b>
                                    </div>
                                    <div className="col s10">
                                        <Form.EmailAutoComplete onChange={this.onChange} name="cc" value={this.state.cc} valueList={this.state.ccList} data={this.props.emails} flags={[flags.ALLOW_EMPTY]} />
                                    </div>
                                    <div className="col s1">
                                        <Form.Button color="black" type="outline" label="Bcc" onClick={this.useBcc} />
                                    </div>
                                </div> :
                                <div className="row">
                                    <div className="col s1 badgeCol">
                                        <b>Cc</b>
                                    </div>
                                    <div className="col s11">
                                        <Form.EmailAutoComplete onChange={this.onChange} name="cc" value={this.state.cc} valueList={this.state.ccList} data={this.props.emails} flags={[flags.ALLOW_EMPTY]} />
                                    </div>
                                </div>
                            }
                            {this.state.useBcc ?
                                <div className="row">
                                    <div className="col s1 badgeCol">
                                        <b>Bcc</b>
                                    </div>
                                    <div className="col s11">
                                        <Form.EmailAutoComplete onChange={this.onChange} name="bcc" value={this.state.bcc} valueList={this.state.bccList} data={this.props.emails} flags={[flags.ALLOW_EMPTY]} />
                                    </div>
                                </div> :
                                null
                            }
                            <div className="row">
                                <div className="col s1 badgeCol">
                                    <b>Subject</b>
                                </div>
                                <div className="col s11">
                                    <Form.TextInput placeholder="Add a Subject" name="subject" noblurcap="true" onChange={this.onChange} value={this.state.subject} />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col s12" id="tiny-holder">
                                    <Editor id="tinyEditor" value={this.state.body} onEditorChange={this.handleEditorChange}
                                        init={{
                                            deprecation_warnings: false,
                                            height: 500,
                                            mode: "textareas",
                                            branding: false,
                                            force_br_newlines: true,
                                            force_p_newlines: false,
                                            forced_root_block: '',
                                            init_instance_callback: this.props.generatePdf || this.props.generateHtml ? '' : this.selectorFunction,
                                            menu: {
                                                file: { title: 'File', items: 'newdocument restoredraft | preview | print ' },
                                                edit: { title: 'Edit', items: 'undo redo | cut copy paste | selectall | searchreplace' },
                                                view: { title: 'View', items: 'code | visualaid visualchars visualblocks | spellchecker | preview fullscreen' },
                                                insert: { title: 'Insert', items: 'link media template codesample inserttable | charmap emoticons hr | pagebreak nonbreaking anchor toc | insertdatetime' },
                                                format: { title: 'Format', items: 'bold italic underline strikethrough superscript subscript codeformat | formats blockformats fontformats fontsizes align | forecolor backcolor | removeformat' },
                                                tools: { title: 'Tools', items: 'spellchecker spellcheckerlanguage | code wordcount' },
                                                table: { title: 'Table', items: 'inserttable | cell row column | tableprops deletetable' },
                                                help: { title: 'Help', items: 'help' }
                                            },
                                            plugins: [
                                                "advlist autoresize autolink lists link image charmap print preview anchor",
                                                "searchreplace visualblocks code fullscreen ",
                                                "insertdatetime media table paste code help wordcount"
                                            ],
                                            toolbar: [
                                                "insert | undo redo |  fontselect fontsizeselect formatselect | bold italic strikethrough forecolor backcolor alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | help"
                                            ]
                                        }} />
                                </div>
                            </div>
                            <div className={styles.emailFormButtonRow}>
                                {this.props.fileInfo && this.props.fileInfo.fileType === "bookingAck" ? <Form.Switch className="useChangesSwitch" name="useAllChanges" value={this.state.useAllChanges} onChange={this.onChange} col="col s3" label="Use All Changes" /> : null}
                                {this.props.fileInfo && this.props.fileInfo.fileType === "bookingAck" ? <Form.Switch col="col s3" label="Use PDF" className="usePdf" name="usePdf" value={this.state.usePdf} onChange={this.onChange} /> : null}
                                {this.props.batchEmails ? <Form.Switch col="col s3" label="Use PDF" className="usePdf" name="usePdf" value={this.state.usePdf} onChange={this.batchOnChange} /> : null}
                                {this.props.attachments && !this.props.disableMergeCheckBox ? <Form.CheckBox col="col s3" name="mergeFiles" value={this.state.mergeFiles} onChange={this.onChange} label="Merge Files" /> : null}
                                <Form.Button col={`${(this.props.attachments && !this.props.disableMergeCheckBox) || this.props.batchEmails ? `col s3 offset-s6` : `col s3 offset-s3`}`} label="Send" id="emailButton" onClick={this.sendEmail} />
                            </div>
                            <div className="row">
                                <ul className="col s12 attachmentList">
                                    {this.state.attachments.map((a, index) => (
                                        <li key={a.id} className="attachmentListItem row">
                                            <div className="col s11">{a.fileName}.{a.fileType} </div>
                                            {!this.props.disableMergeCheckBox ?
                                                <span className="col s1 attachmentListIcons">
                                                    <i className="material-icons " onClick={() => this.props.previewFile(a.id)}>launch</i>
                                                    <i className="material-icons" onClick={() => { this.props.unAttachFile(index, this.persistEmails, this.state.recipientList) }}>close</i>
                                                </span>
                                                :
                                                null}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                        {this.props.fileInfo ?
                            <div className="row">
                                <div className="col s12 pdfViewer">
                                    {this.state.loadingPdf ?
                                        <div>
                                            <div className="preloader-wrapper active pdf-loader">
                                                <div className="spinner-layer spinner-blue-only">
                                                    <div className="circle-clipper left">
                                                        <div className="circle"></div>
                                                    </div><div className="gap-patch">
                                                        <div className="circle"></div>
                                                    </div><div className="circle-clipper right">
                                                        <div className="circle"></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <object id="displayWindow" name="Booking Acknowledgement" type="application/pdf">
                                        </object>
                                    }
                                </div>
                            </div>
                            : null}
                    </div >
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    const { user, urls, index, party } = state;
    const url = urls[index];
    return { user, url, party }
}

export default connect(mapStateToProps)(EmailForm)

import React from 'react';
import { connect } from 'react-redux';
import { sidebarActions } from '../../../Redux/actions';
import { Switch, Route } from 'react-router-dom';
import AllianceOverview from './AllianceOverview';
import CreateAlliance from './CreateAlliance';

class Alliance extends React.Component {


    getPath = () => ('/carrier/alliance');
    rebuildNav = () => this.props.dispatch({ type: sidebarActions.REBUILD_NAV, payload: { url: this.getPath() } })
    componentDidMount = () => this.rebuildNav();

    render = () => (
        <Switch>
            <Route exact path={this.getPath()} component={AllianceOverview} />
            <Route exact path={this.getPath() + '/create'} component={CreateAlliance} />
        </Switch>
    )
}


export default connect()(Alliance);
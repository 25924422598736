import React from 'react';
import { connect } from 'react-redux';
import styles from './bookingrequest.module.css';
import M from 'materialize-css';
import moment from 'moment';

class BookingRequestOverview extends React.Component {
    constructor(props) { super(props); this.state = this.initState() };

    initState = () => ({ booking: null });
    initModal = () => M.Modal.init(document.querySelector('#booking-modal'));

    componentDidMount = () => this.initModal();

    formatDate = date => moment(date).format('MMM DD');
    getParty = id => this.props.parties.filter(p => p.id === id)[0].name;
    getCarrier = id => this.props.carriers.filter(c => c.id === id)[0].name;
    getLocation = id => this.props.locations.filter(l => l.id === id)[0].code;
    getContainerType = id => this.props.containerTypes.filter(c => c.id === id)[0].code;

    showModal = booking => {
        this.setState({ booking }, () => {
            M.Modal.getInstance(document.querySelector('#booking-modal')).open();
        })
    }

    hideModal = () => M.Modal.getInstance(document.querySelector('#booking-modal')).close();

    render = () => (
        <div>
            <table className={styles.table}>
                <thead className={styles.thead}>
                    <tr>
                        <th>Status<span /></th>
                        <th>Req<span /></th>
                        <th>Assn<span /></th>
                        <th>Bkg #<span /></th>
                        <th>Shipper<span /></th>
                        <th>Carrier<span /></th>
                        <th>FCL<span /></th>
                        <th>Origin<span /></th>
                        <th>Dest<span /></th>
                        <th>Ref #<span /></th>
                        <th>Consignee<span /></th>
                        <th>Product<span /></th>
                    </tr>
                </thead>
                <tbody className={styles.tbody}>
                    {this.props.bookings.map((bkg, index) => (
                        <tr key={index} onClick={() => this.showModal(bkg)}>
                            <td>{bkg.status}</td>
                            <td>{this.formatDate(bkg.date_requested)}</td>
                            <td>UNFINISHED</td>
                            <td>UNFINISHED</td>
                            <td>{this.getParty(bkg.shipperId)}</td>
                            <td>{this.getCarrier(bkg.carrierId)}</td>
                            <td><b>{bkg.containerCount}</b> {this.getContainerType(bkg.containerTypeId)}</td>
                            <td>{this.getLocation(bkg.originId)}</td>
                            <td>{this.getLocation(bkg.destId)}</td>
                            <td>{bkg.shipperReferenceNumber}</td>
                            <td>{bkg.consigneeName}</td>
                            <td>{bkg.product}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <div className="modal white" id="booking-modal">
                <div className="modal-content">
                    {this.state.booking ?
                        <h5>This page Postponed</h5>

                        : null}
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    const { locations, parties, carriers, containerTypes } = state;
    return { locations, parties, carriers, containerTypes };
}

export default connect(mapStateToProps)(BookingRequestOverview);
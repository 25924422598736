import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import axios from 'axios';
import { auth } from '../../helperFunctions';
import Form from '../../Components/NewComponents';
import moment from 'moment';
import { globalToastActions } from '../../Redux/actions';
import { flags } from "../../constants";
import styles from "./Reports.module.css";


const BookingsCreatedReport = (props) => {

    const [filters, setValues] = useState({
        user: 0,
        bookingStartDate: moment(new Date()).subtract(30, "day").format("MM/DD/YY"),
        bookingEndDate: moment(new Date()).add(30, "day").format("MM/DD/YY"),
        siStartDate: moment(new Date()).subtract(30, "day").format("MM/DD/YY"),
        siEndDate: moment(new Date()).add(30, "day").format("MM/DD/YY"),
        invoiceStartDate: moment(new Date()).subtract(30, "day").format("MM/DD/YY"),
        invoiceEndDate: moment(new Date()).add(30, "day").format("MM/DD/YY"),
        dummyStatus: "ALL",
        currentSort: "user",
        reverse: 1
    });
    const [reportItems, setReportItems] = useState([]);

    const filterOnChange = (name, value) => {
        setValues({ ...filters, [name]: value })
    }

    useEffect(() => {
        document.querySelectorAll(".search-field").forEach(el => { el.addEventListener("keydown", searchListener) })
        return () => {
            document.querySelectorAll(".search-field").forEach(el => { el.removeEventListener("keydown", searchListener) })
        }
    }, []);

    useEffect(() => {
        getCreatedList(filters);
    }, [filters.user, filters.dummyStatus, filters.currentSort, filters.reverse])

    const searchListener = (e) => {
        if (e.key === "Enter") {
            e.preventDefault();
            document.getElementById("searchButton").click();
        }
    }

    const getCreatedList = (filters) => {
        axios.get("/api/v1/report/bookingscreated", { params: { ...auth.getAuthData(), filters } }).then(result => {
            if (result === "NOT AUTHENTICATED") {
                localStorage.clear();
                return this.props.dispach({ type: "SCORCHED_EARTH" });
            } else if (result.data.errno) {
                setReportItems([]);
                return props.dispatch({ type: globalToastActions.UPDATE_MSG, payload: { msg: `Error: ${result.data.code}`, type: "error" } })
            } else {
                if (!result.data.length) {
                    props.dispatch({ type: globalToastActions.UPDATE_MSG, payload: { msg: "No Results" } });
                }
                setReportItems(result.data)
            }
        })
    }

    const sort = (sortName) => {
        if (!sortName) {
            return;
        } else {
            const reverse = filters.currentSort === sortName ? (-1 * filters.reverse) : 1;
            setValues({ ...filters, currentSort: sortName, reverse })
        }
    }

    return (
        <div>
            <div className="row" style={{ marginTop: "15px" }}>
                <Form.Select col="col s3" label="User" placeholder="ALL" name="user" value={filters.user} onChange={filterOnChange} list={props.portalUser} filter={{ label: "fullName", value: "id" }} />
                <Form.DateInput col="col s3 search-field" label="Bkg Start" name="bookingStartDate" value={filters.bookingStartDate} onChange={filterOnChange} flags={[flags.ALLOW_EMPTY]} />
                <Form.DateInput col="col s3 search-field" label="Bkg End" name="bookingEndDate" value={filters.bookingEndDate} onChange={filterOnChange} flags={[flags.ALLOW_EMPTY]} />
                <Form.DateInput col="col s3 search-field" label="SI Start" name="siStartDate" value={filters.siStartDate} onChange={filterOnChange} flags={[flags.ALLOW_EMPTY]} />
                <Form.DateInput col="col s3 search-field" label="SI End" name="siEndDate" value={filters.siEndDate} onChange={filterOnChange} flags={[flags.ALLOW_EMPTY]} />
                <Form.DateInput col="col s3 search-field" label="Invoice Start" name="invoiceStartDate" value={filters.invoiceStartDate} onChange={filterOnChange} flags={[flags.ALLOW_EMPTY]} />
                <Form.DateInput col="col s3 search-field" label="Invoice End" name="invoiceEndDate" value={filters.invoiceEndDate} onChange={filterOnChange} flags={[flags.ALLOW_EMPTY]} />
                <Form.Button id="searchButton" col="col s3" type="outline" color="green" label="Search" onClick={() => getCreatedList(filters)} />
            </div>
            <div className="row">
                <table className={styles.reportTable}>
                    <thead>
                        <tr>
                            <th onClick={() => sort('user')}>User&nbsp;{filters.currentSort === "user" ? filters.reverse === 1 ? `⮝` : `⮟` : ""}</th>
                            <th onClick={() => sort('booking')}>Bkgs Created&nbsp;{filters.currentSort === "booking" ? filters.reverse === 1 ? `⮝` : `⮟` : ""}</th>
                            <th onClick={() => sort('si')}>SIs Created&nbsp;{filters.currentSort === "si" ? filters.reverse === 1 ? `⮝` : `⮟` : ""}</th>
                            <th onClick={() => sort('invoice')}>Invoices Created&nbsp;{filters.currentSort === "invoice" ? filters.reverse === 1 ? `⮝` : `⮟` : ""}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {reportItems.map((item, index) => (
                            <tr key={index} >
                                <td>{item.user}</td>
                                <td>{item.bookingCount ? item.bookingCount : 0}</td>
                                <td>{item.siCount ? item.siCount : 0}</td>
                                <td>{item.invoiceCount ? item.invoiceCount : 0}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
}

const mapStateToProps = state => {
    const { urls, index, user, portalUser } = state;
    const url = urls[index];
    return { url, user, portalUser }
}

export default connect(mapStateToProps)(withRouter(BookingsCreatedReport));
import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import BarGraph from "../../Components/ChartComponents/BarChart";
import Axios from 'axios';
import { auth } from "../../helperFunctions";

class OverviewGraph extends Component {
    state = {
        days: 0,
        dayArr: [],
        bookingCountArr: [],
        containerCountArr: [],
        bookings: [],
        dataLabels: [],
        showContextMenu: false,
        containerPerDay: false
    }

    getChartData = async (allDates) => {

        return new Promise(async (resolve, reject) => {
            let startDate;
            let endDate;

            if (allDates) {
                startDate = "01/01/2020";
                endDate = "01/01/2020";
            } else {
                startDate = this.props.startDate;
                endDate = this.props.endDate;
            }

            await this.getAllBookings();

            let dateType;
            switch (this.props.dateType) {
                case "DOC":
                    dateType = "fullDocCutDate"
                    break;
                case "CUT":
                    dateType = "fullCargoCutDate"
                    break;
                case "ETD":
                    dateType = "fullEtd"
                    break;
                case "ETA":
                    dateType = "fullEta"
                    break;
                default:
                    break;
            }

            let bookings = this.state.bookings;

            let days = moment(endDate, "MM/DD/YY").diff(moment(startDate, "MM/DD/YY"), 'days') + 1;
            let dayArr = [];
            let containerCountArr = [...new Array(days)].fill(0);
            let bookingCountArr = [...new Array(days)].fill(0);

            for (let i = 0; days > i; i++) {
                dayArr.push(moment(startDate, "MM/DD/YY").add(i, 'days').format('MM/DD/YY'));
            }

            bookings.forEach(b => {
                containerCountArr[dayArr.indexOf(b[dateType])] += b.containerCount;
                bookingCountArr[dayArr.indexOf(b[dateType])]++
            })

            resolve(this.setState({ dayArr, bookingCountArr, containerCountArr }));
        })
    }


    getAllBookings = () => {

        return new Promise((resolve, reject) => {
            Axios.get("/api/v1/report/overviewreport", { params: { ...auth.getAuthData(), filters: this.props.filters, filterList: this.props.filterList } }).then(result => {
                if (result.data === "NOT AUTHENTICATED") {
                    localStorage.clear()
                    this.props.dispatch({ type: "SCORCHED_EARTH" });
                    return;
                }
                resolve(this.setState({ bookings: result.data.result }));
            })
        })
    }

    handleContextMenu = (e) => {
        e.preventDefault();
        this.setState({ showContextMenu: true, x: e.pageX, y: e.pageY })
        document.addEventListener('click', this.clickListener);
    };


    clickListener = e => {
        this.setState({ showContextMenu: false }, () => document.removeEventListener('click', this.clickListener))
    }

    toggleChart = () => {
        let containerPerDay = !this.state.containerPerDay
        this.setState({ containerPerDay })
    }

    componentDidUpdate = (prevProps, prevState) => {
        if (!this.props.showGraph && document.getElementById("barWrapper").style.display === "block") {
            return document.getElementById("barWrapper").style.display = "none";
        } else if (!this.props.showGraph) {
            return
        } else if (prevProps.bookings !== this.props.bookings && prevProps.bookings.length) {
            if (moment(this.props.endDate, "MM/DD/YY").diff(moment(this.props.startDate, "MM/DD/YY"), "days") > 91 || !this.props.startDate.length || !this.props.endDate.length) {
                this.getChartData(true).then(() => {
                    document.getElementById("chart-alert").style.display = "block"
                })

            } else if (moment(this.props.startDate, "MM/DD/YY").isValid() === true && moment(this.props.endDate, "MM/DD/YY").isValid() === true) {
                document.getElementById("chart-alert").style.display = "none"
                this.getChartData();
            } else {
                document.getElementById("chart-alert").style.display = "none"
                this.getChartData(true);
            }
        }

        if (prevProps.showGraph !== this.props.showGraph) {
            if (this.props.showGraph) {
                document.getElementById("barWrapper").style.display = "block"
            } else if (document.getElementById("barWrapper").style.display === "block") {
                document.getElementById("barWrapper").style.display = "none"
                document.getElementById("chart-alert").style.display = "none"
            }
        }
    }


    render = () => (
        <div>
            <div style={{ position: "relative" }}>
                <div id="barWrapper" style={{ "display": "none" }} onContextMenu={e => this.handleContextMenu(e)} >
                    <BarGraph showGraph={this.props.showGraph} dataSet={this.state.containerPerDay ? this.state.containerCountArr : this.state.bookingCountArr} labels={this.state.dayArr} dataSetLabels={this.state.containerPerDay ? "Containers Per Day" : "Bookings Per Day"} />
                </div>
                <div id="chart-alert">{this.props.showGraph ? 'Graph Requires Date Range Less Than 90 Days' : ""}</div>
                <div style={{ position: 'fixed', top: this.state.y, left: this.state.x }} className="lf-chart-context-menu lf-shipment-context-menu">
                    <ul className="collection">
                        {this.state.showContextMenu && <li onClick={this.toggleChart}>TOGGLE CHART TYPE</li>}
                    </ul>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    const { urls, index } = state;
    const url = urls[index];
    return { url }
}


export default connect(mapStateToProps)(OverviewGraph);
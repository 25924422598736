import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import CreateInvoice from './CreateInvoice';
import InvoiceOverview from './InvoiceOverview';

class Invoice extends React.Component {
    getPath = () => ('/invoice');
    // rebuildNav = () => this.props.dispatch({ type: sidebarActions.REBUILD_NAV, payload: { url: this.getPath() } });
    // componentDidMount = () => this.rebuildNav();

    render = () => (
        <Switch>
            <Route exact path={this.getPath()} component={InvoiceOverview} />
            <Route exact path={this.getPath() + '/create'} component={CreateInvoice} />
        </Switch>
    )
}

export default connect()(Invoice);
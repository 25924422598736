import React from 'react';
import { tabActions } from '../../Redux/actions';
// import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import './tabs.css';


class Tabs extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hoverIndex: -1 };
    }

    //REDUX DISPATCH FUNCTIONS
    addTab = event => { if (event) { event.preventDefault() }; this.props.dispatch({ type: tabActions.ADD_TAB }); };
    tabLeft = () => this.props.dispatch({ type: tabActions.TAB_LEFT });
    tabRight = () => this.props.dispatch({ type: tabActions.TAB_RIGHT });
    selectTab = index => this.props.dispatch({ type: tabActions.SELECT_TAB, payload: index });
    closeTab = index => this.props.dispatch({ type: tabActions.CLOSE_TAB, payload: index });
    // --END REDUX DISPATCH FUNCTIONS

    //REACT LIFECYCLE METHODS
    componentDidMount = () => this.initKeyListener();
    componentDidUpdate = () => this.initKeyListener();
    // --END REACT LIFECYCLE METHODS

    //KEY LISTENER FUNCTIONS
    initKeyListener = () => document.addEventListener('keydown', this.parseKeys);
    removeKeyListener = () => document.removeEventListener('keydown', this.parseKeys);
    pauseKeyListener = callback => { this.removeKeyListener(); callback(); setTimeout(this.initKeyListener, 50) };
    parseKeys = event => {
        if (event.ctrlKey && event.keyCode === 39)
            this.pauseKeyListener(this.tabRight);
        if (event.ctrlKey && event.keyCode === 37)
            this.pauseKeyListener(this.tabLeft);
        if (event.ctrlKey && event.keyCode === 32)
            this.pauseKeyListener(this.addTab);
    }
    // --END KEY LISTENER FUNCTIONS

    render = () => {
        return (
            <div id={this.props.user ? this.props.user.isEmployee ? "" : 'hiddenTabs' : ""}>




            {/* TEMPORARILY DISABLING THESE TABS FOR SAKE OF SPACE UNTIL WE RETURN TO THEM IN THE FUTURE AND IMPLEMENT FIXES AND FINISHES */}



                {/* <div>
                    <div className="col s12">
                        <ul className="customTabs">
                            {this.props.tabCount.map((empty, index) => {
                                if (index === this.state.hoverIndex)
                                    return (
                                        <li
                                            key={index}
                                            className={`tab col s3 ${this.props.index === index ? 'active' : null}`}
                                            onClick={() => this.selectTab(index)}
                                            onMouseLeave={() => this.setState({ hoverIndex: -1 })}
                                        >
                                            <Link to='/'>
                                                {`Tab ${index + 1}`}
                                                <i className="material-icons red-text right tab-close" onClick={() => this.closeTab(index)}>close</i>
                                            </Link>
                                        </li>
                                    )

                                else
                                    return <li key={index} className={`tab col s3 ${this.props.index === index ? 'active' : null}`} onClick={() => this.selectTab(index)} onMouseEnter={() => this.setState({ hoverIndex: index })}><Link to='/'>{`Tab ${index + 1}`}</Link></li>;
                            })}
                            <li className="tab col s3"><Link to="/" onClick={this.addTab}>New Tab</Link></li>
                        </ul>
                    </div>
                </div> */}
            </div>
        )
    }
}

const mapStateToProps = state => {
    const { index, user } = state;
    const sidebar = state.sidebars[index];
    const tabCount = [...Array(state.tabCount)];
    return { index, tabCount, sidebar, user };
}


export default connect(mapStateToProps)(Tabs);
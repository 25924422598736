import React from 'react';
import { connect } from 'react-redux';
import Form from '../../../Components/NewComponents';
import Axios from 'axios';
import { globalToastActions } from '../../../Redux/actions';
import { auth } from "../../../helperFunctions";


class Split extends React.Component {
    constructor(props) { super(props); this.state = this.initState() }

    initState = () => ({
        original: {
            search: '',
            booking: null,
            newBookingNumber: '',
            containers: []
        },
        split: {
            search: '',
            booking: null,
            newBookingNumber: '',
            containers: []
        }
    })

    initListeners = () => {
        document.addEventListener('keydown', this.enterSearch);
    }

    removeListeners = () => {
        document.removeEventListener("keydown", this.enterSearch);
    }

    enterSearch = (e) => {
        if (document.activeElement.id === 'search' && e.keyCode === 13) {
            this.searchBookings(document.activeElement.getAttribute("data-name"));
        }
    }

    searchBookings = which => {
        let { search } = JSON.parse(JSON.stringify(this.state[which]));

        if (!search)
            return;

        Axios.get(`/api/v1/booking/bookingnumber/${search}`, { params: auth.getAuthData() })
            .then(result => {
                if (result.data === 'NOT AUTHENTICATED') {
                    localStorage.clear();
                    this.props.dispatch({ type: 'SCORCHED_EARTH' })
                    return;
                }

                if (result.data === 'No results') {
                    this.props.dispatch({ type: globalToastActions.UPDATE_MSG, payload: { msg: 'No Results', type: 'warning' } });
                    return;
                }

                const bkg = JSON.parse(JSON.stringify(this.state[which]));
                bkg.booking = result.data;
                this.setState({ [which]: bkg }, () => this.searchContainers(which));
            })
    }

    searchContainers = which => {
        const { bookingNumber } = JSON.parse(JSON.stringify(this.state[which].booking.booking));

        Axios.get(`/api/v1/container/bookingnumbersplit/${bookingNumber}`, { params: auth.getAuthData() })
            .then(result => {
                if (result.data === 'NOT AUTHENTICATED') {
                    localStorage.clear();
                    this.props.dispatch({ type: 'SCORCHED_EARTH' })
                    return;
                }

                const bkg = JSON.parse(JSON.stringify(this.state[which]));
                bkg.containers = result.data;

                this.setState({ [which]: bkg });
            })
    }

    toggleContainer = (index, name) => {
        if (!this.state.original.booking || !this.state.split.booking)
            return;

        const bkg = JSON.parse(JSON.stringify(this.state[name]));
        const otherName = name === 'original' ? 'split' : 'original';
        const otherBkg = JSON.parse(JSON.stringify(this.state[otherName]));
        otherBkg.containers.push(bkg.containers.splice(index, 1)[0]);
        this.setState({ [name]: bkg, [otherName]: otherBkg });
    }

    ogChange = (name, value) => {
        const original = JSON.parse(JSON.stringify(this.state.original));
        original[name] = value;
        this.setState({ original });
    }

    splitChange = (name, value) => {
        const split = JSON.parse(JSON.stringify(this.state.split));
        split[name] = value;
        this.setState({ split });
    }

    getCarrier = id => this.props.carrier.filter(c => c.id === id)[0].name;
    getLocation = id => {
        return this.props.location.filter(l => l.id === id)[0].name;
    }

    save = () => Axios.post('/api/v1/booking/split', {
        ...auth.getAuthData(),
        ...this.state,
        cancelOriginal: this.state.original.containers.length ? false : true
    })
        .then(result => {
            if (result.data === 'NOT AUTHENTICATED') {
                localStorage.clear();
                this.props.dispatch({ type: 'SCORCHED_EARTH' })
                return;
            }

            if (result.data.errCode)
                this.props.dispatch({ type: globalToastActions.UPDATE_MSG, payload: { msg: 'An Error Occurred', type: 'error' } });
            else
                this.props.dispatch({ type: globalToastActions.UPDATE_MSG, payload: { msg: 'Split Successful', type: 'success' } });
        })


    componentDidMount = () => {
        this.initListeners();
    }
    componentWillUnmount = () => {
        this.removeListeners();
    }

    render = () => (
        <div>
            <div className="row">
                <div className="col s6">
                    <Form.Section label={`Original: ${this.state.original.booking ? this.state.original.booking.booking.bookingNumber : ''}`}>
                        <div className="row">
                            {!this.state.original.booking ? <Form.TextInput name="search" data="original" label="Search" value={this.state.original.search} onChange={this.ogChange}/> : null}
                        </div>
                        {this.state.original.booking ?
                            <Form.Section label="Info">
                                <div className="row">
                                    <div className="col s6">
                                        <b className="col">Vessel:</b>
                                        <p className="col">{this.state.original.booking.vessel.name} {this.state.original.booking.vesselVoyage.voyageNumber}</p>
                                    </div>
                                    <div className="col s6">
                                        <b className="col">Carrier:</b>
                                        <p className="col">{this.getCarrier(this.state.original.booking.vesselVoyage.carrierId)}</p>
                                    </div>
                                    <div className="col s6">
                                        <b className="col">Origin:</b>
                                        <p className="col">{this.getLocation(this.state.original.booking.portPair.placeOfReceiptId)}</p>
                                    </div>
                                    <div className="col s6">
                                        <b className="col">Destination:</b>
                                        <p className="col">{this.getLocation(this.state.original.booking.portPair.portOfDeliveryId)}</p>
                                    </div>
                                </div>
                                {/* <div className="row">
                                    <Form.TextInput name="newBookingNumber" label="New Booking #" value={this.state.original.newBookingNumber} onChange={this.ogChange} />
                                </div> */}
                            </Form.Section>
                            : null
                        }
                        <Form.Section label="containers">
                            {this.state.original.containers ? this.state.original.containers.map((c, index) =>
                                <div
                                    key={`c.containerNumber-${index}`}
                                    className="row"
                                >
                                    <b className="col">Container #:</b>
                                    <p className="col">{c.containerNumber}</p>
                                    <i
                                        className={`material-icons col`}
                                        onClick={() => this.toggleContainer(index, 'original')}
                                    >chevron_right</i>
                                </div>
                            ) : null}
                        </Form.Section>
                    </Form.Section>
                </div>





                <div className="col s6">
                    <Form.Section label={`Split: ${this.state.split.booking ? this.state.split.booking.booking.bookingNumber : ''}`}>
                        <div className="row">
                            {!this.state.split.booking ? <Form.TextInput name="search" data="split" label="Search" value={this.state.split.search} onChange={this.splitChange} /> : null}
                        </div>
                        {this.state.split.booking ?
                            <Form.Section label="Info">
                                <div className="row">
                                    <div className="col s6">
                                        <b className="col">Vessel:</b>
                                        <p className="col">{this.state.split.booking.vessel.name} {this.state.split.booking.vesselVoyage.voyageNumber}</p>
                                    </div>
                                    <div className="col s6">
                                        <b className="col">Carrier:</b>
                                        <p className="col">{this.getCarrier(this.state.split.booking.vesselVoyage.carrierId)}</p>
                                    </div>
                                    <div className="col s6">
                                        <b className="col">Origin:</b>
                                        <p className="col">{this.getLocation(this.state.split.booking.portPair.placeOfReceiptId)}</p>
                                    </div>
                                    <div className="col s6">
                                        <b className="col">Destination:</b>
                                        <p className="col">{this.getLocation(this.state.split.booking.portPair.portOfDeliveryId)}</p>
                                    </div>
                                </div>
                                {/* <div className="row">
                                    <Form.TextInput name="newBookingNumber" label="New Booking #" value={this.state.split.newBookingNumber} onChange={this.splitChange} />
                                </div> */}
                            </Form.Section>
                            : null
                        }
                        <Form.Section label="containers">
                            {this.state.split.containers ? this.state.split.containers.map((c, index) =>
                                <div
                                    key={`c.containerNumber-${index}`}
                                    className="row"
                                >
                                    <b className="col">Container #:</b>
                                    <p className="col">{c.containerNumber}</p>
                                    <i
                                        className={`material-icons col`}
                                        onClick={() => this.toggleContainer(index, 'split')}
                                    >chevron_right</i>
                                </div>
                            ) : null}
                        </Form.Section>
                    </Form.Section>
                </div>
            </div>
            <div className="row">
                {this.state.split.containers.length ?
                    <Form.Button col="s2" label="Save" icon="save" color="green" type="outline" onClick={this.save} />
                    : null}
            </div>
            <small>Note: Splits only go one way. Original `{'->'}` Split.</small>
        </div>
    )
}

const mapStateToProps = state => {
    const { carrier, location } = state;
    return { carrier, location }
};

export default connect(mapStateToProps)(Split);
import React from "react";
import { connect } from "react-redux";
import {
  urlActions,
  navActions,
  globalToastActions
} from "../../Redux/actions";
import { withRouter } from "react-router-dom";
import Axios from "axios";
import "./sidebar.css";
import SideBarTab from "./SideBarTab";
import { auth } from "../../helperFunctions";
import GlobalSearch from './GlobalSearch';

class SideBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedLink: -1,
      masterSearch: "",
      links: [],
      activeLink: "/shipment",
      carrierList: this.props.url.url.split("/") === "contract" ? this.props.url.state.carrierList : []
    };
  }

  updateUrl = url => {
    this.props.dispatch({
      type: urlActions.UPDATE_URL,
      payload: url
    });

  }
  setNavFilterByShipper = bool =>
    this.props.dispatch({ type: navActions.SET_FILTER, payload: bool });
  // --END REDUX DISPATCH FUNCTIONS

  getNav = () => {
    return this.props.sidebar.navs[this.props.sidebar.index]
  };

  getCustNav = () => this.props.sidebar.custNavs[this.props.sidebar.index];

  isLastNav = () =>
    this.props.sidebar.navs.length - 1 === this.props.sidebar.index;
  getIndex = () => this.props.sidebar.index;

  adminCheck = () => {
    if (!this.props.user) {
      return;
    } else if (this.props.user.isEmployee || this.props.user.is_employee) {
      if (this.props.user.is_admin || this.props.user.isAdmin) {
        this.setState({
          links: this.getNav().links
        })
      } else if (this.props.user.is_bookkeeper || this.props.user.isBookkeeper) {
        let notAdminLinks = this.getNav().links.filter(link => link.url !== "report");
        this.setState({
          links: notAdminLinks
        })
      } else {
        let notAdminLinks = this.getNav().links.filter(link => link.url !== "invoice" && link.url !== "report");
        this.setState({
          links: notAdminLinks
        })
      }
    } else {
      if ([84, 1011, 319].includes(parseInt(this.props.user.partyId ? this.props.user.partyId : this.props.user.party_id, 10))) {
        this.setState({
          links: this.getCustNav().links
        })
      } else {
        let customerLinks = this.getCustNav().links.filter(link => link.url !== "request")
        this.setState({
          links: customerLinks
        })
      }
    }
  }

  filterShippers = () => {
    let shipperList = Array.from(new Set(this.props.shipper.map(s =>
      s.name))).map(name => {
        return this.props.shipper.find(s =>
          s.name === name)
      });
    this.setState({ shipperList });

  };


  activeLinkSwitch = () => {
    let urlArr = this.props.location.pathname.split("/");
    switch (urlArr[1]) {
      case 'shipment':
        if (urlArr[2]) {
          if (urlArr[2] === 'split') {
            this.setState({ activeLink: '/shipment/split' })
          } else {
            this.setState({ activeLink: '/shipment/create/ ' });
          }
        } else {
          this.setState({ activeLink: '/shipment' });
        }
        break
      case 'party':
        this.setState({ activeLink: "/party" })
        break;
      case 'carrier':
        this.setState({ activeLink: "/carrier" });
        break;
      case 'location':
        this.setState({ activeLink: "/location" });
        break;
      case 'contract':
        this.setState({ activeLink: "/contract" });
        break;
      case 'service':
        this.setState({ activeLink: "/service/" });
        break;
      case 'report':
        this.setState({ activeLink: "/report" });
        break;
      case 'forecast':
        this.setState({ activeLink: this.props.location.pathname, hide: true })
        break;
      default:
        this.setState({ activeLink: this.props.location.pathname });
        break;
    }
  }

  componentDidMount = () => {
    this.adminCheck();
    this.activeLinkSwitch();

    if (this.props.user && (this.props.user.isEmployee === 1 || this.props.user.is_employee === 1)) {
      this.filterShippers();
    }

  };

  componentDidUpdate = (prevProps, prevState) => {

    if (this.props.location.pathname !== prevProps.location.pathname) {
      this.activeLinkSwitch();
    }

    if (this.props.url.state.searchTerm !== prevProps.url.state.searchTerm && this.props.url.state.searchTerm) {
      this.setState({ masterSearch: this.props.url.state.searchTerm })
    }

    if (prevProps.user !== this.props.user) {

      this.adminCheck();
    }
  }

  search = e => {
    if (e.keyCode === 13 && this.state.masterSearch) {
      let masterSearch = this.state.masterSearch
        .trim()
        .split("-")
        .join("");


      Axios.get(`/api/v1/booking/search/${masterSearch}`, {
        params: auth.getAuthData()
      }).then(result => {
        if (result.data === "NOT AUTHENTICATED") {
          localStorage.clear();
          this.props.dispatch({ type: "SCORCHED_EARTH" });
          return;
        }

        if (result.data) {
          const regex = /^([A-Za-z]{5})$|^([A-Za-z]{2}-[A-Za-z]{3})$/;
          const bool = regex.test(this.state.masterSearch);
          this.props.dispatch({
            type: urlActions.UPDATE_URL,
            payload: {
              url: `/shipment/${bool ? "instructions" : `create/${result.data.bookingNumber}`}`,
              state: { searchTerm: result.data.bookingNumber, shipperId: result.data.shipperId }
            }
          });
        } else if (!result.data) {
          this.props.dispatch({
            type: globalToastActions.UPDATE_MSG,
            payload: { msg: "No Results", type: "warning" }
          });
        } else {
          this.props.dispatch({
            type: globalToastActions.UPDATE_MSG,
            payload: { msg: "Error", type: "error" }
          })
        }
      });
    }
  };

  pasteSearch = (event) => {
    if (event.clipboardData || window.clipboardData) {

      let regexp = new RegExp(/[^A-Za-z0-9]/g)

      let masterSearch;
      if (event.clipboardData) {
        masterSearch = event.clipboardData.getData("text").trim().split("-").join("");
        masterSearch = masterSearch.split("/")[0].replace(regexp, "");
      } else {
        masterSearch = window.clipboardData.getData("text").trim().split("-").join("");
        masterSearch = masterSearch.split("/")[0].replace(regexp, "");
      }

      if (!masterSearch) {
        return;
      }

      Axios.get(`/api/v1/booking/search/${masterSearch}`, {
        params: auth.getAuthData()
      }).then(result => {
        if (result.data === "NOT AUTHENTICATED") {
          localStorage.clear();
          this.props.dispatch({ type: "SCORCHED_EARTH" });
          return;
        }

        if (result.data) {
          const regex = /^([A-Z]{5})$|^([A-Z]{2}-[A-Z]{3})$/;
          const bool = regex.test(this.state.masterSearch);
          this.props.dispatch({
            type: urlActions.UPDATE_URL,
            payload: {
              url: `/shipment/${bool ? "instructions" : `create/${result.data.bookingNumber}`}`,
              state: { searchTerm: result.data.bookingNumber, shipperId: result.data.shipperId }
            }
          });

        } else {
          this.props.dispatch({
            type: globalToastActions.UPDATE_MSG,
            payload: { msg: "No Results", type: "warning" }
          });
        }
      });
    }
  }

  shipperOnChange = (name, value) => {
    this.setState({ [name]: value })
    this.props.dispatch({
      type: navActions.CHANGE_ID, payload: parseInt(value)
    })
  }

  goToCarrierContract = (c, state) => {

    if (c.carrierName === "NO OTHER CONTRACTS") {
      return;
    }

    Axios.get("/api/v1/contract/carriers", { params: { ...auth.getAuthData(), shipperId: state.shipperId, carrierId: c.carrierId, contractId: c.id, commodityId: c.commodityId, containerTypeId: c.containerTypeId } }).then(result => {
      if (result.data === "NOT AUTHENTICATED") {
        localStorage.clear();
        this.props.dispatch({ type: "SCORCHED_EARTH" });
        return;
      }
      let contracts = result.data.length ? result.data : [{ carrierName: "NO OTHER CONTRACTS" }];

      this.props.dispatch({
        type: urlActions.UPDATE_URL, payload: {
          url: "/contract/edit", state: {
            origin: "contractOverview", contractSearchTerm: c.id, shipperId: state.shipperId, shipperName: state.shipperName,
            filters: this.props.filters, carrierId: c.carrierId, carrierName: c.carrierDisplayName, contracts
          }
        }
      })
    })
  }

  render = () => {
    if (this.props.hideSidebar)
      return null
    else
      return (
        <div id="lf-sidebar-nav" className="sidebar">
          <ul className="collection with-header sidebar-collection" style={{ overflow: 'auto' }}>
            <div className="search-wrapper row">
              {this.props.user && (this.props.user.is_employee || this.props.user.isEmployee) ?
                <GlobalSearch />
                : null}

            </div>
            <li className="collection-header sidebar-collection-header" >{this.getNav().title}</li>

            {this.state.links.map((link, index) => (
              <SideBarTab
                key={index}
                listKey={index}
                updateUrl={this.updateUrl}
                url={this.getNav().path + link.url}
                linkName={link.name}
                activeLink={this.state.activeLink}
              />

            ))}

            {this.props.url.state.contracts ?
              <ul style={{ overflowY: "auto", maxHeight: "175px" }}>{this.props.url.state.contracts[0].shipperCode} Contracts:
                {this.props.url.state.contracts.map((c, index) => (

                  <li key={index} className="carrierListTabs" style={c.activeLineage ? { color: "white", backgroundColor: "#33679e" } : {}} onClick={c.activeLineage ? null : () => this.goToCarrierContract(c, this.props.url.state)}>
                    {c.carrierName} {c.displayCommodityType ? ` - ${c.commodity}` : ""} {c.displayContainerType ? ` - ${c.containerType}` : ""}
                    &nbsp;{c.overlapContracts > 1 ? `(${c.overlapContracts})` : ``}
                  </li>
                ))}
              </ul>
              : null}
          </ul>

        </div>
      );
  };
}

const mapStateToProps = state => {
  const { navs, index, sidebars, urls, user, shipper } = state;
  const url = urls[index];
  return { sidebar: sidebars[index], url, user, navs, shipper };
};

export default connect(mapStateToProps)(withRouter(SideBar));

import React from 'react';
import Form from "../../../../Components/NewComponents";


const BSE = (props) => {
    return (
        <div>
            <div className="row" style={{ margin: "0px", marginTop: "-20px", marginBottom: "0px" }}>
                        <Form.TextArea tabIndex="2" col="col s8" label="Description" height="130px" name="description" value={props.description} onChange={props.onChange} />
                    <div className="col s2">
                        <div className="row">
                            <Form.TextInput tabIndex="2" col="s12" label="Product" name="product" value={props.product} onChange={props.onChange} />
                        </div>                        
                        <div className="row">
                            <Form.TextInput tabIndex="2" col="s12" label="Gross Net Weight (MT)" name="grossWeight" value={props.grossWeight} onChange={props.onChange} />
                        </div>
                    </div>
                    <div className="col s2">
                        <div className="row">
                            <Form.AutoComplete tabIndex="2" col="s12" label="State Of Notary" name="stateOfNotary" value={props.stateOfNotary} onChange={props.onChange} data={[{ name: "Arizona" }, { name: "Oregon" }]} filterValue="name" />
                        </div>
                        <div className="row">
                            <Form.AutoComplete tabIndex="2" col="s12" label="County Of Notary" name="countyOfNotary" value={props.countyOfNotary} onChange={props.onChange} data={[{ name: "Maricopa" }, { name: "Multnomah" }]} filterValue="name" />
                        </div>
                        <div className="row">
                            <Form.AutoComplete tabIndex="2" col="s12" label="City Of Notary" name="cityOfNotary" value={props.cityOfNotary} onChange={props.onChange} data={[{ name: "Mesa" }, { name: "Portland" }]} filterValue="name" />
                        </div>
                    </div>
            </div>
        </div>
    )
}



export default BSE;
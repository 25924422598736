import React from 'react';
import { connect } from 'react-redux';
import { sidebarActions } from '../../Redux/actions';
import { Switch, Route } from 'react-router-dom';
import BookingRequest from './BookingRequest';
//import BookingRequestOverview from './BookingRequestOverview';

class Bookings extends React.Component {

    getPath = () => ('/booking');
    rebuildNav = () => this.props.dispatch({ type: sidebarActions.REBUILD_NAV, payload: { url: '/booking/' } });
    componentDidMount = () => this.rebuildNav();

    render = () => (
        <Switch>
            <Route path={this.getPath()} component={BookingRequest} />
        </Switch>
    )
}

export default connect()(Bookings);
import React from 'react';
import styles from "./Requests.module.css";
import Form from "../../Components/NewComponents";
import { flags } from '../../constants';

const StatusEditSection = (props) => {

    return <div className={`row ${styles.noMarginBottom}`}>
        <div>
            {!props.employee ?
                <div>
                    <div className="row">
                        <div className={`col s2 ${styles[props.status]}`}>
                            {props.status}
                        </div>
                        <div className={`col s4 ${styles.shipperViewText}`}>
                            {props.statusMemo}
                        </div>
                        <div className={`col s6 ${styles.shipperViewText} ${styles.alignRight}`}>
                            Assigned To: {props.assignedToFullName}
                        </div>
                    </div>
                    <div className="row">
                        <div className={`col s6  ${styles.shipperViewText}`}>
                            {props.bookingNumber ?
                                <div>
                                    <label className={styles.bookingNumberLabel} htmlFor="bookingNumberSpan">Bkg #</label>
                                    <span id='bookingNumberSpan' className={styles.bookingNumberSpan}>
                                        {props.bookingRequestNumber}
                                    </span>
                                </div>
                                :
                                null}
                        </div>
                    </div>
                </div>
                :
                <div>
                    {!props.settingReminder ?
                        props.reminderNote ?
                            <div>
                                <div className="row">
                                    <div className={`col s3 ${styles[props.status]}`} onContextMenu={(e) => props.handleStatusMenu(e, props.id)}>{props.status}</div>
                                    <Form.Select col="col s3" label="Status Memo" name="statusMemo" value={props.statusMemo} onChange={props.statusMemoOnChange} placeholder="(NONE)" list={props.statusMemoList} filter={{ label: 'name', value: 'name' }} flags={[flags.ALLOW_EMPTY]} />
                                    <Form.Select col="s6" label="Assigned To" name="assignedTo" value={props.assignedTo} onChange={props.assignedToOnChange} placeholder="NO DEFAULT" list={props.portalUser} filter={{ label: "fullName", value: "id" }} flags={[flags.ALLOW_EMPTY]} />
                                </div>
                                <div className={`row ${styles.noMarginBottom}`}>
                                    <form onSubmit={(e) => { props.saveBookingRequestNumber(e) }}>
                                        <Form.TextInput col="col s6" label="Booking Req. #" name="bookingRequestNumber" value={props.bookingRequestNumber} onChange={props.onChange} onBlur={props.changeTabOrder} flags={[flags.ALLOW_EMPTY]} />
                                    </form>
                                    <div className="col s6">
                                        <div id="reminderBox" className={`row ${styles.reminderRow}`}>
                                            <label htmlFor="reminderBox">Reminder</label>
                                            <div className="col s6">{props.reminderRecipientFirstName}</div>
                                            <div className="col s2">{props.reminderDate}</div>
                                            <div className="col s2">{props.reminderTime}</div>
                                            <div className={`col s2 ${styles.reminderButtons}`}>
                                                <i className="material-icons" onClick={props.openReminderSection}>edit</i>
                                                <i className="material-icons" onClick={props.deleteReminder}>delete</i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            :
                            <div>
                                <div className="row">
                                    <div id="statusDisplay" className={`col s2 ${styles[props.status]} ${styles.statusDisplay}`} onContextMenu={(e) => props.handleStatusMenu(e, props.id)}>
                                        <label htmlFor="statusDisplay" className={styles.statusDisplayLabel}>Status</label>
                                        {props.status}
                                    </div>
                                    <Form.Select col="col s4" label="Status Memo" name="statusMemo" value={props.statusMemo} onChange={props.statusMemoOnChange} placeholder="(NONE)" list={props.statusMemoList} filter={{ label: 'name', value: 'name' }} flags={[flags.ALLOW_EMPTY]} />
                                    <Form.Select col="s6" label="Assigned To" name="assignedTo" value={props.assignedTo} onChange={props.assignedToOnChange} placeholder="NO DEFAULT" list={props.portalUser} filter={{ label: "fullName", value: "id" }}  flags={[flags.ALLOW_EMPTY]}/>
                                </div>
                                <div className={`row ${styles.noMarginBottom} ${styles.bookingNumberAndAssignee}`}>
                                    <form onSubmit={(e) => { props.saveBookingRequestNumber(e) }}>
                                        <Form.TextInput col="col s6" label="Booking Req. #" name="bookingRequestNumber" value={props.bookingRequestNumber} onChange={props.onChange} onBlur={props.changeTabOrder}  flags={[flags.ALLOW_EMPTY]}/>
                                    </form>
                                    <Form.Button col="col s3 offset-s3" label="Reminder" type="outline" color="black" icon="email" onClick={props.openReminderSection} />
                                </div>
                            </div> :
                        <div>
                            <div className="row">
                                <div className={`col s3 ${styles[props.status]}`} onContextMenu={(e) => props.handleStatusMenu(e, props.id)}>
                                    {props.status}
                                </div>
                                <Form.Select col="col s3" label="Status Memo" name="statusMemo" value={props.statusMemo} onChange={props.onChange} placeholder="(NONE)" list={props.statusMemoList} filter={{ label: 'name', value: 'name' }} flags={[flags.ALLOW_EMPTY]} />
                                <Form.Select col="s6" label="Recipient" name="reminderRecipient" value={props.reminderRecipient} onChange={props.onChange} placeholder="Choose recipient..." list={props.portalUser} filter={{ label: "fullName", value: "id" }} />
                            </div>
                            <div className={`row ${styles.noMarginBottom}`}>
                                <form onSubmit={(e) => { props.saveBookingRequestNumber(e) }}>
                                    <Form.TextInput col="col s6" label="Booking Req. #" name="bookingRequestNumber" value={props.bookingRequestNumber} onChange={props.onChange} onBlur={props.changeTabOrder} flags={[flags.ALLOW_EMPTY]} />
                                </form>
                                <Form.DateInput col="col s1" label="Date" name="reminderDateFull" value={props.reminderDateFull} onChange={props.onChange} />
                                <Form.Select col="col s1" label="Time" name="reminderTime" value={props.reminderTime} onChange={props.onChange} list={[{ label: "09:30" }, { label: "12:30" }, { label: "15:00" }]} filter={{ label: "label", value: "label" }} />
                                <Form.TextInput col="col s3" label="Note to Self" name="reminderNote" value={props.reminderNote} onChange={props.onChange} maxLength={"120"} flags={[flags.ALLOW_EMPTY]} />
                                <button className={styles.saveReminderButton} onClick={(e) => props.saveReminder(e)}><i className="material-icons">save</i></button>
                            </div>
                        </div>
                    }
                </div>
            }
        </div>
    </div>
}

export default StatusEditSection
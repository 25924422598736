const navTree = {

    home: {
        title: 'HOME',
        isHome: true,
        path: '/',
        links: [
            { name: 'Home', url: '', isFiltered: false },
            { name: 'Bookings', url: 'booking', isFiltered: false },
            { name: 'Shipments', url: 'shipment', isFiltered: false },
            { name: 'Invoices', url: 'invoice', isFiltered: false },
            { name: 'Parties', url: 'party', isFiltered: false },
            { name: 'Carriers', url: 'carrier', isFiltered: false },
            { name: 'Places', url: 'location', isFiltered: false }
        ]
    },

    booking: {
        title: 'BKG REQUESTS',
        isHome: false,
        path: '/booking/',
        links: []
    },

    alliance: {
        title: 'ALLIANCES',
        isHome: false,
        path: '/carrier/alliance/',
        links: [
            { name: 'Overview', 'url': '', isFiltered: false },
            { name: 'Create', 'url': 'create', isFiltered: false }
        ]
    },

    carrier: {
        title: 'CARRIERS',
        isHome: false,
        path: '/carrier/',
        links: [
            { name: 'Overview', 'url': '', isFiltered: false },
            { name: 'Create', 'url': 'create', isFiltered: false },
            { name: 'Services', url: 'serviceroute', isFiltered: false },
            { name: 'Alliances', url: 'alliance', isFiltered: false },
            { name: 'Vessels', url: 'vessel', isFiltered: false },
        ]
    },

    serviceroute: {
        title: 'SERVICE ROUTES',
        isHome: false,
        path: '/carrier/serviceroute/',
        links: [
            { name: 'Overview', url: '', isFiltered: false },
            { name: 'Create', url: 'create', isFiltered: false }
        ]
    },

    vessel: {
        title: 'VESSELS',
        isHome: false,
        path: '/carrier/vessel/',
        links: [
            { name: 'Overview', url: '', isFiltered: false },
            { name: 'Create', url: 'create', isFiltered: false }
        ]
    },

    contract: {
        title: 'CONTRACTS',
        isHome: false,
        path: '/shippers/contracts/',
        links: [
            { name: 'Overview', url: '' },
            { name: 'APL', url: 'apl' },
            { name: 'CMA', url: 'cma' },
            { name: 'HYD', url: 'hyd' }
        ]
    },

    party: {
        title: 'SHIPPERS',
        isHome: false,
        path: '/party/',
        links: [
            { name: 'Overview', url: '', isFiltered: false },
            { name: 'Create', url: 'create', isFiltered: false },
            { name: 'People', url: 'user', isFiltered: true }
        ]
    },

    user: {
        title: 'PEOPLE',
        isHome: false,
        path: '/party/user',
        links: [
            { name: 'Overview', url: '', isFiltered: true },
            { name: 'Create', url: '/create', isFiltered: true }
        ]
    },

    shipment: {
        title: 'SHIPMENTS',
        isHome: false,
        path: '/shipment',
        links: [
            { name: 'Overview', url: '', isFiltered: false },
            { name: 'Create', url: '/create', isFiltered: true },
            { name: 'Instructions', url: '/instructions', isFiltered: true }
        ]
    },

    invoice: {
        title: 'INVOICES',
        isHome: false,
        path: '/invoice',
        links: [
            { name: 'Overview', url: '', isFiltered: false },
            { name: 'Create', url: '/create', isFiltered: false }
        ]
    }
}

export default navTree;
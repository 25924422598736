import React from "react";
import { BrowserRouter as Router, Route, Switch, withRouter } from 'react-router-dom';
import { init } from "./Redux/actions";
import { connect } from 'react-redux';
import NavBar from './Components/NavBar/NavBar';
import Tabs from './Components/Tabs/Tabs';
import SideBar from './Components/SideBar/SideBar';
import Content from './Components/Content/Content';
import CustomerContent from './Components/Content/CustomerContent';
import Login from './Pages/Login/Login'
import GlobalToast from './Components/GlobalToast/GlobalToast';
import PasswordRecovery from './Pages/Login/PasswordRecovery/PasswordRecovery';
import './App.css';
import axios from "axios";
import { auth } from "./helperFunctions";
import SideBarToggle from "./Components/SideBar/SidebarToggle";

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isReady: false, checkingInit: false }
  }

  initState = () => ({
    isReady: false,
    checkingInit: false,
    hideSidebar: false
  })

  componentDidUpdate = (prevProps) => {
    if (prevProps !== this.props && !this.state.isReady) {
      if (this.props.user !== undefined) {
        if (this.props.user.isEmployee || this.props.user.is_employee) {
          if (
            !this.props.logout &&
            this.props.party &&
            this.props.locations &&
            this.props.carrier &&
            this.props.containerType &&
            this.props.serviceRoute &&
            this.props.country &&
            this.props.user &&
            this.props.navs[this.props.index] &&
            this.props.urls[this.props.index] &&
            this.props.sidebars[this.props.index]
          ) {
            this.setState({ isReady: true });
          }
        } else if (
          !this.props.logout &&
          this.props.navs[this.props.index] &&
          this.props.urls[this.props.index] &&
          this.props.sidebars[this.props.index]) {
          this.setState({ isReady: true });
        }
      }
    }
    else if (this.props.url) {
      if (this.props.url.url === "/" && prevProps.url.url !== "/") {
        this.props.history.push("/login")
      }
    }

  }

  checkForInitialization = () => {
    return new Promise((resolve, reject) => {
      this.setState({ checkingInit: true })
      axios.post("/api/v1/login/token", auth.getAuthData()).then(async result => {
        if (result.data.id) {
          this.setState({ checkingInit: false })
          resolve(await this.getInitData(result.data));
        } else {
          this.setState({ checkingInit: false })
          resolve(
            <Switch>
              <Route exact path="/login" component={Login} />
              <Route exact path="/passwordrecovery" component={PasswordRecovery} />
              <Route component={Login} />
            </Switch>
          )
        }
      });
    });
  }

  getInitData = (user) => {
    return new Promise((resolve, reject) => {
      if (user.is_employee || user.isEmployee) {
        setTimeout(() => {
          axios.get('/api/v1/redux', { params: auth.getAuthData() }).then(result => {
            if (result.data && result.data !== "NOT AUTHENTICATED") {
              result.data.user = user;
              resolve(Object.keys(result.data).forEach(key => this.props.dispatch({ type: init.INITIALIZE_STORE, payload: { listName: [key], list: result.data[key] } })));
            }
          })
        }, auth.getAuthData()._user ? 100 : 600)
      } else {
        setTimeout(() => {
          axios.get('/api/v1/redux/customer', { params: auth.getAuthData() }).then(result => {
            if (result.data && result.data !== "NOT AUTHENTICATED") {
              result.data.user = user;
              resolve(Object.keys(result.data).forEach(key => this.props.dispatch({ type: init.INITIALIZE_STORE, payload: { listName: [key], list: result.data[key] } })));
            }
          })
        }, auth.getAuthData()._user ? 100 : 600)
      }
    })
  }

  shouldComponentUpdate = () => {
    if (this.state.checkingInit) {
      return false;
    } else {
      return true
    }
  }


  loginConfirmSwitch = (loggedIn) => {
    switch (loggedIn) {
      case undefined:
        return (
          <Switch>
            <Route exact path="/login" component={Login} />
            <Route exact path="/passwordrecovery" component={PasswordRecovery} />
            <Route component={Login}
            />
          </Switch>
        )
      default:
        if (loggedIn.id) {
          return this.employeeSwitch(this.props.user.isEmployee);
        } else {
          return this.checkForInitialization();
        }
    }
  }

  employeeSwitch = (isEmployee) => {
    switch (isEmployee) {
      case 1:
        return (
          <Router>
            <div id="app">
              <NavBar />
              <Tabs />
              <div className="body">
                <SideBar hideSidebar={this.state.hideSidebar} />
                <SideBarToggle
                  hideSidebar={this.state.hideSidebar}
                  toggleSidebar={this.toggleSidebar}
                />
                <Content />
              </div>
              <GlobalToast />
            </div>
          </Router>
        )

      default:
        return (
          <Router>
            <div id="app">
              <NavBar />
              <Tabs />
              <div className="body">
                <SideBar hideSidebar={this.state.hideSidebar} />
                <SideBarToggle
                  hideSidebar={this.state.isHidden}
                  toggleSidebar={this.state.toggleSidebar}
                />
                <CustomerContent />
              </div>
              <GlobalToast />
            </div>
          </Router>
        )

    }
  }

  toggleSidebar = () => this.setState(p => ({ hideSidebar: !p.hideSidebar }))

  render() {
    return this.loginConfirmSwitch(this.props.user);
  }
}

const mapStateToProps = state => {
  const { party, location, carrier, containerType, serviceRoute, country, user, navs, index, urls, sidebars, logout } = state;
  return { url: urls[index], party, locations: location, carrier, containerType, serviceRoute, country, user, navs, index, urls, sidebars, logout };
}

export default connect(mapStateToProps)(withRouter(App));
import React from 'react';
import styles from './section.module.css';

const Section = props => (
    <div className={`${props.className ? props.className : ''} ${props.noShadow ? '' : 'z-depth-1'} ${props.col ? `col ${props.col}` : ''} ${styles.section}`}>
        <h5 className={styles.label}>{props.label}</h5>
        <div style={props.style}>
            {props.children}
        </div>

    </div>
)

export default Section;
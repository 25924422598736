import React from 'react';
import Password from '../../Components/NewComponents/Password';
import Button from '../../Components/NewComponents/Button';
import Axios from 'axios';
import { connect } from 'react-redux';
import { flags } from '../../constants';
import { auth } from "../../helperFunctions";

class ChangePassword extends React.Component {
    constructor(props) { super(props); this.state = this.initState(); }

    initState = () => ({
        oldPassword: '',
        newPassword: '',
        confirmNewPassword: ''
    })

    onChange = (name, value) => this.setState({ [name]: value })

    submit = () => {
        if (this.verifyNewPassword()) {
            this.setState({ msg: '' })

            Axios.post('/api/v1/portaluser/changepassword', {
                oldPassword: this.state.oldPassword,
                newPassword: this.state.newPassword,
                ...auth.getAuthData()
            })
                .then(result => {

                    if (result.data === 'NOT AUTHENTICATED')
                        return this.props.dispatch({ type: 'SCORCHED_EARTH' })

                    if (result.data === 'Password is incorrect')
                        return this.setState({ msg: 'Current password is incorrect. Password not changed' })

                    if (result.data.errno)
                        return this.setState({ msg: 'An unknown error occurred. Password not changed' })

                    this.setState({
                        msg: 'Password changed',
                        oldPassword: '',
                        newPassword: '',
                        confirmNewPassword: ''
                    })

                });
        }
    }

    verifyNewPassword = () => {
        const { newPassword, confirmNewPassword } = this.state;

        if (newPassword.length < 8)
            return this.setState({ msg: 'Password must be a minimum of 8 characters' });

        if (!this.checkPasswordForDigits())
            return this.setState({ msg: 'Password must have at least one digit' });

        if (!this.checkPasswordForSymbol())
            return this.setState({ msg: 'Password must have one special character. ie: !,@,#,$' });

        if (newPassword !== confirmNewPassword)
            return this.setState({ msg: 'Passwords do not match' });

        return true;
    }

    checkPasswordForDigits = () => {
        return /\d/.test(this.state.newPassword);
    }

    checkPasswordForSymbol = () => {
        return /[\s~`!@#$%^&*+=\-[\]\\';,/{}|\\":<>?()._]/g.test(this.state.newPassword);
    }

    enterListener = (e) => {
        if(e.keyCode === 13 && document.activeElement.id === "confirmNewPassword"){
            this.submit();
        }
    }

    componentDidMount = () => {
        document.addEventListener('keyup', this.enterListener);
    }

    componentWillUnmount = () => {
        document.removeEventListener('keyup', this.enterListener);
    }

    render = () => (
        <div>
            <div className="row">
                <Password noAutoComplete col="s6" label="Current Password" name="oldPassword" value={this.state.password} onChange={this.onChange} flags={[flags.ALLOW_EMPTY]} />
            </div>
            <div className="row">
                <Password noAutoComplete col="s6" label="New Password" name="newPassword" value={this.state.newPassword} onChange={this.onChange} flags={[flags.ALLOW_EMPTY]} />
                <Password noAutoComplete col="s6" label="Confirm New Password" name="confirmNewPassword" value={this.state.confirmNewPassword} onChange={this.onChange} flags={[flags.ALLOW_EMPTY]} />
            </div>
            <div className="row">
                <Button col="s3" type="outline" label="Change Password" color="green" onClick={this.submit} />
                <p className="col s9" style={{color:"green"}}>{this.state.msg}</p>
            </div>
        </div>
    )
}

export default connect()(ChangePassword);
import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import axios from 'axios';
import { auth } from '../../helperFunctions';
import Form from '../../Components/NewComponents';
import moment from 'moment';
import { globalToastActions } from '../../Redux/actions';
import styles from "./Reports.module.css";

const ActiveBookingsReport = (props) => {

    const [filters, setValues] = useState({
        user: 0,
        startDate: moment(new Date()).subtract(30, "day").format("MM/DD/YY"),
        endDate: moment(new Date()).add(30, "day").format("MM/DD/YY"),
        dummyStatus: "ALL",
        currentSort: 'total',
        reverse: -1
    });
    const [reportItems, setReportItems] = useState([]);
    const loading = useRef(false);
    const dummyOptions = [{ label: "ALL" }, { label: "DUMMIES ONLY" }, { label: "NO DUMMIES" }];

    const filterOnChange = (name, value) => {
        setValues({ ...filters, [name]: value })
    }

    useEffect(() => {
        document.querySelectorAll(".search-field").forEach(el => { el.addEventListener("keydown", searchListener) })
        return () => {
            document.querySelectorAll(".search-field").forEach(el => { el.removeEventListener("keydown", searchListener) })
        }
    }, []);

    useEffect(() => {
        getActiveBookings(filters);
    }, [filters.user, filters.dummyStatus, filters.currentSort, filters.reverse])

    const searchListener = (e) => {
        if (e.key === "Enter" && !loading.current) {
            e.preventDefault();
            document.getElementById('searchButton').click();
        }
    }

    const getActiveBookings = async (filters) => {
        loading.current = true;
        axios.get("/api/v1/report/activebookings", { params: { ...auth.getAuthData(), filters } }).then(result => {
            if (result === "NOT AUTHENTICATED") {
                localStorage.clear();
                return this.props.dispach({ type: "SCORCHED_EARTH" });
            } else if (result.data.errno) {
                loading.current = false;
                setReportItems([]);
                return props.dispatch({ type: globalToastActions.UPDATE_MSG, payload: { msg: `Error: ${result.data.code}`, type: "error" } })
            } else {
                if (!result.data.length) {
                    loading.current = false;
                    setReportItems([]);
                    return props.dispatch({ type: globalToastActions.UPDATE_MSG, payload: { msg: "No Results" } });
                }
                loading.current = false;
                setReportItems(result.data)
            }
        })
    }

    const sort = (sortName) => {
        if (!sortName) {
            return;
        } else {
            const reverse = filters.currentSort === sortName ? (-1 * filters.reverse) : 1;
            setValues({ ...filters, currentSort: sortName, reverse })
        }
    }

    return (
        <div>
            <div className="row" style={{ marginTop: "15px" }}>
                <Form.Select col="col s4" label="User" placeholder="ALL" name="user" value={filters.user} onChange={filterOnChange} list={props.portalUser} filter={{ label: "fullName", value: "id" }} />
                <Form.DateInput col="col s2 search-field" stylingClass="search-field" label="Cargo Cut Start" id="startDate" name="startDate" value={filters.startDate} onChange={filterOnChange} />
                <Form.DateInput col="col s2 search-field" label="Cargo Cut End" name="endDate" value={filters.endDate} onChange={filterOnChange} />
                <Form.Select col="col s2" label="Dummy Status" name="dummyStatus" value={filters.dummyStatus} onChange={filterOnChange} list={dummyOptions} filter={{ label: "label", value: "label" }} />
                <Form.Button id="searchButton" col="col s2" type="outline" color="green" label="Search" onClick={() => getActiveBookings(filters)} />
            </div>
            <div className="row">
                <table className={styles.reportTable}>
                    <thead>
                        <tr>
                            <th onClick={() => sort('user')}>User&nbsp;{filters.currentSort === "user" ? filters.reverse === 1 ? `⮝` : `⮟` : ""}</th>
                            <th onClick={() => sort('dummy')}># of Dummy Bookings&nbsp;{filters.currentSort === "dummy" ? filters.reverse === 1 ? `⮝` : `⮟` : ""}</th>
                            <th onClick={() => sort('nonDummy')}># of Non-Dummy Bookings&nbsp;{filters.currentSort === "nonDummy" ? filters.reverse === 1 ? `⮝` : `⮟` : ""}</th>
                            <th onClick={() => sort('total')}>Total # of Bookings&nbsp;{filters.currentSort === "total" ? filters.reverse === 1 ? `⮝` : `⮟` : ""}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {loading.current ? <tr>
                            <td>
                                <div className="preloader-wrapper big active overviewLoader">
                                    <div className="spinner-layer spinner-blue-only">
                                        <div className="circle-clipper left">
                                            <div className="circle"></div>
                                        </div><div className="gap-patch">
                                            <div className="circle"></div>
                                        </div><div className="circle-clipper right">
                                            <div className="circle"></div>
                                        </div>
                                    </div>
                                </div>
                            </td>
                        </tr>
                            :
                            reportItems.map((item, index) => (
                                <tr key={index} >
                                    <td>{item.bookingUser}</td>
                                    <td>{item.dummyBookingCount}</td>
                                    <td>{item.nonDummyBookingCount}</td>
                                    <td>{item.bookingCount}</td>
                                </tr>
                            ))
                        }
                    </tbody>
                </table>
            </div>
        </div>
    );
}

const mapStateToProps = state => {
    const { urls, index, user, portalUser } = state;
    const url = urls[index];
    return { url, user, portalUser }
}

export default connect(mapStateToProps)(withRouter(ActiveBookingsReport));
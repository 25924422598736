import React from "react";
import axios from "axios";
import { auth } from "../../helperFunctions";
import { connect } from "react-redux";
import Form from "../../Components/NewComponents";
import { init, urlActions } from "../../Redux/actions";
import { Link } from 'react-router-dom';
import M from "materialize-css";

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.initState();
  }

  initState = () => ({
    email: process.env.REACT_APP_EMAIL || "",
    password: process.env.REACT_APP_PASSWORD || ""
  });

  onChange = (name, value) => this.setState({ [name]: value, msg: '' });
  clear = () => this.setState(this.initState());

  login = () => {
    this.showSpinner();
    if (!this.state.email || !this.state.password) {
      this.setState({ msg: 'Password and Email are required' })
      this.hideSpinner();
      return;
    }

    axios.post('/api/v1/login', this.state).then(result => {
      const _token = result.data.token;
      const user = result.data.user;
      const _session_id = result.data.session_id;

      if (_token && user && _session_id) {
        this.getInitData(user);
        localStorage.setItem('_token', _token);
        localStorage.setItem('_user', user.id);
        localStorage.setItem('_session_id', _session_id);
      }
      else {
        this.hideSpinner();
        this.setState({ msg: 'An error ocurred' })
      }
    })
  }

  getInitData = (user) => {
    if (user.is_employee || user.isEmployee) {
      setTimeout(() => {
        axios.get('/api/v1/redux', { params: auth.getAuthData() }).then(result => {
          if (result.data) {
            result.data.user = user;
            Object.keys(result.data).forEach(key => this.props.dispatch({ type: init.INITIALIZE_STORE, payload: { listName: [key], list: result.data[key] } }));
          }
        })
      }, auth.getAuthData()._user ? 100 : 600)
    } else {
      setTimeout(() => {
        axios.get('/api/v1/redux/customer', { params: auth.getAuthData() }).then(result => {
          if (result.data) {
            result.data.user = user;
            Object.keys(result.data).forEach(key => this.props.dispatch({ type: init.INITIALIZE_STORE, payload: { listName: [key], list: result.data[key] } }));
          }
        })
      }, auth.getAuthData()._user ? 100 : 600)
    }
  }

  initListeners = () => {
    document.querySelector('#password').addEventListener('keyup', this.submit);
  }

  initModal = () => {
    M.Modal.init(document.querySelectorAll('.modal'))
  }

  removeListeners = () => {
    document.querySelector('#password').removeEventListener('keyup', this.submit);
  }

  submit = e => {
    const password = document.querySelector('#password');
    if (document.activeElement === password && e.keyCode === 13) {
      document.querySelector('#log-in').focus();
      this.showSpinner();
      this.login();
    }
  }

  componentDidMount = () => {
    
    document.getElementById("email").focus();

    const { _token, _user, _session_id } = auth.getAuthData();

    if (_token && _user && _session_id)
      axios.post("/api/v1/login/token", auth.getAuthData()).then(result => {
        if (result.data.id) this.getInitData(result.data);
      });

    document.getElementById("email").focus();

    this.initListeners();
    this.initModal();
  }

  componentWillUnmount = () => {
    this.removeListeners();
  }

  hideSpinner = () => {
    M.Modal.getInstance(document.querySelector("#modal")).close()
  }

  showSpinner = () => {
    M.Modal.getInstance(document.querySelector("#modal")).options.dismissible = false;
    M.Modal.getInstance(document.querySelector("#modal")).options.startingTop = '40%';
    M.Modal.getInstance(document.querySelector("#modal")).options.endingTop = '40%'
    M.Modal.getInstance(document.querySelector("#modal")).open()
  }

  goToPasswordRecovery = e => {
    this.props.dispatch({ type: urlActions.UPDATE_URL, payload: '/passwordrecovery' })
  }

  render = () => (
    <div>
      <img src="/images/lf_logo.bmp" style={{ height: '160px', width: 'auto' }} alt="Lindsey Forwarders Logo" />
      <div className="row">
        <h5 className="orange-text center">{this.props.logout && 'You have been logged out'}</h5>
      </div>
      <div className="row" style={{ marginTop: '10vh' }}>
        <div className="card col s8 offset-s2 l4 offset-l4">
          <div className="card-content">
            {/* <h4 className="card-title col s5 offset-s4" style={{ marginBottom: '32px', wordSpacing: "3px", letterSpacing: "5px" }}></h4> */}
            <div className="row" style={{marginTop: "125px"}}>
              <Form.TextInput col="s6 offset-s3" name="email" label="Email" value={this.state.email} onChange={this.onChange} />
            </div>
            <div className="row">
              <Form.Password col="s6 offset-s3" name="password" label="Password" value={this.state.password} onChange={this.onChange} />
            </div>
          </div>
          <div className="row">
            <p className="col s12 center red-text">{this.state.msg}</p>
          </div>
          <div className="row" style={{display: "flex", justifyContent: "center", marginBottom: "26px"}}>
            <div className="col s4"></div>
            <Form.Button id="log-in" col="s2" color="blue" type="outline" label="Log in" onClick={this.login} />
            <Form.Button col="s2" color="red" type="outline" label="Clear" onClick={this.clear} />
            <div className="col s4"></div>
          </div>
        </div>
      </div>
      <Link to="/passwordrecovery" style={{ display: 'flex', justifyContent: 'center' }} onClick={this.goToPasswordRecovery}>Forgot Password?</Link>
      <div id="modal" className="modal" style={{ width: "250px", height: "250px", textAlign: "center", borderRadius: "10px" }}>
        <div className="modal-content" >
          <div className="preloader-wrapper active" style={{ marginBottom: "25px", marginTop: "50px" }}>
            <div className="spinner-layer spinner-blue-only">
              <div className="circle-clipper left">
                <div className="circle">
                </div>
              </div>
              <div className="gap-patch">
                <div className="circle">
                </div>
              </div>
              <div className="circle-clipper right">
                <div className="circle">
                </div>
              </div>
            </div>
          </div>
          <br></br>
          <h5>Loading . . . </h5>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = state => {
  const { logout, index, urls } = state;
  return { logout, url: urls[index] }
}

export default connect(mapStateToProps)(Login);
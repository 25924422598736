import React from 'react';
import { connect } from 'react-redux';
import { flags } from '../../constants';
import Form from '../../Components/NewComponents';
import axios from 'axios'
import moment from 'moment';
import BookingRequestOverview from './BookingRequestOverview';
import { auth } from "../../helperFunctions";
class RequestBooking extends React.Component {
    constructor(props) { super(props); this.state = this.initState(); }

    initState = () => ({
        carrierId: 1,
        originId: 1,
        destId: 1,
        containerCount: '',
        containerTypeId: 1,
        shipperReferenceNumber: '',
        consigneeName: '',
        startDate: '',
        endDate: '',
        dateType: 'CUTOFF',
        product: '',
        notes: '',
        numberOfDuplicates: 0,
        saveTemplate: false,
        shipperId: this.props.shipperId,
        shipper: null,
        bookings: [],
        msg: { color: '', text: '' }
    })


    UNSAFE_componentWillReceiveProps = props => this.setState({ shipper: props.party.filter(p => p.id === props.shipperId)[0] })
    onChange = (name, value) => this.setState({ [name]: value })
    getOrigins = () => {
        if (this.state.shipper)
            return this.props.locations.filter(loc => ( (loc.countryId === 1 || loc.countryId === 17) && loc.typeId !== 3));
    }

    getDestinations = () => {
        if (this.state.shipper)
            return this.props.locations.filter(loc => ((loc.countryId === 1 || loc.countryId === 17)  && loc.typeId !== 3))
    }

    getDateTypes = () => ([
        { type: 'CUTOFF' },
        { type: 'PROMPT' },
        { type: 'ETD' },
        { type: 'ETA' }
    ])

    getBookings = () => axios.get('/api/v1/bookingrequest', { params: { ...auth.getAuthData() }}).then(result => this.setState({ bookings: result.data }))
    refresh = () => { this.getBookings(); }
    componentDidMount = () => {this.refresh();}


    submit = () => {
        const shipDateStart = moment(this.state.startDate, 'MM/DD/YY').format('YYYY-MM-DD HH:mm');
        const shipDateEnd = this.state.startDate ? moment(this.state.startDate, 'MM/DD/YY').format('YYYY-MM-DD HH:mm') : null;

        axios.post('/api/v1/bookingrequest', { ...this.state, shipDateEnd, shipDateStart }).then(result => {
            if (result.data.errno)
                this.setState({ msg: { color: 'red', text: `Error: ${result.data.sqlMessage}` } });
            else
                this.setState({ msg: { color: 'green', text: `Submitted ID: ${result.data.insertId}` } }, this.refresh)
        })
    }




    componentDidMount = () => {
        this.getBookings();
    }

    render = () => (
        <div>
            <div className="row">
                <h5>Booking Request</h5>
            </div>
            <div className="row">
                <Form.Select col="s3" name="carrierId" label="Carrier" required onChange={this.onChange} value={this.state.carrierId} list={this.props.carriers} filter={{ label: 'name', value: 'id' }} />
                <Form.Select col="s3" name="originId" label="Origin" required onChange={this.onChange} value={this.state.originId} list={this.props.origins} filter={{ label: 'code', value: 'id' }} />
                <Form.Select col="s3" name="destId" label="Destination" required onChange={this.onChange} value={this.state.destId} list={this.props.destinations} filter={{ label: 'code', value: 'id' }} />
                <Form.NumberInput col="s1" name="containerCount" label="Count" required onChange={this.onChange} value={this.state.containerCount} />
                <Form.Select col="s2" name="containerType" label="Type" onChange={this.onChange} value={this.state.containerTypeId} list={this.props.containerTypes} filter={{ label: 'code', value: 'id' }} />
            </div>
            <div className="row">
                <Form.TextInput col="s2" name="shipperReferenceNumber" label="Shipper Ref #" onChange={this.onChange} value={this.state.shipperReferenceNumber} flags={[flags.ALLOW_EMPTY]} />
                <Form.TextInput col="s2" name="consigneeName" label="Consignee" onChange={this.onChange} value={this.state.consigneeName} flags={[flags.ALLOW_EMPTY]} />
                <Form.DateRange disabled={this.state.dateType === 'PROMPT'} col="s4" label="Shipment Date" onChange={this.onChange} names={{ start: 'startDate', end: 'endDate' }} />
                <Form.Select col="s4" name="dateType" label="Date Type" onChange={this.onChange} value={this.state.dateType} list={this.getDateTypes()} filter={{ label: 'type', value: 'type' }} />
            </div>
            <div className="row">
                <Form.TextInput col="s4" name="product" label="Product/Packaging" onChange={this.onChange} value={this.state.product} flags={[flags.ALLOW_EMPTY]} />
                <Form.TextInput col="s8" name="notes" label="Notes" onChange={this.onChange} value={this.state.notes} flags={[flags.ALLOW_EMPTY]} />
            </div>
            <div className="row">
                <Form.NumberInput col="s1" name="numberOfDuplicates" label="Duplicate" onChange={this.onChange} value={this.state.numberOfDuplicates} flags={[flags.ALLOW_EMPTY, flags.MAX_VALUE + 9]} />
                <Form.CheckBox col="s3" name="saveTemplate" label="Save as Template" onChange={this.onChange} value={this.state.saveTemplate} />
                <Form.Button color="blue" type="outline" label="Submit Request" onClick={this.submit} />
                <Form.Button color="red" type="outline" label="Clear" onClick={this.clear} />
            </div>
            <div className="row">
                <h6 className={`${this.state.msg.color}-text`}>{this.state.msg.text}</h6>
                <div className="divider" />
            </div>
            <BookingRequestOverview bookings={this.state.bookings} />
        </div>
    )
}

const mapStateToProps = state => {
    const { navs, index, carriers, location, containerTypes, party } = state;
    let origins = location.filter(loc => ((loc.countryId === 1 || loc.countryId === 17)  && loc.typeId !== 3));
    let destinations = location.filter(loc => ((loc.countryId === 1 || loc.countryId === 17)  && loc.typeId !== 3))
    const shipperId = navs[index].shipperId;
    return { shipperId, carriers, location, containerTypes, party, origins, destinations };
}

export default connect(mapStateToProps)(RequestBooking);
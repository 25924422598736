import React from 'react';
import { connect } from 'react-redux';
// import { sidebarActions } from '../../Redux/actions';
import { Switch, Route } from 'react-router-dom';
import Contracts from './Contracts/Contracts';
import PartyOverview from './PartyOverview';
import CreateParty from './CreateParty';
import User from './Users/User';
import UpdateParty from './updateParty'

class Party extends React.Component {

    getPath = () => ('/party');
    // rebuildNav = () => this.props.dispatch({ type: sidebarActions.REBUILD_NAV, payload: { url: this.getPath() } })
    // componentDidMount = () => this.rebuildNav();

    render = () => {
        return (
            <Switch>
                <Route exact path={this.getPath()} component={PartyOverview} />
                <Route path={`${this.getPath()}/create`} component={CreateParty} />
                <Route path={this.getPath() + '/contracts'} component={Contracts} />
                <Route path={this.getPath() + '/user'} component={User} />
                <Route path={this.getPath() + '/update'} component={UpdateParty} />
            </Switch>
        )
    }
}

export default connect()(Party);

import React from 'react';
import { connect } from 'react-redux';
import Form from '../../../../Components/NewComponents';
import M from 'materialize-css';
import Axios from 'axios';
import { globalToastActions } from '../../../../Redux/actions';
import { flags } from '../../../../constants';
import { auth } from "../../../../helperFunctions";

class CILineItemModal extends React.Component {
    constructor(props) { super(props); this.state = this.initState(); }

    initState = () => ({
        lineItems: [],
        description: '',
        amount: ''
    })

    onChange = (name, value) => this.setState({ [name]: value });

    addLine = () => {
        const { lineItems, description, amount } = JSON.parse(JSON.stringify(this.state));
        lineItems.push({ description, amount });
        this.setState({ lineItems, description: '', amount: '' });
    }
    
    initModal = () => M.Modal.init(document.querySelector('#ci-line-item'));

    open = () => M.Modal.getInstance(document.querySelector('#ci-line-item')).open();

    close = () => M.Modal.getInstance(document.querySelector('#ci-line-item')).close();

    componentDidMount = () => { this.initModal(); this.getLineItems(); }
    componentDidUpdate = (prevProps) => {
        if (prevProps !== this.props) {
            this.getLineItems();
        }
    }

    getLineItems = () => {
        Axios.get("/api/v1/instructions/get/cilineitem", { params: { ...auth.getAuthData(), shippingId: this.props.shippingId } })
            .then(result => {
                if (result.data === "NOT AUTHENTICATED") {
                    localStorage.clear(); this.props.dispatch({
                        type: 'SCORCHED_EARTH'
                    })
                    return;
                }
                if (result.data)
                    this.setState({
                        lineItems: result.data
                    })
                else {
                    this.setState({
                        lineItems: []
                    })
                }
            })
    }

    deleteCiLineItem = (index, id) => {
        if (id) {
            Axios.post("/api/v1/instructions/deletecilineitem", { ...auth.getAuthData(), id: id })
                .then(result => {
                    if (result.data === "NOT AUTHENTICATED") {
                        localStorage.clear();
                        this.props.dispatch({
                            type: 'SCORCHED_EARTH'
                        })
                        return;
                    }

                })
        }

        let newItems = this.state.lineItems
        newItems.splice(index, 1);
        this.setState({ lineItems: newItems });

    }

    save = () => Axios.post('/api/v1/instructions/cilineitem', {
        ...auth.getAuthData(),
        lineItems: this.state.lineItems,
        shippingId: this.props.shippingId
    })
        .then(result => {
            if (result.data === 'NOT AUTHENTICATED') {
                localStorage.clear();
                this.props.dispatch({ type: 'SCORCHED_EARTH' })
                return;
            } else if (result.data.error === "No Shipping Id") {
                this.props.dispatch({ type: globalToastActions.UPDATE_MSG, payload: { msg: "Please Save Instructions Before Adding Line Items", type: "error" } });

            } else if (result.data.errno) {
                this.props.dispatch({ type: globalToastActions.UPDATE_MSG, payload: { msg: 'An Error Occurred', type: 'error' } });

            } else if (result.data.error === "No Line Items") {
                this.props.dispatch({ type: globalToastActions.UPDATE_MSG, payload: { msg: "Nothing To Save", type: 'error' }});
            }

            else {
                this.props.dispatch({ type: globalToastActions.UPDATE_MSG, payload: { msg: `Saved. ID: ${result.data.insertId}`, type: 'success' } })
                this.close();
                this.getLineItems();
            }

        });

    render = () => {

        return (
            <div id="ci-line-item" className="modal">
                <div className="modal-content">
                    <table>
                        <thead>
                            <tr>
                                <th>Item #</th>
                                <th>Description</th>
                                <th>Amount</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.lineItems.length ? this.state.lineItems.map((item, index) => (
                                <tr key={index} style={{ textAlign: 'right' }}>
                                    <td>{index + 1}</td>
                                    <td>{item.description}</td>
                                    <td>{item.amount}</td>
                                    <td></td>
                                    <td><i style={{ "marginTop": "5px" }} className="material-icons" onClick={() => this.deleteCiLineItem(index, item.id)}>cancel</i></td>
                                </tr>
                            )) : null}
                        </tbody>
                    </table>
                    <div className="row" style={{ margin: '12px 0px' }}>
                        <Form.TextInput col="s8" name="description" label="Description" value={this.state.description} onChange={this.onChange} flags={[flags.ALLOW_EMPTY]} />
                        <Form.TextInput col="s2" name="amount" label="Amount" value={this.state.amount} onChange={this.onChange} flags={[flags.ALLOW_EMPTY]} />
                        <Form.Button round type="outline" label="Add" icon="add" onClick={this.addLine} />

                    </div>
                    <div className="row">
                        <Form.Button type="outline" color="green" icon="save" label="Save" onClick={this.save} />
                        <Form.Button type="outline" color="red" icon="cancel" label="Close" onClick={this.close} />
                    </div>
                </div>
            </div >
        )
    }
}

const mapStateToProps = state => {
    return {}
}

export default connect(mapStateToProps)(CILineItemModal);
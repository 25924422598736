import React from "react"
import { Link } from 'react-router-dom';
import "./sidebar.css";

const SideBarTab = props => {
    return (
        <div>
            <li
                onClick={() => {
                  props.updateUrl(props.url);
                }}
                style={{ cursor: "pointer", borderBottom: '1px solid lightgrey' }}
                className={`collection-item thing sideLink ${props.activeLink === props.url ? "active-link" : "unactive-link"}`}>
                <Link to={props.url}>
                  {props.linkName}
                </Link>
              </li>
        </div>
    )
}

export default SideBarTab;
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Form from "../../Components/NewComponents";
import axios from "axios";
import { auth } from "../../helperFunctions";

class ContainerByWeekReportOld extends Component {
    state = {
        shipperId: "0",
        carrierId: "0",
        startDate: "",
        endDate: "",
        placeOfReceiptId: '',
        portOfDeliveryId: '',
        destCountry: ""
    }

    onChange = (name, value) => this.setState({ [name]: value });

    generateReport = async () => {
        axios.post(`/api/v1/pages/salesrecap`, {
            ...auth.getAuthData(),
            filters: {
                ...this.state
            }
        }).then(result => {
            if (result.data === 'NOT AUTHENTICATED') {
                localStorage.clear();
                this.props.dispatch({ type: 'SCORCHED_EARTH' })
                return;
            }

            const win = window.open('', '', 'width=960 height=1080');
            win.document.write(result.data);
        })
    }

    getLocationOptions = () => {
        let arr = [];
        arr = this.props.location.filter(l => (l.typeId === 1 || l.typeId === 2));
        arr.unshift({ code: 'ALL', id: 9999 });
        return arr;
    }
    render = () => {
        return (
            <div>
                <h4>Sales Recap</h4>
                <div>&nbsp;</div>
                <div className="row">
                    <Form.Select col="col s2" label="Shipper" onChange={this.onChange} placeholder="Select a Shipper" name="shipperId" value={this.state.shipperId} list={this.props.shipper} filter={{ label: "name", value: "id" }} />
                    <Form.Select col="col s2" label="Carrier" onChange={this.onChange} placeholder="Select a Carrier" name="carrierId" value={this.state.carrierId} list={this.props.carrier} filter={{ label: "name", value: "id" }} />
                    <Form.DateInput col="col s2" label="LOB Start" onChange={this.onChange} name="startDate" value={this.state.startDate} />
                    <Form.DateInput col="col s2" label="LOB End" onChange={this.onChange} name="endDate" value={this.state.endDate} />
                    <Form.Select col ="col s1" label="PLR" onChange={this.onChange} name="placeOfReceiptId" value={this.state.placeOfReceiptId} list={this.getLocationOptions()} filter={{ label: "code", value: "id" }} />
                    <Form.Select col="col s1" label="PLD" onChange={this.onChange} name="portOfDeliveryId" value={this.state.portOfDeliveryId} list={this.getLocationOptions()} filter={{ label: "code", value: "id"}} />
                    <Form.Select col="col s2" label="Dest Country" onChange={this.onChange} name="destCountry" value={this.state.destCountry} list={this.props.country} placeholder="ALL" filter={{ label: "name", value: "id" }}/>
                </div>
                <div className="row">
                    <Form.Button col="col s2" onClick={this.generateReport} label="Generate Report" type="outline" color="blue" />
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    const { urls, index, party, shipper, carrier, location, country } = state;
    const url = urls[index];
    return { shipper, party, url, carrier, location, country }
}

export default connect(mapStateToProps)(ContainerByWeekReportOld);
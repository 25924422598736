import React from 'react';
import Form from '../../../Components/NewComponents';


class CreateServiceRoute extends React.Component {
    constructor(props) { super(props); this.state = this.initState() }
    initState = () => ({ name: '' });

    render = () => (
        <div>
            <div className="row">
                <h5>Service Route</h5>
            </div>
            <div className="row">
                <Form.TextInput col="s6 l4" name="name" label="Name" onChange={this.onChange} value={this.state.name} />
            </div>
            <div className="row">
                <Form.Button color="blue" round type="outline" onClick={() => alert('save')} label="Save" icon="save" />
                <Form.Button color="red" round type="outline" onClick={() => alert('clear')} label="Clear" icon="cancel" />
            </div>
        </div>
    )
}

export default CreateServiceRoute;
import React from "react";
import styles from "./button.module.css";

const Button = props => (
  <div className={`col ${props.col} ${styles.div} ${styles.toolTip}`}>
    {props.tooltip ?
      <span className={styles.toolTipText}>{props.tooltip}</span>
      :
      null}
    <button
      className={`
      ${props.type ? styles[props.type] : styles.filled} 
      ${props.color ? styles[props.color] : styles.blue} 
      ${styles.button}
      ${props.address ? styles[props.address] : ""}
      ${props.styling ? styles[props.styling] : ""}
      ${props.disabled ? styles.disabled : ""}
      ${props.buttonClass ? props.buttonClass : ""}
      ${props.round ? styles.round : ""} waves-effect waves-dark`}
      style={props.inlineStyle}
      onClick={props.onClick}
      disabled={props.disabled}
      id={props.id}
      value={props.value}
      tabIndex={props.tabIndex ? props.tabIndex : 0}
    >
      {props.label}
      <i className={`${props.iconSize} material-icons ${styles.icon} ${props.iconClass}`}>{props.icon}</i>
    </button>
  </div>
);

export default Button;

import React from 'react';
import { connect } from 'react-redux';
import { sidebarActions } from '../../../Redux/actions';
import { Switch, Route } from 'react-router-dom';

class Contracts extends React.Component {

    getPath = () => ('/shippers/contracts');
    rebuildNav = () => this.props.dispatch({ type: sidebarActions.REBUILD_NAV, payload: { url: this.getPath() } })
    componentDidMount = () => this.rebuildNav();

    render = () => (
        <Switch>
            <Route exact path={this.getPath()} component={() => <h1>Contracts Overview</h1>} />
        </Switch>
    )
}

export default connect()(Contracts);
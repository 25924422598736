import React, { Component } from 'react';
import { connect } from "react-redux";
import { flags } from "../../constants";
import Form from "../../Components/NewComponents";
import axios from "axios";
import { globalToastActions } from '../../Redux/actions';
import styles from "./ServiceRoute.module.css";
import { auth } from "../../helperFunctions";
import ServiceResultsDisplay from "./ServiceRouteResultsDisplay";
import LoadingSpinner from "../../Components/LoadingSpinner/LoadingSpinner";
import moment from 'moment-timezone';

class ServiceRouteOverview extends Component {
    state = {
        services: [],
        serviceRouteLocations: [],
        serviceRouteParties: [],
        carrierProvidedServices: [
            {
                carrierEtds: []
            }
        ],
        etdPorts: [],
        loading: false,
        filters: {
            carrierHtml: 15,
            locationChoice: 3,
            serviceRoute: "PN2",
            carrierName: "ONE",
            etdStart: moment(new Date()).format("MM/DD/YY"),
            etdEnd: moment(new Date()).add(90, "days").format("MM/DD/YY"),
            cargoCutStart: "",
            cargoCutEnd: "",
            locationOptions: [
                { id: 1, label: "PORTLAND", code: "USPDX" },
                { id: 2, label: "SEATTLE", code: "USSEA" },
                { id: 3, label: "TACOMA", code: "USTIW" },
                { id: 4, label: "LONG BEACH", code: "USLGB" },
                { id: 5, label: "LOS ANGELES", code: "USLAX" },
                { id: 6, label: "OAKLAND", code: "USOAK" }
            ]
        },
        serviceChoice: 0,
        carrierHtmlOptions: [
            { name: "YANG MING", id: 15 },
            { name: "EVERGREEN", id: 5 }
        ],
        serviceOptions: {
            15: [
                { label: "CEN", id: 1 },
                { label: "FP1", id: 2 },
                { label: "FP2", id: 3 },
                { label: "PN1", id: 4 },
                { label: "PN2", id: 5 },
                { label: "PN3", id: 6 },
                { label: "PN4", id: 7 },
                { label: "PS3", id: 8 },
                { label: "PS4", id: 9 },
                { label: "PS5", id: 10 },
                { label: "PS6", id: 11 },
                { label: "PS8", id: 12 }
            ],
            5: [
                { label: "TPA", id: 1 },
                { label: "CEN", id: 2 },
                { label: "PNW1", id: 3 },
                { label: "TPN", id: 4 }
            ]
        },
        locationOptions: [
            { id: 1, label: "PORTLAND", code: "USPDX" },
            { id: 2, label: "SEATTLE", code: "USSEA" },
            { id: 3, label: "TACOMA", code: "USTIW" },
            { id: 4, label: "LONG BEACH", code: "USLGB" },
            { id: 5, label: "LOS ANGELES", code: "USLAX" },
            { id: 6, label: "OAKLAND", code: "USOAK" }
        ]

    }

    initListeners = () => {
        document.querySelectorAll('.search-field').forEach(el => el.addEventListener('keydown', this.searchListener));
    }

    removeListeners = () => {
        document.querySelectorAll('.search-field').forEach(el => el.removeEventListener('keydown', this.searchListener));
    }

    searchListener = (e) => {
        if (e.key === "Enter") {
            e.preventDefault();
            this.getServices();
        }
    }

    onChange = (name, value) => {
        this.setState({ [name]: value });
    };

    filterOnChange = (name, value) => {
        this.setState({ filters: { ...this.state.filters, [name]: value } });
    }

    carrierHtmlOnChange = (name, value) => {
        this.setState({ filters: { ...this.state.filters, [name]: value, serviceRoute: this.state.serviceOptions[parseInt(value, 10)][0].label } },
            () => {
                this.getServices();
            });
    }

    filterOnChangePlus = (name, value) => {
        this.setState({ filters: { ...this.state.filters, [name]: value } }, () => {
            this.getServices();
        })
    }

    serviceOptionChange = (name, value) => {
        this.setState({ [name]: value }, () => {
            this.getServices();
        });
    }

    getServices = () => {
        if (!this.state.filters.serviceRoute && !this.state.filters.carrierName) {
            return this.props.dispatch({ type: globalToastActions.UPDATE_MSG, payload: { msg: "Please Select Service And Carrier", type: "warning" } });
        } else {

            this.setState({ loading: true }, () => {

                switch (parseInt(this.state.filters.carrierHtml, 10)) {
                    case 15:
                        this.handleYangMingRequest();
                        break;
                    case 5:
                        this.handleEvergreenRequest();
                        break;
                    default:
                        break;
                }

            })
        }
    }

    handleYangMingRequest = () => {
        axios.get("/api/v1/serviceroute/schedule", { params: { ...auth.getAuthData(), filters: this.state.filters, serviceChoice: this.state.serviceChoice } }).then((result) => {
            console.log(result.data);

            if (result.data === "NOT AUTHENTICATED") {
                localStorage.clear();
                return this.props.dispatch({ type: "SCORCHED_EARTH" });
            } else if (result.data === "No Results") {
                this.setState({ loading: false, carrierProvidedServices: [] });
                return this.props.dispatch({ type: globalToastActions.UPDATE_MSG, payload: { type: "warning", msg: "No Results" } });
            } else {
                let { services, rawServiceData, html, } = result.data
                let serviceRouteParties = rawServiceData.length && rawServiceData[0].shippers ? rawServiceData[0].shippers.split("@") : [];
                let serviceRouteLocations = rawServiceData.length && rawServiceData[0].locations ? rawServiceData[0].locations.split("@") : [];

                return this.setState({
                    serviceRouteParties,
                    serviceRouteLocations,
                    carrierProvidedServices: services,
                    etdPorts: [this.state.filters.locationOptions.filter(l => l.id === parseInt(this.state.filters.locationChoice, 10))[0].label],
                    html,
                    loading: false
                })

            }

        })
    }

    handleEvergreenRequest = () => {
        axios.get("/api/v1/serviceroute/schedule", { params: { ...auth.getAuthData(), filters: this.state.filters, serviceChoice: this.state.serviceChoice } }).then((result) => {

            if (result.data === "NOT AUTHENTICATED") {
                localStorage.clear();
                return this.props.dispatch({ type: "SCORCHED_EARTH" });
            } else if (result.data === "No Results") {
                this.setState({ loading: false, carrierProvidedServices: [] });
                return this.props.dispatch({ type: globalToastActions.UPDATE_MSG, payload: { type: "warning", msg: "No Results" } });
            } else {
                let { services, rawServiceData, html, } = result.data
                let serviceRouteParties = rawServiceData.length && rawServiceData[0].shippers ? rawServiceData[0].shippers.split("@") : [];
                let serviceRouteLocations = rawServiceData.length && rawServiceData[0].locations ? rawServiceData[0].locations.split("@") : [];

                return this.setState({
                    serviceRouteParties,
                    serviceRouteLocations,
                    carrierProvidedServices: services,
                    etdPorts: [this.state.filters.locationOptions.filter(l => l.id === parseInt(this.state.filters.locationChoice, 10))[0].label],
                    html,
                    loading: false
                })
            }

        })
    }

    componentDidMount = () => {
        this.initListeners();
        if (Object.values(this.props.match.params).includes(undefined)) {
            this.getServices();
        } else {
            let serviceRoute = this.props.match.params.serviceRoute;
            let carrierId = parseInt(this.props.match.params.carrierId, 10);
            let originName = this.props.match.params.originName;
            let carrierHtml;
            if (carrierId === 1 || carrierId === 2 || carrierId === 3 || carrierId === 5 || carrierId === 11) {
                carrierHtml = 5;
            } else {
                carrierHtml = 15;
            }
            let serviceChoice = this.state.serviceOptions[carrierHtml.toString()].filter(s => s.label === serviceRoute);
            if (!serviceChoice.length) {
                this.props.dispatch({ type: globalToastActions.UPDATE_MSG, payload: { msg: "Cannot Search Service", type: "error" } });
                return this.getServices();
            }
            let locationChoice = this.state.filters.locationOptions.filter(l => l.label === originName);
            if (!locationChoice.length) {
                this.props.dispatch({ type: globalToastActions.UPDATE_MSG, payload: { msg: "Cannot Search POL ETD", type: "error" } });
                return this.getServices();
            }
            let carrierName = this.props.carrier.filter(c => parseInt(c.id, 10) === carrierId);
            if (!carrierName.length) {
                this.props.dispatch({ type: globalToastActions.UPDATE_MSG, payload: { msg: "Cannot Search Carrier", type: "error" } });
                return this.getServices();
            }
            this.setState({ serviceChoice: serviceChoice[0].id, filters: { ...this.state.filters, carrierHtml, carrierName: carrierName[0].code, locationChoice: locationChoice[0].id, serviceRoute } }, () => {
                this.getServices();
            })
        }
    }

    componentWillUnmount = () => {
        this.removeListeners();
    }

    render = () => {
        return (

            <div>
                <div className={`row ${styles.noMarginBottom}`}>
                    <Form.Select col={`col s1 ${styles.carrierField}`} label="Carrier Data" name="carrierHtml" value={this.state.filters.carrierHtml} onChange={this.carrierHtmlOnChange} list={this.state.carrierHtmlOptions} filter={{ label: "name", value: "id" }} />
                    <Form.Select col={`col s1 ${styles.carrierField}`} label="POL ETD" placeholder="Choose Location . . ." name="locationChoice" value={this.state.filters.locationChoice} onChange={this.filterOnChangePlus} list={this.state.locationOptions} filter={{ label: "label", value: "id" }} />
                    <Form.Select col={`col s1 ${styles.carrierField}`} label="Service" placeholder="Choose Service . . . " name="serviceRoute" value={this.state.filters.serviceRoute} onChange={this.filterOnChangePlus} list={this.state.serviceOptions[this.state.filters.carrierHtml]} filter={{ label: "label", value: "label" }} />
                    <Form.DateInput col={`col s2 search-field ${styles.carrierField}`} label="ETD Start" name="etdStart" value={this.state.filters.etdStart} onChange={this.filterOnChange} flags={[flags.ALLOW_EMPTY]} />
                    <Form.DateInput col={`col s2 search-field ${styles.carrierField}`} label="ETD End" name="etdEnd" value={this.state.filters.etdEnd} onChange={this.filterOnChange} flags={[flags.ALLOW_EMPTY]} />
                    <Form.Select col={`col s1 search-field ${styles.appField}`} label="Carrier" name="carrierName" value={this.state.filters.carrierName} onChange={this.filterOnChangePlus} list={this.props.carrier} filter={{ label: "code", value: "code" }} flags={[flags.ALLOW_EMPTY]} />
                    <Form.DateInput col={`col s2 search-field  ${styles.appField}`} label="Cargo Cut Start" name="cargoCutStart" value={this.state.filters.cargoCutStart} onChange={this.filterOnChange} flags={[flags.ALLOW_EMPTY]} />
                    <Form.DateInput col={`col s2 search-field  ${styles.appField}`} label="Cargo Cut End" name="cargoCutEnd" value={this.state.filters.cargoCutEnd} onChange={this.filterOnChange} flags={[flags.ALLOW_EMPTY]} />
                </div>
                {this.state.loading ?
                    <div className={styles.loadingSpinnerWrapper}>
                        <LoadingSpinner size="large" color="blue" />
                    </div>
                    :
                    <div>
                        <div className={`row ${styles.tableDisplay}`} >
                            <div id="yangMingHalf" className="">
                                <table cellSpacing="0" rule="all" border="1" className={`${styles.servicesTable} ${styles.leftTable}`}>
                                    <thead className="lf-portal-services-thead">
                                        <tr className={`${styles.serviceTableHeader}`}>
                                            <th data-sort="newVoyageNumber" onClick={this.sort}>New Voy. Code &nbsp;
                                                {this.state.filters.currentSort === "newVoyageNumber" ? this.state.filters.reverse === -1 ? `⮝` : `⮟` : ""}<span /></th>
                                            <th data-sort="vesselName" className={styles.statusMemoColumn} onClick={this.sort}>Vessel Abbr. &nbsp;
                                                {this.state.filters.currentSort === "vesselName" ? this.state.filters.reverse === -1 ? `⮝` : `⮟` : ""}<span /></th>
                                            <th data-sort="voyageNumber" onClick={this.sort}>Comn Voy. &nbsp;
                                                {this.state.filters.currentSort === "voyageNumber" ? this.state.filters.reverse === -1 ? `⮝` : `⮟` : ""}<span /></th>

                                            <th data-sort="USTIW" onClick={this.sort}>{this.state.filters.locationOptions.filter(l => { return l.id === parseInt(this.state.filters.locationChoice, 10) })[0].code} ETD &nbsp;
                                                {this.state.filters.currentSort === `${this.state.filters.locationChoice}` ? this.state.filters.reverse === -1 ? `⮝` : `⮟` : ""}<span /></th>

                                            <th data-sort="USTIW" onClick={this.sort}>Vessel &nbsp;
                                                {this.state.filters.currentSort === "USTIW" ? this.state.filters.reverse === -1 ? `⮝` : `⮟` : ""}<span /></th>
                                            <th data-sort="USTIW" onClick={this.sort}>Voyage &nbsp;
                                                {this.state.filters.currentSort === "USTIW" ? this.state.filters.reverse === -1 ? `⮝` : `⮟` : ""}<span /></th>
                                            <th data-sort="USTIW" onClick={this.sort}>ETD &nbsp;
                                                {this.state.filters.currentSort === "USTIW" ? this.state.filters.reverse === -1 ? `⮝` : `⮟` : ""}<span /></th>

                                            {this.state.serviceRouteLocations.map(p => (
                                                <th key={p} data-sort="" onClick={this.sort}>{p} &nbsp;
                                                    {this.state.filters.currentSort === "USTIW" ? this.state.filters.reverse === -1 ? `⮝` : `⮟` : ""}<span /></th>
                                            ))}

                                            {this.state.serviceRouteParties.map(p => (
                                                <th key={p} data-sort="" onClick={this.sort}>{p} &nbsp;
                                                    {this.state.filters.currentSort === "USTIW" ? this.state.filters.reverse === -1 ? `⮝` : `⮟` : ""}<span /></th>
                                            ))}

                                            <th data-sort="USTIW" onClick={this.sort}>Total Cans &nbsp;
                                                {this.state.filters.currentSort === "USTIW" ? this.state.filters.reverse === -1 ? `⮝` : `⮟` : ""}<span /></th>
                                        </tr>

                                    </thead>
                                    <tbody>
                                        <ServiceResultsDisplay
                                            filters={this.state.filters}
                                            services={this.state.carrierProvidedServices}
                                            loading={this.state.loading}
                                            serviceRouteLocations={this.state.serviceRouteLocations}
                                            serviceRouteParties={this.state.serviceRouteParties}
                                        />
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                }
            </div>
        )
    }
}

const mapStateToProps = state => {
    const { navs, index, carrier, location, user, shipper } = state;
    const shipperId = navs[index].shipperId;
    return { shipperId, carrier, location, user, shipper }
}

export default connect(mapStateToProps)(ServiceRouteOverview);
import React, { Component } from "react";
import axios from "axios";
import { connect } from "react-redux";
import styles from "./party.module.css";
import "./party.css";
import { globalToastActions, urlActions } from "../../Redux/actions";
import Form from "../../Components/NewComponents";
import { auth } from "../../helperFunctions";
import TableHeader from "../../Components/TableHeader/TableHeader";

class PartyOverview extends Component {
  state = {
    parties: [],
    name: "",
    code: "",
    country: "",
    country_names: "",
    role: "",
    filters: {
      name: "",
      code: "",
      country: "",
      country_names: "",
      role: "ALL",
      currentSort: "name",
      reverse: 1,
      currentSort2: "",
      reverse2: "",
      currentSort3: "",
      reverse3: ""
    }
  };

  initState = () => ({
    parties: [],
    name: "",
    code: "",
    country: "",
    country_names: "",
    role: "",
    filters: {
      name: "",
      code: "",
      country: "",
      country_names: "",
      role: "ALL",
      currentSort: "",
      reverse: ""
    }
  });

  getParties = async () => {
    const parties = await axios.get("/api/v1/party/overview", {
      params: { ...auth.getAuthData(), filters: this.state.filters }
    });
    if (parties.data === "NOT AUTHENTICATED") {
      localStorage.clear();
      this.props.dispatch({ type: "SCORCHED_EARTH " });
      return;
    }
    this.setState({
      parties: parties.data
    }, this.sortCheck);
    if (!parties.data.length) {
      this.props.dispatch({
        type: globalToastActions.UPDATE_MSG,
        payload: { msg: "No Results", type: "warning" }
      });
    }
  };

  handleFailure = err => console.log(err);
  refresh = () => this.getParties();

  openParty = party => {
    this.props.dispatch({
      type: urlActions.UPDATE_URL,
      payload: {
        url: "/party/update",
        state: { id: party.id, name: party.name, filters: this.state.filters }
      }
    });
  };

  initListeners = elem => {
    document
      .querySelectorAll(".search-field")
      .forEach(elem => elem.addEventListener("keyup", this.searchListener));
    document.addEventListener("keydown", this.clearListener);
  };

  removeListeners = elem => {
    document
      .querySelectorAll(".search-field")
      .forEach(elem => elem.removeEventListener("keyup", this.searchListener));
    document.removeEventListener("keydown", this.clearListener);
  };

  searchListener = e => {
    if (e.keyCode === 13) {
      this.refresh();
    }
  };

  clearListener = e => {
    if (e.keyCode === 27) {
      this.clear();
    }
  };

  clear = () => {
    this.setState(this.initState(), this.refresh);
  };

  sort = e => {
    if (!e.target.getAttribute("data-sort")) {
      return;
    } else if (e.ctrlKey) {
      const value = typeof e === "string" ? this.props.url.state.filters.currentSort2 : e.target.getAttribute('data-sort');
      const reverse2 = this.state.filters.currentSort2 === value ? (-1 * this.state.filters.reverse2) : 1;
      this.setState({ filters: { ...this.state.filters, currentSort2: value, reverse2 } }, () => { this.refresh(); })
    } else if (e.altKey) {
      const value = typeof e === "string" ? this.props.url.state.filters.currentSort3 : e.target.getAttribute('data-sort');
      const reverse3 = this.state.filters.currentSort3 === value ? (-1 * this.state.filters.reverse3) : 1;
      this.setState({ filters: { ...this.state.filters, currentSort3: value, reverse3 } }, () => { this.refresh(); })
    } else {
      const value = typeof e === "string" ? this.props.url.state.filters.currentSort : e.target.getAttribute('data-sort');
      const reverse = this.state.filters.currentSort === value ? (-1 * this.state.filters.reverse) : 1;
      this.setState({ filters: { ...this.state.filters, currentSort: value, reverse } }, () => { this.refresh(); })
    }
  };

  sortCheck = () => {
    if (this.props.url.state) {
      if (this.props.url.state.currentSort) {
        let reverseFlip = (this.props.url.state.reverse * -1)
        this.setState({ currentSort: this.props.url.state.currentSort, reverse: reverseFlip },
          () => this.sort(this.props.url.state.currentSort));
      }
    }
  }
  filterCheck = () => {
    if (this.props.url.state) {
      if (this.props.url.state.filters) {
        this.setState({ filters: this.props.url.state.filters });
      }
    }
  }

  filterChange = (name, value) => {
    const { filters } = this.state;
    filters[name] = value;
    this.setState({ filters });
  };

  roleFilterChange = (name, value) => {
    const { filters } = this.state;
    filters[name] = value;
    this.setState({ filters });
    this.refresh();
  }

  createParty = e => {
    e.preventDefault();
    this.props.dispatch({
      type: urlActions.UPDATE_URL,
      payload: { url: "/party/create", state: { currentSort: this.state.currentSort, reverse: this.state.reverse, filters: this.state.filters } }
    });
  };

  UNSAFE_componentWillMount = () => {
    if (this.props.url.state.filters) {
      this.setState({ filters: { ...this.initState().filters, ...this.props.url.state.filters } })
    }
  }

  componentDidMount = async () => {
    this.getParties();
    this.initListeners();
    document.getElementById('name').select();
  };

  componentWillUnmount = () => {
    this.removeListeners();
  };

  render = () => (
    <div>
      <div className="bkg-ov-search-fields">
        <div className="row">
          <Form.TextInput
            col="s3 search-field"
            name="name"
            label="Party"
            value={this.state.filters.name}
            onChange={this.filterChange}
            flags={["ALLOW_EMPTY"]}
          />
          <Form.TextInput
            col="s3 search-field"
            name="code"
            label="Code"
            value={this.state.filters.code}
            onChange={this.filterChange}
            flags={["ALLOW_EMPTY"]}
          />
          <Form.Select
            name="role"
            label="Role"
            value={this.state.filters.role}
            onChange={this.roleFilterChange}
            col="s3 search-field"
            list={[
              { name: "ALL" },
              { name: "SHIPPER" },
              { name: "CONSIGNEE" },
              { name: "BOTH" }
            ]}
            filter={{ label: "name", value: "name" }}
          />

          <Form.TextInput
            col="s3 search-field"
            name="country_names"
            label="Country"
            value={this.state.filters.country_names}
            onChange={this.filterChange}
            flags={["ALLOW_EMPTY"]}
          />
        </div>
      </div>
      <table className="lf-portal-table " >
        <TableHeader
          headers={[
            { label: "Name", attribute: "name" },
            { label: "Short Name", attribute: "code" },
            { label: "Role", attribute: "role" },
            { label: "Country", attribute: "country_names" }
          ]}
          sort={this.sort}
          filters={this.state.filters}
        />
        <tbody className={styles.tbody}>
          {this.state.parties.map((party, index) => (
            <tr key={index}>
              <td onClick={() => this.openParty(party)}>{party.name}</td>
              <td onClick={() => this.openParty(party)}>{party.code}</td>
              <td onClick={() => this.openParty(party)}>{party.role}</td>
              <td onClick={() => this.openParty(party)}>
                {party.country_names}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div onClick={this.createParty} className="fixed-action-btn">
        <a href="/" className="btn-floating btn-large green">
          <i className="large material-icons">add</i>
        </a>
      </div>
    </div>
  );
}

const mapStateToProps = state => {
  const { countries, urls, index } = state;
  const url = urls[index];
  return { countries, url, index };
};

export default connect(mapStateToProps)(PartyOverview);

import React from 'react';
import styles from "./Requests.module.css";
import Form from "../../Components/NewComponents";
import { flags } from '../../constants';

const EmployeeSection = (props) => {
    return <div>
        <div className={`row ${styles.userSection}`}>
            <div className={`row ${styles.commentsRow}`}>
                <h6>Log</h6>
                <div className={`row ${styles.addCommentRow}`}>
                    <Form.TextArea id="comment" col={`col s12 ${styles.commentTextArea}`} label="New Comment" name="comment" value={props.comment} onChange={props.onChange} onInput={() => props.resizeTextArea('comment')} flags={[flags.ALLOW_EMPTY]}/>
                    <Form.Button col="col s3" label="Add Comment" type="outline" color="blue" onClick={props.postComment} />
                    <Form.Button col="col s3" label="Clear" type="outline" color="red" onClick={() => props.setComment('comment', "")} />
                    {props.requiresAttention ?
                        <Form.Button col="col s3" label="Remove Flag" type="outline" color="black" onClick={props.removeAttentionFlag} />
                        :
                        null}
                </div>
                {props.bookingRequestComments.map((log, index) => (
                    <div key={index} className={`${styles.commentBox}`}>
                        <div className={`col s12 ${styles.commentHeader}`}>
                            <div className={`${styles.commentName}`}>
                                {log.userName}
                            </div>
                            <div className={`${styles.commentTime}`}>
                                {log.datetimePosted}
                            </div>
                        </div>
                        {log.comment && log.comment.length ?
                            log.comment.map( (c, index) => (
                                <div key={index} className={`col s12 ${styles.commentBody} ${log.userGenerated[index] ? styles.italicComment : ''}`}>
                                    {c}
                                </div>
                            )) :
                            null}
                    </div>
                ))}
            </div>
        </div>
    </div>
}

export default EmployeeSection
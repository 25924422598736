import React from 'react';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import styles from './DatePicker.module.css';
import './daterange.css';

const container = props => (
    <div style={{ display: 'flex', border: 'black solid 1px', position: 'absolute', backgroundColor: 'white' }}>
        {props.children}
    </div>
)

class DateRange extends React.Component {
    constructor(props) { super(props); this.state = this.initState(); }

    initState = () => ({
        startDate: this.props.startDate ? this.props.startDate : '',
    })

    sendToParent = (name, date) => {
        const value = date ? moment(date).format('MM/DD/YY') : '';
        this.props.onChange(name, value);
        if(this.props.onBlur){
            this.props.onBlur();
        }
    }

    componentDidUpdate =(prevProps, prevState) => {
        if(prevProps.value !== this.props.value && !this.props.value){
            this.setState({ startDate: '' });
        }
    }

    startChange = date => {
        this.setState({ startDate: date }, () => this.sendToParent(this.props.name, date));
    } 
        
    render = () => (
        <div className={`col ${this.props.col} ${styles.div}`}>
            <DatePicker
                selected={this.state.startDate}
                selectsStart
                startDate={this.state.startDate}
                endDate={this.state.endDate}
                dateFormat="MM/dd/yy"
                className={`browser-default ${styles.input} ${styles.left} ${this.props.disabled ? styles.disabled : ''}`}
                onChange={this.startChange}
                showWeekNumbers
                monthsShown={1}
                calendarContainer={container}
                placeholderText="Start"
                disabled={this.props.disabled}
                popperClassName={styles.popperClassName}
            />
            <label className={`${styles.label} ${this.props.disabled ? styles.disabled : ''}`}>{this.props.label} {this.props.required ? <span style={{color: "red"}}>*</span> : ''}</label>
        </div>
    )
}

export default DateRange;
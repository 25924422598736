import React from 'react';
import styles from './FilterContainer.module.css';
import { types } from '../../constants';


class FilterContainer extends React.Component {
    constructor(props) {
        super(props); this.state = this.initState()
    }

    initState = () => ({ value: '', err: null })
    onChange = e => {
        const value = e.target.value;
        this.setState({ value }, () => this.props.onChange(this.props.name, value));
    }

    removeBubble = (index) => {
        this.props.spliceFilterList(index);
    }

    getDivClassName = () => {
        return this.props.col ? `col ${this.props.col} ${styles.div} ${styles.container}` : `${styles.div} ${styles.container}`;
    }

    genInputClass = () => {
        return `browser-default ${styles.input} ${this.state.err ? styles.err : ''}`;
    }

    getInputErrInfo = () => ([{ name: 'value', type: types.EMAIL, value: this.props.value, flags: this.props.flags }])

    renderCompleteComponent= () => {
        return (
            <div className={this.getDivClassName()}>
                {this.props.filterList.map( (f, index) => (
                    <div key={index} className={`${styles.span} ${styles[f.type]}`}>{f.searchTerm}<i className={`material-icons tiny ${styles.icon}`} onClick={() => this.removeBubble(index)}>cancel</i></div>
                ))}                
            </div>
        )
    }

    render = () => {
        return this.renderCompleteComponent()
    }
    
}

FilterContainer.defaultProps = {
    filterList: [
            {searchTerm: "ACTIVE", type: "status", id: 1 }
    ]
}

export default FilterContainer;
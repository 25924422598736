import React, { Component } from 'react';
import { connect } from 'react-redux';
import { flags } from '../../constants';
import styles from "./Requests.module.css";
import Form from "../../Components/NewComponents";
import TemplateModal from "./TemplateModal.js";
import axios from 'axios'
import moment from 'moment';
import { globalToastActions } from '../../Redux/actions';
import { auth } from "../../helperFunctions";
import M from 'materialize-css';

class RequestForm extends Component {

    state = {
        collapsibleIsOpen: this.props.user.isEmployee ? false : true,
        employee: this.props.user.isEmployee ? true : false,
        templateId: 0,
        saveAsTemplate: false,
        shipperId: this.props.user.isEmployee ? 0 : this.props.user.partyId,
        carrierId: 0,
        originId: 0,
        destId: 0,
        containerCount: 5,
        containerTypeId: 1,
        shipperReferenceNumber: "",
        consigneeName: "",
        isDateRange: false,
        bookedContainers: 0,
        requestedContainers: 0,
        averageContainerCount: 0,
        bookedPercentage: 0,
        percentageColor: 'white',
        containerRowColor: "black",
        startDate: "",
        endDate: "",
        dateType: "CUT",
        productDescription: "",
        note: "",
        amount: 1,
        dateTypeList: [
            { type: 'CUT' },
            { type: 'ETD' },
            { type: 'ETA' }
        ],
        origins: [{ name: "No Contract Origins", id: 0 }],
        destinations: [{ name: "No Contract Destinations", id: 0 }],
        carriers: [],
        allConsignees: [],
        templateList: [],
        loading: true
    }

    onChange = (name, value) => {
        if (name === "dateType") {
            this.getBookingAndPendingContainers();
        }
        this.setState({ [name]: value })
    };

    templateOnChange = (name, value) => {
        if (!parseInt(value)) {
            this.setState({ [name]: value, carrierId: 0, originId: 1, destId: 1, containerCount: 0, containerTypeId: 1, consigneeName: "", dateType: "ETD", isDateRange: 0 });
        } else {
            let template = this.state.templateList.filter(t => t.templateId === parseInt(value))[0];
            this.setState({ [name]: value, ...template })
        }
    }

    shipperOnChange = (name, value) => {
        let shipperName = this.props.shipper.filter(s => s.id === parseInt(value))[0].name;
        let shipperCode = this.props.shipper.filter(s => s.id === parseInt(value))[0].code;
        this.setState({ [name]: value, shipperName, shipperCode }, () => {
            this.getShipperLists(value);
            this.getBookingAndPendingContainers();
        });
    }

    clearListener = e => {
        this.initState()
    }

    openTemplateModal = () => { M.Modal.getInstance(document.querySelector('.templateModal')).open(); };

    closeTemplateModal = () => { M.Modal.getInstance(document.querySelector('.templateModal')).close(); };

    getShipperLists = (shipperId) => {
        axios.get("/api/v1/request/shipperlists", { params: { ...auth.getAuthData(), shipperId } }).then(result => {
            if (result === "NOT AUTHENTICATED") {
                localStorage.clear();
                this.props.dispatch({ type: "SCORCHED_EARTH" });
                return;
            } else {
                let { origins, destinations, carriers, templates, consignees } = result.data;
                if (!origins.length) {
                    origins = [{ name: "No Contract Origins", id: 0 }]
                }
                if (!destinations.length) {
                    destinations = [{ name: "No Contract Destinations", id: 0 }]
                }
                return this.setState({
                    origins,
                    originId: origins[0].id,
                    destinations,
                    destId: destinations[0].id,
                    carriers,
                    allConsignees: consignees,
                    templateList: templates
                });
            }
        })
    }

    getShipperTemplates = (shipperId) => {
        axios.get("/api/v1/request/template", { params: { ...auth.getAuthData(), shipperId } }).then(result => {
            if (result === "NOT AUTHENTICATED") {
                localStorage.clear();
                this.props.dispatch({ type: "SCORCHED_EARTH" });
                return;
            } else {
                this.setState({ templateList: result.data })
            }
        })
    }

    deleteTemplate = (id, index) => {
        axios.post('/api/v1/request/template/delete', { ...auth.getAuthData(), id }).then((result) => {
            if (result.data === "NOT AUTHENTICATED") {
                localStorage.clear();
                this.props.dispatch({ type: "SCORCHED_EARTH" });
                return;
            } else {
                let templateList = [...this.state.templateList];
                templateList.splice(index, 1);
                this.setState({ templateList });
            }
        })
    }


    getBookingAndPendingContainers = () => {

        setTimeout(() => {

            if (!moment(this.state.startDate, "MM/DD/YY").isValid() || !this.state.shipperId) {
                return this.setState({ bookedContainers: 0, requestedContainers: 0, averageContainerCount: 0, bookedPercentage: 0, percentageColor: "white" });
            }

            let { startDate, endDate, dateType, shipperId } = this.state;

            axios.get("/api/v1/container/booked", { params: { ...auth.getAuthData(), shipperId, dateType, startDate, endDate } }).then(result => {
                if (result.data === "NOT AUTHENTICATED") {
                    localStorage.clear();
                    return this.props.dispatch({ type: "SCORCHED_EARTH" });
                } else {
                    let { bookedContainers, requestedContainers, averageContainerCount } = result.data[0];
                    if (!!bookedContainers && !!averageContainerCount) {
                        let bookedPercentage = Math.round((bookedContainers / averageContainerCount) * 100);
                        let percentageColor = bookedPercentage > 100 ? bookedPercentage > 149 ? "red" : "yellow" : "white";
                        // let containerRowColor = bookedPercentage > 100 ? bookedPercentage > 149 ? "red" : "yellow" : "black";
                        this.setState({ bookedContainers, requestedContainers, averageContainerCount, bookedPercentage, percentageColor });
                    } else {
                        this.setState({ bookedContainers, requestedContainers, averageContainerCount, bookedPercentage: 0, percentageColor: "white" });
                    }

                }
            })

        }, 500)

    }

    submit = async () => {
        if (!this.state.shipperId) {
            return this.props.dispatch({ type: globalToastActions.UPDATE_MSG, payload: { msg: "Must Select Shipper", type: "error" } });
        } else if (this.state.isDateRange) {
            if (!this.state.startDate || !this.state.endDate) {
                return this.props.dispatch({ type: globalToastActions.UPDATE_MSG, payload: { msg: "Must Select Date(s)", type: "error" } });
            }
        } else if (!this.state.isDateRange) {
            if (!this.state.startDate) {
                return this.props.dispatch({ type: globalToastActions.UPDATE_MSG, payload: { msg: "Must Select Date(s)", type: "error" } });
            }
        } else if (!this.state.containerCount) {
            return this.props.dispatch({ type: globalToastActions.UPDATE_MSG, payload: { msg: "Must Enter Container Count", type: "error" } });
        }

        const carrierId = this.state.carrierId ? this.state.carrierId : null;
        const startDate = this.state.startDate ? this.state.startDate : null;
        const endDate = this.state.isDateRange && this.state.endDate ? this.state.endDate : null;
        let requestInfo = { ...this.state };
        delete requestInfo.requests;

        let emailInfo = {
            amount: this.state.amount,
            shipperName: this.state.employee ? this.state.shipperName : this.props.user.partyName,
            shipperCode: this.state.employee ? this.state.shipperCode : this.props.user.partyCode,
            carrierName: carrierId ? this.state.carriers.filter(c => c.id === parseInt(carrierId))[0].name : 'Best Fit',
            carrierCode: carrierId ? this.state.carriers.filter(c => c.id === parseInt(carrierId))[0].code : "Best Fit",
            containerCount: this.state.containerCount,
            containerType: this.props.containerType.filter(ct => ct.id === parseInt(this.state.containerTypeId))[0].code,
            originCode: this.state.origins.filter(o => o.id === parseInt(this.state.originId))[0].code,
            destCode: this.state.destinations.filter(d => d.id === parseInt(this.state.destId))[0].code,
            startDate: this.state.startDate,
            formattedStartDate: moment(this.state.startDate, "MM/DD/YY").format("MM/DD"),
            endDate: this.state.endDate,
            formattedEndDate: moment(this.state.endDate, "MM/DD/YY").format("MM/DD"),
            dateType: this.state.dateType,
            shipperReferenceNumber: this.state.shipperReferenceNumber,
            consigneeName: this.state.consigneeName,
            productDescription: this.state.productDescription,
            note: this.state.note
        }

        axios.post(`/api/v1/request/${this.state.employee ? 'e' : 'c'}`, { ...auth.getAuthData(), ...requestInfo, carrierId, startDate, endDate, emailInfo }).then(result => {
            if (result.data === "NOT AUTHENTICATED") {
                localStorage.clear();
                this.props.dispatch({ type: "SCORCHED_EARTH" });
                return;
            } else {
                if (result.data.errno)
                    this.setState({ msg: { color: 'red', text: `Error: ${result.data.sqlMessage}` } });
                else
                    this.setState({ msg: { color: 'green', text: `Submitted ID: ${result.data.insertId}` }, saveAsTemplate: 0 },
                        () => {
                            this.props.getBookingRequests(true);
                            this.getShipperTemplates(this.state.shipperId) // THIS NEEDS TO BE CHANGED TO A BACK END COMBINATION WITH THE GET QUERY INSTEAD
                            this.getBookingAndPendingContainers();
                            this.props.emitUpdateTrigger([this.state.shipperId]);
                        })
            }
        })
    }

    clear = () => {
        this.setState({
            shipperId: this.state.employee ? 0 : this.state.shipperId,
            carrierId: 0,
            originId: 0,
            destId: 0,
            containerCount: 5,
            containerTypeId: 1,
            shipperReferenceNumber: "",
            consigneeName: "",
            origins: this.state.employee ? [{ name: "No Contract Origins", id: 0 }] : this.state.origins,
            destinations: this.state.employee ? [{ name: "No Contract Destinations", id: 0 }] : this.state.destinations,
            carriers: this.state.employee ? [] : this.state.carriers,
            allConsignees: this.state.employee ? [] : this.state.allConsignees,
            isDateRange: false,
            startDate: "",
            endDate: "",
            dateType: "CUT",
            productDescription: "",
            note: "",
            amount: 1,
            templateId: 0,
            templateList: this.state.employee ? [] : this.state.templateList,
            saveAsTemplate: 0

        }, () => { this.props.getBookingRequests(); });
    }

    componentDidMount = () => {
        setTimeout(() => {
            let div = document.querySelector('.preload');
            if (div) {
                div.classList.remove('preload')
            } else {
                return;
            }
        }, 500);
        this.getShipperLists(this.state.shipperId);
    }

    render = () => {
        return (
            <div className={`preload ${styles.bookingRequestForm}`}>
                {this.state.employee ?
                    <div>
                        {/* THIS IS THE EMPLOYEE SECTION */}
                        <div className={`' ${this.state.collapsibleIsOpen ? styles.newRequestHeaderOpen : styles.newRequestHeaderClose}`} style={{ margin: '0px', padding: '0px', maxHeight: '12px', border: 'none' }} >
                            <i className="material-icons" onClick={() => this.setState({ collapsibleIsOpen: !this.state.collapsibleIsOpen })}>{this.state.collapsibleIsOpen ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}</i>
                            <p style={{ position: 'relative', bottom: '4px', width: "200px", paddingTop: "5px" }} onClick={() => this.setState({ collapsibleIsOpen: !this.state.collapsibleIsOpen })}>Booking Request Form</p>
                            <div className={styles.collapsibleHeaderForms}>
                                <Form.Select col="s5" name="templateId" label="Template" value={this.state.templateId} onChange={this.templateOnChange} placeholder="Choose Template..." list={this.state.templateList} filter={{ label: 'templateName', value: 'templateId' }} flags={[flags.ALLOW_EMPTY]} />
                                <Form.Button col="s5" color="blue" type="outline" label="Manage" onClick={this.openTemplateModal} />
                            </div>
                        </div>
                        <div className={`${styles.newRequestForm} ${this.state.collapsibleIsOpen ? styles.openForm : styles.closeForm}`}>
                            <div className="row">
                                <Form.Select col="s3" name="shipperId" label="Shipper" required placeholder="SELECT SHIPPER" onChange={this.shipperOnChange} value={this.state.shipperId} list={this.props.shipper} filter={{ label: "name", value: "id" }} />
                                <Form.Select col="s2" name="carrierId" label="Carrier" required placeholder="Best Fit" onChange={this.onChange} value={this.state.carrierId} list={this.state.carriers} filter={{ label: 'displayName', value: 'id' }} flags={[flags.ALLOW_EMPTY]} />
                                <Form.Select col="s2" name="originId" label="Origin" required onChange={this.onChange} value={this.state.originId} list={this.state.origins} filter={{ label: 'name', value: 'id' }} />
                                <Form.Select col="s2" name="destId" label="Destination" required onChange={this.onChange} value={this.state.destId} list={this.state.destinations} filter={{ label: 'name', value: 'id' }} />
                                <Form.NumberInput col="s1" name="containerCount" label="Count" required onChange={this.onChange} value={this.state.containerCount} maxLength="2" />
                                <Form.Select col="s2" name="containerTypeId" label="Type" required onChange={this.onChange} value={this.state.containerTypeId} list={this.props.containerType} filter={{ label: 'code', value: 'id' }} />
                            </div>
                            <div className="row">
                                <Form.TextInput col="s2" name="shipperReferenceNumber" label="Shipper Ref #" onChange={this.onChange} value={this.state.shipperReferenceNumber} flags={[flags.ALLOW_EMPTY]} />
                                <Form.AutoComplete col="s2" name="consigneeName" label="Consignee" onChange={this.onChange} value={this.state.consigneeName} data={this.state.allConsignees} filterValue="name" flags={[flags.ALLOW_EMPTY]} />
                                <Form.CheckBox col="s2 isDateRangeCheckBox" name="isDateRange" label="Range" onChange={this.onChange} value={this.state.isDateRange} flags={[flags.ALLOW_EMPTY]} />
                                <div className={`col s4`} style={{ padding: "0px" }}>
                                    {this.state.isDateRange ?
                                        <Form.DateRange label="Shipment Date" required onChange={this.onChange} names={{ start: 'startDate', end: 'endDate' }} onBlur={this.getBookingAndPendingContainers} />
                                        :
                                        <Form.DatePicker label="Shipment Date" required onChange={this.onChange} name="startDate" value={this.state.startDate} onBlur={this.getBookingAndPendingContainers} />
                                    }
                                </div>
                                <Form.Select col="s2" name="dateType" label="Date Type" required onChange={this.onChange} value={this.state.dateType} list={this.state.dateTypeList} filter={{ label: 'type', value: 'type' }} onBlur={this.getBookingAndPendingContainers} />
                            </div>
                            <div className="row">
                                <Form.TextInput col="s4" name="productDescription" label="Product/Packaging" onChange={this.onChange} value={this.state.productDescription} flags={[flags.ALLOW_EMPTY]} />
                                <Form.TextInput col="s8" name="note" label="Note" onChange={this.onChange} value={this.state.note} noblurcap={"true"} flags={[flags.ALLOW_EMPTY]} />
                            </div>
                            <div className={`row ${styles.noMarginBottom}`}>
                                <div className={`col s3 ${styles.containerCountToolTip}`}>
                                    {!this.state.bookedContainers && !this.state.requestedContainers && !this.state.averageContainerCount ?
                                        <span className={styles.containerCountsSpan}>Bkd: - | Req.: - | Avg: -</span>
                                        :
                                        <div>
                                            <div className={styles.containerCountToolTipText}>
                                                <span>Average weekly containers shipped over past 90 days: {this.state.averageContainerCount}</span>
                                                <span>Number of containers booked for this particular week: {this.state.bookedContainers}</span>
                                                <span>Number of additional containers requested for this particular week: {this.state.requestedContainers}</span>
                                                <span>You are booked at <span style={{ color: this.state.percentageColor, textDecoration: "underline" }}>{this.state.bookedPercentage}%</span> of your average for this particular week</span>
                                            </div>
                                            <span className={`${styles.containerCountsSpan}`} style={{ color: this.state.containerRowColor }}> Bkd: {this.state.bookedContainers} | Req: {this.state.requestedContainers} | Avg: {this.state.averageContainerCount} </span>
                                        </div>
                                    }
                                </div>
                                <Form.NumberInput col="s1" name="amount" label="# to Request" onChange={this.onChange} value={this.state.amount} maxLength="1" flags={[flags.ALLOW_EMPTY, flags.MAX_VALUE + 9]} />
                                {this.state.collapsibleIsOpen ?
                                    null
                                    :
                                    <div>
                                        <Form.Select col="s2" name="templateId" label="Template" value={this.state.templateId} onChange={this.templateOnChange} placeholder="Choose Template..." list={this.state.templateList} filter={{ label: 'templateName', value: 'templateId' }} />
                                        <Form.Button col="s2" color="blue" type="outline" label="Manage" onClick={this.openTemplateModal} />
                                    </div>
                                }
                                <Form.Button col="s2" color="blue" type="outline" label="Submit Request" onClick={this.submit} />
                                <Form.CheckBox col="s3" name="saveAsTemplate" label="Save as Template" onChange={this.onChange} value={this.state.saveAsTemplate} />
                                <Form.Button col="s2" color="red" type="outline" label="Clear" onClick={this.clear} />
                            </div>
                        </div>
                    </div>
                    :
                    // THIS IS THE CUSTOMER SECTION
                    <div>
                        <div className={`' ${this.state.collapsibleIsOpen ? styles.newRequestHeaderOpen : styles.newRequestHeaderClose}`} style={{ margin: '0px', padding: '0px', maxHeight: '12px', border: 'none' }} >
                            <i className="material-icons" onClick={() => this.setState({ collapsibleIsOpen: !this.state.collapsibleIsOpen })}>{this.state.collapsibleIsOpen ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}</i>
                            <p style={{ position: 'relative', bottom: '4px', width: "200px", paddingTop: "5px" }} onClick={() => this.setState({ collapsibleIsOpen: !this.state.collapsibleIsOpen })}>Booking Request Form</p>
                            <div className={styles.collapsibleHeaderForms}>
                                <Form.Select col="s5" name="templateId" label="Template" value={this.state.templateId} onChange={this.templateOnChange} placeholder="Choose Template..." list={this.state.templateList} filter={{ label: 'templateName', value: 'templateId' }} flags={[flags.ALLOW_EMPTY]} />
                                <Form.Button col="s5" color="blue" type="outline" label="Manage" onClick={this.openTemplateModal} />
                            </div>
                        </div>
                        <div className={`${styles.newRequestForm} ${this.state.collapsibleIsOpen ? styles.openForm : styles.closeForm}`}>
                            <div className="row">
                                <Form.Select col="s3" name="carrierId" label="Carrier" required placeholder="Best Fit" onChange={this.onChange} value={this.state.carrierId} list={this.state.carriers} filter={{ label: 'displayName', value: 'id' }} />
                                <Form.Select col="s3" name="originId" label="Origin" required onChange={this.onChange} value={this.state.originId} list={this.state.origins} filter={{ label: 'name', value: 'id' }} />
                                <Form.Select col="s3" name="destId" label="Destination" required onChange={this.onChange} value={this.state.destId} list={this.state.destinations} filter={{ label: 'name', value: 'id' }} />
                                <Form.NumberInput col="s1" name="containerCount" label="Count" required onChange={this.onChange} value={this.state.containerCount} />
                                <Form.Select col="s2" name="containerTypeId" label="Type" required onChange={this.onChange} value={this.state.containerTypeId} list={this.props.containerType} filter={{ label: 'code', value: 'id' }} />
                            </div>
                            <div className="row">
                                <Form.TextInput col="s2" name="shipperReferenceNumber" label="Shipper Ref #" onChange={this.onChange} value={this.state.shipperReferenceNumber} flags={[flags.ALLOW_EMPTY]} />
                                <Form.AutoComplete col="s2" name="consigneeName" label="Consignee" onChange={this.onChange} value={this.state.consigneeName} data={this.state.allConsignees} filterValue="name" flags={[flags.ALLOW_EMPTY]} />
                                <Form.CheckBox col="s2 isDateRangeCheckBox" name="isDateRange" label="Range" onChange={this.onChange} value={this.state.isDateRange} flags={[flags.ALLOW_EMPTY]} />
                                <div className={`col s4`} style={{ padding: "0px" }}>
                                    {this.state.isDateRange ?
                                        <Form.DateRange label="Shipment Date" required onChange={this.onChange} names={{ start: 'startDate', end: 'endDate' }} onBlur={this.getBookingAndPendingContainers} />
                                        :
                                        <Form.DatePicker label="Shipment Date" required onChange={this.onChange} name="startDate" value={this.state.startDate} onBlur={this.getBookingAndPendingContainers} />
                                    }
                                </div>
                                <Form.Select col="s2" name="dateType" label="Date Type" required onChange={this.onChange} value={this.state.dateType} list={this.state.dateTypeList} filter={{ label: 'type', value: 'type' }} onBlur={this.getBookingAndPendingContainers} />
                            </div>
                            <div className="row">
                                <Form.TextInput col="s4" name="productDescription" label="Product/Packaging" onChange={this.onChange} value={this.state.productDescription} flags={[flags.ALLOW_EMPTY]} />
                                <Form.TextInput col="s8" name="note" label="Note" onChange={this.onChange} value={this.state.note} noblurcap="true" flags={[flags.ALLOW_EMPTY]} />
                            </div>
                            <div className="row">
                                <div className={`col s3 ${styles.containerCountToolTip}`}>
                                    {!this.state.bookedContainers && !this.state.requestedContainers && !this.state.averageContainerCount ?
                                        <span className={styles.containerCountsSpan}>Bkd: - | Req.: - | Avg: -</span>
                                        :
                                        <div>
                                            <div className={styles.containerCountToolTipText}>
                                                <span>Average weekly containers shipped over past 90 days: {this.state.averageContainerCount}</span>
                                                <span>Number of containers booked for this particular week: {this.state.bookedContainers}</span>
                                                <span>Number of additional containers requested for this particular week: {this.state.requestedContainers}</span>
                                                <span>You are booked at <span style={{ color: this.state.percentageColor, textDecoration: "underline" }}>{this.state.bookedPercentage}%</span> of your average for this particular week</span>
                                            </div>
                                            <span className={`${styles.containerCountsSpan}`} style={{ color: this.state.containerRowColor }}> Bkd: {this.state.bookedContainers} | Req: {this.state.requestedContainers} | Avg: {this.state.averageContainerCount} </span>
                                        </div>}
                                </div>
                                <Form.NumberInput col="s1" name="amount" label="Amount" onChange={this.onChange} value={this.state.amount} flags={[flags.ALLOW_EMPTY, flags.MAX_VALUE + 9]} maxLength="1" />
                                {this.state.collapsibleIsOpen ?
                                    null
                                    :
                                    <div>
                                        <Form.Select col="s2" name="templateId" label="Template" value={this.state.templateId} onChange={this.templateOnChange} placeholder="Choose Template..." list={this.state.templateList} filter={{ label: 'templateName', value: 'templateId' }} />
                                        <Form.Button col="s2" color="blue" type="outline" label="Manage" onClick={this.openTemplateModal} />
                                    </div>
                                }
                                <Form.Button col="s2" color="blue" type="outline" label="Submit Request" onClick={this.submit} />
                                <Form.CheckBox col="s3" name="saveAsTemplate" label="Save as Template" onChange={this.onChange} value={this.state.saveAsTemplate} />
                                <Form.Button col="s2" color="red" type="outline" label="Clear" onClick={this.clear} />
                            </div>
                        </div>
                    </div>
                }
                <TemplateModal
                    shipperName={this.state.shipperName}
                    closeTemplateModal={this.closeTemplateModal}
                    templateList={this.state.templateList}
                    deleteTemplate={this.deleteTemplate}
                />
            </div>
        )
    }
}

const mapStateToProps = state => {
    const { navs, index, carrier, location, containerType, party, user, shipper } = state;
    const shipperId = navs[index].shipperId;
    return { shipperId, carrier, location, containerType, party, user, shipper };
}

export default connect(mapStateToProps)(RequestForm);
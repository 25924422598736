import React from 'react';
import { connect } from 'react-redux';
import { sidebarActions } from '../../../Redux/actions';
import { Switch, Route } from 'react-router-dom';
import UserOverview from './UserOverview';
import CreateUser from './CreateUser';

class Users extends React.Component {

    getpath = () => ('/party/user');
    rebuildNav = () => this.props.dispatch({ type: sidebarActions.REBUILD_NAV, payload: { url: this.getpath() } })

    componentDidMount = () => this.rebuildNav();

    render = () => (
        <Switch>
            <Route exact path={this.getpath()} component={UserOverview} />
            <Route path={this.getpath() + '/create'} component={CreateUser} />
        </Switch>
    )
}

export default connect()(Users);
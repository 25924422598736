import React, { Component } from 'react';
import { connect } from 'react-redux';
import { flags } from "../../../constants";
import M from 'materialize-css';
import Form from '../../../Components/NewComponents';
import axios from 'axios';
import moment from "moment-timezone";
import styles from "../contracts.module.css";
import { globalToastActions, urlActions } from "../../../Redux/actions";
import NewContractModal from '../ContractModal/NewContractModal';
import RatesDisplay from "./RatesDisplay";
import SpecialRestrictionsDisplay from './SpecialRestrictionsDisplay';
import FreetimeDisplay from "./FreetimeDisplay";
import SalesRepDisplay from "./SalesRepDisplay";
import FreightChargesDisplay from "./FreightChargesDisplay"
import "./ContractEdit.css";
import { auth } from "../../../helperFunctions";
import LoadingSpinner from "../../../Components/LoadingSpinner/LoadingSpinner";

class ContractEdit extends Component {

    state = {
        origins: [{ id: 0 }],
        destinations: [{ id: 0 }],
        portPairs: [],
        contract: {
            contractDetails: {
                commodity: "",
                contractNumber: "",
                minimumQuantityCommitment: "",
                containerTypeId: "",
                commissionAmount: "",
                commissionUsePercentage: "",
                note: "",
                status: ""
            },
            carrierChargesList: [],
            charges: {
                additionalCharges: [],
                perContainerCharges: [],
                perContainerChargesTotal: []
            },
            contractSalesReps: [],
            freetime: [],
            rates: [],
            specialRestrictions: []
        },
        emails: [],
        carrierSalesReps: [],
        rateMatrixParams: {
            shipperId: this.props.url.state ? this.props.url.state.shipperId : 1,
            feeDisplay: "BASE",
            carrierId: 0,
            originId: 0,
            destId: 0,
            destCountryId: 0,
            reportType: "rateMatrix"
        },
        targetContract: {},
        shippers: [],
        flipSort: false,
        reverse: false,
        loading: true,
        overWrite: false,
        badge: {
            type: "",
            color: "#33679e",
            id: ""
        },
        editingDetails: false
    }

    onChange = (name, value) => this.setState({ [name]: value });

    rateMatrixOnChange = (name, value) => {
        this.setState({ rateMatrixParams: { ...this.state.rateMatrixParams, [name]: value } })
    }

    showPrintModal = () => {
        M.Modal.getInstance(document.querySelector("#rate-print-modal")).open();
    }

    contractDetailsOnChange = (name, value) => {

        if (name === "shipperId") {

            let shipperId = parseInt(value);
            let shipperName = this.props.shipper.filter(s => s.id === parseInt(value))[0].name;
            const carrierId = parseInt(this.state.contract.contractDetails.carrierId);
            let carrierName = this.props.carrier.filter(c => c.id === carrierId)[0].name
            let containerTypeId = this.state.contract.contractDetails.containerTypeId;
            let commodityId = parseInt(this.state.contract.contractDetails.commodityId);


            axios.get("/api/v1/contract/carriers", { params: { ...auth.getAuthData(), shipperId, carrierId, commodityId, containerTypeId } }).then(result => {
                if (result.data === "NOT AUTHENTICATED") {
                    localStorage.clear();
                    this.props.dispatch({ type: "SCORCHED_EARTH" });
                    return;
                }

                if (!result.data.length || ![...result.data.map(r => r.carrierId)].includes(carrierId)) {
                    this.props.dispatch({ type: globalToastActions.UPDATE_MSG, payload: { type: "error", msg: "Selected Shipper Has No Contracts With Carrier" } });
                } else if (result.data.length && [...result.data.map(r => r.carrierId)].includes(carrierId)) {

                    let contractSearchTerm = result.data.filter(c => !!c.activeLineage).length ?
                        result.data.filter(c => !!c.activeLineage)[0].id : result.data.filter(c => c.carrierId === carrierId)[0].id;

                    let contracts = result.data;

                    this.props.dispatch({
                        type: urlActions.UPDATE_URL, payload: {
                            url: "/contract/edit", state: {
                                origin: "contractOverview", contractSearchTerm, shipperId, shipperName, filters: this.props.filters,
                                carrierId, carrierName, contracts
                            }
                        }
                    })
                }
            })
        }

        this.setState({ contract: { ...this.state.contract, contractDetails: { ...this.state.contract.contractDetails, [name]: value } } });

    }

    ratesOnChange = (name, value) => {
        let nameAndIndex = name.split(":");
        let index = nameAndIndex[1];
        let portPairs = this.state.portPairs
        portPairs[index].amount = value

        this.setState({ portPairs: [...this.state.portPairs, this.state.portPairs[index].amount] });
    }

    specialRestOnChange = (name, value) => {
        let nameAndIndex = name.split(":");
        let trueName = nameAndIndex[0];
        let index = nameAndIndex[1];
        let specialRestrictions = this.state.contract.specialRestrictions;
        specialRestrictions[index][trueName] = value;

        this.setState({ contract: { ...this.state.contract, specialRestrictions } })
    }

    perContainerChargeOnChange = (name, value) => {
        let nameAndIndex = name.split(":");
        let trueName = nameAndIndex[0];
        let index = nameAndIndex[1];
        let charges = this.state.contract.charges.perContainerCharges;
        charges[index][trueName] = value;

        this.setState({ contract: { ...this.state.contract, charges: { ...this.state.contract.charges, charges } } })
    }

    additionalChargeOnChange = (name, value) => {
        let nameAndIndex = name.split(":");
        let trueName = nameAndIndex[0];
        let index = nameAndIndex[1];
        let charges = this.state.contract.charges.additionalCharges;
        charges[index][trueName] = value;

        this.setState({ contract: { ...this.state.contract, charges: { ...this.state.contract.charges, charges } } })
    }

    freetimeOnChange = (name, value) => {
        let nameAndIndex = name.split(":");
        let trueName = nameAndIndex[0];
        let index = nameAndIndex[1];
        let freetime = this.state.contract.freetime;
        freetime[index][trueName] = value;

        let demType = freetime[index].demurrageTimeType;
        let detType = freetime[index].detentionTimeType;

        if (trueName === "demurrageTimeType" && (value === "COMBINED BUSINESS" || value === "COMBINED CALENDAR")) {
            freetime[index].detentionTimeType = value;
            freetime[index].detentionDays = freetime[index].demurrageDays;
        } else if (trueName === "detentionTimeType" && (value === "COMBINED BUSINESS" || value === "COMBINED CALENDAR")) {
            freetime[index].demurrageTimeType = value;
            freetime[index].demurrageDays = freetime[index].detentionDays;
        }
        if (freetime[index].detentionTimeType === "COMBINED BUSINESS" || freetime[index].detentionTimeType === "COMBINED CALENDAR"
            || freetime[index].demurrageTimeType === "COMBINED BUSINESS" || freetime[index].demurrageTimeType === "COMBINED CALENDAR") {
            if (trueName === "detentionDays") {
                freetime[index].demurrageDays = value;
            } else if (trueName === "demurrageDays") {
                freetime[index].detentionDays = value;
            }
        }

        if (trueName === "demurrageTimeType") {
            if (value !== "COMBINED BUSINESS" && value !== "COMBINED CALENDAR" && (detType === "COMBINED BUSINESS" || detType === "COMBINED CALENDAR")) {
                freetime[index].detentionTimeType = value;
            }
        } else if (trueName === "detentionTimeType") {
            if (value !== "COMBINED BUSINESS" && value !== "COMBINED CALENDAR" && (demType === "COMBINED BUSINESS" || demType === "COMBINED CALENDAR")) {
                freetime[index].demurrageTimeType = value;
            }
        }

        this.setState({ contract: { ...this.state.contract, freetime } })
    }

    salesRepOnChange = (name, value) => {
        let nameAndIndex = name.split(':');
        let trueName = nameAndIndex[0];
        let index = nameAndIndex[1];
        let contractSalesReps = this.state.contract.contractSalesReps;
        contractSalesReps[index][trueName] = value;

        this.setState({ contract: { ...this.state.contract, contractSalesReps } })
    }

    initTabs = () => {
        M.Tabs.init(document.getElementById("tabs"));
    }

    initActionButton = () => {
        M.FloatingActionButton.init(document.querySelectorAll(".fixed-action-btn"));
    }

    initPrintModal = () => {
        if (document.getElementById("rate-print-modal")) {
            M.Modal.init(document.getElementById("rate-print-modal"))
        } else {
            setTimeout(() => {
                this.initPrintModal();
            }, 500)
        }
    }

    initListener = () => {
        document.addEventListener("keydown", this.escListener);
    }

    removeListener = () => {
        document.removeEventListener("keydown", this.escListener);
    }

    escListener = (e) => {
        if (e.keyCode === 27) {

            this.setState({ loading: true }, () => {
                e.preventDefault();
                let modals = document.querySelectorAll(".modal");
                let closeModals = false;

                modals.forEach(m => { if (M.Modal.getInstance(m) && M.Modal.getInstance(m).isOpen) { closeModals = true } })

                if (closeModals) {
                    modals.forEach(m => {
                        if (M.Modal.getInstance(m).isOpen) {
                            M.Modal.getInstance(m).close();
                        }
                    })
                } else {
                    this.props.dispatch({
                        type: urlActions.UPDATE_URL, payload: {
                            url: "/contract", state: { filters: this.props.url.state.filters }
                        }
                    })
                }
            })
        }

    }

    clickListener = () => {
        document.querySelector(".contractStatus").classList.remove("menu-selected");
        this.setState({ showStatusMenu: false }, () => document.removeEventListener("click", this.clickListener))
    }

    showContractModal = () => {
        M.Modal.getInstance(document.querySelector("#contract-modal")).open();
    }

    hideContractModal = () => {
        M.Modal.getInstance(document.querySelector("#contract-modal")).close();
    }

    handleStatusMenu = (e, status) => {
        e.preventDefault();
        this.setState({ showStatusMenu: true, x: e.pageX, y: e.pageY });

        document.addEventListener("click", this.clickListener);
    }

    sort = (e) => {

        const value = typeof e === "string" ? this.props.url.state.currentSort : e.target.getAttribute('data-sort');
        const arr = value.split(".");
        const name = arr
            .join(" ")
            .replace(/[a-z][A-Z]/g, x => x.split("").join(" "))
            .replace(/^\w|\s\w/g, x => x.toUpperCase());
        const reverse =
            this.state.currentSort === value ? -1 * this.state.reverse : 1;
        const freetime = JSON.parse(JSON.stringify(this.state.contract.freetime));

        const getValue = (obj, arr, index) => {
            if (!index) index = 0;
            if (index === arr.length - 1) return obj[arr[index]];
            else return getValue(obj[arr[index]], arr, index + 1);
        };
        freetime.sort((a, b) => {
            if (getValue(a, arr) < getValue(b, arr)) return -1 * reverse;
            if (getValue(a, arr) > getValue(b, arr)) return 1 * reverse;

            return 0;
        });
        this.setState({ currentSort: value, reverse, contract: { ...this.state.contract, freetime } }, () =>
            this.props.dispatch({
                type: globalToastActions.UPDATE_MSG,
                payload: { msg: `${reverse === 1 ? "S" : "Reverse s"}orted by ${name}` }
            })
        );

    }


    generateRateMatrix = () => {

        let rateMatrixParams = this.state.rateMatrixParams;
        rateMatrixParams.timezone = moment.tz.guess();

        axios.get("/api/v1/report/ratematrix", { params: { ...auth.getAuthData(), rateMatrixParams } }).then(result => {
            if (result.data === "NOT AUTHENTICATED") {
                localStorage.clear();
                this.props.dispatch({ type: "SCORCHED_EARTH" });
                return;
            }
            if (!result.data.length) {
                this.props.dispatch({ type: globalToastActions.UPDATE_MSG, payload: { msg: "No Rates", type: "warning" } });
                return;
            }

            const win = window.open('', '', 'width=960 height=1080');
            win.document.write(result.data);

        })
    }

    generateChargeReport = () => {

        let reportParams = this.state.rateMatrixParams;

        axios.get("/api/v1/report/chargereport", { params: { ...auth.getAuthData(), reportParams } }).then(result => {
            if (result.data === "NOT AUTHENTICATED") {
                localStorage.clear();
                this.props.dispatch({ type: "SCORCHED_EARTH" });
                return;
            }
            if (!result.data.length) {
                this.props.dispatch({ type: globalToastActions.UPDATE_MSG, payload: { msg: "No Charges", type: "warning" } });
                return;
            }

            const win = window.open('', '', 'width=960 height=1080');
            win.document.write(result.data);

        })
    }

    getContract = async () => {

        this.setState({ loading: true }, async () => {

            let timezone = moment.tz.guess();
            let { shipperId, carrierId, contractSearchTerm } = this.props.url.state;
            let commodityId = this.state.contract.contractDetails.commodityId;

            await axios.get("/api/v1/contract/edit", { params: { ...auth.getAuthData(), shipperId, carrierId, commodityId, contractSearchTerm, timezone } }).then(async result => {
                if (result.data === "NOT AUTHENTICATED") {
                    localStorage.clear();
                    this.props.dispatch({ type: "SCORCHED_EARTH" });
                    return;
                }

                let carrierSalesReps = result.data.carrierSalesReps.length ? result.data.carrierSalesReps : [{ id: 0, name: "No Carrier Contacts" }];
                let contract = result.data.contract;
                let countries = [];
                let portPairs = [];
                let { origins, destinations } = result.data;

                if (contract.rates.length) {

                    if (this.state.flipSort) {
                        destinations = destinations.sort((a, b) => (a.countryName > b.countryName) - (a.countryName < b.countryName))
                    }

                    countries = await this.props.country.filter(c => origins.map(o => o.countryId).includes(c.id) || destinations.map(d => d.countryId).includes(c.id));
                    portPairs = await this.generatePairMatrix(origins, destinations, contract.rates, contract);

                }

                if (!contract.contractDetails.id) {
                    this.setState({ loading: false });
                    return this.props.dispatch({ type: globalToastActions.UPDATE_MSG, payload: { msg: "No Results", type: "warning" } });
                } else {
                    this.setState({ contract, loading: false, origins, destinations, countries, portPairs, carrierSalesReps, flipSort: false })
                }
            })
        })
    }

    copyContract = () => {

        if (!this.state.contract.contractDetails.id) {
            return this.props.dispatch({ type: globalToastActions.UPDATE_MSG, payload: { msg: "No Contract To Duplicate", type: "error" } })
        }

        this.setState({ overWrite: true });
        this.showContractModal();
    }

    makeDetailsActive = () => {
        this.setState({ editingDetails: true });
    }

    makeDetailsInactive = () => {
        this.setState({ editingDetails: false }, () => this.saveContractDetails)
    }

    saveContractDetails = () => {
        let contractDetails = this.state.contract.contractDetails;
        axios.post("/api/v1/contract/details", { ...auth.getAuthData(), contractDetails }).then(result => {
            if (result.data === "NOT AUTHENTICATED") {
                localStorage.clear();
                this.props.dispatch({ type: "SCORCHED_EARTH" });
                return;
            }
            if (result.data.errno) {
                this.props.dispatch({
                    type: globalToastActions.UPDATE_MSG, payload: { type: "error", msg: "Error Saving" }
                });
            } else {
                this.props.dispatch({
                    type: globalToastActions.UPDATE_MSG, payload: { type: "success", msg: "Save Succesful" }
                });
                this.getContract();
            }
        })
    }

    getEmployees = () => {
        let employees = [];
        let reduxUsers = this.props.redux.portalUser;
        let i = 0;
        while (i < reduxUsers.length) {
            employees.push({ name: reduxUsers[i].first + " " + reduxUsers[i].last, id: reduxUsers[i].id });
            i++
        }
        this.setState({ employees })
    }

    generatePairMatrix = async (origins, destinations, rates, contract) => {
        let portPairs = [];

        origins.forEach((o, index) => {
            destinations.forEach((d) => portPairs.push({
                contractId: contract.contractDetails.id,
                originId: o.id,
                destId: d.id,
                amount: ""
            }))
        });

        portPairs.forEach((p, index) => {
            rates.forEach(r => {
                if (r.originId === p.originId && r.destId === p.destId && p.contractId === r.contractId) {
                    portPairs.splice(index, 1, r);
                }
            });
        });
        return portPairs;
    }

    sortByCountry = () => {
        let origins = this.state.origins;
        let destinations = this.state.destinations;

        if (!this.state.flipSort) {
            origins = origins.sort((a, b) => (a.countryName > b.countryName) - (a.countryName < b.countryName));
            destinations = destinations.sort((a, b) => (a.countryName > b.countryName) - (a.countryName < b.countryName));
        } else {
            origins = origins.sort((a, b) => (a.name > b.name) - (a.name < b.name));
            destinations = destinations.sort((a, b) => (a.name > b.name) - (a.name < b.name));
        }

        this.setState({ flipSort: !this.state.flipSort, origins, destinations })
    }

    //IN ORDER TO SORT BY COUNTRY, FIRST NEED TO FILTER BY COUNTRY ID TO GET COUNTRY NAME
    addOriginOrDest = async (type, location) => {

        if (type === "o") {
            let origins = this.state.origins;

            let port = this.props.location.filter(l => l.id === location)[0]
            origins.push(port);
            origins.sort((a, b) => (a.name > b.name) - (a.name < b.name));
            let portPairs = await this.generatePairMatrix(origins, this.state.destinations, this.state.contract.rates, this.state.contract);

            this.setState({ origins, portPairs });
        } else {
            let destinations = this.state.destinations;
            let port = this.props.location.filter(l => l.id === location)[0]
            destinations.push(port);
            destinations.sort((a, b) => (a.name > b.name) - (a.name < b.name));
            let portPairs = await this.generatePairMatrix(this.state.origins, destinations, this.state.contract.rates, this.state.contract);
            this.setState({ destinations, portPairs });

        }
    }

    navigateLineage = (parentOrChild) => {

        this.setState({ loading: true }, () => {

            let lineageDirection = parentOrChild;

            let childContractInfo = {
                shipperId: this.state.contract.contractDetails.shipperId,
                carrierId: this.state.contract.contractDetails.carrierId,
                commodityId: this.state.contract.contractDetails.commodityId,
                containerTypeId: this.state.contract.contractDetails.containerTypeId,
                status: this.state.contract.contractDetails.status,
                effectiveDate: moment(this.state.contract.contractDetails.effectiveDate, "MM/DD/YY").format("YYYY-MM-DD"),
                expirationDate: moment(this.state.contract.contractDetails.expirationDate, "MM/DD/YY").format("YYYY-MM-DD"),
                timezone: moment.tz.guess(),
                lineageDirection
            }

            axios.get(`/api/v1/contract/${lineageDirection}/`, { params: { ...auth.getAuthData(), childContractInfo } }).then(result => {
                if (result === "NOT AUTHENTICATED") {
                    localStorage.clear();
                    this.props.dispatch({ type: "SCORCHED_EARTH" });
                    return;
                }

                if (result.data === "No Contract") {
                    let type = parentOrChild.charAt(0).toUpperCase() + parentOrChild.substring(1)
                    this.props.dispatch({ type: globalToastActions.UPDATE_MSG, payload: { msg: `No ${type} Contract Available`, type: "error" } });
                    this.setState({ loading: false });
                    return;
                } else {

                    let contract = result.data.contract;

                    axios.get("/api/v1/contract/carriers", { params: { ...auth.getAuthData(), shipperId: this.state.contract.contractDetails.shipperId, carrierId: this.state.contract.contractDetails.carrierId, contractId: this.state.contract.contractDetails.id, commodityId: this.state.contract.contractDetails.commodityId, containerTypeId: this.state.contract.contractDetails.containerTypeId } }).then(result => {
                        if (result === "NOT AUTHENTICATED") {
                            localStorage.clear();
                            this.props.dispatch({ type: "SCORCHED_EARTH " });
                        } else {

                            let contracts = result.data.length ? result.data : [{ carrierName: "NO OTHER CONTRACTS" }];

                            this.props.dispatch({
                                type: urlActions.UPDATE_URL, payload: {
                                    url: '/contract/edit', state: {
                                        origin: "contractOverview", contractSearchTerm: contract.contractDetails.id, shipperId: contract.contractDetails.shipperId,
                                        filters: this.props.filters, status: contract.contractDetails.status, carrierId: contract.contractDetails.carrierId, carrierName: contract.contractDetails.carrierName, contracts
                                    }
                                }
                            })

                        }

                    })
                }
            })

        })

    }


    changeStatus = (status) => {
        let contractDetails = { ...this.state.contract.contractDetails };
        let contractId = this.state.contract.contractDetails.id;
        contractDetails.status = status;
        axios.post("/api/v1/contract/details", { ...auth.getAuthData(), contractDetails }).then(result => {
            if (result.data === "NOT AUTHENTICATED") {
                localStorage.clear();
                this.props.dispatch({ type: "SCORCHED_EARTH" });
                return;
            }
            if (status === "ACTIVE" && this.state.contract.contractDetails.status !== "ACTIVE") {

                let sortedRates = this.state.portPairs.reduce((result, element, i) => {
                    if (!!element.amount) {
                        result[0].push(element)
                    } else if (!element.amount && !!element.id) {
                        result[1].push(element);
                    }
                    return result;
                }, [[], []]);

                axios.post("/api/v1/contract/rate/edit/all", { ...auth.getAuthData(), sortedRates, contractId }).then(result => {
                    this.getContract(true, status);
                })
            } else {
                this.getContract(true, status);
            }

        });
    }

    componentDidMount = () => {
        this.initTabs();
        this.initListener();
        this.initActionButton();
        this.getContract();
        this.initPrintModal();
    }

    componentDidUpdate = (prevProps, prevState) => {
        if (prevProps.url.state.contractSearchTerm !== this.props.url.state.contractSearchTerm) {
            this.getContract();
        }
        if (prevState.loading !== this.state.loading && this.state.loading === false) {
            this.initTabs();
        }

    }

    componentWillUnmount = () => {
        this.removeListener();
    }


    render = () => {
        return (

            <div style={{ position: "relative" }}>
                {this.state.loading ?
                    <div className={styles.transparentSpinner}>
                        <LoadingSpinner className={styles.contractLoadingSpinner} size="big" color="blue" />
                    </div> : null}

                <div className="row">
                    {this.state.contract.contractDetails.status === "CLOSED" ? <div className="closedStamp" unselectable="on">CLOSED</div> : null}
                    <div className="col s12">
                        <div className={`card-panel ${styles.contractCard} contractEditHeader row`}>
                            {this.state.contract.contractDetails.parentContracts ?
                                <div className="col s1 contractLineageButtonContainer">

                                    <i className="material-icons contractLineageButton" onClick={() => this.navigateLineage("parent")}>navigate_before</i><br></br>
                                    <span className="childParentContractCount">{this.state.contract.contractDetails.currentContractPosition} of {this.state.contract.contractDetails.totalOtherContracts}</span>
                                </div> :
                                <i className="col s1 contractLineageButton"></i>
                            }
                            <div className="col s3">
                                <div className="row">
                                    <div className="col s11" style={{ color: "orange" }}>
                                        {this.state.editingDetails ?
                                            this.state.contract.contractDetails.shipperName
                                            :
                                            <Form.Select className="contractShipperDropdown" labelClassName="contractShipperDropdownLabel" name="shipperId" value={this.state.contract.contractDetails.shipperId} label="Shipper" list={this.props.shipper} onChange={this.contractDetailsOnChange} filter={{ label: "name", value: "id" }} />
                                        }
                                    </div>
                                    <div className="col s1">
                                        <i className="material-icons" style={{ marginTop: "5px", float: "left", color: "orange" }}>{this.state.contract.contractDetails.isManaged && this.state.contract.contractDetails.isSigned ?
                                            'star' : this.state.contract.contractDetails.isManaged && !this.state.contract.contractDetails.isSigned ?
                                                'star_half' : "star_border"}
                                        </i>
                                    </div>
                                    <div className="col s12">

                                        {this.state.editingDetails ?
                                            <Form.TextInput stylingClass="contractEditInputs" labelClassName="contractEditInputsLabel" label="Contract Number" name="contractNumber" value={this.state.contract.contractDetails.contractNumber} onChange={this.contractDetailsOnChange} />
                                            :
                                            `Contract Number: ${this.state.contract.contractDetails.contractNumber}`
                                        }
                                    </div>
                                    <div className="col s12">
                                        Commodity: {this.state.contract.contractDetails.commodity}
                                    </div>
                                    <div className="col s12">
                                        Container Type: {this.state.contract.contractDetails.containerType}
                                    </div>
                                    <div className="col s12">
                                        {this.state.editingDetails ?
                                            <Form.TextInput stylingClass="contractEditInputs" labelClassName="contractEditInputsLabel" label="MQC" name="minimumQuantityCommitment" value={this.state.contract.contractDetails.minimumQuantityCommitment} onChange={this.contractDetailsOnChange} />
                                            :
                                            `MQC: ${this.state.contract.contractDetails.minimumQuantityCommitment}/${this.state.contract.contractDetails.enteredMQC}`
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="col s4" style={{ textAlign: "center" }}>
                                <div className="row" style={{ marginBottom: "0px" }}>
                                    <div className="col s12" style={{ marginBottom: "0px", color: "orange" }}>
                                        <h3 style={{ marginTop: "0px" }}>{this.state.contract.contractDetails.carrierName}</h3>
                                    </div>
                                    <div className="row contractDates ">
                                        <div>
                                            Effective Date
                                        </div>
                                        <div className="dateMargins">

                                        </div>
                                        <div>
                                            Expiration Date
                                        </div>
                                    </div>
                                    <div className="row contractDates dateLabels">
                                        <div>
                                            {this.state.contract.contractDetails.effectiveDate}
                                        </div>
                                        <div className="dateMargins">
                                            -
                                        </div>
                                        <div>
                                            {this.state.contract.contractDetails.expirationDate}
                                        </div>
                                    </div>
                                    <div onContextMenu={e => this.handleStatusMenu(e, true)} className={`row contractStatus ${this.state.contract.contractDetails.status ? this.state.contract.contractDetails.status.split(" ").join("") : null}`} >
                                        {this.state.contract.contractDetails.status}
                                    </div>
                                </div>
                            </div>
                            <div className="col s3">
                                <div className="row">
                                    <div className="col s3" style={{ position: "relative", color: "orange", marginTop: "5px" }}>
                                        {this.state.editingDetails ?
                                            <i className="material-icons" onClick={this.makeDetailsInactive}>save</i>
                                            :
                                            <i className="material-icons" onClick={this.makeDetailsActive}>edit</i>
                                        }
                                    </div>
                                    <Form.Button col="col s3 right" label='Print' color="black" onClick={this.showPrintModal} type="outline" />
                                    <div className="col s12">
                                        {this.state.editingDetails ?
                                            <div>
                                                <Form.CheckBox focusStyle="contractEditCheckBox" label="Use Percentage" value={this.state.contract.contractDetails.commissionUsePercentage} name={"commissionUsePercentage"} onChange={this.contractDetailsOnChange} />
                                                <Form.TextInput stylingClass="contractEditInputs" labelClassName="contractEditInputsLabel" label="Commission" value={this.state.contract.contractDetails.commissionAmount} name={"commissionAmount"} onChange={this.contractDetailsOnChange} />
                                            </div>
                                            :
                                            `Commission: ${this.state.contract.contractDetails.commissionUsePercentage ? "" : "$"}${this.state.contract.contractDetails.commissionAmount}${this.state.contract.contractDetails.commissionUsePercentage ? "%" : "/container"}`
                                        }
                                    </div>
                                    <div className="col s12">
                                        Created By: {this.state.contract.contractDetails.createdByName} -- {this.state.contract.contractDetails.datetimeCreated}
                                    </div>
                                    <div className="col s12">
                                        Modified By: {this.state.contract.contractDetails.lastModifiedByName} -- {this.state.contract.contractDetails.datetimeModified}
                                    </div>
                                </div>
                            </div>
                            {this.state.contract.contractDetails.childContracts ?
                                <div className="col s1 contractLineageButtonContainer">
                                    <i className="material-icons contractLineageButton" onClick={() => this.navigateLineage("child")}>navigate_next</i><br></br>
                                    <span className="childParentContractCount">{this.state.contract.contractDetails.currentContractPosition} of {this.state.contract.contractDetails.totalOtherContracts}</span>
                                </div> :
                                <i className="col s1 contractLineageButton"></i>
                            }
                        </div>
                    </div>
                    <div>
                        <div className="col s12">
                            <ul id="tabs" className="tabs">
                                <li className="tab col s6"><a href={`#RATES`} >Rates</a></li>
                                <li className="tab col s6"><a href={`#FREETIME`}>Free Time / Sales Reps</a></li>
                            </ul>
                        </div>
                        <div id={`RATES`} className="col s12">
                            <div className="row">
                                <div className="col s12 m12 l8">
                                    <RatesDisplay
                                        contract={this.state.contract}
                                        origins={this.state.origins}
                                        destinations={this.state.destinations}
                                        portPairs={this.state.portPairs}
                                        flipSort={this.state.flipSort}
                                        ratesOnChange={this.ratesOnChange}
                                        getContract={this.getContract}
                                        addOriginOrDest={this.addOriginOrDest}
                                        sortByCountry={this.sortByCountry} />
                                </div>
                                <div className="col s12 m12 l4">
                                    <FreightChargesDisplay
                                        contract={this.state.contract}
                                        getContract={this.getContract}
                                        perContainerChargeOnChange={this.perContainerChargeOnChange}
                                        additionalChargeOnChange={this.additionalChargeOnChange}
                                        origins={this.state.origins}
                                        destinations={this.state.destinations} />
                                </div>
                                <div className="col s12">
                                    <SpecialRestrictionsDisplay
                                        contract={this.state.contract}
                                        origins={this.state.origins}
                                        destinations={this.state.destinations}
                                        getContract={this.getContract}
                                        specialRestOnChange={this.specialRestOnChange} />
                                </div>
                                <div className="col s12">
                                    <Form.TextArea label="Contract Notes" name="note" value={this.state.contract.contractDetails.note} onChange={this.contractDetailsOnChange} onBlur={this.saveContractDetails} />
                                </div>
                            </div>
                        </div>
                        <div id={`FREETIME`} className="col s12">
                            <div className="row">
                                <div className="col s12">
                                    <FreetimeDisplay contract={this.state.contract} origins={this.state.origins} destinations={this.state.destinations} countries={this.state.countries} getContract={this.getContract} freetimeOnChange={this.freetimeOnChange} sort={this.sort} />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col s12">
                                    <SalesRepDisplay contract={this.state.contract} getContract={this.getContract} carrierSalesReps={this.state.carrierSalesReps} salesRepOnChange={this.salesRepOnChange} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <NewContractModal contract={this.state.contract} portPairs={this.state.portPairs} origins={this.state.origins} destinations={this.state.destinations} overWrite={this.state.overWrite} />
                <div style={{ position: 'fixed', top: this.state.y, left: this.state.x }} className="lf-shipment-context-menu">
                    <ul className="collection">
                        {this.state.showStatusMenu && <li onClick={() => this.changeStatus('ACTIVE')} className="collection-item green-text">ACTIVE</li>}
                        {this.state.showStatusMenu && <li onClick={() => this.changeStatus('PENDING REVIEW')} className="collection-item purple-text">PENDING REVIEW</li>}
                        {this.state.showStatusMenu && <li onClick={() => this.changeStatus('UPCOMING')} className="collection-item blue-text">UPCOMING</li>}
                        {this.state.showStatusMenu && <li onClick={() => this.changeStatus('PENDING UPCOMING')} className="collection-item purple-text">PENDING UPCOMING</li>}
                        {this.state.showStatusMenu && <li onClick={() => this.changeStatus('CLOSED')} className="collection-item grey-text">CLOSED</li>}
                        {this.state.showStatusMenu && <li onClick={() => this.changeStatus("ARCHIVED")} className="collection-item black-text">ARCHIVE</li>}
                    </ul>
                </div>
                {/* PRINT MODAL */}
                <div className={`modal`} id="rate-print-modal">
                    <div className="modal-content" style={{ paddingTop: "12px" }}>
                        <div className="row" style={{ marginBottom: "12px" }}>
                            <div className="col s6">
                                <h4 style={{ marginBottom: "0px", textAlign: "center" }}>Print Rate Matrix</h4>
                            </div>
                        </div>
                        <div className="row">
                            <Form.Select col="col s3" label="Shipper" name="shipperId" value={this.state.rateMatrixParams.shipperId} placeholder={this.state.rateMatrixParams.reportType !== "rateMatrix" ? "ANY" : null} onChange={this.rateMatrixOnChange} list={this.props.shipper} filter={{ label: "name", value: "id" }} flags={[flags.ALLOW_EMPTY]} />
                            <Form.Select col="col s3" label="Carrier" name="carrierId" value={this.state.rateMatrixParams.carrierId} placeholder="ANY" onChange={this.rateMatrixOnChange} list={this.props.carrier} filter={{ label: "code", value: "id" }} flags={[flags.ALLOW_EMPTY]} />
                            <Form.Select
                                col="col s3"
                                label="Origin"
                                name="originId"
                                value={this.state.rateMatrixParams.originId}
                                placeholder="ANY"
                                onChange={this.rateMatrixOnChange}
                                list={this.props.location.filter(l => (l.countryId === 1 || l.countryId === 17) && l.typeId !== 3).sort((a, b) => a.name.localeCompare(b.name))}
                                filter={{ label: "name", value: "id" }}
                                flags={[flags.ALLOW_EMPTY]} />
                            <Form.Select col="col s3" label="Fees" name="feeDisplay" value={this.state.rateMatrixParams.feeDisplay} disabled={this.state.rateMatrixParams.reportType !== "rateMatrix" ? true : false} onChange={this.rateMatrixOnChange} list={[{ label: "BASE" }, { label: "BASE/FEE" }, { label: "COMBINED" }]} filter={{ label: "label", value: "value" }} flags={[flags.ALLOW_EMPTY]} />
                        </div>
                        <div className="row">
                            <div className="col s3">
                                <Form.Radio col="col s3" label="Rate Matrix" name="reportType" value={"rateMatrix"} onChange={this.rateMatrixOnChange} checked={this.state.rateMatrixParams.reportType === 'rateMatrix'} />
                            </div>
                            <div className="col s3">
                                <Form.Radio col="col s3" label="Charge Types" name="reportType" value="chargeTypes" onChange={this.rateMatrixOnChange} />
                            </div>
                            <Form.Select
                                col="col s3"
                                label="Dest"
                                name="destId"
                                value={this.state.rateMatrixParams.destId}
                                placeholder="ANY"
                                disabled={parseInt(this.state.rateMatrixParams.destCountryId) !== 0 || this.state.rateMatrixParams.reportType !== "rateMatrix" ? true : false}
                                onChange={this.rateMatrixOnChange}
                                list={this.props.location.filter(l => l.countryId !== 17 && l.countryId !== 1 && l.typeId !== 3).sort((a, b) => a.name.localeCompare(b.name))}
                                filter={{ label: "name", value: "id" }}
                                flags={[flags.ALLOW_EMPTY]}
                            />
                            <Form.Select col="col s3" label="Dest Country" name="destCountryId" value={this.state.rateMatrixParams.destCountryId} placeholder="ANY" disabled={parseInt(this.state.rateMatrixParams.destId) !== 0 || this.state.rateMatrixParams.reportType !== "rateMatrix" ? true : false} onChange={this.rateMatrixOnChange} list={this.props.country.filter(c => c.id !== 1 && c.id !== 17).sort((a, b) => a.name.localeCompare(b.name))} filter={{ label: "name", value: "id" }} flags={[flags.ALLOW_EMPTY]} />
                        </div>
                        <div className="row">
                            <Form.Button col="col s3 offset-s9" label='Print!' color="black" onClick={this.state.rateMatrixParams.reportType === "rateMatrix" ? this.generateRateMatrix : this.generateChargeReport} type="outline" />
                        </div>
                    </div>
                </div>
                {/* FLOATING ACTION BUTTON FOR NEW CONTRACT CREATION */}
                <div className="fixed-action-btn">
                    <button className="btn-floating btn-large blue" onClick={this.copyContract}>
                        <i className="large material-icons">add</i>
                    </button>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    const { navs, urls, index, party, shipper, carrier, location, containerType, serviceRoute, country, portalUser, user } = state;
    const shipperId = navs[index].shipperId;
    const url = urls[index];
    return { shipperId, party, shipper, carrier, location, containerType, serviceRoute, country, portalUser, url, user };
}

export default connect(mapStateToProps)(ContractEdit);
import React from 'react';
import styles from './textinput.module.css';
import { types } from '../../constants';
import { inputValidation } from '../../helperFunctions';
import MaskedInput from 'react-text-mask';



class MaskInput extends React.Component {
    constructor(props) {
        super(props); this.state = this.initState()
    }

    UNSAFE_componentWillReceiveProps = props => this.setState({value: props.value})

    initState = () => ({ value: this.props.value || '', err: null })
    onChange = e => {
        const value = e.target.value;
        this.setState({ value }, () => this.props.onChange(this.props.name, value));
    }

    getDivClassName = () => {
        return this.props.col ? `col ${this.props.col} ${styles.div}` : styles.div;
    }

    genInputClass = () => {
        return `browser-default ${styles.input} ${this.state.err ? styles.err : ''} ${this.props.disabled ? styles.disabled : ''}`;
    }

    getInputErrInfo = () => ([{ name: 'value', type: types.STRING, value: this.props.value, flags: this.props.flags }])

    onBlur = () => {
        inputValidation(this.getInputErrInfo(), this.success, this.failure);
        this.setState(p => ({value: p.value ? p.value.toString().toUpperCase() : p.value}), () => this.props.onChange(this.props.name, this.state.value ? this.state.value.toString().toUpperCase() : ''))
    }
    success = value => this.setState({ err: null }, this.props.onBlur);
    failure = value => this.setState({ err: { msg: value.value.msg } }, this.props.onBlur);

    render = () => (
        <div className={this.getDivClassName()}>
            <MaskedInput
                type="text"
                className={this.genInputClass()}
                value={this.props.value}
                onChange={this.onChange}
                onBlur={this.onBlur}
                id={this.props.name}
                disabled={this.props.disabled}
                placeholder={this.props.placeholder}
                mask={this.props.mask?this.props.mask:[/^[a-zA-Z0-9_.-]$/]}
            />
            <label className={`${this.props.labelStyle?this.props.labelStyle:styles.label}`}>{this.props.label} {this.props.required ? '*' : ''}</label>
            {this.state.err ? <span className={styles.msg}>{this.state.err.msg}</span> : null}
        </div>
    )
}

export default MaskInput;
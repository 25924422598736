import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import ShipmentOverview from './ShipmentOverview';
import CreateBooking from './CreateBooking/CreateBooking';
import ShipmentInstructions from './ShipmentInstructions';
import Split from './CreateBooking/Split';
import BookingNumberEditor from './CreateBooking/BookingNumberEditor';

class Shipments extends React.Component {
    getPath = () => ('/shipment');
    // rebuildNav = () => this.props.dispatch({ type: sidebarActions.REBUILD_NAV, payload: { url: this.getPath() } });
    // componentDidMount = () => this.rebuildNav();

    render = () => (
        <Switch>
            <Route exact path={this.getPath()} component={ShipmentOverview} />
            <Route exact path={this.getPath() + '/create/:bookingNumber'} component={CreateBooking} />
            <Route exact path={this.getPath() + '/create/'} component={CreateBooking} />
            <Route exact path={this.getPath() + '/instructions'} component={ShipmentInstructions} />
            <Route exact path={this.getPath() + '/split'} component={Split} />
            <Route exact path={this.getPath() + '/bookingnumber/edit'} component={BookingNumberEditor} />
        </Switch>
    )
}

export default connect()(Shipments);

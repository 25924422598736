import React, { Fragment } from "react";
import { connect } from "react-redux";
import styles from "./Requests.module.css";

const RequestResultsDisplay = (props) => {

    return (
        <Fragment>
            {props.loading ?
                <tr>
                    <td>
                        <div className="preloader-wrapper big active overviewLoader">
                            <div className="spinner-layer spinner-blue-only">
                                <div className="circle-clipper left">
                                    <div className="circle"></div>
                                </div><div className="gap-patch">
                                    <div className="circle"></div>
                                </div><div className="circle-clipper right">
                                    <div className="circle"></div>
                                </div>
                            </div>
                        </div>
                    </td>
                </tr>
                :
                props.requests.map((req, index) => (
                    <tr
                        onClick={(e) => props.editRequest(e, req, index)}
                        tabIndex="0"
                        className={`resultsDisplay req-tr-${index} ${styles.requestResultRow} ${!req.hasBeenRead && req.assignedTo === props.user.id ? styles.unread : ''} ${req.requiresAttention ? styles.requiresAttention : ''} ${index === props.editRequestIndex ? styles.editRequestIndex : ""}`}
                        id={`${req.id}`}
                        key={index}
                        onFocus={index === 0 ? props.onFocusReset : function(){}}
                        onMouseDown={(e) => props.handleMouseDownHighlight(e)}
                    >
                        <td className={`${styles.notificationIcon}`} onClick={(e) => props.editRequest(e, req, index)} ><i className={`material-icons`}>{req.hasReminder}</i></td>
                        <td className={styles[req.status]} onClick={(e) => props.editRequest(e, req, index)} >{req.status}</td>
                        <td onClick={(e) => props.editRequest(e, req, index)}>{req.statusMemo}</td>
                        <td onClick={(e) => props.editRequest(e, req, index)}>{req.datetimeRequestedOverview}</td>
                        {props.user.is_employee || props.user.isEmployee ?
                            <td className="tooltip" onClick={(e) => props.editRequest(e, req, index)}>
                                {req.bookingRequestComment ?
                                    <span className="tooltiptext">
                                        <div className={styles.toolTipCommentContainer}>
                                            <span className={styles.toolTipCommentAuthor}>{JSON.parse(req.bookingRequestComment).user} {JSON.parse(req.bookingRequestComment).datetimePosted}</span>
                                            <span className={styles.toolTipComment}>{JSON.parse(req.bookingRequestComment).comment}</span>
                                        </div>
                                    </span>
                                    :
                                    null}
                                {req.assignedToFirstName}
                            </td> :
                            <td onClick={(e) => props.editRequest(e, req, index)}>{req.assignedToFirstName}</td>
                        }
                        <td onClick={(e) => props.editRequest(e, req, index)}>{req.bookingRequestNumber}</td>
                        <td onClick={(e) => props.editRequest(e, req, index)}>{req.shipperCode}</td>
                        <td onClick={(e) => props.editRequest(e, req, index)}>{req.carrierCode}</td>
                        <td onClick={(e) => props.editRequest(e, req, index)}>{req.containerCount}x{req.containerType}</td>
                        <td onClick={(e) => props.editRequest(e, req, index)}>{req.originCode}</td>
                        <td onClick={(e) => props.editRequest(e, req, index)}>{req.destCode}</td>
                        <td className="tooltip" onClick={(e) => props.editRequest(e, req, index)} style={req.dateType !== "CUT" ? { color: "red" } : { color: 'black' }}>
                            <span className="tooltiptextdate">
                                {req.startDateYear} {req.endDateYear}
                            </span>
                            {req.overviewDateInfo}
                        </td>
                        <td onClick={(e) => props.editRequest(e, req, index)}>{req.shipperReferenceNumber}</td>
                        <td onClick={(e) => props.editRequest(e, req, index)}>{req.consigneeName}</td>
                        <td onClick={(e) => props.editRequest(e, req, index)}>{req.overviewNote}</td>
                    </tr>
                ))}
        </Fragment>
    )
}


const mapStateToProps = state => {
    const { location, user, urls, index } = state;
    const url = urls[index];
    return { location, user, url }
}

export default connect(mapStateToProps)(RequestResultsDisplay)
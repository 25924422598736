import React, { Component } from 'react';
import Form from '../../Components/NewComponents';
import { connect } from 'react-redux';
import { globalToastActions } from '../../Redux/actions';
import moment from 'moment';
import M from 'materialize-css';
import Chart from 'chart.js';
import axios from 'axios';
import { auth } from "../../helperFunctions";

class ContainerReport extends Component {
    constructor(props) {
        super(props);
        this.chart = null;
        this.colors = ['#f44336', '#e91e63', '#9c27b0', '#673ab7', '#3f51b5', '#2196f3', '#03a9f4', '#00bcd4', '#009688', '#4caf50', '#8bc34a', '#cddc39', '#ffeb3b', '#ffc107', '#ff9800', '#ff5722']
        this.state = this.initState();
    }

    onChange = (name, value) => this.setState({ [name]: value });


    initState = () => ({
        currentTab: 'cpp',
        shipperId: '0',
        startDate: '',
        endDate: '',
        dummy: 'ALL BOOKINGS',
        status: 'ALL',
        containerList: [],
        sortByLadenOnBoardDate: true,
        reportType: 'cpp'
    })

    componentDidMount = () => {
        M.Modal.init(document.querySelectorAll('.modal'));
    }

    generateReport = () => {
        const reportData = this.getReportData();
        if (!reportData) { return }

        this.setState({ loading: true }, () => {
            axios.get('/api/v1/report/containersbyweek', {
                params: {
                    ...auth.getAuthData(),
                    ...reportData
                }
            })
                .then(result => {
                    if (result.data === "NOT AUTHENTICATED") {
                        localStorage.clear();
                        this.props.dispatch({
                            type: "SCORCHED_EARTH"
                        })
                        return;
                    }

                    if (!result.data)
                        return this.props.dispatch({ type: globalToastActions.UPDATE_MSG, payload: { msg: 'No Results', type: 'info' } })

                    this.setState(p => ({ ...p, ...result.data }), () => {
                        this.generateChart();
                    })
                })
                .catch(err => {
                    if (err.response)
                        this.props.dispatch({ type: globalToastActions.UPDATE_MSG, payload: { msg: 'An unkown error occurred', type: 'error' } })
                })
                .finally(() => this.setState({ loading: false }))
        })
    }

    generateChart = () => {
        const weekList = JSON.parse(JSON.stringify(this.state.weekList));
        let graphData = null;

        switch (this.state.currentTab) {
            case 'cpp':
                graphData = JSON.parse(JSON.stringify(this.state.carrierPortPair.graphData));
                break;
            case 'pld':
                graphData = JSON.parse(JSON.stringify(this.state.placeOfDelivery));
                break;
            case 'cbc':
                graphData = JSON.parse(JSON.stringify(this.state.containersByCountry));
                break;
            case 'comm':
                graphData = JSON.parse(JSON.stringify(this.state.containersByCommodity));
                break;
            default:
                graphData = JSON.parse(JSON.stringify(this.state.carrierPortPair.graphData));
                break;
        }

        const datasets = Object.values(graphData).map((cpp, index) => {
            return {
                label: cpp.label,
                data: cpp.data,
                fill: false,
                backgroundColor: this.colors[index],
                borderColor: this.colors[index]
            }
        })

        if (!this.chart) {
            const ctx = document.querySelector('#chart').getContext('2d');
            this.chart = new Chart(ctx, {
                type: 'line',
                data: {
                    labels: weekList,
                    datasets
                },
                options: {
                    scales: {
                        yAxes: [{
                            ticks: {
                                beginAtZero: true
                            }
                        }]
                    }
                }
            });
        }
        else {
            this.chart.data.labels = weekList;
            this.chart.data.datasets = datasets;
            this.chart.update();
        }
    }

    handleTabClick = e => {
        e.preventDefault();
        const currentTab = e.target.getAttribute('data-ref');
        document.querySelector(".activeTab").classList.remove('activeTab');
        e.target.classList.add('activeTab')
        this.setState({ currentTab }, () => {
            if (this.state.weekList)
                this.generateChart();
        })
    }

    getCurrentTabHTML = () => {
        const { currentTab } = this.state;
        switch (currentTab) {
            case 'cpp':
                return this.rendercpp();
            case 'pld':
                return this.renderpld();
            case 'cbc':
                return this.rendercbc();
            case 'comm':
                return this.renderComm();
            default: 
                return this.rendercpp();
        }

    }

    getPrintables = () => [
        { label: 'Container & Port Pair', value: 'cpp' },
        { label: 'Place of Delivery', value: 'pld' },
        { label: 'Country', value: 'cbc' },
        { label: 'Commodity', value: 'comm' }
    ]

    print = () => {
        const reportData = this.getReportData();
        if (!reportData) { return }

        const shipperName = this.props.shipper.filter(s => s.id === parseInt(this.state.shipperId))[0].name;
        const reportName = this.getPrintables().filter(p => p.value === this.state.reportType)[0].label;

        axios.post(`/api/v1/pages/containersbyweek`, {
            ...auth.getAuthData(),
            ...reportData,
            reportType: this.state.reportType,
            shipperName,
            reportName
        }).then(result => {
            if (result.data === 'NOT AUTHENTICATED') {
                localStorage.clear();
                this.props.dispatch({ type: 'SCORCHED_EARTH' })
                return;
            }

            const win = window.open('', '', 'width=960 height=1080');
            win.document.write(result.data);
        })
    }

    getReportData = () => {
        const startDate = moment(this.state.startDate, 'MM/DD/YY');
        const endDate = moment(this.state.endDate, 'MM/DD/YY');

        if (parseInt(this.state.shipperId) === 0) {
            this.props.dispatch({
                type: globalToastActions.UPDATE_MSG, payload: {
                    msg: "Please Select a Shipper", type: "error"
                }
            })
            return null;
        }
        if ((startDate || endDate) && startDate > endDate) {
            this.props.dispatch({
                type: globalToastActions.UPDATE_MSG, payload: {
                    msg: "Starting Date Must Be Before Ending Date", type: "error"
                }
            })
            return;
        }
        if (Math.abs(startDate.diff(endDate, 'weeks')) > 53) {
            const continueExecution = window.confirm('The chosen dates are more than a year apart, do you want to continue?')
            if (!continueExecution)
                return;
        }


        let reportData = {
            shipperId: this.state.shipperId,
            startDate: this.state.startDate ? startDate.format('YYYY-MM-DD') : '',
            endDate: this.state.endDate ? endDate.format('YYYY-MM-DD') : '',
            sortByLadenOnBoardDate: this.state.sortByLadenOnBoardDate,
            dummies: this.state.dummy,
            status: this.state.status
        }

        return reportData;
    }

    render = () => (
        <div>
            <div>&nbsp;</div>
            <div className="row">
                <Form.Select col="col s4" label="Shipper" onChange={this.onChange} placeholder="Select a Shipper" name="shipperId" value={this.state.shipperId} list={this.props.shipper} filter={{ label: "name", value: "id" }} />
                <Form.Select col="col s2" label="Status" onChange={this.onChange} name="status" value={this.state.status} list={[{ label: 'NOT ARCHIVED' }, { label: 'ACTIVE' }, { label: 'CLOSED' }, { label: 'CANCELLED' }, { label: 'ARCHIVED' }]} filter={{ label: "label", value: "label" }} />
                <Form.Select col="col s2" label="Dummy" onChange={this.onChange} name="dummy" value={this.state.dummy} list={[{ label: "ALL BOOKINGS" }, { label: "NO DUMMIES" }, { label: "DUMMIES ONLY" }]} filter={{ label: "label", value: "label" }} />
                <Form.Select col="col s2" label="Sort By Date" onChange={this.onChange} name="sortByLadenOnBoardDate" list={[{ label: 'LOB', value: true }, { label: 'CUT', value: false }]} filter={{ label: 'label', value: 'value' }} />
            </div>
            <div className="row">
                <Form.DateInput col="col s2" label="Start Date" onChange={this.onChange} name="startDate" value={this.state.startDate} />
                <Form.DateInput col="col s2" label="End Date" onChange={this.onChange} name="endDate" value={this.state.endDate} />
                {this.state.loading ?
                    <div>
                        <div className="preloader-wrapper small active">
                            <div className="spinner-layer spinner-blue-only">
                                <div className="circle-clipper left">
                                    <div className="circle"></div>
                                </div><div className="gap-patch">
                                    <div className="circle"></div>
                                </div><div className="circle-clipper right">
                                    <div className="circle"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    :
                    <Form.Button col="col s2" onClick={this.generateReport} label="Generate Report" type="outline" color="blue" />
                }
                {/* <Form.Select col="col s2 offset-s2" label="Printable Report" onChange={this.onChange} name="reportType" list={this.getPrintables()} filter={{ label: 'label', value: 'value' }} />
                <Form.Button col="col s2" onClick={this.print} label="Print" type="outline" color="blue" /> */}
            </div>
            <div className="row">
                <div className="col s12">
                    <ul className="tabs">
                        <li className="tab col activeTab"><a href="/" data-ref="cpp" onClick={this.handleTabClick}>Container Port Pair</a></li>
                        <li className="tab col"><a href="/" data-ref="pld" onClick={this.handleTabClick}>Place of Delivery</a></li>
                        <li className="tab col"><a href="/" data-ref="cbc" onClick={this.handleTabClick}>Country</a></li>
                        <li className="tab col"><a href="/" data-ref="comm" onClick={this.handleTabClick}>Commodity</a></li>
                    </ul>
                </div>
            </div>
            {this.getCurrentTabHTML()}
            <div className="row">
                <div className="col s12">
                    <div className="card">
                        <div className="card-content">
                            <canvas id="chart" height="68px"></canvas>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
    //////////////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////TABS HTML///////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////////

    rendercpp = () => (
        <div className="row">
            <div className="row">
                <h5>Carrier & Port Pair</h5>
            </div>
            <div style={{ overflowX: 'auto' }}>
                {this.state.carrierPortPair && Object.keys(this.state.carrierPortPair.tableData).map(carrierCode => {
                    const wks = JSON.parse(JSON.stringify(this.state.weekList));
                    wks.unshift('');
                    return (
                        <div key={carrierCode}>
                            <div className="row">
                                <b>{carrierCode}</b>
                            </div>
                            <div className="row" style={{ fontSize: '.75em' }}>
                                <table>
                                    <thead>
                                        <tr>
                                            {wks.map(wk => <th key={`${carrierCode}-${wk}`} style={{ padding: '4px' }}>{wk}</th>)}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {Object.keys(this.state.carrierPortPair.tableData[carrierCode].portPairs).map(portPair => (
                                            <tr key={`${carrierCode}-${portPair}`} style={{ borderTop: portPair === 'Total' ? '2px solid black' : '' }}>
                                                <td style={{ padding: '4px', fontWeight: '600' }}>{portPair}</td>
                                                {this.state.carrierPortPair.tableData[carrierCode].portPairs[portPair].map((count, index) => (
                                                    <td key={`${carrierCode}-${portPair}-${index}`} style={{ padding: '4px' }}>{count ? count : '-'}</td>
                                                ))}
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    )
                }
                )}
            </div>
        </div>
    );

    renderpld = () => (
        <div className="row">
            <div className="row">
                <h5>Place of Delivery</h5>
            </div>
            <div style={{ overflowX: 'auto', fontSize: '.75em' }}>
                <table>
                    <thead>
                        <tr>
                            <th></th>
                            {this.state.weekList && this.state.weekList.map(wk => <th key={wk} style={{ padding: '4px' }}>{wk}</th>)}
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.placeOfDelivery && Object.keys(this.state.placeOfDelivery).map(pld => (
                            <tr key={pld}>
                                <td style={{ padding: '4px' }}>{pld}</td>
                                {this.state.placeOfDelivery[pld].data.map((count, index) => <td key={`pld-${index}`} style={{ padding: '4px' }}>{count ? count : "-"}</td>)}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    )

    rendercbc = () => (
        <div className="row">
            <div className="row">
                <h5>Country</h5>
            </div>
            <div style={{ overflowX: 'auto', fontSize: '.75em' }}>
                <table>
                    <thead>
                        <tr>
                            <th></th>
                            {this.state.weekList && this.state.weekList.map(wk => <th key={wk} style={{ padding: '4px' }}>{wk}</th>)}
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.containersByCountry && Object.keys(this.state.containersByCountry).map(c => (
                            <tr key={c}>
                                <td style={{ padding: '4px' }}>{c}</td>
                                {this.state.containersByCountry[c].data.map((count, index) => <td key={`contry-${index}`} style={{ padding: '4px' }}>{count ? count : "-"}</td>)}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    )

    renderComm = () => (
        <div className="row">
            <div className="row">
                <h5>Commodity</h5>
            </div>
            <div style={{ overflowX: 'auto', fontSize: '.75em' }}>
                <table>
                    <thead>
                        <tr>
                            <th></th>
                            {this.state.weekList && this.state.weekList.map(wk => <th key={wk} style={{ padding: '4px' }}>{wk}</th>)}
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.containersByCommodity && Object.keys(this.state.containersByCommodity).map(c => (
                            <tr key={c}>
                                <td style={{ padding: '4px' }}>{c}</td>
                                {this.state.containersByCommodity[c].data.map((count, index) => <td key={`contry-${index}`} style={{ padding: '4px' }}>{count ? count : '-'}</td>)}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    const { urls, index, party, shipper } = state;
    const url = urls[index];
    return { shipper, party, url }
}

export default connect(mapStateToProps)(ContainerReport);
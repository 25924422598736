export const types = {
    INT: 'INT',
    STRING: 'STRING',
    BOOL: 'BOOL',
    PHONE: 'PHONE',
    FLOAT: 'FLOAT',
    EMAIL: 'EMAIL',
    URL: 'URL',
    ID: 'ID',
    SIMPLE_DATE: 'SIMPLE_DATE',
    SIMPLE_TIME: 'SIMPLE_TIME'
};

export const flags = {
    MIN_LENGTH: 'MIN_LENGTH:',
    MAX_LENGTH: 'MAX_LENGTH:',
    ALLOW_EMPTY: 'ALLOW_EMPTY',
    NO_SPACES: 'NO_SPACES',
    MIN_MAX_LENGTH: 'MIN_MAX_LENGTH:',
    EXACT_LENGTH: 'EXACT_LENGTH:',
    MIN_VALUE: 'MIN_VALUE:',
    MAX_VALUE: 'MAX_VALUE:',
    MIN_MAX_VALUE: 'MIN_MAX_VALUE:',
}
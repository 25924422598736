import React, { Component } from 'react';
import { connect } from 'react-redux';
import M from 'materialize-css';
import Form from '../../Components/NewComponents';
import axios from 'axios';
import { flags } from "../../constants";
import ContractsResultsDisplay from './ContractsResultsDisplay';
import RatesResultsDisplay from './RatesDisplay/RatesResultsDisplay';
import NewContractModal from './ContractModal/NewContractModal';
import { globalToastActions } from '../../Redux/actions';
import { auth } from "../../helperFunctions";
import styles from "./contracts.module.css";
import TableHeader from '../../Components/TableHeader/TableHeader';


class ContractOverview extends Component {
    state = {
        shippers: [],
        shipperId: "0",
        carrierId: "0",
        rateMatrixShipper: 0,
        contracts: [],
        rates: [],
        step: 1,
        carrierCharges: [],
        contract: {
            originId: "0",
            destId: '0',
            manageType: "lfManaged",
            lfSigned: false,
            carrierId: "0",
            contractNumber: "",
            startDate: "",
            endDate: "",
            origins: [],
            destinations: [],
            rates: [],
            charges: [],
            salesReps: [],
            freetime: []
        },
        rateMatrixParams: {
            shipperId: this.props.shipper[0].id,
            feeDisplay: "BASE",
            carrierId: 0,
            originId: 0,
            destId: 0,
            destCountryId: 0,
            reportType: "rateMatrix"
        },
        filters: {
            shipperName: "",
            carrierName: "",
            contractNumberFilter: "",
            commodity: "",
            chargeName: "",
            dateType: "EFF DATE",
            startDate: "",
            endDate: "",
            status: "ALL",
            lastModifiedBy: "",
            lastModifited: "",
            manageType: "",
            salesRep: "",
            insideSales: "",
            originName: "",
            destName: "",
            commission: "",
            totalPerContainerCharges: "",
            countryName: "",
            currentSort: "shipperName",
            reverse: '1',
            currentSort2: "",
            reverse2: "",
            currentSort3: "",
            reverse3: "",
            displayRates: this.props.url.state.filters ? this.props.url.state.filters.displayRates : false
        },
        loading: false
    }

    onChange = (name, value) => this.setState({ [name]: value });

    rateMatrixOnChange = (name, value) => {
        this.setState({ rateMatrixParams: { ...this.state.rateMatrixParams, [name]: value } })
    }

    manageTypeOnChange = (buttonType) => {
        let currentType = this.state.filters.manageType;
        if (buttonType === currentType) {
            this.setState({ filters: { ...this.state.filters, manageType: "" } }, () => this.state.filters.displayRates ? this.getRates() : this.getContracts());
            return;
        }
        this.setState({ filters: { ...this.state.filters, manageType: buttonType } }, () => this.state.filters.displayRates ? this.getRates() : this.getContracts());
    }

    filterChange = (name, value) => {
        this.setState({ filters: { ...this.state.filters, [name]: value } });
    }


    filterChangePlus = (name, value) => {
        this.setState({ filters: { ...this.state.filters, [name]: value } });
        if (this.state.filters.displayRates) {
            this.getRates();
        } else {
            this.getContracts();
        }
    }

    modalOnChange = (name, value) => {
        this.setState({ contract: { ...this.state.contract, [name]: value } })
    }

    initModal = () => {
        M.Modal.init(document.querySelectorAll('.modal'));
    }

    initListeners = () => {
        document.querySelectorAll('.search-field').forEach(elem => elem.addEventListener('keyup', this.searchListener));
        document.addEventListener("keydown", this.switchViewListener);
        document.addEventListener("keydown", this.clearListener);
    }

    removeListeners = () => {
        document.querySelectorAll(".search-field").forEach(elem => elem.addEventListener("keyup", this.searchListener));
        document.removeEventListener("keydown", this.switchViewListener)
        document.removeEventListener("keydown", this.clearListener)
    }

    searchListener = (e) => {
        if (e.keyCode === 13) {
            e.preventDefault();
            if (this.state.filters.displayRates) {
                this.getRates();
            } else {
                this.getContracts();
            }

        }
    }

    clearListener = e => { if (e.keyCode === 27) this.clear(); }

    switchViewListener = (e) => {
        if (e.keyCode === 113) {
            e.preventDefault();
            this.switchOverviewView(113);
        } else if (e.keyCode === 112) {
            e.preventDefault();
            this.switchOverviewView(112)
        }
    }

    clear = (e) => {
        let filters = {
            shipperName: "",
            carrierName: "",
            contractNumberFilter: "",
            commodity: "",
            chargeName: "",
            dateType: "EFF DATE",
            startDate: "",
            endDate: "",
            status: "ACTIVE",
            lastModifiedBy: "",
            lastModifited: "",
            manageType: "",
            salesRep: "",
            insideSales: "",
            originName: "",
            destName: "",
            commission: "",
            totalPerContainerCharges: "",
            countryName: "",
            currentSort: "shipperName",
            reverse: '1',
            displayRates: this.state.filters.displayRates
        }
        this.setState({ filters }, () => this.state.filters.displayRates ? this.getRates() : this.getContracts())
    }

    sort = e => {
        if (!e.target.getAttribute("data-sort")) {
            return
        } else if (e.ctrlKey) {
            const value = typeof e === "string" ? this.props.url.state.filters.currentSort2 : e.target.getAttribute('data-sort');
            const reverse2 = this.state.filters.currentSort2 === value ? (-1 * this.state.filters.reverse2) : 1;
            this.setState({ filters: { ...this.state.filters, currentSort2: value, reverse2 } }, () => { this.state.filters.displayRates ? this.getRates() : this.getContracts() })
        } else if (e.altKey) {
            const value = typeof e === "string" ? this.props.url.state.filters.currentSort3 : e.target.getAttribute('data-sort');
            const reverse3 = this.state.filters.currentSort3 === value ? (-1 * this.state.filters.reverse3) : 1;
            this.setState({ filters: { ...this.state.filters, currentSort3: value, reverse3 } }, () => { this.state.filters.displayRates ? this.getRates() : this.getContracts() })
        } else {
            const value = typeof e === "string" ? this.props.url.state.filters.currentSort : e.target.getAttribute('data-sort');
            const reverse = this.state.filters.currentSort === value ? (-1 * this.state.filters.reverse) : 1;
            this.setState({ filters: { ...this.state.filters, currentSort: value, reverse } }, () => { this.state.filters.displayRates ? this.getRates() : this.getContracts() })
        }
    }

    showContractModal = () => {
        if (!this.state.carrierCharges.length) {
            axios.get("/api/v1/carrier/allcarriercharges/", { params: { ...auth.getAuthData() } }).then(result => {
                if (result.data === "NOT AUTHENTICATED") {
                    localStorage.clear();
                    this.props.dispatch({ type: "SCORCHED_EARTH" });
                    return;
                } else {
                    this.setState({ carrierCharges: result.data });
                }
            })
        }
        M.Modal.getInstance(document.querySelector("#contract-modal")).open();
    }

    showPrintModal = () => {
        M.Modal.getInstance(document.querySelector("#rate-print-modal")).open();
    }

    switchOverviewView = (keycode) => {
        this.removeListeners();
        if (keycode === 113) {
            this.setState({ filters: { ...this.state.filters, displayRates: true } }, () => this.initListeners());
        } else if (keycode === 112) {
            this.setState({ filters: { ...this.state.filters, displayRates: false } }, () => this.initListeners());
        }
    }

    getContracts = () => {
        if (this.state.loading) {
            return;
        }

        this.setState({ loading: true, }, async () => {

            await axios.get("/api/v1/contract/", { params: { ...auth.getAuthData(), filters: this.state.filters } }).then(result => {
                if (result.data === "NOT AUTHENTICATED") {
                    localStorage.clear();
                    this.props.dispatch({ type: "SCORCHED_EARTH" });
                    return;
                };

                if (!result.data.contracts.length) {
                    this.props.dispatch({
                        type: globalToastActions.UPDATE_MSG, payload: {
                            type: "warning", msg: "No Results"
                        }
                    })
                }

                let { contracts, filters } = result.data;
                this.setState({ contracts, rates: [], filters, loading: false });
            })
        })

    }

    getRates = () => {

        if (this.state.loading) {
            return;
        }

        this.setState({ loading: true }, async () => {

            await axios.get("/api/v1/contract/", { params: { ...auth.getAuthData(), filters: this.state.filters } }).then(result => {
                if (result.data === "NOT AUTHENTICATED") {
                    localStorage.clear();
                    this.props.dispatch({ type: "SCORCHED_EARTH" });
                    return;
                }
                let { rates, filters } = result.data;

                this.setState({ contracts: [], rates, filters, loading: false });

            })
        })
    }


    generateRateMatrix = () => {

        let rateMatrixParams = this.state.rateMatrixParams;

        axios.get("/api/v1/report/ratematrix", { params: { ...auth.getAuthData(), rateMatrixParams } }).then(result => {
            if (result.data === "NOT AUTHENTICATED") {
                localStorage.clear();
                this.props.dispatch({ type: "SCORCHED_EARTH" });
                return;
            }
            if (!result.data.length) {
                this.props.dispatch({ type: globalToastActions.UPDATE_MSG, payload: { msg: "No Rates", type: "warning" } });
                return;
            }

            const win = window.open('', '', 'width=960 height=1080');
            win.document.write(result.data);

        })
    }

    generateChargeReport = () => {

        let reportParams = this.state.rateMatrixParams;

        axios.get("/api/v1/report/chargereport", { params: { ...auth.getAuthData(), reportParams } }).then(result => {
            if (result.data === "NOT AUTHENTICATED") {
                localStorage.clear();
                this.props.dispatch({ type: "SCORCHED_EARTH" });
                return;
            }
            if (!result.data.length) {
                this.props.dispatch({ type: globalToastActions.UPDATE_MSG, payload: { msg: "No Charges", type: "warning" } });
                return;
            }

            const win = window.open('', '', 'width=960 height=1080');
            win.document.write(result.data);

        })
    }


    initCollapsible = () => {
        M.Collapsible.init(document.querySelector(".collapsible"));
    }

    initActionButton = () => {
        M.FloatingActionButton.init(document.querySelectorAll(".fixed-action-btn"));
    }

    componentDidMount = () => {

        let filters;

        if (this.props.url.state.filters) {
            filters = { ...this.props.url.state.filters }
        } else {
            filters = { ...this.state.filters };
        }

        this.setState({ filters: { ...filters } }, () => {
            this.initCollapsible();
            this.initListeners();
            this.initModal();
            this.initActionButton();
        })
    }

    componentWillUnmount = () => {
        this.removeListeners();
    }

    render = () => {
        return (
            <div className="row" style={{ position: "relative" }}>
                {!this.state.filters.displayRates ?
                    <div>
                        <div id="bkg-ov-search-fields">
                            {/* <ul className="collapsible overviewCollapsible" style={{ padding: '12px' }} >
                                <li>
                                    <div className="collapsible-header" onClick={this.openCol} style={{ margin: '0px', padding: '0px', maxHeight: '12px', border: 'none' }}>
                                        <i className="material-icons searchOpenIcon">{!this.state.searchOpen ? "expand_more" : "expand_less"}</i><p style={{ position: 'relative', bottom: '4px' }}>Advanced Search</p>
                                    </div>
                                    <div className="collapsible-body overviewCollapsibleBody" style={{ margin: '0px', border: 'none', paddingBottom: "0px" }}> */}
                            <div className="row customMarginRemove">
                                <Form.Select col="s2 search-field" name="dateType" label="Date Type" onChange={this.filterChange} value={this.state.filters.dateType} list={[{ label: "EFF DATE" }, { label: "EXP DATE" }]} filter={{ label: "label", value: "label" }} />
                                <Form.DateInput col="s2 search-field" name="startDate" label="Start Date" onChange={this.filterChange} value={this.state.filters.startDate} flags={[flags.ALLOW_EMPTY]} />
                                <Form.DateInput col="s2 search-field" name="endDate" label="End Date" onChange={this.filterChange} value={this.state.filters.endDate} flags={[flags.ALLOW_EMPTY]} />
                                <Form.TextInput col="s2 search-field" name="salesRep" label="Sales Rep" onChange={this.filterChange} value={this.state.filters.salesRep} flags={[flags.ALLOW_EMPTY]} />
                                <Form.TextInput col="s2 search-field" name="insideSales" label="Inside Sales" onChange={this.filterChange} value={this.state.filters.insideSales} flags={[flags.ALLOW_EMPTY]} />
                                <div className="col s2">
                                    <Form.Button col={`manageButton ${this.state.filters.manageType === "shipperManaged" ? "chartButtonPressed" : ""}`} icon="star_border" type="outline" color="black" iconClass="manageButtonIcon" onClick={() => this.manageTypeOnChange("shipperManaged")} />
                                    <Form.Button col={`manageButton ${this.state.filters.manageType === "lfManaged" ? "chartButtonPressed" : ""}`} icon="star_half" type="outline" color="black" iconClass="manageButtonIcon" onClick={() => this.manageTypeOnChange("lfManaged")} />
                                    <Form.Button col={`manageButton ${this.state.filters.manageType === "lfManagedAndSigned" ? "chartButtonPressed" : ""}`} icon="star" type="outline" color="black" iconClass="manageButtonIcon" onClick={() => this.manageTypeOnChange("lfManagedAndSigned")} />
                                </div>
                            </div>
                            {/* </div>
                                </li>
                            </ul> */}
                            <div className="row customMarginRemove">
                                <Form.TextInput col="s2 search-field" name="shipperName" label="Shipper" onChange={this.filterChange} value={this.state.filters.shipperName} flags={[flags.ALLOW_EMPTY]} />
                                <Form.TextInput col="s2 search-field" name="carrierName" label="Carrier" onChange={this.filterChange} value={this.state.filters.carrierName} flags={[flags.ALLOW_EMPTY]} />
                                <Form.TextInput col="s2 search-field" name="contractNumberFilter" label="Contract #" onChange={this.filterChange} value={this.state.filters.contractNumberFilter} flags={[flags.ALLOW_EMPTY]} />
                                <Form.TextInput col="s2 search-field" name="commodity" label="Commodity" onChange={this.filterChange} value={this.state.filters.commodity} flags={[flags.ALLOW_EMPTY]} />
                                <Form.Select col="s2 search-field" name="status" label="Status" onChange={this.filterChangePlus} value={this.state.filters.status} list={[{ label: "ALL" }, { label: "ACTIVE" }, { label: "PENDING REVIEW" }, { label: "PENDING UPCOMING" }, { label: "UPCOMING" }, { label: "CLOSED" }, { label: "ARCHIVED" }]} filter={{ label: 'label', value: 'label' }} />
                                <Form.Switch col="col s2" name="displayRates" label="Rates View" onChange={() => this.switchOverviewView(113)} value={this.state.filters.displayRates} />
                            </div>
                        </div>
                        <table className="lf-portal-table-shipment-overview">
                            <TableHeader
                                headers={[
                                    { label: "Status", attribute: "status" },
                                    { label: "Shipper", attribute: "shipperName" },
                                    { label: "Carrier", attribute: "carrierName" },
                                    { label: "Contract #", attribute: "contractNumberFilter" },
                                    { label: "MQC", attribute: "minimumQuantityCommitment" },
                                    { label: "Commodity", attribute: "commodity" },
                                    { label: "Eff. Date", attribute: "effectiveDate" },
                                    { label: "Exp. Date", attribute: "expirationDate" },
                                    { label: "Sales Rep", attribute: "contractSalesRep" },
                                    { label: "Inside Sales", attribute: "contractInsideSales" },
                                    { label: "Manager", attribute: "isManaged" },
                                ]}
                                sort={this.sort}
                                filters={this.state.filters}
                            />
                            <tbody className={`lf-portal-tbody ${styles.contractsTbody}`}>
                                <ContractsResultsDisplay
                                    handleContextMenu={this.handleContextMenu}
                                    handleDelete={this.handleDelete}
                                    handleStatusMenu={this.handleStatusMenu}
                                    handleAssigneeMenu={this.handleAssigneeMenu}
                                    handleEdit={this.state.handleEdit}
                                    contracts={this.state.contracts}
                                    submitUserNames={this.state.submitUserNames}
                                    getContracts={this.getContracts}
                                    filters={this.state.filters}
                                    loading={this.state.loading} />
                            </tbody>
                        </table>
                    </div>
                    :
                    //YEAH THIS IS REATES
                    <div>
                        <div id="bkg-ov-search-fields">
                            {/* <ul className="collapsible overviewCollapsible" style={{ padding: '12px' }} >
                                <li>
                                    <div className="collapsible-header" onClick={this.openCol} style={{ margin: '0px', padding: '0px', maxHeight: '12px', border: 'none' }}>
                                        <i className="material-icons searchOpenIcon">{!this.state.searchOpen ? "expand_more" : "expand_less"}</i><p style={{ position: 'relative', bottom: '4px' }}>Advanced Search</p>
                                    </div>
                                    <div className="collapsible-body overviewCollapsibleBody" style={{ margin: '0px', border: 'none', paddingBottom: "0px" }}> */}
                            <div className="row customMarginRemove">
                                <Form.TextInput col="col s2 search-field" name="chargeName" label="Charge Name" onChange={this.filterChange} value={this.state.filters.chargeName} flags={[flags.ALLOW_EMPTY]} />
                                <Form.TextInput col="s1 search-field" name="commission" label="Commission" onChange={this.filterChange} value={this.state.filters.commission} flags={[flags.ALLOW_EMPTY]} />
                                <Form.TextInput col="s2 search-field" name="totalPerContainerCharges" label="TPCC" onChange={this.filterChange} value={this.state.filters.totalPerContainerCharges} flags={[flags.ALLOW_EMPTY]} />
                                <Form.TextInput col="s2 search-field" name="countryName" label="Country" onChange={this.filterChange} value={this.state.filters.countryName} flags={[flags.ALLOW_EMPTY]} />
                                <Form.Select col="s2 search-field" name="dateType" label="Date Type" onChange={this.filterChange} value={this.state.filters.dateType} list={[{ label: "EFF DATE" }, { label: "EXP DATE" }]} filter={{ label: "label", value: "label" }} />
                                <Form.DateInput col="s1 search-field" name="startDate" label="Start Date" onChange={this.filterChange} value={this.state.filters.startDate} flags={[flags.ALLOW_EMPTY]} />
                                <Form.DateInput col="s1 search-field" name="endDate" label="End Date" onChange={this.filterChange} value={this.state.filters.endDate} flags={[flags.ALLOW_EMPTY]} />
                                <Form.Button col="col s1" label='Print' color="black" onClick={this.showPrintModal} type="outline" />
                            </div>
                            {/* </div>
                                </li>
                            </ul> */}
                            <div className="row customMarginRemove">
                                <Form.Select col="s1 search-field" name="status" label="Status" onChange={this.filterChangePlus} value={this.state.filters.status} list={[{ label: "ACTIVE" }, { label: "CLOSED" }, { label: "PENDING" }, { label: "ARCHIVED" }]} filter={{ label: 'label', value: 'label' }} />
                                <Form.TextInput col="s2 search-field" name="shipperName" label="Shipper" onChange={this.filterChange} value={this.state.filters.shipperName} flags={[flags.ALLOW_EMPTY]} />
                                <Form.TextInput col="s2 search-field" name="carrierName" label="Carrier" onChange={this.filterChange} value={this.state.filters.carrierName} flags={[flags.ALLOW_EMPTY]} />
                                <Form.TextInput col="s2 search-field" name="originName" label="Origin" onChange={this.filterChange} value={this.state.filters.originName} flags={[flags.ALLOW_EMPTY]} />
                                <Form.TextInput col="s2 search-field" name="destName" label="Dest" onChange={this.filterChange} value={this.state.filters.destName} flags={[flags.ALLOW_EMPTY]} />
                                <Form.TextInput col="s2 search-field" name="contractNumberFilter" label="Contract #" onChange={this.filterChange} value={this.state.filters.contractNumberFilter} flags={[flags.ALLOW_EMPTY]} />
                                <Form.Switch col="col s1" name="displayRates" label="Rates View" onChange={() => this.switchOverviewView(112)} value={this.state.filters.displayRates} />
                            </div>
                        </div>
                        <table className="lf-portal-table">
                            <TableHeader
                                headers={[
                                    { label: "Status", attribute: "status" },
                                    { label: "Shipper", attribute: "shipperName" },
                                    { label: "Carrier", attribute: "carrierName" },
                                    { label: "Origin", attribute: "originName" },
                                    { label: "Dest", attribute: "destName" },
                                    { label: "Base Freight", attribute: "baseFreight" },
                                    { label: "TPCC", attribute: "totalPerContainerCharges" },
                                    { label: "Total Rate", attribute: "totalRate" },
                                    { label: "Dest Country", attribute: "destCountry" },
                                    { label: "Contract #", attribute: "contractNumberFilter" },
                                    { label: "Commission", attribute: "commission" },
                                    { label: "Eff. Date", attribute: "effectiveDate" },
                                    { label: "Exp. Date", attribute: "expirationDate" }
                                ]}
                                sort={this.sort}
                                filters={this.state.filters}
                            />
                            <tbody className={`lf-portal-tbody ${styles.contractsTbody}`}>
                                <RatesResultsDisplay
                                    handleContextMenu={this.handleContextMenu}
                                    handleDelete={this.handleDelete}
                                    handleStatusMenu={this.handleStatusMenu}
                                    handleAssigneeMenu={this.handleAssigneeMenu}
                                    handleEdit={this.state.handleEdit}
                                    rates={this.state.rates}
                                    submitUserNames={this.state.submitUserNames}
                                    getRates={this.getRates}
                                    filters={this.state.filters}
                                    loading={this.state.loading} />
                            </tbody>
                        </table>
                    </div>
                }
                <NewContractModal contracts={this.state.contracts} getContracts={this.getContracts} carrierCharges={this.state.carrierCharges} />
                {/* PRINT MODAL */}
                <div className={`modal`} id="rate-print-modal">
                    <div className="modal-content" style={{ paddingTop: "12px" }}>
                        <div className="row" style={{ marginBottom: "12px" }}>
                            <div className="col s6">
                                <h4 style={{ marginBottom: "0px", textAlign: "center" }}>Print Rate Matrix</h4>
                            </div>
                        </div>
                        <div className="row">
                            <Form.Select
                                col="col s3"
                                label="Shipper"
                                name="shipperId"
                                value={this.state.rateMatrixParams.shipperId}
                                placeholder={this.state.rateMatrixParams.reportType !== "rateMatrix" ? "ANY" : null}
                                onChange={this.rateMatrixOnChange}
                                list={this.props.shipper}
                                filter={{ label: "name", value: "id" }}
                                flags={[flags.ALLOW_EMPTY]} />
                            <Form.Select
                                col="col s3"
                                label="Carrier"
                                name="carrierId"
                                value={this.state.rateMatrixParams.carrierId}
                                placeholder="ANY"
                                onChange={this.rateMatrixOnChange}
                                list={this.props.carrier}
                                filter={{ label: "code", value: "id" }}
                                flags={[flags.ALLOW_EMPTY]} />
                            <Form.Select
                                col="col s3"
                                label="Origin"
                                name="originId"
                                value={this.state.rateMatrixParams.originId}
                                placeholder="ANY"
                                onChange={this.rateMatrixOnChange}
                                list={this.props.location.filter(l => (l.countryId === 1 || l.countryId === 17) && l.typeId !== 3).sort((a, b) => a.name.localeCompare(b.name))}
                                filter={{ label: "name", value: "id" }}
                                flags={[flags.ALLOW_EMPTY]} />
                            <Form.Select
                                col="col s3"
                                label="Fees"
                                name="feeDisplay"
                                value={this.state.rateMatrixParams.feeDisplay}
                                disabled={this.state.rateMatrixParams.reportType !== "rateMatrix" ? true : false}
                                onChange={this.rateMatrixOnChange}
                                list={[{ label: "BASE" }, { label: "BASE/FEE" }, { label: "COMBINED" }]}
                                filter={{ label: "label", value: "value" }}
                                flags={[flags.ALLOW_EMPTY]} />
                        </div>
                        <div className="row">
                            <div className="col s3">
                                <Form.Radio col="col s3" label="Rate Matrix" name="reportType" value={"rateMatrix"} onChange={this.rateMatrixOnChange} checked={this.state.rateMatrixParams.reportType === 'rateMatrix'} />
                            </div>
                            <div className="col s3">
                                <Form.Radio col="col s3" label="Charge Types" name="reportType" value="chargeTypes" onChange={this.rateMatrixOnChange} />
                            </div>
                            <Form.Select
                                col="col s3"
                                label="Dest"
                                name="destId"
                                value={this.state.rateMatrixParams.destId}
                                placeholder="ANY"
                                disabled={parseInt(this.state.rateMatrixParams.destCountryId) !== 0 || this.state.rateMatrixParams.reportType !== "rateMatrix" ? true : false}
                                onChange={this.rateMatrixOnChange}
                                list={this.props.location.filter(l => l.countryId !== 17 && l.countryId !== 1 && l.typeId !== 3).sort((a, b) => a.name.localeCompare(b.name))}
                                filter={{ label: "name", value: "id" }}
                                flags={[flags.ALLOW_EMPTY]}
                            />
                            <Form.Select col="col s3" label="Dest Country" name="destCountryId" value={this.state.rateMatrixParams.destCountryId} placeholder="ANY" disabled={parseInt(this.state.rateMatrixParams.destId) !== 0 || this.state.rateMatrixParams.reportType !== "rateMatrix" ? true : false} onChange={this.rateMatrixOnChange} list={this.props.country.filter(c => c.id !== 1 && c.id !== 17).sort((a, b) => a.name.localeCompare(b.name))} filter={{ label: "name", value: "id" }} flags={[flags.ALLOW_EMPTY]} />
                        </div>
                        <div className="row">
                            <Form.Button col="col s3 offset-s9" label='Print!' color="black" onClick={this.state.rateMatrixParams.reportType === "rateMatrix" ? this.generateRateMatrix : this.generateChargeReport} type="outline" />
                        </div>
                    </div>
                </div>
                <div className="fixed-action-btn">
                    <span className="btn-floating btn-large blue" onClick={this.showContractModal}>
                        <i className="large material-icons">add</i>
                    </span>
                </div>
            </div>
        )
    }
}
const mapStateToProps = state => {
    const { urls, index, party, shipper, carrier, location, containerType, country, portalUser, user } = state;
    const url = urls[index];
    return { url, party, shipper, carrier, location, containerType, country, portalUser, user }
}

export default connect(mapStateToProps)(ContractOverview)
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { globalToastActions } from '../../Redux/actions';
import { flags } from "../../constants";
import Form from '../../Components/NewComponents';
import axios from 'axios';
import { auth } from "../../helperFunctions";
import moment from "moment-timezone";

class StickyNote extends Component {

    state = {
        ...this.props.shipment,
        bookingNumber: Array.isArray(this.props.shipment.bookingNumber) ? this.props.getBookingNumbers(this.props.shipment.bookingNumber) : this.props.shipment.bookingNumber
    }

    componentDidUpdate = (prevProps, prevState) => {

        if (this.props.shipment.lcChecked !== prevProps.shipment.lcChecked && prevProps.shipment.id) {
            this.setState({ lcChecked: this.props.shipment.lcChecked }, () => {
                this.checkBoxOnChange('lcChecked', this.props.shipment.lcChecked);
            });
        }

        if (this.props.shipment.id !== prevProps.shipment.id) {
            this.setState({ ...this.props.shipment });
        }

    }

    refreshModal = () => {
        axios.get('/api/v1/documentation/checklist', { params: { ...auth.getAuthData(), id: this.state.id } }).then(result => {
            if (result.data === "NOT AUTHENTICATED") {
                localStorage.clear();
                return this.props.dispatch({ type: "SCORCHED_EARTH" });
            } else {
                return this.setState({ ...result.data[0] });
            }
        })
    }

    textOnChange = (name, value) => {
        this.setState({ [name]: value })
    }

    checkBoxOnChange = (name, value) => {
        let updatedShipment = { ...this.state }
        delete updatedShipment.portPairList;
        delete updatedShipment.serviceRouteList;
        delete updatedShipment.containerYards;
        delete updatedShipment.consignees;
        delete updatedShipment.vessels;
        delete updatedShipment.emails;
        delete updatedShipment.docsEmails;
        let fieldName = name

        updatedShipment[fieldName] = value;

        if (fieldName === "useNotes" && value === false) {
            return;
        }

        // CONVERT TO UTC WHEN BEING ENTERED INTO DB    
        if (value === true && (fieldName === "aesProofed" || fieldName === "blProofed" || fieldName === "phytoOrdered" || fieldName === "phytoProofed" || fieldName === "ratedProofSent" || fieldName === "aesVerified")) {
            updatedShipment[`${fieldName}User`] = this.props.user.id;
            updatedShipment[`${fieldName}Date`] = moment(new Date()).format("YYYY-MM-DD h:mm:ss");
            this.setState({
                [`${fieldName}User`]: this.props.user.id,
                [`${fieldName}Date`]: moment(new Date()).format("YYYY-MM-DD h:mm:ss"),
                [`${fieldName}Name`]: `${this.props.user.first.substring(0, 1)}${this.props.user.last.substring(0, 1)}`,
                [`${fieldName}DateFull`]: moment(new Date()).format("MM/DD h:mm"),
                [name]: value
            });
        } else if (value === false && (fieldName === "aesProofed" || fieldName === "blProofed" || fieldName === "phytoOrdered" || fieldName === "phytoProofed" || fieldName === "ratedProofSent" || fieldName === "aesVerified")) {
            updatedShipment[`${fieldName}User`] = null
            updatedShipment[`${fieldName}Date`] = null
            this.setState({
                [`${fieldName}User`]: null,
                [`${fieldName}Date`]: null,
                [`${fieldName}Name`]: null,
                [`${fieldName}DateFull`]: null,
                [name]: value
            })
        } else {
            this.setState({ [name]: value })
        }

        updatedShipment.cust1Date = updatedShipment.cust1Date ? moment(updatedShipment.cust1Date, "MM/DD/YY").format("YYYY-MM-DD") : null;
        updatedShipment.cust2Date = updatedShipment.cust2Date ? moment(updatedShipment.cust2Date, "MM/DD/YY").format("YYYY-MM-DD") : null;
        updatedShipment.cust3Date = updatedShipment.cust3Date ? moment(updatedShipment.cust3Date, "MM/DD/YY").format("YYYY-MM-DD") : null;

        axios.post("/api/v1/documentation/checklist", { ...auth.getAuthData(), updatedShipment }).then(result => {
            if (result.status !== 200 && result.status !== 304) {
                this.refreshModal();
            } else {
                this.props.dispatch({ type: globalToastActions.UPDATE_MSG, payload: { msg: "Stage Saved", type: "success" } });
                return this.props.overview ? this.props.updateParent(updatedShipment) : null;
            }
        })
    }

    saveStagesText = () => {
        let updatedShipment = { ...this.state };
        delete updatedShipment.portPairList;
        delete updatedShipment.serviceRouteList;
        delete updatedShipment.containerYards;
        delete updatedShipment.consignees;
        delete updatedShipment.vessels;
        delete updatedShipment.emails;
        delete updatedShipment.docsEmails;

        updatedShipment.cust1Date = updatedShipment.cust1Date ? moment(updatedShipment.cust1Date, "MM/DD/YY").format("YYYY-MM-DD") : null;
        updatedShipment.cust2Date = updatedShipment.cust2Date ? moment(updatedShipment.cust2Date, "MM/DD/YY").format("YYYY-MM-DD") : null;
        updatedShipment.cust3Date = updatedShipment.cust3Date ? moment(updatedShipment.cust3Date, "MM/DD/YY").format("YYYY-MM-DD") : null;
        axios.post("/api/v1/documentation/checklist", { ...auth.getAuthData(), updatedShipment }).then(result => {
            this.refreshModal();
            this.props.dispatch({ type: globalToastActions.UPDATE_MSG, payload: { msg: "Text Saved", type: "success" } });
            return this.props.overview ? this.props.updateParent(updatedShipment) : null;
        })
    }

    render = () => {
        return (
            <div id={this.props.id} className="stagesWindow modal">
                <div className="modal-content">

                    <div className="col s12">
                        <div className="row" style={this.state.flaggedForRevision ? { color: "red" } : { color: "black" }}>
                            <div className="col s4 stagesWindowHeader"><strong>{this.state.bookingNumber}</strong></div>
                            <div className="col s4 stagesWindowHeader"><strong>{this.props.shipperName ? this.props.shipperName : this.state.shipperName} - {this.props.carrierName ? this.props.carrierName : this.state.carrierName}</strong></div>
                            <div className="col s4 stagesWindowHeader"><strong><span style={{ color: 'darkgrey' }}>{this.state.datetimePrintedDate}</span>{` - ${this.state.etd}`}</strong></div>
                            <span className="closeModalIcon" onClick={this.props.closeModal}><i className="material-icons">close</i></span>
                        </div>
                        {this.state.useNotes ?
                            <div className="row stagesWindowChecks">
                                <Form.Switch col="col s2 offset-s1" name={`ratedProofSentChecked`} value={this.state.ratedProofSentChecked} onChange={this.checkBoxOnChange} label="Rated Proof" />
                                <Form.Switch col="col s2" name={`phytoChecked`} value={this.state.phytoChecked} onChange={this.checkBoxOnChange} label="Phyto" />
                                <Form.Switch col="col s2" name={`bseChecked`} value={this.state.bseChecked} onChange={this.checkBoxOnChange} label="BSE" />
                                <Form.Switch col="col s2" name={`lcChecked`} value={this.state.lcChecked} onChange={this.checkBoxOnChange} label="LC" />
                                <Form.Switch col="col s2" name={`marineInsuranceChecked`} value={this.state.marineInsuranceChecked} onChange={this.checkBoxOnChange} label="Marine Ins." />
                            </div>
                            :
                            <div className="row stagesWindowChecks">
                                <Form.Switch col="col s2" name={`ratedProofSentChecked`} value={this.state.ratedProofSentChecked} onChange={this.checkBoxOnChange} label="Rated Proof" />
                                <Form.Switch col="col s2" name={`phytoChecked`} value={this.state.phytoChecked} onChange={this.checkBoxOnChange} label="Phyto" />
                                <Form.Switch col="col s2" name={`bseChecked`} value={this.state.bseChecked} onChange={this.checkBoxOnChange} label="BSE" />
                                <Form.Switch col="col s2" name={`lcChecked`} value={this.state.lcChecked} onChange={this.checkBoxOnChange} label="LC" />
                                <Form.Switch col="col s2" name={`marineInsuranceChecked`} value={this.state.marineInsuranceChecked} onChange={this.checkBoxOnChange} label="Marine Ins." />
                                <Form.Button col="col s2 generateButton" label="Generate" color="blue" type="outline" iconClass="generateButtonIcon" onClick={() => this.checkBoxOnChange(`useNotes`, true)} />
                            </div>
                        }
                        {this.state.useNotes ?
                            <div>
                                <div className="row">
                                    <Form.CheckBox col="col s4 slimRow" className="flaggedForRevisionCheckbox" name={`lcPending`} value={this.state.lcPending} onChange={this.checkBoxOnChange} label="Awaiting LC" />
                                    <Form.CheckBox col="col s4 slimRow" className="flaggedForRevisionCheckbox" name={`awaitingPayment`} value={this.state.awaitingPayment} onChange={this.checkBoxOnChange} label="Awaiting Payment" />
                                    <Form.CheckBox label="Flagged for Revision" col="col s4 slimRow" className="flaggedForRevisionCheckbox" name={`flaggedForRevision`} value={this.state.flaggedForRevision} onChange={this.checkBoxOnChange} />
                                </div>
                                <div className="row stickyNoteRow">
                                    <Form.CheckBox col="col s1" focusStyle="stagesCheckbox" name={`aesProofed`} value={this.state.aesProofed} onChange={this.checkBoxOnChange} />
                                    <span className="col s2">AES Proofed</span>
                                    <div className="col s2 dateAndName">{this.state.aesProofedUser === null ? "-" : this.state.aesProofedName}</div>
                                    <div className="col s2 dateAndName">{this.state.aesProofedDate === null ? "-" : this.state.aesProofedDateFull}</div>
                                    <Form.TextInput col="col s5" label="Notes" name={`aesProofedNotes`} value={this.state.aesProofedNotes} onChange={this.textOnChange} onblurcap="true" flags={[flags.ALLOW_EMPTY]} />
                                </div>
                                <div className="row stickyNoteRow">
                                    <Form.CheckBox col="col s1" focusStyle="stagesCheckbox" name={`blProofed`} value={this.state.blProofed} onChange={this.checkBoxOnChange} />
                                    <span className="col s2">B/L Proofed</span>
                                    <div className="col s2 dateAndName">{this.state.blProofedUser === null ? "-" : this.state.blProofedName}</div>
                                    <div className="col s2 dateAndName">{this.state.blProofedDate === null ? "-" : this.state.blProofedDateFull}</div>
                                    <Form.TextInput col="col s5" label="Notes" name={`blProofedNotes`} value={this.state.blProofedNotes} autoComplete="off" onChange={this.textOnChange} noblurcap="true" flags={[flags.ALLOW_EMPTY]} />
                                </div>
                                {this.state.ratedProofSentChecked ?
                                    <div className="row stickyNoteRow">
                                        <Form.CheckBox col="col s1" focusStyle="stagesCheckbox" name={`ratedProofSent`} value={this.state.ratedProofSent} onChange={this.checkBoxOnChange} />
                                        <span className="col s2">Rated Proofed Sent</span>
                                        <div className="col s2 dateAndName">{this.state.ratedProofSentUser === null ? "-" : this.state.ratedProofSentName}</div>
                                        <div className="col s2 dateAndName">{this.state.ratedProofSentDate === null ? "-" : this.state.ratedProofSentDateFull}</div>
                                        <Form.TextInput col="col s5" label="Notes" name={`ratedProofSentNotes`} value={this.state.ratedProofSentNotes} autoComplete="off" onChange={this.textOnChange} noblurcap="true" flags={[flags.ALLOW_EMPTY]} />
                                    </div> : null}
                                {this.state.phytoChecked ?
                                    <div className="row stickyNoteRow">
                                        <Form.CheckBox col="col s1" focusStyle="stagesCheckbox" name={`phytoOrdered`} value={this.state.phytoOrdered} onChange={this.checkBoxOnChange} />
                                        <span className="col s2">Phyto Ordered</span>
                                        <div className="col s2 dateAndName">{this.state.phytoOrderedUser === null ? "-" : this.state.phytoOrderedName}</div>
                                        <div className="col s2 dateAndName">{this.state.phytoOrderedDate === null ? "-" : this.state.phytoOrderedDateFull}</div>
                                        <Form.TextInput col="col s5" label="Phyto #" name={`phytoNumber`} value={this.state.phytoNumber} autoComplete="off" onChange={this.textOnChange} noblurcap="true" flags={[flags.ALLOW_EMPTY]} />
                                    </div> : null}
                                {this.state.phytoChecked ?
                                    <div className="row stickyNoteRow">
                                        <Form.CheckBox col="col s1" focusStyle="stagesCheckbox" name={`phytoProofed`} value={this.state.phytoProofed} onChange={this.checkBoxOnChange} />
                                        <span className="col s2">Phyto Proofed</span>
                                        <div className="col s2 dateAndName">{this.state.phytoProofedUser === null ? "-" : this.state.phytoProofedName}</div>
                                        <div className="col s2 dateAndName">{this.state.phytoProofedDate === null ? "-" : this.state.phytoProofedDateFull}</div>
                                        <Form.TextInput col="col s5" label="Notes" name={`phytoProofedNotes`} value={this.state.phytoProofedNotes} autoComplete="off" onChange={this.textOnChange} noblurcap="true" flags={[flags.ALLOW_EMPTY]} />
                                    </div> : null}
                                <div className="row stickyNoteRow">
                                    <Form.CheckBox col="col s1" name={'cust1Complete'} value={this.state.cust1Complete} onChange={this.checkBoxOnChange} />
                                    <Form.TextInput col="col s2" label="Custom Step" name={`cust1`} value={this.state.cust1} autoComplete="off" onChange={this.textOnChange} noblurcap="true" flags={[flags.ALLOW_EMPTY]} />
                                    <Form.TextInput col="col s2" label="User" name={`cust1User`} value={this.state.cust1User} autoComplete="off" onChange={this.textOnChange} flags={[flags.ALLOW_EMPTY]} />
                                    <Form.DateInput col="col s2" label="Date " name={`cust1Date`} value={this.state.cust1Date} onChange={this.textOnChange} flags={[flags.ALLOW_EMPTY]} />
                                    <Form.TextInput col="col s5" label="Notes" name={`cust1Notes`} value={this.state.cust1Notes} autoComplete="off" onChange={this.textOnChange} noblurcap="true" flags={[flags.ALLOW_EMPTY]} />
                                </div>
                                <div className="row stickyNoteRow">
                                    <Form.CheckBox col="col s1" name={'cust2Complete'} value={this.state.cust2Complete} onChange={this.checkBoxOnChange} />
                                    <Form.TextInput col="col s2" label="Custom Step" name={`cust2`} value={this.state.cust2} autoComplete="off" onChange={this.textOnChange} noblurcap="true" flags={[flags.ALLOW_EMPTY]} />
                                    <Form.TextInput col="col s2" label="User" name={`cust2User`} value={this.state.cust2User} autoComplete="off" onChange={this.textOnChange} flags={[flags.ALLOW_EMPTY]} />
                                    <Form.DateInput col="col s2" label="Date" name={`cust2Date`} value={this.state.cust2Date} onChange={this.textOnChange} flags={[flags.ALLOW_EMPTY]} />
                                    <Form.TextInput col="col s5" label="Notes" name={`cust2Notes`} value={this.state.cust2Notes} autoComplete="off" onChange={this.textOnChange} noblurcap="true" flags={[flags.ALLOW_EMPTY]} />
                                </div>
                                <div className="row stickyNoteRow">
                                    <Form.CheckBox col="col s1" name={'cust3Complete'} value={this.state.cust3Complete} onChange={this.checkBoxOnChange} />
                                    <Form.TextInput col="col s2" label="Custom Step" name={`cust3`} value={this.state.cust3} autoComplete="off" onChange={this.textOnChange} noblurcap="true" flags={[flags.ALLOW_EMPTY]} />
                                    <Form.TextInput col="col s2" label="User" name={`cust3User`} value={this.state.cust3User} autoComplete="off" onChange={this.textOnChange} flags={[flags.ALLOW_EMPTY]} />
                                    <Form.DateInput col="col s2" label="Date" name={`cust3Date`} value={this.state.cust3Date} onChange={this.textOnChange} flags={[flags.ALLOW_EMPTY]} />
                                    <Form.TextInput col="col s5" label="Notes" name={`cust3Notes`} value={this.state.cust3Notes} autoComplete="off" onChange={this.textOnChange} noblurcap="true" flags={[flags.ALLOW_EMPTY]} />
                                </div>
                                <div className="row stickyNoteRow">
                                    <Form.CheckBox col="col s1" focusStyle="stagesCheckbox" name={`aesVerified`} value={this.state.aesVerified} autoComplete="off" onChange={this.checkBoxOnChange} flags={[flags.ALLOW_EMPTY]} />
                                    <span className="col s2">AES Verified</span>
                                    <div className="col s2 dateAndName">{this.state.aesVerifiedUser === null ? "-" : this.state.aesVerifiedName}</div>
                                    <div className="col s2 dateAndName">{this.state.aesVerifiedDate === null ? "-" : this.state.aesVerifiedDateFull}</div>
                                    <Form.TextInput col="col s5" label="Notes" name={`aesVerifiedNotes`} value={this.state.aesVerifiedNotes} autoComplete="off" onChange={this.textOnChange} noblurcap="true" flags={[flags.ALLOW_EMPTY]} />
                                </div>
                                <div className="row lastNotesRow">
                                    <div className="col s12">

                                        <div className="row">
                                            <Form.TextInput col={`col s${6}`} label="B/L" name={`blNote`} value={this.state.blNote} autoComplete="off" onChange={this.textOnChange} flags={[flags.ALLOW_EMPTY]} />
                                            <Form.TextInput col={`col s6`} disabled={this.state.phytoChecked ? false : true} label="Phyto" name={`phytoNote`} value={this.state.phytoNote} autoComplete="off" onChange={this.textOnChange} flags={[flags.ALLOW_EMPTY]} />
                                        </div>
                                        <div className="row stickyNoteRow">
                                            <Form.TextInput col={`col s${4}`} label="Fedex #1" name={`fedexNote`} value={this.state.fedexNote} autoComplete="off" onChange={this.textOnChange} flags={[flags.ALLOW_EMPTY]} />
                                            <Form.TextInput col={`col s${4}`} label="Fedex #2" name={`fedexNote2`} value={this.state.fedexNote2} autoComplete="off" onChange={this.textOnChange} flags={[flags.ALLOW_EMPTY]} />
                                            <Form.TextInput col={`col s${4}`} label="Fedex #3" name={`fedexNote3`} value={this.state.fedexNote3} autoComplete="off" onChange={this.textOnChange} flags={[flags.ALLOW_EMPTY]} />
                                        </div>
                                        <div className="row lastInnerNotesRow">
                                            <Form.TextInput col={`col s${4}`} label="CC" name={`containerCommissionNote`} value={this.state.containerCommissionNote} autoComplete="off" onChange={this.textOnChange} flags={[flags.ALLOW_EMPTY]} />
                                            <Form.TextInput col={`col s${4}`} disabled={this.state.bseChecked ? false : true} label="BSE" name={`bseNote`} value={this.state.bseNote} autoComplete="off" onChange={this.textOnChange} flags={[flags.ALLOW_EMPTY]} />
                                            <Form.TextInput col={`col s${4}`} disabled={this.state.marineInsuranceChecked ? false : true} label="Marine Ins." name={`marineInsuranceNote`} value={this.state.marineInsuranceNote} autoComplete="off" onChange={this.textOnChange} flags={[flags.ALLOW_EMPTY]} />
                                        </div>
                                    </div>
                                </div>
                                {/* <div className="row stickyNoteRow">
                                    <div className="col s3">
                                        SWB Req:
                                    </div>
                                    <div style={{ fontWeight: 700 }} className="col s3">
                                        {this.state.datetimeSwbReleaseRequested} {this.state.swbReleaseLastRequestedBy}
                                    </div>
                                    <div className="col s3">
                                        OBL Req:
                                    </div>
                                    <div style={{ fontWeight: 700 }} className="col s3">
                                        {this.state.datetimeOblReleaseRequested} {this.state.oblReleaseLastRequestedBy}
                                    </div>
                                </div> */}

                                <div className="row">
                                    <Form.Button col="col s4 offset-s4" label="Save Text" type="outline" color="blue" onClick={this.saveStagesText} icon="save" />
                                </div>
                            </div>
                            :
                            null
                        }
                    </div>

                </div>

            </div>
        )
    }
}


const mapStateToProps = state => {
    const { user, urls, index } = state;
    const url = urls[index];
    return { user, url }
}

export default connect(mapStateToProps)(StickyNote)
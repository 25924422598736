import React from 'react';
import Axios from 'axios';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import Form from '../../../Components/NewComponents';
import { urlActions } from '../../../Redux/actions';

class PasswordRecovery extends React.Component {
    constructor(props) { super(props); this.state = this.initState() }

    initState = () => ({
        emailAddress: '',
        msg: ''
    })

    onChange = (name, value) => this.setState({ [name]: value });

    submit = () => {

        if (!this.state.emailAddress) { return; }

        this.setState({ msg: "Sending Email . . ."});
        Axios.post('/api/v1/login/passwordrecovery', {
            email: this.state.emailAddress
        }).then(result => this.setState({ msg: result.data.msg }));

    }

    clear = () => {
        this.setState({ emailAddress: "", msg: "" })
    }

    goToLogin = () => {
        this.props.dispatch({ type: urlActions.UPDATE_URL, payload: '/' })
    }

    enterListener = (e) => {
        if (e.keyCode === 13 && document.activeElement.id === "emailAddress") {
            this.submit();
        }
    }

    componentDidMount = () => {
        document.addEventListener('keyup', this.enterListener);
    }

    componentWillUnmount = () => {
        document.removeEventListener('keyup', this.enterListener);
    }

    render = () => (
        <div>
            <img src="/images/lf_logo.bmp" style={{ height: '160px', width: 'auto' }} alt="Lindsey Forwarders Logo" />
            <div className="row">
                <h5 className="orange-text center">{this.props.logout && 'You have been logged out'}</h5>
            </div>
            <div className="row" style={{ marginTop: '10vh' }}>
                <div className="card col s8 offset-s2 l4 offset-l4">
                    <div className="card-content">
                        <h4 className="card-title" style={{ marginBottom: '18px', wordSpacing: "3px", letterSpacing: "5px", textAlign: 'center' }}>Password Recovery</h4>
                        <p className="col s8 offset-s2" style={{ marginBottom: '32px', textAlign: 'center' }}>Enter the email address you used to sign up and we'll send you an email to get you logged in.</p>
                        <div className="row">
                            <Form.TextInput col="s6 offset-s3" name="emailAddress" label="Email" value={this.state.emailAddress} onChange={this.onChange} />
                        </div>
                    </div>
                    <div className="row">
                        <p className="col s12 center" style={{ color: "green" }}>{this.state.msg}</p>
                    </div>
                    <div className="row">
                        <div className="col s4 offset-s4">
                            <Form.Button id="log-in" col="s3" color="blue" type="outline" label="Submit" onClick={this.submit} />
                            <Form.Button col="s3" color="red" type="outline" label="Clear" onClick={this.clear} />
                        </div>
                    </div>
                </div>
            </div>
            <Link to="/" style={{ display: 'flex', justifyContent: 'center' }} onClick={this.goToLogin}>Return to Login Page</Link>
        </div>

    )
}

export default connect()(PasswordRecovery);
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Form from "../../Components/NewComponents";
import axios from "axios";
import { globalToastActions } from "../../Redux/actions";
import { flags } from "../../constants";
import M from "materialize-css";
import moment from "moment-timezone";
import CustomerResultsDisplay from "../Customer/CustomerResultsDisplay";
import PageCountDisplay from "../Shipments/PageCountDisplay/PageCountDisplay";
import { auth } from "../../helperFunctions";
import { CSVLink } from "react-csv";

class CustomerOverview extends Component {
    state = {
        bookings: {},
        filters: {
            shipperName: '',
            supplierName: "",
            carrierName: '',
            originCode: '',
            destCode: '',
            bookingNumber: '',
            shipperRef: '',
            consigneeName: '',
            vesselName: '',
            voyageNumber: '',
            billOfLadingNumber: '',
            lNumber: '',
            isDummy: "ALL",
            flaggedForReview: false,
            dateType: 'CUT',
            startDate: '',
            endDate: '',
            returnLocation: "",
            pickupLocation: "",
            destCountry: "",
            currentSort: 'cargoCutDate',
            reverse: 1,
            reportFormat: "0",
            pageSize: 100,
            page: 0,
            count: [],
            resultsCount: null,
            showCount: false,
            searchOpen: false,
            loading: true,

        },
        csvHeaders: [
            { label: "Shipper", key: "shipperName" },
            { label: "Booking #", key: "bookingNumber" },
            { label: "Ref #", key: "shipperReferenceNumber" },
            { label: "Supplier", key: "supplierName" },
            { label: "Consignee", key: "consigneeName" },
            { label: "Carrier", key: "carrierName" },
            { label: "FCL", key: "containerCount" },
            { label: "Origin", key: "origin" },
            { label: "POL", key: "pol" },
            { label: "Dest", key: "dest" },
            { label: "Vessel", key: "vesselName" },
            { label: "Voyage", key: "voyageNumber" },
            { label: "ERD", key: "erd" },
            { label: "Cut", key: "cargoCutDate" },
            { label: "Doc", key: "docCutDate" },
            { label: "ETD", key: "etd" },
            { label: "ETA", key: "eta" },
            { label: "Pickup", key: "pickupLocationCode" },
            { label: "Return", key: "returnLocationCode" }
        ]
    }

    initListeners = () => {
        document.querySelectorAll('.search-field').forEach(elem => elem.addEventListener('keyup', this.searchListener));
        document.addEventListener('keydown', this.clearListener);
    }

    removeListeners = () => {
        document.querySelectorAll('.search-field').forEach(elem => elem.removeEventListener('keyup', this.searchListener));
        document.removeEventListener('keydown', this.clearListener);
    }

    clearListener = e => { if (e.keyCode === 27) this.clear(); }

    getDateTypes = () => ([{ label: "CUT" }, { label: "DOC" }, { label: "ETA" }, { label: "ETD" }]);


    getBookings = async () => {

        let partyId = await this.props.user.partyId === undefined ? this.props.user.party_id : this.props.user.partyId;
        // let partyRole = await this.props.party.filter(p => p.partyId === partyId)[0].role;

        axios.get(`/api/v1/customer/c/filter`, { params: { ...auth.getAuthData(), filters: this.state.filters, partyId } }).then((result) => {

            if (result.data === 'NOT AUTHENTICATED') {
                localStorage.clear();
                this.props.dispatch({ type: 'SCORCHED_EARTH' })
                return;
            }

            const bookings = result.data.result
            const filters = result.data.filters

            this.getBookingsCount();
            this.setState({
                bookings,
                filters: {
                    ...this.state.filters,
                    currentSort: filters.currentSort,
                    reverse: filters.reverse
                }
            })
            if (!bookings.length) {
                this.props.dispatch({ type: globalToastActions.UPDATE_MSG, payload: { msg: 'No Results', type: 'warning' } })
            }
        });
    }

    pageCheck = () => {
        if (this.props.url.state.filters) {
            if (this.props.url.state.filters.page && this.props.url.state.filters.page > 0) {
                this.setState({ filters: { ...this.state.filters, page: this.props.url.state.filters.page } })
            }
        }
    }

    setPage = (page) => {
        return new Promise((resolve, reject) => {
            if (page === undefined) {
                page = 0
            }
            if (page < 0) {
                return
            }
            if (this.state.filters.count.length < 1) {
                page = 0
            } else if (this.state.filters.count.length > 0) {
                if (page === this.state.filters.count.length) {
                    return
                }
            }
            this.setState({ filters: { ...this.state.filters, page: page } })
            resolve(page);
            reject(new Error('Promise Rejected'))
        }).catch(e => {
            console.log(e)
        })
    }


    getBookingsCount = async () => {

        let partyId = await this.props.user.partyId === undefined ? this.props.user.party_id : this.props.user.partyId;
        // let partyRole = await this.props.party.filter(p => p.partyId === partyId)[0].role;

        let result = (await axios.get('/api/v1/customer/c/count', { params: { ...auth.getAuthData(), filters: this.state.filters, partyId } })).data

        let enteredContainerResultsCount = result.result[0].enteredContainerResultsCount;
        let containerResultsCount = result.result[0].containerResultsCount;
        let showCount = result.showCount;
        let amount = result.result[0].count;
        let list = Math.ceil(amount / this.state.filters.pageSize);
        let count = [];
        for (list; list > 0; list--) {
            count.push(list)
        }
        this.setState({ filters: { ...this.state.filters, count: count.reverse(), resultsCount: amount, enteredContainerResultsCount, containerResultsCount, showCount: showCount } })
    }

    setToToday = () => {
        let today = moment(new Date()).format("MM/DD/YY");
        this.setState({ filters: { ...this.state.filters, startDate: today, endDate: today } }, this.getBookings);
    }

    setToThisWeek = () => {
        let startDate = moment().startOf('week').format("MM/DD/YY");
        let endDate = moment().startOf('week').add(6, "days").format("MM/DD/YY");
        this.setState({ filters: { ...this.state.filters, startDate, endDate } }, this.getBookings)
    }

    increaseWeek = () => {
        let startDate = this.state.filters.startDate;
        let endDate = this.state.filters.endDate;
        if (startDate) {
            startDate = moment(startDate, "MM/DD/YY").add(7, 'days').format("MM/DD/YY");
        }
        if (endDate) {
            endDate = moment(endDate, "MM/DD/YY").add(7, "days").format("MM/DD/YY");
        }
        this.setState({ filters: { ...this.state.filters, startDate, endDate } }, this.getBookings)
    }

    decreaseWeek = () => {
        let startDate = this.state.filters.startDate;
        let endDate = this.state.filters.endDate;
        if (startDate) {
            startDate = moment(startDate, "MM/DD/YY").subtract(7, 'days').format("MM/DD/YY");
        }
        if (endDate) {
            endDate = moment(endDate, "MM/DD/YY").subtract(7, "days").format("MM/DD/YY");
        }
        this.setState({ filters: { ...this.state.filters, startDate, endDate } }, this.getBookings)
    }

    singlePageTurn = async (e) => {
        let direction = e.target.getAttribute('name')
        let page = await this.state.filters.page;
        direction === 'back' ? page-- : page++;
        this.setPage(page).then(() => {
            this.getBookings();
        })
    }

    pageJump = (e) => {
        let choice = e.target.getAttribute("name");
        this.setPage((parseInt(choice) - 1)).then(() => {
            this.getBookings();
        })
    }

    searchListener = e => {
        if (e.keyCode === 13)
            this.freshSearch();
    }

    freshSearch = () => {
        this.setPage().then(() => {
            this.getBookings();
        })
    }

    openCol = () => {
        if (this.state.searchOpen) {
            this.setState({ searchOpen: false })
        } else {
            this.setState({ searchOpen: true })
        }
    }

    filterChange = (name, value) => {
        this.setState({ filters: { ...this.state.filters, [name]: value } });
    }

    filterChangePlus = (name, value) => {
        const { filters } = this.state;
        filters[name] = value;
        this.setState({ filters }, this.freshSearch);
    }

    formatChange = (name, value) => {
        let { filters } = this.state;
        filters[name] = value;
        switch (value) {
            case "DEFAULT":
                this.setState({
                    filters: {
                        ...this.state.filters,
                        carrierName: '',
                        originCode: '',
                        destCode: '',
                        bookingNumber: '',
                        shipperRef: '',
                        consigneeName: '',
                        vesselName: '',
                        voyageNumber: '',
                        isDummy: "ALL",
                        flaggedForReview: false,
                        dateType: 'CUT',
                        startDate: '',
                        endDate: ''
                    }
                })
                this.getBookings();
                break;
            case "DOC CUT TODAY":
                this.setState({
                    filters: {
                        ...this.state.filters,
                        carrierName: '',
                        originCode: '',
                        destCode: '',
                        bookingNumber: '',
                        shipperRef: '',
                        consigneeName: '',
                        vesselName: '',
                        voyageNumber: '',
                        isDummy: "ALL",
                        flaggedForReview: false,
                        dateType: 'DOC',
                        startDate: moment().format("MM/DD/YY"),
                        endDate: moment().format("MM/DD/YY")
                    }
                });
                this.getBookings();
                break;
            default:

        }
    }

    clear = () => this.setState({
        bookings: {},
        filters: {
            shipperName: '',
            supplierName: "",
            carrierName: '',
            originCode: '',
            destCode: '',
            bookingNumber: '',
            shipperRef: '',
            consigneeName: '',
            vesselName: '',
            voyageNumber: '',
            billOfLadingNumber: '',
            lNumber: '',
            isDummy: "ALL",
            flaggedForReview: false,
            dateType: 'CUT',
            startDate: '',
            endDate: '',
            returnLocation: "",
            pickupLocation: "",
            destCountry: "",
            currentSort: 'cargoCutDate',
            reverse: 1,
            reportFormat: "0",
            pageSize: 100,
            page: 0,
            count: [],
            resultsCount: null,
            showCount: false,
            searchOpen: false,
            loading: true,

        },
        csvHeaders: [
            { label: "Shipper", key: "shipperName" },
            { label: "Booking #", key: "bookingNumber" },
            { label: "Ref #", key: "shipperReferenceNumber" },
            { label: "Supplier", key: "supplierName" },
            { label: "Consignee", key: "consigneeName" },
            { label: "Carrier", key: "carrierName" },
            { label: "FCL", key: "containerCount" },
            { label: "Origin", key: "origin" },
            { label: "POL", key: "pol" },
            { label: "Dest", key: "dest" },
            { label: "Vessel", key: "vesselName" },
            { label: "Voyage", key: "voyageNumber" },
            { label: "ERD", key: "erd" },
            { label: "Cut", key: "cargoCutDate" },
            { label: "Doc", key: "docCutDate" },
            { label: "ETD", key: "etd" },
            { label: "ETA", key: "eta" },
            { label: "Pickup", key: "pickupLocationCode" },
            { label: "Return", key: "returnLocationCode" }
        ]
    }, this.freshSearch)

    refresh = () => { this.getBookings() }

    sort = e => {
        const value = typeof e === "string" ? this.props.url.state.filters.currentSort : e.target.getAttribute('data-sort');
        const reverse = this.state.filters.currentSort === value ? (-1 * this.state.filters.reverse) : 1;
        this.setState({ filters: { ...this.state.filters, currentSort: value, reverse } }, () => { this.getBookings(); })
    }

    componentDidMount = () => {
        this.freshSearch(); this.pageCheck(); this.initListeners(); M.Collapsible.init(document.querySelector('.collapsible'))
        if (this.props.url.state.filters) {
            if (this.props.url.state.filters.originCode || this.props.url.state.filters.destCode ||
                this.props.url.state.filters.consigneeName || this.props.url.state.filters.vesselName ||
                this.props.url.state.filters.voyageNumber || this.props.url.state.filters.billOfLadingNumber ||
                this.props.url.state.filters.startDate || this.props.url.state.filters.endDate || this.props.url.state.filters.flaggedForReview) {
                M.Collapsible.init(document.querySelector('.collapsible')).open();
            }
        }
    }

    componentWillUnmount = () => { this.removeListeners() }

    render() {
        return (
            <div>
                <div id="bkg-ov-search-fields">

                    <div className="row customMarginRemove">
                        <Form.TextInput col="col s2 m2 l2 xl2 search-field" name="pickupLocation" label="Pickup" onChange={this.filterChange} value={this.state.filters.pickupLocation} flags={[flags.ALLOW_EMPTY]} />
                        <Form.TextInput col="col s2 m2 l2 xl2 search-field" name="returnLocation" label="Return" onChange={this.filterChange} value={this.state.filters.returnLocation} flags={[flags.ALLOW_EMPTY]} />
                        <Form.TextInput col="col s2 m2 l2 xl2 search-field" name="destCountry" label="Dest Country" onChange={this.filterChange} value={this.state.filters.destCountry} flags={[flags.ALLOW_EMPTY]} />
                        <Form.TextInput col="col s2 m2 l2 xl2 search-field" name="supplierName" label="Supplier" onChange={this.filterChange} value={this.state.filters.supplierName} flags={[flags.ALLOW_EMPTY]} />
                        <Form.TextInput col="s4 search-field" name="shipperRef" label="Ref #" onChange={this.filterChange} value={this.state.filters.shipperRef} flags={[flags.ALLOW_EMPTY]} />
                    </div>

                    <div className="row customMarginRemove">
                        {/* <Form.TextInput col="col s2 m2 l2 xl2 search0field" name="shipperName" label="shipperName" onChange={this.filterChange} value={this.state.filters.shipperName} flags={[flags.ALLOW_EMPTY]} /> */}
                        <Form.TextInput col="col s2 m2 l2 xl2 search-field" name="consigneeName" label="Consignee" onChange={this.filterChange} value={this.state.filters.consigneeName} flags={[flags.ALLOW_EMPTY]} />
                        <Form.TextInput col="col s2 m2 l2 xl2 search-field" name="originCode" label="Origin" onChange={this.filterChange} value={this.state.filters.originCode} flags={[flags.ALLOW_EMPTY]} />
                        <Form.TextInput col="col s2 m2 l2 xl2 search-field" name="destCode" label="Destination" onChange={this.filterChange} value={this.state.filters.destCode} flags={[flags.ALLOW_EMPTY]} />
                        <Form.TextInput col="col s2 m2 l2 xl2 search-field" name="vesselName" label="Vessel" onChange={this.filterChange} value={this.state.filters.vesselName} flags={[flags.ALLOW_EMPTY]} />
                        <Form.TextInput col="col s2 m2 l2 xl2 search-field push" name="voyageNumber" label="Voyage" onChange={this.filterChange} value={this.state.filters.voyageNumber} flags={[flags.ALLOW_EMPTY]} />
                        <Form.Select col="s2 search-field" name="format" label="Search Format" onChange={this.formatChange} value={this.state.filters.format} list={[{ label: "DEFAULT" }, { label: "DOC CUT TODAY" }]} filter={{ label: "label", value: "label" }} />
                    </div>
                    <div className="row customMarginRemove">
                        <Form.TextInput col="s2 search-field" name="carrierName" label="Carrier" onChange={this.filterChange} value={this.state.filters.carrierName} flags={[flags.ALLOW_EMPTY]} />
                        <Form.TextInput col="s3 search-field" name="bookingNumber" label="Booking #" onChange={this.filterChange} value={this.state.filters.bookingNumber} flags={[flags.ALLOW_EMPTY]} />
                        <div className="col s1 m1 l1 xl1 row dateSearchButtonContainer">
                            <Form.Button col="s3 dateSearchButtons" icon="today" iconClass="tiny" tooltip="Today" type="outline" color="black" onClick={this.setToToday} />
                            <Form.Button col="s3 dateSearchButtons" icon="view_week" iconClass="tiny" tooltip="This Week" type="outline" color="black" onClick={this.setToThisWeek} />
                            <Form.Button col="s3 dateSearchButtonsArrowBack" icon="arrow_backward" iconClass="tiny" tooltip="1 Week Back" type="outline" color="black" onClick={this.decreaseWeek} />
                            <Form.Button col="s3 dateSearchButtons dateSearchButtonsRight" icon="arrow_forward" iconClass="tiny" tooltip="1 Week Forward" type="outline" color="black" onClick={this.increaseWeek} />
                        </div>
                        <Form.Select col="col s1 search-field" name="dateType" label="Date Type" onChange={this.filterChange} value={this.state.filters.dateType} list={this.getDateTypes()} filter={{ label: 'label', value: 'label' }} />
                        <Form.DateInput col="col s1 search-field" name="startDate" label="Start Date" onChange={this.filterChange} value={this.state.filters.startDate} flags={[flags.ALLOW_EMPTY]} />
                        <Form.DateInput col="col s1 search-field" name="endDate" label="End Date" onChange={this.filterChange} value={this.state.filters.endDate} flags={[flags.ALLOW_EMPTY]} />
                        <Form.Button col="col s1 searchButtons" color="blue" type="outline" label="Search" icon="search" onClick={this.freshSearch} />
                        <Form.Button col="col s1 searchButtons" color="red" type="outline" label="Clear" icon="clear" onClick={this.clear} />
                        <div>
                            {this.state.bookings.length ?
                                <div className="customerDelayedTooltip" tabIndex="-1">
                                    <span className="tooltiptext">Export As CSV</span>
                                    <CSVLink filename={`${moment(new Date()).format("MM/DD/YY_HH:mm")}_bookings_list.csv`} headers={this.state.csvHeaders} tabIndex="-1" data={this.state.bookings} >
                                        <Form.Button col="col s1" color="black" type="outline" label="Export" icon="file_download" tabIndex="-1" />
                                    </CSVLink>
                                </div>
                                : null}
                        </div>
                    </div>
                </div>
                <table className="lf-portal-table customer-portal-table">
                    <thead className={`lf-portal-thead`}>
                        <tr>
                            <th data-sort="shipperName" onClick={this.sort}> Shipper &nbsp;
                                {this.state.filters.currentSort === "shipperName" ? this.state.filters.reverse === 1 ? `⮝` : `⮟` : ""} <span /></th>
                            <th data-sort="bookingNumber" onClick={this.sort}> Booking # &nbsp;
                                {this.state.filters.currentSort === "bookingNumber" ? this.state.filters.reverse === 1 ? `⮝` : `⮟` : ""}<span /></th>
                            <th data-sort="shipperReferenceNumber" onClick={this.sort}> Ref # &nbsp;
                                {this.state.filters.currentSort === "shipperReferenceNumber" ? this.state.filters.reverse === 1 ? `⮝` : `⮟` : ""}<span /></th>
                            <th data-sort="supplierName" onClick={this.sort}> Supplier &nbsp;
                                {this.state.filters.currentSort === "supplierName" ? this.state.filters.reverse === 1 ? `⮝` : `⮟` : ""}<span /></th>
                            <th data-sort="consigneeName" onClick={this.sort}> Consignee &nbsp;
                                {this.state.filters.currentSort === "consigneeName" ? this.state.filters.reverse === 1 ? `⮝` : `⮟` : ""}<span /></th>
                            <th data-sort="carrierName" onClick={this.sort}> Carrier &nbsp;
                                {this.state.filters.currentSort === "carrierName" ? this.state.filters.reverse === 1 ? `⮝` : `⮟` : ""}<span /></th>
                            <th data-sort="containerCount" onClick={this.sort}> FCL &nbsp;
                                {this.state.filters.currentSort === "containerCount" ? this.state.filters.reverse === 1 ? `⮝` : `⮟` : ""}<span /></th>
                            <th data-sort="origin" onClick={this.sort}> Origin &nbsp;
                                {this.state.filters.currentSort === "origin" ? this.state.filters.reverse === 1 ? `⮝` : `⮟` : ""}<span /></th>
                            <th data-sort="dest" onClick={this.sort}> Dest &nbsp;
                                {this.state.filters.currentSort === "dest" ? this.state.filters.reverse === 1 ? `⮝` : `⮟` : ""}<span /></th>
                            <th data-sort="vesselName" onClick={this.sort}> Vessel &nbsp;
                                {this.state.filters.currentSort === "vesselName" ? this.state.filters.reverse === 1 ? `⮝` : `⮟` : ""}<span /></th>
                            <th data-sort="voyageNumber" onClick={this.sort}> Voyage &nbsp;
                                {this.state.filters.currentSort === "voyageNumber" ? this.state.filters.reverse === 1 ? `⮝` : `⮟` : ""}<span /></th>
                            <th data-sort="erd" onClick={this.sort}> ERD &nbsp;
                                {this.state.filters.currentSort === "erd" ? this.state.filters.reverse === 1 ? `⮝` : `⮟` : ""}<span /></th>
                            <th data-sort="cargoCutDate" onClick={this.sort}> Cut &nbsp;
                                {this.state.filters.currentSort === "cargoCutDate" ? this.state.filters.reverse === 1 ? `⮝` : `⮟` : ""}<span /></th>
                            <th data-sort="docCutDate" onClick={this.sort}> Doc &nbsp;
                                {this.state.filters.currentSort === "docCutDate" ? this.state.filters.reverse === 1 ? `⮝` : `⮟` : ""}<span /></th>
                            <th data-sort="etd" onClick={this.sort}> ETD &nbsp;
                                {this.state.filters.currentSort === "etd" ? this.state.filters.reverse === 1 ? `⮝` : `⮟` : ""}<span /></th>
                            <th data-sort="eta" onClick={this.sort}> ETA &nbsp;
                                {this.state.filters.currentSort === "eta" ? this.state.filters.reverse === 1 ? `⮝` : `⮟` : ""}<span /></th>
                            <th data-sort="pickupLoc" onClick={this.sort}> Pickup &nbsp;
                                {this.state.filters.currentSort === "pickupLoc" ? this.state.filters.reverse === 1 ? `⮝` : `⮟` : ""}<span /></th>
                            <th data-sort="returnLoc" onClick={this.sort}> Return &nbsp;
                                {this.state.filters.currentSort === "returnLoc" ? this.state.filters.reverse === 1 ? `⮝` : `⮟` : ""}<span /></th>
                        </tr>
                    </thead>
                    <tbody className={`lf-portal-tbody customer-portal-tbody`}>
                        <CustomerResultsDisplay
                            loading={this.state.loading}
                            bookings={this.state.bookings}
                            getBookings={this.getBookings}
                            filters={this.state.filters}
                            handleContextMenu={this.handleContextMenu}
                            handleStatusMenu={this.handleStatusMenu}
                            handleProofMenu={this.handleProofMenu}
                            handleDelete={this.handleDelete}
                            editBkg={this.state.editBkg}
                        />
                    </tbody>
                </table>
                <PageCountDisplay
                    url={this.props.url.url}
                    pageJump={this.pageJump}
                    singlePageTurn={this.singlePageTurn}
                    count={this.state.filters.count}
                    page={this.state.filters.page}
                    pageReset={this.props.url.state.origin ? true : false}
                />
                {this.state.filters.showCount ? <b className="containerResultsCount">Total Containers: {this.state.filters.containerResultsCount}</b> : <b></b>}
                {this.state.filters.showCount ? <b className="resultsCount">Total Results: {this.state.filters.resultsCount}</b> : <b></b>}
            </div>
        )
    }
}



const mapStateToProps = (state) => {
    const { urls, index, user } = state;
    const url = urls[index];
    return { url, user }
}

export default connect(mapStateToProps)(CustomerOverview);
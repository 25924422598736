import React, { Component } from "react";
import axios from "axios";
import Form from "../../Components/NewComponents";
import styles from "./party.module.css";
import "./party.css";
import { flags } from "../../constants";
import { connect } from "react-redux";
import M from "materialize-css";
import Card from "../../Components/NewComponents/Card";
import Confirm from "../../Components/Confirm/Confirm";
import { globalToastActions, urlActions, init } from "../../Redux/actions";
import ContactDisplay from "./ContactDisplay";
import { auth } from "../../helperFunctions";

class UpdateParty extends Component {
  state = {
    conDisabled: false,
    shipDisabled: false,
    id: undefined,
    name: "",
    code: "",
    main_phone: "",
    main_fax: "",
    email: "",
    website: "",
    role: "",
    useAlternateSubjectLine: false,
    useIndividualVoyage: false,
    companyCode: "",
    taxIdNumber: "",
    pointOfContact: "",
    party_id: "",
    addresses: [],
    newAddress: {
      id: "",
      pa_id: "",
      party_id: "",
      country: 1,
      addressLabel: "",
      address1: "",
      address2: "",
      city: "",
      state: "",
      zip: "",
      printable: ""
    },
    consignees: [],
    consigneeChoice: "",
    shippers: [],
    shipperChoice: "",
    clients: [],
    client: "",
    clientAddListSelection: "List of Connections",
    clientsAdded: [
      {
        shipperId: "No Current Connections",
        consigneeId: "No Current Connections"
      }
    ],
    contactName: "",
    contactEmail: "",
    contactRole: "BOOKINGS",
    contactDefault: 0,
    contactField: "TO",
    contactFieldOptions: [{ label: "TO" }, { label: "CC" }, { label: "BCC" }],
    contacts: [],
    employees: [],
    requestsEmployee: 0,
    bookingsEmployee: 0,
    submitSiEmployee: 0,
    proofEmployee: 0,
    closeoutEmployee: 0,
    invoicingEmployee: 0,
    bookKeepingEmployee: 0,
    contractsEmployee: 0,
    editIndex: undefined,
    editId: undefined,
    editName: "",
    editEmail: "",
    editDefault: 0,
    editField: 0,
    editRole: "TO"
  };

  clearNewAddForm = () => {
    this.setState({
      newAddress: {
        id: "",
        pa_id: "",
        party_id: "",
        country: 1,
        addressLabel: "",
        address1: "",
        address2: "",
        city: "",
        state: "",
        zip: "",
        printable: ""
      }
    });
  };

  onChange = (name, value) => {
    this.setState({
      [name]: value
    });
  };

  roleOnChange = (name, value) => {
    this.setState({
      [name]: value
    }, this.chooseList);
  };

  newAddOnChange = (name, value) => {
    const { newAddress } = this.state;
    newAddress[name] = value;
    this.setState({
      newAddress
    });
  };

  contactOnChange = (name, value) => {
    let tabChoice = value === "P/L REMINDERS" ? "PLREMINDERS" : value;
    this.setState({ [name]: value }, () => M.Tabs.getInstance(document.getElementById("tabs")).select(tabChoice))
  }

  changeTabFocus = (option) => {
    this.setState({ contactRole: option });
  }

  //Final submission function with validation that needs to be reworked
  submitUpdate = async () => {
    try {
      let partyBlock = {
        party: {
          id: this.state.id,
          name: this.state.name.trim(),
          code: this.state.code ? this.state.code.trim() : this.state.code,
          role: this.state.role,
          main_phone: this.state.main_phone,
          main_fax: this.state.main_fax,
          email: this.state.email,
          website: this.state.website,
          point_of_contact: this.state.pointOfContact,
          use_alternate_subject_line: this.state.useAlternateSubjectLine,
          use_individual_voyage: this.state.useIndividualVoyage,
          company_code: this.state.companyCode,
          tax_id_number: this.state.taxIdNumber,
          bookings: this.state.bookingsEmployee,
          submit_si: this.state.submitSiEmployee,
          proof: this.state.proofEmployee,
          closeout: this.state.closeoutEmployee,
          invoicing: this.state.invoicingEmployee,
          book_keeping: this.state.bookKeepingEmployee,
          contracts: this.state.contractsEmployee,
          requests: this.state.requestsEmployee
        }
      };

      if (!partyBlock.party.name) {
        this.props.dispatch({ type: "UPDATE_MSG", payload: { msg: "Missing Required Field(s)", type: "error" } });
      } else if (
        partyBlock.party.name.length > 120 && partyBlock.party.code.length > 20 &&
        partyBlock.party.main_phone.length > 20 && partyBlock.party.main_fax.length > 20 &&
        partyBlock.party.email.length > 60 && partyBlock.party.website.length > 60
      ) {
        this.props.dispatch({
          type: "UPDATE_MSG", payload: { msg: "Field(s) Too Long", type: "error" }
        });
      } else if (
        !partyBlock.party.code.length
      ) {
        this.props.dispatch({
          type: "UPDATE_MSG", payload: { msg: "Short Name Required", type: "error" }
        });
      } else {
        axios.post("/api/v1/party/update", { ...auth.getAuthData(), partyBlock })
          .then(result => {
            if (result.data === "NOT AUTHENTICATED") {
              localStorage.clear();
              this.props.dispatch({ type: "SCORCHED_EARTH" });
              return;
            }
            let answer = result.data;
            if (answer.fail) {
              this.props.dispatch({
                type: "UPDATE_MSG",
                payload: { msg: "Name Or Code Already In Use", type: "error" }
              });
            } else {
              this.props.dispatch({ type: "UPDATE_MSG", payload: { msg: "Party Updated", type: "success" } });
              this.props.dispatch({ type: "UPDATE_URL", payload: { url: "/party", state: {} } });
            }
          });
      }
    } catch (err) {
      console.log(err);
    }
  };

  initModal = () => M.Modal.init(document.querySelectorAll(".modal"));

  initTabs = () => {
    M.Tabs.init(document.getElementById("tabs"));
  }

  //Brings up edit modal with relavant information
  showEditModal = (index, id) => {
    M.Modal.getInstance(document.querySelector("#edit-address-modal")).open();
    const { newAddress } = this.state;
    newAddress.id = id;
    newAddress.addressLabel = this.state.addresses[index].addressLabel;
    newAddress.address1 = this.state.addresses[index].address1;
    newAddress.address2 = this.state.addresses[index].address2;
    newAddress.country = this.state.addresses[index].country;
    newAddress.city = this.state.addresses[index].city;
    newAddress.state = this.state.addresses[index].state;
    newAddress.zip = this.state.addresses[index].zip;
    newAddress.printable = this.state.addresses[index].printable;
    this.setState({
      newAddress
    });
  };

  //Determines available dropdowns based on party role
  chooseList = () => {
    if (this.state.role === "CONSIGNEE") {
      this.setState({
        conDisabled: true,
        shipDisabled: false
      });
    } else if (this.state.role === "SHIPPER") {
      this.setState({
        shipDisabled: true,
        conDisabled: false
      });
    } else if (this.state.role === "BOTH") {
      this.setState({
        conDisabled: false,
        shipDisabled: false
      });
    }
    this.grabConnections();
  };


  //Update query for editing specific address, again has reduntant function rewriting that
  //already exists and needs to be reworked
  saveEdit = () => {
    let update = this.state.newAddress;
    axios.post("/api/v1/party/update/address", { ...auth.getAuthData(), update }).then(result => {
      if (result.data === "NOT AUTHENTICATED") {
        localStorage.clear();
        this.props.dispatch({ type: "SCORCHED_EARTH" });
        return;
      } else if (result.data.fail) {
        this.props.dispatch({
          type: "UPDATE_MSG",
          payload: { msg: "Uknown Error", type: "warning" }
        });
      } else {
        let id = this.state.party_id;
        axios.get("/api/v1/party/edit", { params: { ...auth.getAuthData(), id } }).then(result => {
          if (result.data === "NOT AUTHENTICATED") {
            localStorage.clear();
            this.props.dispatch({ type: "SCORCHED_EARTH" });
            return;
          } else {
            this.setState({ addresses: result.data });
            this.hideEditModal();
            this.props.dispatch({
              type: "UPDATE_MSG",
              payload: { msg: "Address Updated", type: "success" }
            });
          }
        });
      }
    });
  };

  hideEditModal = () => {
    M.Modal.getInstance(document.querySelector("#edit-address-modal")).close();
    document.getElementById("updateButton").focus();
  }

  showAddModal = () => {
    this.clearNewAddForm();
    M.Modal.getInstance(document.querySelector("#add-address-modal")).open();
  };

  hideAddModal = () => {
    M.Modal.getInstance(document.querySelector("#add-address-modal")).close();
    document.getElementById("updateButton").focus();
  }

  openConfirmModal = index => {
    M.Modal.getInstance(document.querySelector(`#confirmModal${index}`)).open();
  };

  confirmRemove = index => {
    this.removeConnection(index);
    M.Modal.getInstance(
      document.querySelector(`#confirmModal${index}`)
    ).close();
  };

  initListener = () => {
    document.addEventListener("keydown", this.escListener);
    document.addEventListener("keydown", this.editAddressListener);
    document.addEventListener("keydown", this.addContactListener);
  };

  removeListener = () => {
    document.removeEventListener("keydown", this.escListener);
    document.removeEventListener("keydown", this.editAddressListener);
    document.removeEventListener("keydown", this.addContactListener);
  };

  editAddressListener = (e) => {
    if (e.keyCode === 13) {
      //This is where the listener logic for being able to hit enter on an address to select it would be
    }
  }

  escListener = async e => {
    if (e.keyCode === 27) {
      if (await this.checkForOpenModals()) {
        return;
      } else {
        this.props.dispatch({
          type: urlActions.UPDATE_URL,
          payload: { url: "/party", state: this.props.url.state }
        });
      }
    }
  };

  addContactListener = e => {
    if (e.keyCode === 13 && document.activeElement.id === "addIcon") {
      this.addContact();
    }
  }

  //Insert for the new address , needs to be reworked to imitate the creation page,
  //AS well as remove reduntant axios acalls that exist as other function already
  saveAddress = () => {
    let newAddress = {
      party_id: this.state.party_id,
      country_id: this.state.newAddress.country,
      address_label: this.state.newAddress.addressLabel,
      street_address_line_1: this.state.newAddress.address1,
      street_address_line_2: this.state.newAddress.address2,
      city: this.state.newAddress.city,
      state: this.state.newAddress.state,
      zip: this.state.newAddress.zip,
      printable: this.state.newAddress.printable
    };
    axios.post("/api/v1/party/newAdd", { ...auth.getAuthData(), newAddress }).then(result => {
      if (result.data === "NOT AUTHENTICATED") {
        localStorage.clear();
        this.props.dispatch({ type: "SCORCHED_EARTH" });
        return;
      } else if (result.data.fail) {
        this.props.dispatch({ type: "UPDATE_MSG", payload: { msg: "Error Occurred", type: "warning" } });
      } else {
        let id = this.state.party_id;
        axios.get("/api/v1/party/edit", { params: { ...auth.getAuthData(), id } }).then(result => {
          if (result.data === "NOT AUTHENTICATED") {
            localStorage.clear();
            this.props.dispatch({ type: "SCORCHED_EARTH" });
            return;
          } else {
            let newAddresses = [];
            for (let i = 0; i < result.data.length; i++) {
              newAddresses.push({
                pa_id: result.data[i].pa_id,
                country: result.data[i].country,
                addressLabel: result.data[i].addressLabel,
                address1: result.data[i].address1,
                address2: result.data[i].address2,
                city: result.data[i].city,
                state: result.data[i].state,
                zip: result.data[i].zip,
                printable: result.data[i].printable
              });
            }
            this.setState({ addresses: newAddresses });
            this.clearNewAddForm();
            this.hideAddModal();
          }
        });
      }
    });
  };

  //Uses the id to delete the specific address in party_address table
  deleteAddress = (index, id) => {
    axios.post("/api/v1/party/delete", { ...auth.getAuthData(), id }).then(result => {
      if (result.data === "NOT AUTHENTICATED") {
        localStorage.clear();
        this.props.dispatch({ type: "SCORCHED_EARTH" });
        return;
      } else if (result.fail) {
        this.props.dispatch({ type: "UPDATE_MSG", payload: { msg: "Something went wrong", type: "warning" } });
      } else {
        axios.get("/api/v1/party/edit", { params: { ...auth.getAuthData() } }).then(result => {
          if (result.data === "NOT AUTHENTICATED") {
            localStorage.clear();
            this.props.dispatch({ type: "SCORCHED_EARTH" });
            return;
          }
          let list = this.state.addresses.filter(address => {
            return address.pa_id !== id;
          });
          this.setState({ addresses: list });
        });
      }
    });
  };

  //Init function pulling in current part information based on the party_id
  //passed in by redux
  checkForEdit = () => {
    const id = this.props.url.state.id;
    this.setState({ party_id: id });
    axios.get("/api/v1/party/edit", { params: { ...auth.getAuthData(), id } }).then(result => {
      if (result.data === "NOT AUTHENTICATED") {
        localStorage.clear();
        this.props.dispatch({ type: "SCORCHED_EARTH" });
        return;
      }

      let newAddresses = this.state.addresses;
      let freshAddress = result.data[0].printable;
      if (freshAddress === null) {
        let freshAddress = "There are currenty no printable addresses attached to this party.";
        newAddresses.push(freshAddress);
        this.setState({
          ...result.data[0],
          addresses: newAddresses
        });
      } else {
        for (let i = 0; i < result.data.length; i++) {
          newAddresses.push({
            pa_id: result.data[i].pa_id,
            country: result.data[i].country,
            addressLabel: result.data[i].addressLabel,
            address1: result.data[i].address1,
            address2: result.data[i].address2,
            city: result.data[i].city,
            state: result.data[i].state,
            zip: result.data[i].zip,
            printable: result.data[i].printable
          });
        }
        this.setState({
          ...result.data[0],
          addresses: newAddresses
        });
        this.chooseList();
        this.getContacts();
      }
    });
  };

  checkForOpenModals = async () => {
    let modals = document.querySelectorAll('.modal');
    let openModal = false;
    await modals.forEach(modal => {
      if (M.Modal.getInstance(modal).isOpen) {
        M.Modal.getInstance(modal).close();
        return openModal = true;
      }
    });
    return openModal;
  }

  //Pulls data using the shipper_consignee and party pages to populate the arrays used for the
  //dropdowns and for clients, the array used to hold the ids, as the name is being used as the values
  //in the dropdowns and can not hold the id
  grabPartyRoles = () => {
    axios.get("/api/v1/party/relations", { params: { ...auth.getAuthData() } }).then(result => {
      if (result.data === "NOT AUTHENTICATED") {
        localStorage.clear();
        this.props.dispatch({ type: "SCORCHED_EARTH" });
        return;
      } else {
        let shippers = this.state.shippers;
        let consignees = this.state.consignees;
        let clients = this.state.clients;
        for (let i = 0; i < result.data.length; i++) {
          if (result.data[i].role === "SHIPPER") {
            shippers.push(result.data[i]);
            clients.push(result.data[i]);
          } else if (result.data[i].role === "CONSIGNEE") {
            consignees.push(result.data[i]);
            clients.push(result.data[i]);
          } else if (result.data[i].role === "BOTH") {
            shippers.push(result.data[i]);
            consignees.push(result.data[i]);
            clients.push(result.data[i]);
          }
        }
        this.setState({
          shippers,
          consignees,
          clients,
          consigneeChoice: consignees[0].name,
          shipperChoice: shippers[0].name
        });
      }
    });
  };

  //Default refresh and init call pulling all connections related to
  //the current party
  grabConnections = () => {
    let chunk = {
      id: this.props.url.state.id,
      role: this.state.role
    };
    axios.get("/api/v1/party/connections", { params: { ...auth.getAuthData(), chunk } }).then(result => {
      if (result.data === "NOT AUTHENTICATED") {
        localStorage.clear();
        this.props.dispatch({
          type: "SCORCHED_EARTH"
        });
        return;
      }
      this.setState({ clientsAdded: result.data });
    });
  };

  //Using the data defined by which button is clicked, passes the
  //information given by the dropdown in different orders, sending an axios
  //call to the shipper_consignee table
  addConnection = (role, partyName) => {
    if (role === 1 && !this.state.conDisabled) {
      let index = this.state.clients.map(i => {
        return i.name;
      }).indexOf(partyName);
      let ids = {
        shipperId: this.state.party_id,
        consigneeId: this.state.clients[index].id
      };

      axios.post("/api/v1/party/add/connection", { ...auth.getAuthData(), ids }).then(result => {
        if (result.data.msg === "ER_DUP_ENTRY") {
          this.props.dispatch({
            type: globalToastActions.UPDATE_MSG,
            payload: {
              msg: "Duplicate Connections",
              type: "error"
            }
          });
        } else if (result.data === "NOT AUTHENTICATED") {
          localStorage.clear();
          this.props.dispatch({ type: "SCORCHED_EARTH" });
          return;
        } else {
          this.grabConnections();
          this.props.dispatch({
            type: globalToastActions.UPDATE_MSG,
            payload: {
              msg: "Connection Added",
              type: "success"
            }
          });

        }
      })
    } else if (role === 2 && !this.state.shipDisabled) {
      let index = this.state.clients.map(i => {
        return i.name;
      }).indexOf(partyName);
      let ids = {
        shipperId: this.state.clients[index].id,
        consigneeId: this.state.party_id
      };

      axios.post("/api/v1/party/add/connection", { ...auth.getAuthData(), ids }).then(result => {
        if (result.data.msg === "ER_DUP_ENTRY") {
          this.props.dispatch({
            type: globalToastActions.UPDATE_MSG,
            payload: {
              msg: "Duplicate Connections",
              type: "error"
            }
          });
        } else if (result.data === "NOT AUTHENTICATED") {
          localStorage.clear();
          this.props.dispatch({ type: "SCORCHED_EARTH" });
          return;
        } else {
          this.grabConnections();
          this.props.dispatch({
            type: globalToastActions.UPDATE_MSG,
            payload: {
              msg: "Connection Added",
              type: "success"
            }
          });
        }
      });
    }
  };

  getContacts = () => {
    let partyId = this.props.url.state.id;
    axios.get("/api/v1/contacts/all", { params: { ...auth.getAuthData(), partyId } }).then(result => {
      this.setState({ contacts: result.data })
    })
  }

  addContact = (flag) => {

    let edit = flag === "edit" ? true : false;
    let emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    let contacts = this.state.contacts;
    let contact = edit ?
      {
        id: this.state.editId,
        partyId: this.state.id,
        name: this.state.editName.trim(),
        email: (this.state.editEmail.trim()).toLowerCase(),
        defaultEmail: parseInt(this.state.editDefault) ? true : false,
        field: this.state.editField === "-" && this.state.editDefault ? "TO" : this.state.editField,
        role: this.state.contactRole
      } : {
        partyId: this.state.id,
        name: this.state.contactName.trim(),
        email: (this.state.contactEmail.trim()).toLowerCase(),
        defaultEmail: parseInt(this.state.contactDefault) ? true : false,
        field: this.state.contactField,
        role: this.state.contactRole
      }

    if (!contact.email) {
      this.props.dispatch({
        type: globalToastActions.UPDATE_MSG, payload: { type: "error", msg: "Email Field Is Required" }
      });
      return;
    };
    if (!contact.name) {
      this.props.dispatch({
        type: globalToastActions.UPDATE_MSG, payload: { type: "error", msg: "Name Field Is Required" }
      });
      return;
    }
    if (!emailRegex.test(contact.email)) {
      this.props.dispatch({
        type: globalToastActions.UPDATE_MSG, payload: { type: "error", msg: "Not A Valid Email" }
      });
      return;
    }
    if (contacts.filter(c => c.email === contact.email && c.role === contact.role).length && !edit) {
      this.props.dispatch({
        type: globalToastActions.UPDATE_MSG, payload: { type: "error", msg: "Duplicate Contact" }
      });
      return;
    };

    M.Tabs.getInstance(document.getElementById("tabs")).select(this.state.contactRole)

    axios.post("/api/v1/contacts/add", { ...auth.getAuthData(), contact }).then(result => {
      if (result.data === "NOT AUTHENTICATED") {
        localStorage.clear();
        this.props.dispatch({ type: "SCORCHED_EARTH" });
        return
      }
      if (edit) {
        this.setState({
          editIndex: undefined,
          editId: undefined,
          editName: "",
          editEmail: "",
          editDefault: false,
          editRole: 0,
          editField: "TO"
        });
        this.props.dispatch({
          type: globalToastActions.UPDATE_MSG, payload: { type: "success", msg: "Contact Updated" }
        });
      } else {
        this.props.dispatch({
          type: globalToastActions.UPDATE_MSG, payload: { type: "success", msg: "Contact Added" }
        });
      }
      this.getContacts();
      document.getElementById("contactName").focus();
    })
  }

  removeConfirm = () => {
    return new Promise((resolve, reject) => {
      if (window.confirm("Are you sure you would like to remove this contact?")) {
        resolve(true)
      } else {
        resolve(false)
      }
    })
  }

  editContact = async (id, index, role) => {
    let contact = this.state.contacts.filter(c => c.id === id)[0]
    this.setState({
      editIndex: index,
      editId: id,
      editName: contact.name,
      editEmail: contact.email,
      editDefault: contact.defaultEmail,
      editField: contact.field,
      editRole: role
    }, () => {
    })
  }

  removeContact = async (id) => {
    let confirmRemove;
    confirmRemove = await this.removeConfirm();
    if (confirmRemove) {
      axios.post("/api/v1/contacts/delete", { ...auth.getAuthData(), id }).then((result) => {
        if (result.data === "NOT AUTHENTICATED") {
          localStorage.clear();
          this.props.dispatch({ type: "SCORCHED_EARTH" });
          return;
        } else if (result.data.errno) {
          this.props.dispatch({ type: globalToastActions.UPDATE_MSG, payload: { msg: "Error Deleting Contact", type: "error" } });
        } else {
          this.props.dispatch({ type: globalToastActions.UPDATE_MSG, payload: { msg: "Contact Removed", type: "success" } });
          this.getContacts();
        }
      })
    }
  }

  //Uses the index mapped from the array in an axios call, removing the connection
  removeConnection = index => {
    let ids = {
      shipperId: this.state.clientsAdded[index].id,
      consigneeId: this.state.clientsAdded[index].id2
    };

    axios.post("/api/v1/party/remove/connection", { ...auth.getAuthData(), ids }).then(result => {
      if (result.data === "NOT AUTHENTICATED") {
        localStorage.clear();
        this.props.dispatch({ type: "SCORCHED_EARTH" });
        return;
      }
      this.grabConnections();
      this.props.dispatch({
        type: globalToastActions.UPDATE_MSG,
        payload: {
          msg: "Connection Removed",
          type: "success"
        }
      });
    });
  };

  componentDidMount = () => {
    document.querySelector("#name").focus();
    this.initListener();
    this.checkForEdit();
    this.initModal();
    this.initTabs();
    this.grabPartyRoles();
  };


  componentWillUnmount = () => {
    this.removeListener();
    axios.get("/api/v1/party/populated/all", { params: auth.getAuthData() }).then(result => {
      if (result.data === "NOT AUTHENTICATED") {
        localStorage.clear();
        this.props.dispatch({ type: "SCORCHED_EARTH" });
        return;
      } else if (Array.isArray(result.data)) {
        this.props.dispatch({
          type: init.INITIALIZE_STORE,
          payload: { listName: "party", list: result.data }
        });
      }
    });

    axios.get("/api/v1/redux/addresses", { params: auth.getAuthData() }).then(result => {
      if (result.data === "NOT AUTHENTICATED") {
        localStorage.clear();
        this.props.dispatch({ type: "SCORCHED_EARTH" });
        return;
      } else if (Array.isArray(result.data)) {
        this.props.dispatch({
          type: init.INITIALIZE_STORE,
          payload: { listName: "partyAddress", list: result.data }
        });
      };
    })


    if (this.state.role === "SHIPPER") {
      axios.get("/api/v1/redux/shippers", { params: auth.getAuthData() }).then(result => {
        if (result.data === "NOT AUTHENTICATED") {
          localStorage.clear();
          this.props.dispatch({ type: "SCORCHED_EARTH" });
          return;
        } else if (Array.isArray(result.data)) {
          this.props.dispatch({
            type: init.INITIALIZE_STORE,
            payload: { listName: "shipper", list: result.data }
          });
        };
      })
    } else if (this.state.role === "CONSIGNEE") {

      axios.get("/api/v1/redux/consignees", { params: auth.getAuthData() }).then(result => {
        if (result.data === "NOT AUTHENTICATED") {
          localStorage.clear();
          this.props.dispatch({ type: "SCORCHED_EARTH" });
          return;
        } else if (Array.isArray(result.data)) {
          this.props.dispatch({
            type: init.INITIALIZE_STORE,
            payload: { listName: "consignee", list: result.data }
          });
        };
      })
    } else if (this.state.role === "BOTH") {
      axios.get("/api/v1/redux/shippers", { params: auth.getAuthData() }).then(result => {
        if (result.data === "NOT AUTHENTICATED") {
          localStorage.clear();
          this.props.dispatch({ type: "SCORCHED_EARTH" });
          return;
        } else if (Array.isArray(result.data)) {
          this.props.dispatch({
            type: init.INITIALIZE_STORE,
            payload: { listName: "shipper", list: result.data }
          });
        };
      });
      axios.get("/api/v1/redux/consignees", { params: auth.getAuthData() }).then(result => {
        if (result.data === "NOT AUTHENTICATED") {
          localStorage.clear();
          this.props.dispatch({ type: "SCORCHED_EARTH" });
          return;
        } else if (Array.isArray(result.data)) {
          this.props.dispatch({
            type: init.INITIALIZE_STORE,
            payload: { listName: "consignee", list: result.data }
          });
        };
      });
    }


  };

  render() {
    return (
      <div>
        <Form.Section label="Party Profile">
          <div className="row">
            <Form.TextInput id="name" name="name" label="Name" value={this.state.name} onChange={this.onChange} col="s6" flags={[flags.MAX_LENGTH + 120]} />
            <Form.TextInput name="code" label="Short Name" value={this.state.code} onChange={this.onChange} col="s3" maxLength="20" flags={[flags.MAX_LENGTH + 20, flags.ALLOW_EMPTY]} />
            <Form.Select name="role" label="Role" value={this.state.role} onChange={this.roleOnChange} onClick={this.chooseList} col="s3" list={[{ name: "SHIPPER" }, { name: "CONSIGNEE" }, { name: "BOTH" }]} filter={{ label: "name", value: "name" }} />
          </div>
          <div className="row">
            <Form.TextInput name="main_phone" label="Main Phone" value={this.state.main_phone} onChange={this.onChange} col="s2" flags={[flags.MAX_LENGTH + 20, flags.ALLOW_EMPTY]} />
            <Form.TextInput name="main_fax" label="Main Fax" value={this.state.main_fax} onChange={this.onChange} col="s2" flags={[flags.MAX_LENGTH + 20, flags.ALLOW_EMPTY]} />
            <Form.TextInput name="email" label="Main Email" onChange={this.onChange} value={this.state.email} col="s4" flags={[flags.MAX_LENGTH + 60, flags.ALLOW_EMPTY]} />
            <Form.TextInput name="website" label="Website" onChange={this.onChange} value={this.state.website} col="s4" flags={[flags.MAX_LENGTH + 60, flags.ALLOW_EMPTY]} />
            <p className={styles.notes} />
          </div>
          <div className="row">
            <Form.CheckBox focusStyle="useAlternateSubjectLine" name="useAlternateSubjectLine" label="Use Alt Subject Line" value={this.state.useAlternateSubjectLine} onChange={this.onChange} col="s2" flags={[flags.ALLOW_EMPTY]} />
            <Form.CheckBox focusStyle="useAlternateSubjectLine" name="useIndividualVoyage" label="Use Individual Voyage" value={this.state.useIndividualVoyage} onChange={this.onChange} col="s2" flags={[flags.ALLOW_EMPTY]} />
            <Form.TextInput name="pointOfContact" label="Main Point Of Contact" value={this.state.pointOfContact} onChange={this.onChange} col="s3" flags={[flags.ALLOW_EMPTY]} />
            <Form.TextInput name="companyCode" label="Foreign Company Code" value={this.state.companyCode} onChange={this.onChange} col="s3" flags={[flags.ALLOW_EMPTY]} />
            <Form.TextInput name="taxIdNumber" label="U.S. Tax ID Number" value={this.state.taxIdNumber} onChange={this.onChange} col="s2" flags={[flags.ALLOW_EMPTY]} />
          </div>
          <div className="row">
            <Form.Select name="requestsEmployee" label="Requests" value={this.state.requestsEmployee} onChange={this.onChange} col="s3" list={this.props.portalUser} filter={{ label: "fullName", value: "id" }} placeholder=" " />
            <Form.Select name="bookingsEmployee" label="Bookings" value={this.state.bookingsEmployee} onChange={this.onChange} col="s3" list={this.props.portalUser} filter={{ label: "fullName", value: "id" }} placeholder=" " />
            <Form.Select name="submitSiEmployee" label="Submit SI" value={this.state.submitSiEmployee} onChange={this.onChange} col="s3" list={this.props.portalUser} filter={{ label: "fullName", value: "id" }} placeholder=" " />
            <Form.Select name="proofEmployee" label="Proof" value={this.state.proofEmployee} onChange={this.onChange} col="s3 offset-s9" list={this.props.portalUser} filter={{ label: "fullName", value: "id" }} placeholder=" " />
          </div>
          <div className="row">
            <Form.Select name="closeoutEmployee" label="Closeout" value={this.state.closeoutEmployee} onChange={this.onChange} col="s3" list={this.props.portalUser} filter={{ label: "fullName", value: "id" }} placeholder=" " />
            {/* <Form.Select name="invoicingEmployee" label="Invoicing" value={this.state.invoicingEmployee} onChange={this.onChange} col="s3" list={this.props.portalUser} filter={{ label: "fullName", value: "id" }} placeholder=" " /> */}
            {/* <Form.Select name="bookKeepingEmployee" label="Book Keeping" value={this.state.bookKeepingEmployee} onChange={this.onChange} col="s3" list={this.props.portalUser} filter={{ label: "fullName", value: "id" }} placeholder=" " /> */}
            {/* <Form.Select name="contractsEmployee" label="Contracts" value={this.state.contractsEmployee} onChange={this.onChange} col="s3" list={this.props.portalUser} filter={{ label: "fullName", value: "id" }} placeholder=" " /> */}
          </div>
        </Form.Section>
        <Form.Section label="Contacts">
          <div className="row">
            <Form.TextInput col="col s3" label="Name" onChange={this.onChange} value={this.state.contactName} name="contactName" maxLength="30" flags={[flags.ALLOW_EMPTY]} />
            <Form.TextInput col="col s3" label="Email" onChange={this.onChange} value={this.state.contactEmail} name="contactEmail" maxLength="50" flags={[flags.ALLOW_EMPTY]} />
            <Form.Select col={parseInt(this.state.contactDefault) ? 'col s1' : 'col s2'} label='Default' onChange={this.onChange} value={this.state.contactDefault} name='contactDefault' list={[{ label: 'YES', value: 1 }, { label: "NO", value: 0 }]} filter={{ label: 'label', value: 'value' }} />

            {parseInt(this.state.contactDefault) ?
              <Form.Select col="col s1" label="Field" onChange={this.onChange} value={this.state.contactField} name="contactField" list={this.state.contactFieldOptions} filter={{ label: "label", value: "label" }} />
              :
              null}
            <Form.Select col="col s3" label="Assigned To" onChange={this.contactOnChange} value={this.state.contactRole} name="contactRole" list={[{ label: "BOOKINGS" }, { label: "P/L REMINDERS" }, { label: "DOCS" }, { label: "INVOICES" }]} filter={{ label: "label", value: "label" }} />
            <span tabIndex="0" id="addIcon" onClick={this.addContact}><i className="material-icons">add</i></span>
          </div>

          <div className="row" id="contactsRow">
            <div className="col s12">
              <ul className={`tabs`} id="tabs">
                <li className={`tab col s2`}><a tabIndex="-1" href="#BOOKINGS" onClick={() => this.changeTabFocus('BOOKINGS')}>Bookings</a></li>
                <li className={'tab col s2'}><a tabIndex="-1" href="#PLREMINDERS" onClick={() => this.changeTabFocus('P/L REMINDERS')}>P/L Reminders</a></li>
                <li className="tab col s2 disabled"><a tabIndex="-1" href="#PROOFS">Proofs</a></li>
                <li className="tab col s2"><a tabIndex="-1" href="#DOCS" onClick={() => this.changeTabFocus('DOCS')}>Docs</a></li>
                <li className="tab col s2"><a tabIndex="-1" href="#INVOICES" onClick={() => this.changeTabFocus('INVOICES')}>Invoices</a></li>
                <li className="tab col s2 disabled"><a tabIndex="-1" href="#CONTRACTS">Contracts</a></li>
              </ul>
            </div>
            <div id="BOOKINGS" className="col s12">
              <ContactDisplay
                onChange={this.onChange}
                contacts={this.state.contacts}
                sectionType="BOOKINGS"
                placeholderText={"Bookings"}
                editId={this.state.editId}
                editIndex={this.state.editIndex}
                editName={this.state.editName}
                editEmail={this.state.editEmail}
                editDefault={this.state.editDefault}
                editField={this.state.editField}
                contactFieldOptions={this.state.contactFieldOptions}
                saveContact={this.addContact}
                editContact={this.editContact}
                removeContact={this.removeContact}
              />
            </div>

            <div id="PLREMINDERS" className="col s12">
              <ContactDisplay
                onChange={this.onChange}
                contacts={this.state.contacts}
                sectionType="P/L REMINDERS"
                placeholderText={"P/L Reminder"}
                editId={this.state.editId}
                editIndex={this.state.editIndex}
                editName={this.state.editName}
                editEmail={this.state.editEmail}
                editDefault={this.state.editDefault}
                editField={this.state.editField}
                contactFieldOptions={this.state.contactFieldOptions}
                saveContact={this.addContact}
                editContact={this.editContact}
                removeContact={this.removeContact}
              />
            </div>
            <div id="PROOFS" className="col s12">
              <ContactDisplay
                onChange={this.onChange}
                contacts={this.state.contacts}
                sectionType="PROOFS"
                placeholderText={"Proofs"}
                editId={this.state.editId}
                editIndex={this.state.editIndex}
                editName={this.state.editName}
                editEmail={this.state.editEmail}
                editDefault={this.state.editDefault}
                editField={this.state.editField}
                contactFieldOptions={this.state.contactFieldOptions}
                saveContact={this.addContact}
                editContact={this.editContact}
                removeContact={this.removeContact}
              />
            </div>
            <div id="DOCS" className="col s12">
              <ContactDisplay
                onChange={this.onChange}
                contacts={this.state.contacts}
                sectionType="DOCS"
                placeholderText={"Docs"}
                editId={this.state.editId}
                editIndex={this.state.editIndex}
                editName={this.state.editName}
                editEmail={this.state.editEmail}
                editDefault={this.state.editDefault}
                editField={this.state.editField}
                contactFieldOptions={this.state.contactFieldOptions}
                saveContact={this.addContact}
                editContact={this.editContact}
                removeContact={this.removeContact}
              />
            </div>
            <div id="INVOICES" className="col s12">
              <ContactDisplay
                onChange={this.onChange}
                contacts={this.state.contacts}
                sectionType="INVOICES"
                placeholderText={"Invoices"}
                editId={this.state.editId}
                editIndex={this.state.editIndex}
                editName={this.state.editName}
                editEmail={this.state.editEmail}
                editDefault={this.state.editDefault}
                editField={this.state.editField}
                contactFieldOptions={this.state.contactFieldOptions}
                saveContact={this.addContact}
                editContact={this.editContact}
                removeContact={this.removeContact}
              />
            </div>
            <div id="CONTRACTS" className="col s12">
              <ContactDisplay
                onChange={this.onChange}
                contacts={this.state.contacts}
                sectionType="CONTRACTS"
                placeholderText={"Contracts"}
                editId={this.state.editId}
                editIndex={this.state.editIndex}
                editName={this.state.editName}
                editEmail={this.state.editEmail}
                editDefault={this.state.editDefault}
                editField={this.state.editField}
                contactFieldOptions={this.state.contactFieldOptions}
                saveContact={this.addContact}
                editContact={this.editContact}
                removeContact={this.removeContact}
              />
            </div>
          </div>
        </Form.Section>
        <div className="row">
          <Form.Section col="s6 addConnection" label="Add Connection" style={{ "minHeight": "155px", "maxHeight": "175px" }}>
            <table className="centered addConnectionTable" style={{ "maxHeight": "200px" }}>
              <thead>
                <tr>
                  <td></td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <h5 className="currentParty">{this.state.name}</h5>
                  </td>
                  <td>
                    <Form.Select name="consigneeChoice" label="Available Consignees" value={this.state.consigneeChoice} onChange={this.onChange} list={this.state.consignees} filter={{ label: "name", value: "name" }} disabled={this.state.conDisabled} />
                  </td>
                  <td>
                    <div className="s1">
                      <i
                        className="material-icons right"
                        style={{ borderRadius: 40 + "px", color: "white", backgroundColor: "green", marginTop: 5 + "px" }} onClick={() => this.addConnection(1, this.state.consigneeChoice)}> add</i>
                    </div>
                  </td>
                </tr>
                <tr className="bottomRow">
                  <td>
                    <Form.Select label="Available Shippers" name="shipperChoice" value={this.state.shipperChoice} onChange={this.onChange} list={this.state.shippers} filter={{ label: "name", value: "name" }} disabled={this.state.shipDisabled} />
                  </td>
                  <td>
                    <div className="centered s5 offset-s5" style={{ textAlign: "center" }}>
                      <h5 className="currentParty">{this.state.name}</h5>
                    </div>
                  </td>
                  <td>
                    <div className="s1">
                      <i className="material-icons right" style={{
                        borderRadius: 40 + "px",
                        color: "white",
                        backgroundColor: "green",
                        marginTop: 5 + "px"
                      }} onClick={() => this.addConnection(2, this.state.shipperChoice)}>add</i>{" "}
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </Form.Section>

          <Form.Section col="s6 clientConnections" label="Client Connections" style={{ "maxHeight": "175px", "minHeight": "175px", "overflow": "auto" }}>
            <table className="centered">
              <thead>
                <tr style={{ backgroundColor: "#f3f3f3" }}>
                  <th>Shipper</th>
                  <th>Consignee</th>
                  <th>
                    <span></span>
                  </th>
                </tr>
              </thead>
              <tbody>
                {this.state.clientsAdded.map((client, index) => (
                  <tr className="connection" key={index}>
                    <td>{client.name}</td>
                    <td>{client.name2}</td>
                    <td>
                      <span> <i onClick={() => this.openConfirmModal(index)} className="material-icons connection"  > cancel</i></span>
                      <Confirm id={`confirmModal${index}`} confirm={this.confirmRemove} index={index}
                        msg="Are you sure you would like to delete this connection?" body="All future references to this connection may be affected." />
                    </td>
                  </tr>))}
              </tbody>
            </table>
          </Form.Section>
        </div>

        <div className="row">

          <Form.Section label="Addresses" col="">
            <div className="row" style={{ "paddingLeft": "50px" }}>
              <Card addresses={this.state.addresses} className="cardStyle" onChange={this.onChange} col="s2" color="green" showEditModal={this.showEditModal} deleteAddress={this.deleteAddress} />
              <Form.Button id="addButton" className="button" address="address" col="s2 right" onClick={this.showAddModal} color="green" type="outline" label="New Address" icon="add" />
            </div>
          </Form.Section>



        </div>
        <div className="row">
          <Form.Button id="updateButton" col="s2" className={styles.button} type="outline" onClick={this.submitUpdate} label="Update" icon="sync" color="orange" />
        </div>
        {/* //EDITING ADDRESS MODAL */}

        <div className="modal white" id="edit-address-modal">
          <div className="modal-content">
            <div className="row">
              <div className="col s10">
                <h5>Edit Address</h5>
              </div>
              <div className="col s2">
                <i className="material-icons right" style={{ color: "grey" }} onClick={this.hideEditModal} > close </i>
              </div>
            </div>
            <div className="row">
              <Form.TextInput col="s12" name="address1" label="Address" onChange={this.newAddOnChange} value={this.state.newAddress.address1} flags={[flags.ALLOW_EMPTY]} />
            </div>
            <div className="row">
              <Form.TextInput col="s12" name="address2" label="Address Line 2" onChange={this.newAddOnChange} value={this.state.newAddress.address2} flags={[flags.ALLOW_EMPTY]} />
            </div>
            <div className="row">
              <Form.Select col="s5" name="country" label="Country" onChange={this.newAddOnChange} value={this.state.newAddress.country} list={this.props.country} filter={{ label: "name", value: "id" }} />
              <Form.TextInput col="s3" name="city" label="City" onChange={this.newAddOnChange} value={this.state.newAddress.city} flags={[flags.ALLOW_EMPTY]} />
              <Form.TextInput col="s2" name="state" label="State" onChange={this.newAddOnChange} value={this.state.newAddress.state} flags={[flags.ALLOW_EMPTY]} />
              <Form.TextInput col="s2" name="zip" label="Zip" onChange={this.newAddOnChange} value={this.state.newAddress.zip} flags={[flags.ALLOW_EMPTY]} />
            </div>

            <div className="row">
              <Form.TextInput name="addressLabel" label="Address Label" value={this.state.newAddress.addressLabel} onChange={this.newAddOnChange} maxLength="40" flags={[flags.ALLOW_EMPTY]} />
            </div>
            <div className="row">
              <Form.TextArea name="printable" label="Printable Address" value={this.state.newAddress.printable} onChange={this.newAddOnChange} className={`${styles.textArea}`} flags={[flags.ALLOW_EMPTY]} />
            </div>
          </div>
          <div className={`row modal-action ${styles.modalaction}`}>
            <Form.Button col="s2" label="Save" type="outline" round color="blue" icon="save" onClick={this.saveEdit} />
          </div>
        </div>

        {/* ///ADDING NEW ADDRESS MODAL */}

        <div className="modal white" id="add-address-modal">
          <div className="modal-content">
            <div className="row">
              <div className="col s10">
                <h5>New Address</h5>
              </div>
              <div className="col s2">
                <i className="material-icons right" style={{ color: "grey" }} onClick={this.hideAddModal} >close</i>
              </div>
            </div>
            <div className="row">
              <Form.TextInput col="s12" name="address1" label="Address" onChange={this.newAddOnChange} value={this.state.newAddress.address1} flags={[flags.ALLOW_EMPTY]} />
            </div>
            <div className="row">
              <Form.TextInput col="s12" name="address2" label="Address Line 2" onChange={this.newAddOnChange} value={this.state.newAddress.address2} flags={[flags.ALLOW_EMPTY]} />
            </div>
            <div className="row">
              <Form.Select col="s5" name="country" label="Country" onChange={this.newAddOnChange} value={this.state.newAddress.country} list={this.props.country} filter={{ label: "name", value: "id" }} />
              <Form.TextInput col="s3" name="city" label="City" onChange={this.newAddOnChange} value={this.state.newAddress.city} flags={[flags.ALLOW_EMPTY]} />
              <Form.TextInput col="s2" name="state" label="State" onChange={this.newAddOnChange} value={this.state.newAddress.state} flags={[flags.ALLOW_EMPTY]} />
              <Form.TextInput col="s2" name="zip" label="Zip" onChange={this.newAddOnChange} value={this.state.newAddress.zip} flags={[flags.ALLOW_EMPTY]} />
            </div>

            <div className="row">
              <Form.TextInput name="addressLabel" label="Address Label" value={this.state.newAddress.addressLabel} onChange={this.newAddOnChange} maxLength="40" flags={[flags.ALLOW_EMPTY]} />
            </div>
            <div className="row">
              <Form.TextArea name="printable" label="Printable Address" value={this.state.newAddress.printable} onChange={this.newAddOnChange} className={`${styles.textArea}`} flags={[flags.ALLOW_EMPTY]} />
            </div>
          </div>
          <div className={`row modal-action ${styles.modalaction}`}>
            <Form.Button col="s2" label="Save" type="outline" round color="blue" icon="save" onClick={this.saveAddress} />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { country, urls, index, portalUser } = state;
  const url = urls[index];
  return { country, url, index, portalUser };
};

export default connect(mapStateToProps)(UpdateParty);

import React from 'react';
import Axios from 'axios';
import Confirm from '../../../Components/Confirm/Confirm';
import OverviewTable from '../../../Components/OverviewTable/OverviewTable';

class AllianceOverview extends React.Component {
    constructor(props) {
        super(props);
        this.state = this.initState();
    }

    initState = () => ({ alliances: null, showModal: false, alliance: null });
    getAlliances = () => Axios.get('/api/v1/alliance').then(result => this.setState({ alliances: result.data }));
    refresh = () => this.getAlliances();


    componentDidMount = () => this.refresh();

    showModal = alliance => this.setState({ showModal: true, alliance });
    handleDelete = () => Axios.post('/api/v1/alliance/archive', { id: this.state.alliance.id }).then(result => { this.setState({ showModal: false }); this.refresh() });
    handleEdit = alliance => this.props.history.replace('/carrier/alliance/create', { alliance, editMode: true });

    render = () => (
        <div>
            <h3 className="center blue-text text-darken-4">Alliances</h3>

            <OverviewTable
                fields={[{ header: 'Alliance', prop: 'name' }]}
                rows={this.state.alliances}
                showModal={this.showModal}
                handleEdit={this.handleEdit}
            />
            <Confirm
                id="alliance-confirm-modal"
                show={this.state.showModal}
                msg={`Are you sure you want to delete ${this.state.alliance ? this.state.alliance.name : null}`}
                confirm={this.handleDelete}
            />
        </div>
    )
}

export default AllianceOverview;
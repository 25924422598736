import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Switch, Route } from 'react-router-dom';
import ContractOverview from './ContractOverview';
import ContractEdit from './ContractEdit/ContractEdit.js';
class Contracts extends Component {

    getPath = () => ('/contract');

    render = () => (
        <div>
            <Switch>
                <Route exact path={this.getPath()} component={ContractOverview} />
                <Route exact path={this.getPath() + '/edit'} component={ContractEdit} />
            </Switch>
        </div>
    )
}


export default connect()(Contracts)
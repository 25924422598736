import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from "react-router-dom";
import axios from 'axios';
import { auth } from '../../helperFunctions';
import Form from '../../Components/NewComponents';
import moment from 'moment';
import { globalToastActions } from '../../Redux/actions';
import styles from "./Reports.module.css";

const ProofAndCloseoutReport = (props) => {

    const [filters, setValues] = useState({
        user: 0,
        startDate: moment(new Date()).subtract(30, "day").format("MM/DD/YY"),
        endDate: moment(new Date()).add(30, "day").format("MM/DD/YY"),
        dummyStatus: "ALL",
        currentSort: "user",
        reverse: 1
    });
    const [reportItems, setReportItems] = useState([]);

    const filterOnChange = (name, value) => {
        setValues({ ...filters, [name]: value })
    }

    useEffect(() => {
        document.querySelectorAll(".search-field").forEach(el => { el.addEventListener("keydown", searchListener) })
        return () => {
            document.querySelectorAll(".search-field").forEach(el => { el.removeEventListener("keydown", searchListener) })
        }
    }, []);

    useEffect(() => {
        getProofAndCloseoutCount(filters);
    }, [filters.user, filters.dummyStatus, filters.currentSort, filters.reverse])

    const searchListener = (e) => {
        if (e.key === "Enter") {
            e.preventDefault();
            document.getElementById("searchButton").click();
        }
    }

    const getProofAndCloseoutCount = (filters) => {
        axios.get("/api/v1/report/proofandcloseout", { params: { ...auth.getAuthData(), filters } }).then(result => {
            if (result === "NOT AUTHENTICATED") {
                localStorage.clear();
                return this.props.dispach({ type: "SCORCHED_EARTH" });
            } else if (result.data.errno) {
                setReportItems([]);
                return props.dispatch({ type: globalToastActions.UPDATE_MSG, payload: { msg: `Error: ${result.data.code}`, type: "error" } })
            } else {
                if (!result.data.length) {
                    props.dispatch({ type: globalToastActions.UPDATE_MSG, payload: { msg: "No Results" } });
                }
                setReportItems(result.data)
            }
        })
    }

    const sort = (sortName) => {
        if (!sortName) {
            return;
        } else {
            const reverse = filters.currentSort === sortName ? (-1 * filters.reverse) : 1;
            setValues({ ...filters, currentSort: sortName, reverse })
        }
    }

    return (
        <div>
            <div className="row" style={{ marginTop: "15px" }}>
                <Form.Select col="col s4" label="User" placeholder="ALL" name="user" value={filters.user} onChange={filterOnChange} list={props.portalUser} filter={{ label: "fullName", value: "id" }} />
                <Form.DateInput col="col s3 search-field" label="ETD Start" name="startDate" value={filters.startDate} onChange={filterOnChange} />
                <Form.DateInput col="col s3 search-field" label="ETD End" name="endDate" value={filters.endDate} onChange={filterOnChange} />
                <Form.Button id="searchButton" col="col s2" type="outline" color="green" label="Search" onClick={() => getProofAndCloseoutCount(filters)} />
            </div>
            <div className="row">
                <table className={styles.reportTable}>
                    <thead>
                        <tr>
                            <th onClick={() => sort('user')}>User&nbsp;{filters.currentSort === "user" ? filters.reverse === 1 ? `⮝` : `⮟` : ""}</th>
                            <th onClick={() => sort('proof')}># of Proofed&nbsp;{filters.currentSort === "proof" ? filters.reverse === 1 ? `⮝` : `⮟` : ""}</th>
                            <th onClick={() => sort('closeout')}># of Closed Out&nbsp;{filters.currentSort === "closeout" ? filters.reverse === 1 ? `⮝` : `⮟` : ""}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {reportItems.map((item, index) => (
                            item.user ? <tr key={index} >
                                <td>{item.user}</td>
                                <td>{!item.proofCount && !item.proofTotal ? "N/A" : `${item.proofCount ? item.proofCount : 0}/${item.proofTotal ? item.proofTotal : 0}`}</td>
                                <td>{!item.closeoutCount && !item.closeoutTotal ? "N/A" : `${item.closeoutCount ? item.closeoutCount : 0}/${item.closeoutTotal ? item.closeoutTotal : 0}`}</td>
                            </tr> : null
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
}

const mapStateToProps = state => {
    const { urls, index, user, portalUser } = state;
    const url = urls[index];
    return { url, user, portalUser }
}

export default connect(mapStateToProps)(withRouter(ProofAndCloseoutReport));
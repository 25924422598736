import React, { useEffect, useLayoutEffect } from 'react';

const SideBarToggle = props => {
    const {
        hideSidebar,
        toggleSidebar
    } = props;

    const toggleListener = e => {
        const { ctrlKey, key } = e;

        if (ctrlKey) {
            if (key === '`' || key === 'e') {
                e.preventDefault();
                toggleSidebar();
            }
        }
    }
    
    useEffect(() => {
        const initListeners = () => {
            document.addEventListener('keydown', toggleListener)
        }

        initListeners();
    }, [])

    useLayoutEffect(() => {
        const removeListeners = () => {
            document.removeEventListener('keydown', toggleListener)
        }

        return removeListeners();
    }, [])

    return (
        <button
            className="lf-sidebar-toggle btn-floating waves-effect waves-light"
            onClick={toggleSidebar}
        >
            <i className="material-icons">{`keyboard_arrow_${hideSidebar ? 'right' : 'left'}`}</i>
        </button>
    )
}

export default SideBarToggle;
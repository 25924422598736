import React from 'react';
import { connect } from 'react-redux';
import M from 'materialize-css';
import styles from './globaltoast.module.css';
import { globalToastActions } from "../../Redux/actions";

class GlobalToast extends React.Component {

    prepareHTML = globalToast => {
        const msg =  `<h6><i class="tiny material-icons"></i>${globalToast.msg}</h6>`;
        this.showToast(globalToast, msg);
        this.props.dispatch({type: globalToastActions.UPDATE_MSG, payload: {}});
    }

    showToast = (globalToast, msg) => M.toast({
        html: msg,
        classes: `${styles.toast} ${globalToast.type ? styles[globalToast.type] : ''}`,
        displayLength: globalToast.displayLength || 1750
    })
    
    componentDidUpdate= (prevProps) => {
        if(Object.keys(this.props.globalToast).length){this.prepareHTML(this.props.globalToast)}}
            
    render = () => (
        <div display="none" />
    )
}

const mapStateToProps = state => {
    const { globalToast } = state;
    return { globalToast };
}

export default connect(mapStateToProps)(GlobalToast);